import React, { useEffect, useReducer, useState } from "react";
import GTable from "../design/components/GTable";
import AddIcon from "@mui/icons-material/Add";
import Button from "../design/Button";
import GModal from "../design/components/GModal";
import { MenuItem, Select } from "@mui/material";
import { JService } from "../api-service/ApiService";
import { Box } from "@mui/system";
import userEditIcon from "../../assets/call-logs-edit.svg";
import { useDispatch, useSelector } from "react-redux";
import { setVirtualNumber } from "../features/virtual-number/virtualNumber";
import { topNavAccountSelectedAccountTickIcon } from "../../utility-functions/assetLoader";
import commonStyles from "../design/commonStyles";
import { enqueueSnackbar } from 'notistack';

export default function OutgoingTab({ originalSettings, setOriginalSettings }) {
  const API_BASE_URL = process.env.REACT_APP_BETA;
  const accountDetails = useSelector((state) => state.account.value);
  const dispatch = useDispatch();

  const [rows, setRows] = useState([]);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [addUserOptions, setAddUserOptions] = useState([]);
  const [userToAdd, setUserToAdd] = useState("");
  const [outboundUserIds, setOutboundUserIds] = useState([]);

  const [rowBeingEdited, setRowBeingEdited] = useState(null);
  const [rowOptionsCords, setRowOptionsCords] = useState([0, 0]);
  const [showRemoveUserPrompt, setShowRemoveUserPrompt] = useState(false);

  const [csvData, setCSVData] = useState({
    headers: [["User"], ["Role"], ["Team"]],
    data: [],
    ids: [],
  });

  const initialState = {
    roles: {
      all: [
        { id: "Admin", name: "Admin" },
        { id: "Super Admin", name: "Super Admin" },
        { id: "User", name: "User" },
      ],
      selected: [],
    },
    teams: {
      all: originalSettings?.all_teams?.map((team) => ({
        id: team.id,
        name: team.name,
      })),
      selected: [],
    },
    // currentSet: "",
    searchText: "",
    searchFields: [],
  };

  const [availableData, dispatchAvailableData] = useReducer(
    filterReducer,
    initialState
  );

  useEffect(() => {
    setRows(originalSettings.outbound_users);

    const _outboundUserIds = originalSettings.outbound_users.map(
      (user) => user.id
    );
    setAddUserOptions(
      originalSettings.all_users.filter(
        (user) => !_outboundUserIds.includes(user.id)
      )
    );
    setOutboundUserIds(_outboundUserIds);
  }, [originalSettings]);

  // Use Effect for CSV download
  useEffect(() => {
    setCSVData((prevState) => {
      let newIds = [];
      let newData = [];

      for (let i = 0; i < rows.length; i++) {
        let item = rows[i];
        if (!newIds?.includes(item?.id)) {
          newIds.push(item?.id);
          let temp = [
            item.first_name + " " + item.last_name + " (" + item.email + ")",
            item.role,
            item.teams
              .map((team) => {
                return team.name;
              })
              .join(", "),
          ];
          newData.push(temp);
        }
      }
      return { ...prevState, data: newData, ids: newIds };
    });
  }, [rows]);

  // UseEffect for Search
  useEffect(() => {
    onSearch(availableData?.searchText);
  }, [availableData]);

  // UseEffect to hide three dots menu on scroll
  useEffect(() => {
    const handleScrollWhenRowOptionsOpen = (e) => {
      setRowBeingEdited(null);

      window.removeEventListener("scroll", handleScrollWhenRowOptionsOpen);
    };
    if (rowBeingEdited) {
      window.addEventListener("scroll", handleScrollWhenRowOptionsOpen);
    }

    return () => {
      window.removeEventListener("scroll", handleScrollWhenRowOptionsOpen);
    };
  }, [rowBeingEdited]);

  function filterReducer(state, action) {
    switch (action.type) {
      case "setPayloadToAllOfType": {
        if (action.payload?.payloadFor === "teams")
          return {
            ...state,
            teams: {
              ...state?.teams,
              all: action.payload?.payload,
            },
          };
        if (action.payload?.payloadFor === "roles")
          return {
            ...state,
            roles: {
              ...state?.roles,
              all: action.payload?.payload,
            },
          };
      }
      case "handleSelectedOfType": {
        if (action.payload?.payloadFor === "teams") {
          const a = action.payload?.payload; // will be single element
          const b = state?.teams?.selected;
          let temp = [];
          if (b?.includes(a)) temp = b?.filter((bb) => bb !== a);
          else temp = [...b, a];

          return {
            ...state,
            teams: { ...state?.teams, selected: temp },
          };
        }
        if (action.payload?.payloadFor === "roles") {
          const a = action.payload?.payload; // will be primitive value
          const b = state?.roles?.selected;
          let temp = [];
          if (b?.includes(a))
            temp = b?.filter((bb) => bb !== a); // eslint-disable-line
          else temp = [...b, a];

          return {
            ...state,
            roles: { ...state?.roles, selected: temp },
          };
        }
      }
      // clicking x icon on filter
      case "handleClearOfType": {
        if (action.payload?.payloadFor === "teams") {
          return {
            ...state,
            teams: { ...state?.teams, selected: [] },
          };
        }
        if (action.payload?.payloadFor === "roles") {
          return {
            ...state,
            roles: { ...state?.roles, selected: [] },
          };
        }
      }
      case "setSearchText": {
        if (typeof action.payload !== "string") return state;
        return {
          ...state,
          searchText: action.payload,
        };
      }

      case "clearAllFilters": {
        return initialState;
      }
      default:
        return state;
    }
  }

  const onSearch = (searchText) => {
    const searchTextLower = searchText?.toLowerCase();
    const temp = originalSettings.outbound_users?.filter(
      (row) =>
        (row?.first_name + " " + row?.last_name)
          .toLowerCase()
          .includes(searchTextLower) ||
        row?.email.toLowerCase().includes(searchTextLower)
    );
    combineSearchAndFilters(temp);
  };

  const combineSearchAndFilters = (arg1) => {
    // if filters and search is empty
    if (
      !availableData?.teams?.selected?.length &&
      !availableData?.roles?.selected?.length &&
      availableData?.searchText?.trim()?.length === 0
    ) {
      if (arg1 != rows) {
        setRows(arg1); //eslint-disable-line
      }
      return;
    }

    // teams selected changed
    if (availableData?.teams?.selected?.length) {
      arg1 = arg1?.filter(
        (mr) =>
          mr?.teams
            ?.map((ut) => ut?.id)
            ?.findIndex((t) => availableData?.teams?.selected?.includes(t)) >= 0
      );
    }

    // roles selected changes
    if (availableData?.roles?.selected?.length) {
      arg1 = arg1?.filter((te) =>
        availableData?.roles?.selected?.includes(te?.role)
      );
    }

    setRows(arg1);
  };

  const refreshSelfVirtualNumbers = () => {
    JService.get(API_BASE_URL + "/api/v1/auth/retrieve-virtual-numbers")
      .then((response) => {
        if (response?.success) {
          dispatch(setVirtualNumber(response.data));
          console.log("refreshed users virtual numbers");
        }
      })
      .catch((error) => {
        console.error(error.response);
      });
  };

  const updateOutboundUsers = (userId, type) => {
    let url, updatedValue, payload;

    // Handle type of operation (add/remove)
    if (type === "add") updatedValue = outboundUserIds.concat([userId]);
    else if (type === "remove")
      updatedValue = outboundUserIds.filter((uid) => uid !== userId);

    // Set url based on type of Number (Calling/Voice Broadcast)
    if (originalSettings.type === "Calling") {
      url = `/api/v1/auth/virtual-numbers/calling/${originalSettings.id}/`;
      payload = {
        outbound_user_ids: updatedValue,
      };
    } else if (originalSettings.type === "Voice Broadcast") {
      url = `/api/v1/auth/virtual-numbers/voice-broadcast/${originalSettings.id}/`;
      payload = {
        user_ids: updatedValue,
      };
    } else return;

    JService.patch(url, payload)
      .then((res) => {
        if(!res?.success) throw new Error(res?.message || "Error white updating virtual number") 

		setOriginalSettings(res.data);
		dispatchAvailableData({ type: "clearAllFilters", payload: null });
		
		// refresh virtual numbers -> if user being updated is the logged in user
		if (accountDetails?.data?.profile?.user === userId) refreshSelfVirtualNumbers();
        enqueueSnackbar(res?.message, { variant: "success"})
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error"}))
      .finally(() => {
        setShowRemoveUserPrompt(false);
        setShowAddUserModal(false);
        setUserToAdd("");
      });
  };

  const cols = [
    {
      field: "userName",
      headerName: "User",
      headerClassName: "user-table-header-user-name",
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{ marginLeft: "14px"}}>
            <p className="t6 regular-font nc-gray-900" style={{ margin: 0,fontSize:'14px'  }}>
              {params.row.first_name + " " + params.row.last_name}
            </p>
            <p className="t6 regular-font nc-gray-600" style={{ margin: 0,fontSize:'14px'  }}>
              {params.row.email}
            </p>
          </div>
        );
      },
    },
    {
      field: "role",
      headerName: "Role",
      flex: 0.8,
      renderCell: (params) => {
        return <p className="t6 regular-font nc-gray-900" style={{fontSize:'14px' }}>{params.row.role}</p>;
      },
    },
    {
      field: "team",
      headerName: "Team",
      flex: 1.2,
      renderCell: (params) => {
        return (
          <div>
            <p className="t6 regular-font nc-gray-900" style={{ margin: 0,fontSize:'14px'  }}>
              {params.row.teams
                .map((team) => {
                  return team.name;
                })
                .join(", ")}
            </p>
          </div>
        );
      },
    },
    {
      field: "outboundUsersEdit",
      headerName: "",
      flex: 0.5,
      cellClassName: "table-cell-edit", // @important use this threedots
      renderCell: (params) => {
        return (
          <div>
            <Box>
              <img
                onClick={(e) => {
                  setRowOptionsCords([e["clientX"], e["clientY"]]);
                  e.stopPropagation();
                  setRowBeingEdited(params.row);
                }}
                src={userEditIcon}
                alt="three dots icon"
              />
            </Box>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div style={localStyles.container}>
        <p className="t5 medium-font nc-gray-900">
          {originalSettings.type === "Calling"
            ? "Outgoing calls only"
            : originalSettings.type === "Voice Broadcast"
            ? "Outgoing calls (Voice message only)"
            : ""}
        </p>
        <Button
          variant="filled"
          buttonText="Add user"
          icon={{ startIcon: <AddIcon /> }}
          disabled={false}
          isLoading={false}
          hierarchy="green"
          onClick={() => setShowAddUserModal(true)}
        />
      </div>

      <GTable
        leftHeader={{
          filters: [
            { name: "Team", toggle: false },
            { name: "Role", toggle: false },
          ],
        }}
        rightHeader={{ isSearch: true, isRefresh: true, isDownload: true }}
        download={{
          data: csvData,
          body: null,
          filename:
            `${originalSettings.name || ""}-outgoing-users` +
            new Date().toLocaleString("en-US", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            }),
        }}
        availableData={availableData}
        dispatchAvailableData={dispatchAvailableData}
        fromScreen="virtual-number-outgoing"
        onSearch={onSearch}
        rows={rows}
        columns={cols}
        useMuiTable={true}
        isLoading={false}
        getTableRowClassName={() => `teams-table-row`}
        additionalProps={{ bgHover: true }}
        onRowClick={() => {}}
      />

      {!!rowBeingEdited && (
        <div
          className="row-options-backdrop"
          onClick={() => {
            setRowBeingEdited(null);
          }}
        >
          <div style={{ position: "relative" }}>
            <div
              className="row-options-foreground"
              style={{
                marginLeft: rowOptionsCords[0],
                marginTop: rowOptionsCords[1],
              }}
            >
              <button
                style={{ textAlign: "left" }}
                className="t6 nc-gray-900"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowRemoveUserPrompt(true);
                }}
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      )}

      <GModal
        modalStyle={localStyles.addUserModalStyle}
        closeModal={() => setShowAddUserModal(false)}
        heading={"Add user"}
        visible={showAddUserModal}
        bottomButtonsStyle={{borderTop:'none', paddingTop:'0'}}
        bottomButtons={[
          <Button
            variant="outlined"
            buttonText="Cancel"
            icon={{}}
            disabled={false}
            isLoading={false}
            hierarchy="white"
            onClick={() => setShowAddUserModal(false)}
          />,
          <Button
            variant="filled"
            buttonText={"Add"}
            icon={{}}
            isLoading={false}
            hierarchy="green"
            disabled={false}
            onClick={() => updateOutboundUsers(userToAdd, "add")}
          />,
        ]}
        body={
          <form className="new-contact-list-form add-contact-form virtual-numbers-form">
            <label className="t7 medium-font" style={{ marginTop: "24px" }}>
              User
              <Select
                value={userToAdd}
                onChange={(e) => setUserToAdd(e.target.value)}
                sx={localStyles.userSelect}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                renderValue={(selected) => {
                  if (userToAdd == "") return "";

                  const selectedUserData = originalSettings?.all_users.filter(
                    (user) => user.id === userToAdd
                  )[0];

                  return (
                    (selectedUserData?.first_name +
                    selectedUserData?.last_name)?.trim() ?
                    selectedUserData?.first_name + " " + selectedUserData?.last_name 
                    : selectedUserData?.email
                  );
                }}
              >
                {addUserOptions.map((user) => (
                  <MenuItem
                    sx={localStyles.userMenuItem}
                    value={user.id}
                    key={user.id}
                  >
                  <div>
                  {(user.first_name+user.last_name).trim() && <p className="t7 regular-font nc-gray-900" style={localStyles.pNoOverflow}>{user.first_name + " " + user.last_name}</p>}
                  <p className="t7 regular-font nc-gray-600" style={localStyles.pNoOverflow}>{user.email}</p>
                  </div>
                    {user.id === userToAdd &&
                      topNavAccountSelectedAccountTickIcon()}
                  </MenuItem>
                ))}
              </Select>
            </label>
          </form>
        }
      />

      <GModal
        modalStyle={localStyles.removeUserModalStyle}
        closeModal={() => setShowRemoveUserPrompt(false)}
        heading={"Remove user"}
        visible={showRemoveUserPrompt}
        bottomButtons={[
          <Button
            variant="filled"
            buttonText="Cancel"
            icon={{}}
            disabled={false}
            isLoading={false}
            hierarchy="white"
            onClick={() => setShowRemoveUserPrompt(false)}
          />,
          <Button
            variant="filled"
            buttonText={"Remove"}
            icon={{}}
            isLoading={false}
            hierarchy="red"
            disabled={false}
            onClick={() => updateOutboundUsers(rowBeingEdited.id, "remove")}
          />,
        ]}
        body={
          <div className="t6 regular-font">
            Are you sure you want to remove&nbsp;
            {!!rowBeingEdited && (
              <p
                style={{
                  display: "inline",
                  textDecoration: "underline",
                  fontWeight: "500",
                }}
              >
                {rowBeingEdited.first_name + " " + rowBeingEdited.last_name}
              </p>
            )}
            &nbsp;from outgoing users of&nbsp;
            <p style={localStyles.number}>
              {originalSettings.number}
            </p>
            &nbsp; ?
          </div>
        }
      />
    </>
  );
}

const localStyles = {
	container: {
		margin: "64px 0 32px 0",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
	addUserModalStyle: {
		width: "448px",
		height: "128px",
		paddingTop: "8px",
    marginBottom:'0'
	},
	
	userSelect: {
		...commonStyles.select,
		width: "400px",
		marginTop: "4px",
	},
	userMenuItem: {
		"&.MuiMenuItem-root.Mui-selected": {
			background: "#F0FCF4",
		},
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		width: "100%",
	},

	removeUserModalStyle: { 
		width: "448px",
		height: "128px",
	},

	number: {
		display: "inline",
		textDecoration: "underline",
		fontWeight: "500",
	},

  pNoOverflow: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: '360px',
      margin: 0,
  }
}
