import React, { useEffect, useState } from "react";
import Button from "../../design/Button";
import GModal from "../../design/components/GModal";
import baseFileIcon from "../../../assets/base file icon.svg";
import { formatFileSize } from "../../../utility-functions";
import CloseIcon from "@mui/icons-material/Close";
import { JService } from "../../api-service/ApiService";
import { DatePicker, DateRangePicker } from "rsuite";
import styles from "../../design/styles";
import { Box } from "@mui/system";
import { MenuItem, Select } from "@mui/material";
import colors from "../../design/colors";
import { useSelector } from "react-redux";
import { enqueueSnackbar } from 'notistack';

const { before, after, combine } = DateRangePicker;

const TodaysDate = new Date();

const PickerDates = {
  date: `${TodaysDate.getFullYear()}-${(
    "0" +
    (TodaysDate.getMonth() + 1)
  ).slice(-2)}-${("0" + TodaysDate.getDate()).slice(-2)}`,
  time: `${("0" + TodaysDate.getHours()).slice(-2)}:${(
    "0" + TodaysDate.getMinutes()
  ).slice(-2)}`,
};

const CALL_AFTER = "09:30";
const CALL_BEFORE = "20:30";

export default function VoiceBroadcastModal({
  visible,
  hideModal,
  selectedRows,
  type, // type can be 'NEW' or 'RESEND' or 'EDIT-BEFORE' Or 'EDIT-AFTER'
  campaignId,
  triggerReload,
  defaultName,
  defaultDate,
  defaultTime,
  clearSelection,
}) {
  const [campaignName, setCampaignName] = useState(
    !!defaultName ? defaultName : ""
  );
  const [audioFile, setAudioFile] = useState(null);
  const [date, setDate] = useState(PickerDates?.date || null);
  const [time, setTime] = useState(PickerDates?.time || null);
  const [formUpdateListener, setFormUpdateListener] = useState(0);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [retryAttempt, setRetryAttempt] = useState(1);
  const [retryInterval, setRetryInterval] = useState("1:00");
  const [retryRanges, setRetryRanges] = useState([CALL_AFTER, CALL_BEFORE]);

  useEffect(() => {
    let selectedTime = new Date(date + " " + time)?.getTime();
    let interval = retryInterval;
    interval = interval.split(":");
    interval = parseInt(interval[0]) + (interval[1][0] === "3" ? 0.5 : 0);
    let finalCallTime =
      selectedTime + (retryAttempt - 1) * interval * 60 * 60 * 1000;
    if (type === "NEW") {
      if (
        campaignName != "" &&
        date != "" &&
        time != "" &&
        audioFile != null &&
        (retryAttempt > 1 ? retryInterval != "0:00" : true) &&
        selectedTime > new Date()?.getTime() &&
        selectedTime >= new Date(date + " " + CALL_AFTER)?.getTime() &&
        selectedTime <= new Date(date + " " + CALL_BEFORE) &&
        finalCallTime <= new Date(date + " " + CALL_BEFORE)
      )
        setSubmitDisabled(false);
      else setSubmitDisabled(true);
    } else if (type === "RESEND") {
      if (
        date != "" &&
        time != "" &&
        (retryAttempt > 1 ? retryInterval != "0:00" : true) &&
        selectedTime > new Date()?.getTime() &&
        selectedTime >= new Date(date + " " + CALL_AFTER)?.getTime() &&
        selectedTime <= new Date(date + " " + CALL_BEFORE) &&
        finalCallTime <= new Date(date + " " + CALL_BEFORE)
      )
        setSubmitDisabled(false);
      else setSubmitDisabled(true);
    } else if (type === "EDIT-BEFORE") {
      if (
        campaignName != "" &&
        date != "" &&
        time != "" &&
        (retryAttempt > 1 ? retryInterval != "0:00" : true) &&
        selectedTime > new Date()?.getTime() &&
        selectedTime >= new Date(date + " " + CALL_AFTER)?.getTime() &&
        selectedTime <= new Date(date + " " + CALL_BEFORE) &&
        finalCallTime <= new Date(date + " " + CALL_BEFORE)
      )
        setSubmitDisabled(false);
      else setSubmitDisabled(true);
    } else if (type === "EDIT-AFTER") {
      if (campaignName != "") setSubmitDisabled(false);
      else setSubmitDisabled(true);
    }
  }, [formUpdateListener]);

  const closeModal = () => {
    setCampaignName("");
    setAudioFile(null);
    setDate(PickerDates?.date);
    setTime(PickerDates?.time);
    setSubmitDisabled(true);
    setRetryAttempt(1);
    setRetryInterval("2:30");
    if (clearSelection) clearSelection();
    hideModal();
  };

  const handleAudioFileUpload = async (event) => {
    const audioFile = event.target.files[0];

    const loadAudioMetaData = (file) =>
      new Promise((res, rej) => {
        const audio = document.createElement("audio");
        audio.preload = "metadata";
        audio.onloadedmetadata = () => res(audio);
        audio.onerror = () => {
		  enqueueSnackbar("Please upload an audio file of the right format", { variant: "error"})
          event.target.files[0] = "";
        };
        audio.src = URL.createObjectURL(file);
      });

    const _audio = await loadAudioMetaData(audioFile);
    if (_audio.duration > 45 || _audio.duration == 0) {
	  enqueueSnackbar("Please upload a valid audio file of duration 1 - 45 seconds", { variant: "error"})
      event.target.value = "";
    } else {
      setAudioFile(audioFile);
      setFormUpdateListener(formUpdateListener + 1);
    }
  };

  const handleSubmitNew = () => {
    const audioFormData = new FormData();
    audioFormData.append("audio_file", audioFile);

    JService.post("/core/create-recording/", audioFormData)

      .then((response) => {
        const scheduledTime = date.concat("T").concat(time).concat(":00.000");
        const payload = {
          recording_id: response.data.data.id,
          name: campaignName,
          schedule_time: scheduledTime,
          contacts: selectedRows.map((contact) => {
            return {
              name: contact.first_name,
              phone_number: contact.phone_number,
              country_code: "+91",
            };
          }),
          total_attempts: parseInt(retryAttempt),
          retry_interval: retryInterval,
        };
        return JService.post("/calls/create-campaign/", payload);
      })
      .then((response) => {
        let _type;
        if (!response.data.success) throw new Error(response?.data.message)
        else _type = "SUCCESS";
		
		enqueueSnackbar(response?.data?.message, { variant: "success"})
		// @clevertapv1
		// clevertap.event.push("Voicebroadcast created", {
		// 	"source":"voicebroadcast",
		// 	"action": "create",
		// 	"userEmail": reduxAccount?.data?.email,
		// 	"path": window.location.href,
		// 	"IDENTITY": clevertap.getCleverTapID(), // @check clevertap
		// });
        closeModal();
      })
      .catch((error) => {
        console.log("err", error);
		enqueueSnackbar(error?.response?.message || "Error occurred while creating Voice Broadcast campaign", { variant: "error"})
        // @clevertapv1
        // clevertap.event.push("ERROR on create voicebroadcast", {
        // 	"source":"voicebroadcast",
        // 	"action": "create",
        // 	"userEmail": reduxAccount?.data?.email,
        // 	"path": window.location.href,
        // 	"IDENTITY": clevertap.getCleverTapID(),
        // 	"error": JSON.stringify({error}),
        // });
        closeModal();
      });

    return;
  };

  const handleSubmitResend = () => {
    const callIds = [];
    selectedRows.forEach((row) => {
      callIds.push(row.id);
    });
    const payload = {
      campaign: parseInt(campaignId),
      scheduled_at: date.concat("T").concat(time).concat(":00.000"),
      call_ids: callIds,
      total_attempts: parseInt(retryAttempt),
      retry_interval: retryInterval,
    };

    JService.post("/calls/resend-message/", payload)
      .then((response) => {
        let _type;
        if (!response.data.success) throw new Error(response.data.message)

		enqueueSnackbar(response?.data.message, { variant: "success"})
		// @clevertapv1
		// clevertap.event.push("Voicebroadcast resend message", {
		// 	"source":"voicebroadcast",
		// 	"action": 'resend',
		// 	"userEmail": reduxAccount?.data?.email,
		// 	"path": window.location.href,
		// 	"IDENTITY": clevertap.getCleverTapID(),
		// });
       
        triggerReload();
        closeModal();
      })
      .catch((error) => {
        console.log("err fail", error);
		enqueueSnackbar(error?.response?.message || error.response || "Error occurred while resending Voice Broadcast", { variant: "error"})
        closeModal();

        // @clevertapv1
        // clevertap.event.push("ERROR on resend voicebroadcast", {
        // 	"source":"voicebroadcast",
        // 	"action": 'resend',
        // 	"userEmail": reduxAccount?.data?.email,
        // 	"path": window.location.href,
        // 	"IDENTITY": clevertap.getCleverTapID(),
        // 	"error": JSON.stringify({error}),
        // });
      });
  };

  const handleSubmitEdit = () => {
    const payload = {
      campaign_id: campaignId,
      name: campaignName,
    };

    if (type === "EDIT-BEFORE") {
      payload["schedule_time"] = date
        .concat("T")
        .concat(time)
        .concat(":00.000");
      payload["total_attempts"] = parseInt(retryAttempt);
      payload["retry_interval"] = retryInterval;
    }

    JService.post("/calls/update-campaign/", payload)
      .then((response) => {
        if (!response.data.success) throw new Error(response.data.message)
		enqueueSnackbar(response?.data.message, { variant: "success"})
       
        triggerReload();
        // @clevertapv1
        // clevertap.event.push("Voicebroadcast update", {
        // 	"source":"voicebroadcast",
        // 	"action": 'update',
        // 	"userEmail": reduxAccount?.data?.email,
        // 	"path": window.location.href,
        // 	"IDENTITY": clevertap.getCleverTapID(),
        // });
        closeModal();
      })
      .catch((error) => {
        console.log("err fail", error);
		enqueueSnackbar(error.response?.message || error.response || "Error occurred while updating Voice broadcast campaign", { variant: "error"})
       
        // @clevertapv1
        // clevertap.event.push("ERROR on update voicebroadcast", {
        // 	"source":"voicebroadcast",
        // 	"action": 'update',
        // 	"userEmail": reduxAccount?.data?.email,
        // 	"path": window.location.href,
        // 	"isOnline": navigator.onLine ? "online" : "offline",
        // 	"IDENTITY": clevertap.getCleverTapID(),
        // 	"error": JSON.stringify({error}),
        // });
        closeModal();
      });
  };

  function handleCalendarSet(calendarValueOnOk, dateOrTime) {
    // console.log(' on ok: ', calendarValueOnOk)
    const dateFromArg = new Date(calendarValueOnOk);
    if (dateOrTime === "date")
      setDate(
        `${dateFromArg.getFullYear()}-${(
          "0" +
          (dateFromArg.getMonth() + 1)
        ).slice(-2)}-${("0" + dateFromArg.getDate()).slice(-2)}`
      );
    else if (dateOrTime === "time") {
      let callBeforeTime = `${("0" + dateFromArg.getHours()).slice(-2)}:${(
        "0" + dateFromArg.getMinutes()
      ).slice(-2)}`;
      let callAfterTime = `${("0" + dateFromArg.getHours()).slice(-2)}:${(
        "0" + dateFromArg.getMinutes()
      ).slice(-2)}`;
      //suppose 12:30 is the selected time
      // range becomes 12:30 to 20:30

      if (
        new Date(date + "" + CALL_AFTER)?.getTime() <
        new Date(date + " " + callAfterTime)?.getTime()
      ) {
        // 9:30.getTime() < 12:30.getTime()
        callAfterTime = CALL_AFTER;
      }

      let interval = retryInterval;
      interval = interval.split(":");
      interval = parseInt(interval[0]) + (interval[1][0] === "3" ? 0.5 : 0);

      let finalCallTime =
        new Date(date + " " + callBeforeTime)?.getTime() +
        (retryAttempt - 1) * interval * 60 * 60 * 1000;

      if (finalCallTime >= new Date(date + " " + CALL_BEFORE)?.getTime()) {
        // final call at 20:31.getTime() > 20:30.getTime()
        // range should be initial, 20:30
        // and reset the attempts to 1
        finalCallTime = CALL_BEFORE;
        setRetryAttempt(1);
      } else {
        // final call happens before with current range with current number of attempts
        finalCallTime = CALL_BEFORE;
      }
      setRetryRanges([callAfterTime, finalCallTime]);
      setTime(
        `${("0" + dateFromArg.getHours()).slice(-2)}:${(
          "0" + dateFromArg.getMinutes()
        ).slice(-2)}`
      );
    }

    setFormUpdateListener((prevState) => ++prevState);
  }

  function handleRetryAttemptChange(e) {
    const value = e.target.value;
    if (value > 1) {
      // reset it to 0:00
      setRetryInterval("0:00");
    }

    setRetryAttempt(value);
    setFormUpdateListener((prevState) => ++prevState);
  }

  function handleReapeatIntervalChange(e) {
    const value = e.target.value; // like 0:30, 1:00 till 6:00
    let isWithinRange = true;

    // start time of 12:30
    // 2tries * 1hour gap =>
    // try @ 12:30
    // retry1 @ 13:30
    // new Date(time) + (retryAttempt-1)*gap
    //  should be within range

    setRetryInterval(value);
    setFormUpdateListener((prevState) => ++prevState);
  }

  function determineGapDisabled(value) {
    let isDisabled = false;
    let callAfter = retryRanges[0];
    let callBefore = retryRanges[1];

    callAfter = new Date(date + " " + callAfter);
    callBefore = new Date(date + " " + callBefore);
    let currentCallAfter = new Date(date + " " + time);
    let currentCallBeforeTime =
      currentCallAfter.getTime() + value * (retryAttempt - 1) * 60 * 60 * 1000;

    if (
      currentCallAfter?.getTime() < callAfter?.getTime() ||
      currentCallBeforeTime > callBefore?.getTime()
    ) {
      isDisabled = true;
    }

    return isDisabled;
  }

  return (
    <GModal
      visible={visible}
      closeModal={closeModal}
      heading={
        type == "NEW"
          ? "Voice broadcast"
          : type === "RESEND"
          ? "Resend message"
          : "Edit campaign"
      }
      body={
        <div
          style={{ paddingBottom: "24px" }}
          className="new-contact-list-form t6 nc-gray-900 regular-font"
        >
          {type != "RESEND" && (
            <>
              <label
                className="t7 medium-font nc-gray-900"
                style={{ marginBottom: "32px" }}
              >
                Campaign name
                <input
                  placeholder="Enter Campaign Name"
                  name="name"
                  type="text"
                  onChange={(e) => {
                    setCampaignName(e.target.value);
                    setFormUpdateListener(formUpdateListener + 1);
                  }}
                  value={campaignName}
                />
              </label>
              {(type == "NEW" || type === "RESEND") && (
                <>
                  <span className="t7 medium-font nc-gray-900">
                    Audio message
                  </span>
                  <label
                    htmlFor="upload-list-file"
                    className="medium-font upload-file-label"
                  >
                    {audioFile == null && (
                      <>
                        <div className="no-file">
                          <div>
                            <a className="t6 ic-green-500">Click to upload</a>
                            <span className="t6 medium-font">
                              &nbsp;or drag and drop
                            </span>
                            <p className="t8 regular-font nc-gray-600">
                              File format: mp3 (max size: 10MB)
                            </p>
                          </div>
                        </div>
                        <input
                          id="upload-list-file"
                          placeholder="or drag and drop files here"
                          name="file"
                          type="file"
                          disabled={!!audioFile}
                          accept={".mp3"}
                          onChange={handleAudioFileUpload}
                        />
                      </>
                    )}
                    {!!audioFile && audioFile.name && (
                      <>
                        <div className="has-file">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img src={baseFileIcon} />
                            <div
                              className="t6 regular-font"
                              style={{
                                marginLeft: "20px",
                              }}
                            >
                              <p className="nc-gray-900">
                                {audioFile.name.substring(0, 27)}
                                {audioFile.name.length > 27 ? "…" : ""}
                              </p>

                              <p className="nc-gray-600">
                                {formatFileSize(audioFile.size)}
                              </p>
                            </div>
                          </div>
                          <CloseIcon
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              // e.stopPropagation();
                              setAudioFile(null);
                            }}
                          />
                        </div>
                      </>
                    )}
                  </label>
                </>
              )}
            </>
          )}
          {type != "EDIT-AFTER" && (
            <div
              className="voice-broadcast-datetime"
              style={type === "NEW" ? { marginTop: "24px" } : null}
            >
              <div>
                <p className="t7 medium-font nc-gray-900">Date</p>

                {/* rsuite datepicker */}
                <Box
                  sx={{
                    border: "1.5px solid #e1e6e2",
                    borderRadius: "2px",
                    height: "40px",
                  }}
                >
                  <DatePicker
                    className="voice-modal-calendar"
                    title="Click to choose date"
                    appearance="default"
                    value={new Date(date + " " + time) || null}
                    disabledDate={before(new Date().toString())}
                    format="dd-MM-yyyy"
                    style={{
                      width: "100%",
                      borderRadius: "2px !important",
                    }}
                    onOk={(calendarValueOnOk) =>
                      handleCalendarSet(calendarValueOnOk, "date")
                    }
                    onChangeCalendarDate={(calendarValueOnChange, e) =>
                      handleCalendarSet(calendarValueOnChange, "date")
                    }
                    placeholder="dd-mm-yyyy"
                    placement="bottomEnd"
                    ranges={[]}
                    cleanable={false}
                    preventOverflow={true}
                  />
                </Box>
              </div>

              <div>
                <p className="t7 medium-font nc-gray-900">Time</p>
                {/* rsuite timepicker */}
                <Box
                  sx={{
                    border: "1.5px solid #e1e6e2",
                    borderRadius: "2px",
                    height: "40px",
                  }}
                >
                  <DatePicker
                    className="voice-modal-calendar"
                    title="Click to choose time"
                    appearance="default"
                    value={new Date(date + " " + time) || null}
                    disabledDate={combine(
                      after(new Date(date + " " + CALL_BEFORE).toString()),
                      before(new Date(date + " " + CALL_AFTER))
                    )}
                    format="HH:mm"
                    style={{
                      width: "100%",
                      borderRadius: "2px !important",
                    }}
                    onOk={(calendarValueOnOk) =>
                      handleCalendarSet(calendarValueOnOk, "time")
                    }
                    onChangeCalendarDate={(calendarValueOnChange, e) =>
                      handleCalendarSet(calendarValueOnChange, "time")
                    }
                    placeholder="--:--"
                    placement="bottomEnd"
                    ranges={[]}
                    cleanable={false}
                    preventOverflow={true}
                  />
                </Box>
              </div>

              <div>
                <p className="t7 medium-font nc-gray-900">Total attempts</p>
                <select
                  name="retry-attempt"
                  id="datetime-dropdown"
                  value={retryAttempt}
                  onChange={handleRetryAttemptChange}
                  style={{ width: "100%" }}
                >
                  {/* <option value={0}>0</option> */}
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                </select>
              </div>

              {retryAttempt > 1 && (
                <div>
                  <p className="t7 medium-font nc-gray-900">Attempt interval</p>
                  <Select
                    value={retryInterval}
                    onChange={(e) => handleReapeatIntervalChange(e)}
                    sx={{
                      alignSelf: "flex-start",
                      minWidth: 200,
                      width: "100%",
                      maxWidth: "100%",
                      height: "40px",
                      outline: "none",
                      borderRadius: "4px",
                      padding: 0,
                      marginBottom: "24px",
                      ...styles.t6,
                      ...styles.regular_font,
                      color: colors.nc_gray_900,
                      "&:before": {
                        outline: "none",
                        border: "none",
                      },
                    }}
                    placeholder="Select an interval"
                    renderValue={(selected) => {
                      if (!selected)
                        return (
                          <p className="regular-font t6 nc-gray-400">
                            Select an interval
                          </p>
                        );
                      return (
                        <p className="regular-font t6 nc-gray-900">
                          {selected}
                        </p>
                      );
                    }}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem disabled={false} value="0:00">
                      No repeat
                    </MenuItem>
                    <MenuItem disabled={determineGapDisabled(0.5)} value="0:30">
                      30 mins
                    </MenuItem>
                    <MenuItem disabled={determineGapDisabled(1)} value="1:00">
                      1hr
                    </MenuItem>
                    <MenuItem disabled={determineGapDisabled(1.5)} value="1:30">
                      1hr 30 mins
                    </MenuItem>
                    <MenuItem disabled={determineGapDisabled(2)} value="2:00">
                      2hrs
                    </MenuItem>
                    <MenuItem disabled={determineGapDisabled(2.5)} value="2:30">
                      2hrs 30 mins
                    </MenuItem>
                    <MenuItem disabled={determineGapDisabled(3)} value="3:00">
                      3hrs
                    </MenuItem>
                    <MenuItem disabled={determineGapDisabled(3.5)} value="3:30">
                      3hrs 30 mins
                    </MenuItem>
                    <MenuItem disabled={determineGapDisabled(4)} value="4:00">
                      4hrs
                    </MenuItem>
                    <MenuItem disabled={determineGapDisabled(4.5)} value="4:30">
                      4hrs 30 mins
                    </MenuItem>
                    <MenuItem disabled={determineGapDisabled(5)} value="5:00">
                      5hrs
                    </MenuItem>
                    <MenuItem disabled={determineGapDisabled(5.5)} value="5:30">
                      5hrs 30 mins
                    </MenuItem>
                    <MenuItem disabled={determineGapDisabled(6)} value="6:00">
                      6hrs
                    </MenuItem>
                  </Select>
                </div>
              )}
            </div>
          )}
        </div>
      }
      modalStyle={{
        width: "450.64px",
        maxHeight: "350px",
      }}
      bottomButtons={[
        <Button
          disabled={false}
          hierarchy="white"
          variant="contained"
          buttonText="Cancel"
          isLoading={false}
          onClick={closeModal}
        />,
        <Button
          disabled={submitDisabled}
          hierarchy="green"
          variant="contained"
          buttonText={
            type === "NEW" ? "Create" : type === "RESEND" ? "Send" : "Save"
          }
          isLoading={false}
          onClick={
            type === "NEW"
              ? handleSubmitNew
              : type === "RESEND"
              ? handleSubmitResend
              : handleSubmitEdit
          }
        />,
      ]}
    />
  );
}
