// NOTE: update password.trim().length to min allowed password chara length
const emailRegex = new RegExp(/^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,15}$/)

export function loginValidation({ email, password })
{
    // console.log(email, ' :is being tested')
    if(password.trim()?.length !== 0 && emailRegex?.test(email)){
        // console.log(' ... and is Valid: ', emailRegex?.test(email))
        return {isValid: true}
    }
    // console.log(' ... notValid')
    return {isValid: false}
}

export function signupValidation({ first_name: firstName, last_name: lastName, email, password })
{
    // console.log('here with values: ', firstName, '&', lastName, '&', email, '&', password )
    if(password?.trim().length !== 0 
	// && password?.length > 8 
	&& firstName?.trim().length !== 0 && lastName?.trim().length !== 0 && emailRegex?.test(email)){
        return {isValid: true}
    }
    return {isValid: false}
}

export const loginScreen2Validation = loginScreen2Values => Boolean(Object.values(loginScreen2Values).join("")?.trim().length === 6)

export function resetPasswordValidation({ email }) {
    if(email.includes('@') && email.includes('.')){
        return { isValid: true}
    }
    else return {isValid: false}
}
