import { useEffect, useRef, useState } from 'react'
import styles from './ActivityLogs.module.css'
import CallLogs from '../call-logs/CallLogs'
import SmsLogs from './Sms/SmsLogs'
import { useDispatch, useSelector } from 'react-redux'
import { JService } from '../api-service/ApiService'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import MessageHistoryModal from './Sms/MessageHistoryModal'
import clevertap from 'clevertap-web-sdk'
import { setDialer } from '../features/dialer/dialer'
import { enqueueSnackbar } from 'notistack'
const tabs=['Calls','Messages']
const ActivityLogs=({tab='Calls'})=>{
    const API_BASE_URL=process.env.REACT_APP_BETA

    const params = useParams()
    const dispatch=useDispatch()
    const navigate=useNavigate()

    const reduxVn=useSelector((state)=>state?.virtualNumber?.value);
    const reduxAccount=useSelector((state)=>state?.account?.value)
    const reduxDialer = useSelector((state) => state?.globalDialer?.value);
    const reduxSubscription=useSelector(state=>state?.account?.value?.subscription)

    const maskNumbers = reduxAccount?.data?.number_masking && reduxAccount?.data?.profile?.role !== 1

    const [activeTab,setActiveTab]=useState(tab)
    const [users, setUsers] =useState([])
    const [teams, setTeams]=useState([])
    const [allTags, setAllTags]=useState([])
    const [tagDetailsSet, setTagDetailsSet] = useState(false);

    const [messageHistoryModal, setMessageHistoryModal]=useState({visible:(!params.id && params.id!=0)?false:true, id:params.id, candidateDetails:null, viewOnly:'unknown', viewedMsgId:(!params.id && params.id!=0)?null:params.id})
    const [messagingEnabled, setMessagingEnabled]=useState(false)

    const allTagsRef = useRef([]);


    const handleOpenConversation=(e,params,source)=>{
      //when message icon is clicked either in call logs or sms logs
      if(source==='call-logs' || source==='sms-logs'){
        const candidateDetails={}
        const primaryVirtualNumber=reduxAccount?.data?.profile?.default_sms_number
        const data={
          "user_number": primaryVirtualNumber,
          "creator": reduxAccount?.data?.email
        }
        if(source==='call-logs'){
          candidateDetails['name']=params?.row?.logsContact?.candidateName;
          candidateDetails['phone_number']=params?.row?.logsContact?.candidateNumber;
          data['contact_number']= params?.row?.logsContact?.candidateNumber;
        }
        else if(source==='sms-logs'){
          candidateDetails['name']=params?.row?.contact_name;
          candidateDetails['phone_number']=params?.row?.contact_number;
          data['contact_number']=params?.row?.contact_number;
        }
        setMessageHistoryModal({visible:true, id:null, payload:data, viewOnly:false, viewedMsgId:null, candidateDetails})
      }
    }

    const handleCloseInteractionModal=()=>{
      setMessageHistoryModal({visible:false, id:null, payload:null, candidateDetails:null, viewOnly:null, viewedMsgId:null})
    }

    const initiateCall=(e, candidateNumber, contact_list_id)=>{
   
      e.stopPropagation();

      try {
        let _phone = candidateNumber;
        // const phoneRegExp = new RegExp(
        //   /^(([+][\d]{1,4})?([\d]{10,12}))/gm
        // );
        // const _res = phoneRegExp.exec(_phone);
        // if (Array.isArray(_res)) {
        dispatch(
          setDialer({
            // phone_number: _res.at(-1),
            phone_number: _phone,
            country_code: null,
            isVisible: true,
            contact_list_id,
            maskNumbers
          })
        );
        const payload = {
          candidate_number: _phone,
          virtual_number: "-1",
          //country_code: "+91",
        };
        // window.scrollTo({top: 0, behavior: 'smooth'})
        // @clevertapv1
        clevertap.event.push("Phone button clicked", {
          source: "message logs",
          userEmail: reduxAccount?.data?.user_email,
          payload: JSON.stringify(payload),
          name: "initiating call from phone button",
          path: window.location.href,
          IDENTITY: clevertap.getCleverTapID(),
        });
        // }
      } catch (err) {
        // @clevertap
        clevertap.event.push("ERROR on phone button clicked", {
          source: "message logs",
          userEmail: reduxAccount?.data?.user_email,
          payload: "error on initiating call from phone button",
          path: window.location.href,
          error: JSON.stringify({
            name: err?.name,
            stack: err?.stack,
            message: err?.message,
            payload: {
              candidate_number: reduxDialer?.phone_number,
            },
          }),
          IDENTITY: clevertap.getCleverTapID(),
        });
      }
  
  }
  


    const fetchData=()=>{
       //tags
    let orgUrlParameter = "";
    if (
      reduxAccount?.data?.profile?.account_holder &&
      reduxAccount?.allOrgs?.length > 1
    ) {
      orgUrlParameter = reduxAccount?.selectedOrg + "/";
      if (!reduxAccount?.selectedOrg && reduxAccount?.selectedOrg !== 0)
        orgUrlParameter = "";
    }
    JService.get(API_BASE_URL + `/api/v1/auth/retrieve-tags/${orgUrlParameter}`)
      .then((res) => {
        if (!res?.success)
          throw new Error(res?.message || "Could not retreive tags");
        setAllTags(res?.data);
        setTagDetailsSet(true);
        allTagsRef.current = res?.data;
      })
      .catch((err) =>{
        enqueueSnackbar(err?.message, {variant:'error'})
      }
      );

    // retrieve-users for filter
    // @access 'super admin' and 'admin' allOrgs.length > 1
    if ([1, 4].includes(reduxAccount?.data?.profile?.role)) {
      // retrieve-users based on selectedOrg
      let orgUrlParam = "";
      if (
        reduxAccount?.data?.profile?.account_holder &&
        reduxAccount?.allOrgs?.length > 1
      ) {
        orgUrlParam = encodeURIComponent(reduxAccount?.selectedOrg + "/");
        if (!reduxAccount?.selectedOrg && reduxAccount?.selectedOrg !== 0)
          orgUrlParam = "";
      }
      // console.log('orgUrlParam: call-logs retrieve-users ', orgUrlParam)

      JService.get(
        API_BASE_URL +
          `/api/v1/auth/retrieve-users/${decodeURIComponent(orgUrlParam)}`
      )
        .then((res) => {
          if (!res.success)
            throw new Error(res?.message || "Could not retreive users");
          setUsers(res?.data || []);   
        })
        .catch((err) =>{
          enqueueSnackbar(err?.message, {variant:'error'})
        }
        );
    }

    JService.get(API_BASE_URL + `/api/v1/auth/teams/`)
      .then((res) => {
        if (res?.status === 500)
          throw new Error(
            "Server error please try again or if issue persists contact FreJun"
          );
        if (!res?.success)
          throw new Error(res?.message || `Could not retrieve teams`);
        setTeams(res?.data || [])
        
      })
      .catch((err) => console.warn(`Could not retrieve teams`, err));
    }
      // api-fetch
  useEffect(() => {
   fetchData()
  }, []);

  useEffect(()=>{
    if(reduxVn && Object.keys(reduxVn)?.length){

      const {outbound_virtual_numbers, inbound_virtual_numbers}=reduxVn;
      const intInbound= inbound_virtual_numbers?.filter((vn)=>vn.country_code!=='+91')
      const smsEnabledNumbers= intInbound?.some((vn)=>outbound_virtual_numbers?.find(elem=>elem.id===vn.id))
      setMessagingEnabled(smsEnabledNumbers)
    }
  },[reduxVn]);

   // NOTE: if there is an issue with tags being unavailable in call-logs screen when a user has multiple accounts(orgs), move orgFetch from call-logs to fetchData in ActivityLogs and call fetchData only if tagDetails transitions from true -> false. Optionally move entire account switching(reduxAccount in CallLogs) to ActivityLogs.

    return (
    <div
    className="screen-container"
    style={{
      paddingTop: "24px",
      paddingLeft:'0',
      height:
        document.documentElement.scrollHeight - 56 >= window.innerHeight
          ? "fit-content"
          : window.innerHeight,
    }}
  >
  {!reduxSubscription?.plan?.name?.includes('USD') && <CallLogs displayTitle={true} initiateCall={initiateCall} messagingEnabled={false} setMessageHistoryModal={null} handleOpenConversation={null} users={users} allTags={allTags} teams={teams} fetchData={fetchData} tagDetailsSet={tagDetailsSet} setTagDetailsSet={setTagDetailsSet} allTagsRef={allTagsRef}/>}
  {reduxSubscription?.plan?.name?.includes('USD') && (
    <>
  <div className={styles.tabContainer}>
  <hr />
  {
      [tabs.map((tab,index)=>{
          return (
              <p
                  className={`${styles.tabLabel} ${
                    tab === activeTab ? styles.selectedTabLabel:''
                  } b2 regular-font`}
                  key={index}
                  onClick={() =>{navigate(`/activity-logs/${tab.toLowerCase()}`); setActiveTab(tab)}}
                >
                  {tab}
                </p>
          )
      })]
  }
   <hr />
   </div>
   <>
   {activeTab==="Calls" && <CallLogs displayTitle={false}  initiateCall={initiateCall} messagingEnabled={messagingEnabled} setMessageHistoryModal={setMessageHistoryModal} handleOpenConversation={handleOpenConversation} users={users} allTags={allTags} teams={teams} fetchData={fetchData} tagDetailsSet={tagDetailsSet} setTagDetailsSet={setTagDetailsSet} allTagsRef={allTagsRef}/>}
   {activeTab==='Messages' && <SmsLogs initiateCall={initiateCall}  messagingEnabled={messagingEnabled} setMessageHistoryModal={setMessageHistoryModal} handleOpenConversation={handleOpenConversation} id={params?.id} users={users} allTags={allTags} teams={teams} fetchData={fetchData} tagDetailsSet={tagDetailsSet} setTagDetailsSet={setTagDetailsSet}/>}
   <MessageHistoryModal payload={messageHistoryModal.payload} visible={messageHistoryModal.visible} id={messageHistoryModal.id} candidateDetails={messageHistoryModal.candidateDetails} viewOnly={messageHistoryModal.viewOnly} viewedMsgId={messageHistoryModal.viewedMsgId} onClose={handleCloseInteractionModal}/>
   </>
   </>
  )
}
  </div>
  )
}

export default ActivityLogs