import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useNavigate, useLocation } from 'react-router'
import GModal from '../design/components/GModal'
import { Box } from '@mui/system'
import { useSelector } from 'react-redux'
import Button from '../design/Button'
import './platformrules.css'
var previousPath = null

export default function PlatformRules(props) {
  const [showRules, setShowRules] = useState(true)
  const goBackRef = useRef(null)
  const reduxNavbarPaths = useSelector((state) => state?.navbarPaths?.value);

  useEffect(()=>{
	if(previousPath !== null) return
	previousPath = reduxNavbarPaths?.previous
  }, [reduxNavbarPaths])

  const rulesBody = <Box  sx={localStyles.container}>
	  <section className='rules t6 regular-font nc-gray-900 rules-container' style={localStyles.section}>
		<p className='medium-font'>Rules Regarding Call Recording:</p>
		<p style={localStyles.rulesPara}>When utilizing the FreJun platform for call recording and tracking purposes, it is imperative that you comply with all applicable laws at the country, state, local, and organizational levels concerning consent.*
			In numerous regions, it is a legal requirement to inform participants that a call is being recorded, and it is essential that you consistently adhere to this practice. As stated in our terms of service, FreJun is a tool, and the responsibility lies with you, the user, to dutifully notify all parties involved that the call is being recorded.
		</p>

		<p className='medium-font'>Acquiring Consent for Recording:</p>
		<p style={localStyles.rulesPara}>Verbal notification: It is necessary to inform the person on the call that the conversation is being recorded.
			Written notification: Prior notification should be provided via email or included in the body of the calendar invitation.
		</p>

		<p className='medium-font'>For implicit permission of recordings:</p>
		<p style={localStyles.rulesPara}>You can play recording the calls being recorded for all the outbound and inbound calls. This feature can be accessed by super admins of the accounts in the settings page. For further details on how to set this up, please reach out to FreJun's support team (hello@frejun.com).
			Super admins also have the option to turn off call recording for all kinds of calls.
		</p>

		<p className='medium-font'>Deletion of an Existing Recording:</p>
		<p style={localStyles.rulesPara}>If you need to delete a recording, you may submit a request to your super admin or contact FreJun's support team (hello@frejun.com).
		</p>
		
		<p>*This article does not serve as a substitute for advice from your legal counsel.</p>
	  </section>
	</Box>;

  const handleRulesClose = e => {
	goBackRef.current = false
	setShowRules(false)
	if(props?.handleClose) props.handleClose();	
  }

  return  <>
	{ showRules && 
	  createPortal(
		<GModal
			disableCloseBtn={true}
			closeIconVisible={false}
			closeModal={handleRulesClose}
			body={rulesBody}
			heading={<span className="t6 nc-gray-900 medium-font">Call recording rules</span>}
			visible={showRules}
			bodyWrapperStyle={{
				width: "60vw",
				height: "60vh",
				paddingBottom: "12px",
				...(window.innerWidth <= 768 && {
				  width: "100vw",

				})
			  }}
			className='modal'
			modalStyle={{
				padding: "32px",
			}}
			bottomButtons={[
			<Button
			    className="btn"
				disabled={false}
				hierarchy="green"
				variant="contained"
				buttonText="I agree"
				isLoading={false}
				onClick={handleRulesClose}
			/>,
			]}
      	/>, document.body
	  )
	}
  </>
}

const localStyles = {
	rulesPara: {
		marginBottom: "32px"
	},
	section: {
		width: '100%',
		height: '100%',
		position: 'relative',
	},
	container: {
		width: "100%",
		height: "100%",
		position: "relative",
		
	}
}