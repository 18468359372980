import '../signup/signupScreen4Styles.css'
import vectorArt from '../../assets/signup-screen4.svg'
import dotsBottom from '../../assets/login-dots-bottom.svg'
import dotsTop from '../../assets/login-dots-top.svg'
import logo from '../../assets/top-nav-logo.svg'
import { useState } from 'react'

import { useDispatch } from 'react-redux'
import { Box } from '@mui/material'
import Button from '../design/Button'
import { addAccountOtherIcon, addAccountRecruitmentIcon, addAccountSalesIcon, addAccountSupportIcon, topNavAccountSelectedAccountTickIcon } from '../../utility-functions/assetLoader'
import colors from '../design/colors'
import { saveSelectedOrg } from '../features/account/account'
import { JService } from '../api-service/ApiService'
import { enqueueSnackbar } from 'notistack'

export default function AddAccount() {
	const dispatch = useDispatch()

	const [businessName, setBusinessName] = useState("")
	const [selectedUseCase, setSelectedUseCase] = useState("")

	const resetStates = () => {
		setBusinessName("")
		setSelectedUseCase("")
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		JService.post('/api/v1/auth/organizations/', JSON.stringify({ name: businessName }))
			.then(res => {
				if (!res?.success) throw new Error(res?.message || "Could not add account")
				enqueueSnackbar(res?.message || "Organization created successfully", { variant: "success"})
				dispatch(saveSelectedOrg(res?.data?.id || null))
				setTimeout(() => window.location.replace('/users'), 300);
			})
			.catch(error => enqueueSnackbar(error?.message, { variant: "error"}))
			.finally(() => {
				resetStates()
			})
	}

	const handleBusinessNameChange = e => setBusinessName(e.target.value)

	return (
		<div className='signup-screen3-container signup-screen4-container t6 regular-font nc-gray-900'>
			<div className='signup-art'>
			<a href="https://frejun.com/" target="_blank" rel="noopener noreferrer"> <img src={logo} alt="Logo" /></a>
				<img src={dotsTop} />
				<img src={vectorArt} />
				<img src={dotsBottom} />
			</div>
			<div className='signup-screen3-form'>
				<p className='title color-green'></p>
				<p className='sub-title medium-font t4'>Add acount</p>
				<form onSubmit={handleSubmit} style={localStyles.form}>
					<label style={localStyles.businessLabel} className='t7 medium-font'>
						Business name
						<input type='text' name='organisation-name' value={businessName} onChange={handleBusinessNameChange} className='t6 regular-font nc-gray-900' style={localStyles.textInput} />
					</label>

					<div className='t7 medium-font'>
						<div style={localStyles.usecaseText}>You want to use FreJun for</div>
						<div style={localStyles.useCasesContainer}>
							<div style={localStyles.useCasesUpperRow}>

								{/* sales button */}
								<Button
									disabled={false}
									hierarchy='white'
									variant='outlined'
									buttonText={'Sales'}
									isLoading={false}
									onClick={() => setSelectedUseCase('sales')}
									icon={{
										startIcon: addAccountSalesIcon(),
										endIcon: (selectedUseCase === 'sales') ? topNavAccountSelectedAccountTickIcon() : null
									}}
									styleOverride={{
										...localStyles.useCaseItemButtonStyle,
										left: '0%',
										background: selectedUseCase === 'sales' ? colors.ic_green_100 : '#fff',
									}}
								/>
								{/* recruitment button */}
								<Button
									disabled={false}
									hierarchy='white'
									variant='outlined'
									buttonText={'Recruitment'}
									isLoading={false}
									onClick={() => setSelectedUseCase('recruitment')}
									icon={{
										startIcon: addAccountRecruitmentIcon(),
										endIcon: (selectedUseCase === 'recruitment') ? topNavAccountSelectedAccountTickIcon() : null
									}}
									styleOverride={{
										...localStyles.useCaseItemButtonStyle,
										left: '50%',
										background: selectedUseCase === 'recruitment' ? colors.ic_green_100 : '#fff',
									}}
								/>
							</div>
							<div style={localStyles.useCasesLowerRow}>
								{/* support button */}
								<Button
									disabled={false}
									hierarchy='white'
									variant='outlined'
									buttonText={'Support'}
									isLoading={false}
									onClick={() => setSelectedUseCase('support')}
									icon={{
										startIcon: addAccountSupportIcon(),
										endIcon: (selectedUseCase === 'support') ? topNavAccountSelectedAccountTickIcon() : null
									}}
									styleOverride={{
										...localStyles.useCaseItemButtonStyle,
										left: '0%',
										background: selectedUseCase === 'support' ? colors.ic_green_100 : '#fff',
									}}
								/>

								{/* other */}
								<Button
									disabled={false}
									hierarchy='white'
									variant='outlined'
									buttonText={'Other'}
									isLoading={false}
									onClick={() => setSelectedUseCase('other')}
									icon={{
										startIcon: addAccountOtherIcon(),
										endIcon: (selectedUseCase === 'other') ? topNavAccountSelectedAccountTickIcon() : null
									}}
									styleOverride={{
										...localStyles.useCaseItemButtonStyle,
										left: '50%',
										background: selectedUseCase === 'other' ? colors.ic_green_100 : '#fff',
									}}
								/>
							</div>
						</div>
					</div>
					{/* submit and cancel buttons */}
					<Box sx={localStyles.actionBtnContainer}>
						<Button disabled={false} hierarchy='white' variant='outlined' buttonText='Cancel' isLoading={false} onClick={() => window.history.go(-1)} styleOverride={{ marginRight: '12px', }} />
						<Button disabled={businessName?.trim()?.length === 0 || selectedUseCase?.length === 0} hierarchy='green' variant='outlined' buttonText='Add' isLoading={false} onClick={handleSubmit} />
					</Box>
				</form>
			</div>
		</div>
	)
}

const localStyles = {
	useCaseItemButtonStyle: {
		position: 'absolute',
		width: 'calc(50% - 12px)',
		justifyContent: 'flex-start',
		'& .MuiButton-endIcon': {
			position: 'absolute',
			marginRight: 'unset',
			marginLeft: 'unset',
			right: '18px',
		},
	},
	form: {
		height: 'auto',
	},
	businessLabel: { 
		marginBottom: '32px',
	},
	textInput: {
		height: '40px', 
		padding: '8px 12px',
		border: `1px solid ${colors.nc_gray_300}`, 
		borderRadius: '4px', 
	},
	usecaseText: {
		marginBottom: '16px',
	},
	useCasesContainer: {
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
		marginBottom: '56px',
		position: 'relative',
	},
	useCasesUpperRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		marginBottom: '12px',
		height: '40px',
	},
	useCasesLowerRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		height: '40px',
		position: 'relative',
	},
	actionBtnContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
	}
}