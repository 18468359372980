import { createSlice } from "@reduxjs/toolkit"

let _initial = null
try {
 	 _initial = localStorage.getItem("frejun-autodial")
} catch (err) {
  	console.warn("Error related to global error message(Autodial): ", err)
}

_initial = _initial === null ? {} : JSON.parse(_initial)

export const globalAutodialSlice = createSlice({
	name: "globalAutodial",
	initialState: {
		// CLARIFY: @BOSS is an api call needed, for the case if localStorage data is lost/cleared
		// and there is an ongoing autodial
		// either a check api before starting the session (in the parent component)
		// or an api call from here in the slice (which can be through the dispatch of an action)
	  	value: Object.keys(_initial).length === 0 ? { maximized: false, minimized: false} : _initial,
	},
	reducers: {
		setReduxAutodialState: (state, action) => {
			state.value = action.payload
			localStorage.setItem('frejun-autodial', JSON.stringify(state.value))
		}
	  	
	},
  });
  
  export const { setReduxAutodialState } = globalAutodialSlice.actions
  export default globalAutodialSlice.reducer