import { createSlice } from '@reduxjs/toolkit'

let _initial = null
try{
	_initial = JSON.parse(localStorage.getItem('frejun-search-query'))
}catch(err){
	console.warn('Error related to search-query: ', err)
}

_initial = _initial === null || !Object.keys(_initial).length?false:_initial

export const globalSearchQuery = createSlice({
	name: 'searchQuery',
	initialState: {value: Object.keys(_initial).length?_initial:{search: '', team_members: [], campaigns:[], call_type: ''}},
	reducers: {
		setGlobalSearchQuery: (state, action) => {
			state.value = action.payload
			localStorage.setItem('frejun-search-query', JSON.stringify(state.value))
		}
	}
})


export const { setGlobalSearchQuery } = globalSearchQuery.actions
export default globalSearchQuery.reducer