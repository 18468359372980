// TODO: on page unload update autodial state to {max: false, min: true}
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import AutodialMaximized2 from './AutodialMaximized2'
import { JService } from '../../api-service/ApiService'

const API_BASE_URL = process.env.REACT_APP_BETA
export default function Autodial2({data, ...props}) {
	const globalAutodialState = useSelector(state => state?.autodial?.value)
	const reduxAccount = useSelector(state => state?.account?.value)

	// initially if isOpen autodialMaximized is open
	const [autodialPayload, setAutodialPayload] = useState([])
	const [autodialPayloadSet, setAutodialPayloadSet] = useState(false)
	const [allTags, setAllTags] = useState([])
	const [orgDetails, setOrgDetails] = useState({})
	const [autodialType, setAutodialType] = useState('network');

	useEffect(()=>{
		let orgUrlParameter= ''
		if(reduxAccount?.data?.profile?.account_holder && reduxAccount?.allOrgs?.length > 1) {
			orgUrlParameter = reduxAccount?.selectedOrg+'/'	
			if(!reduxAccount?.selectedOrg && reduxAccount?.selectedOrg !== 0) orgUrlParameter = ''
		}
		
		JService.get(API_BASE_URL + `/api/v1/auth/retrieve-tags/${orgUrlParameter}`)
		.then(res => {
			if(!res.success) throw new Error(res?.message || 'Could not retreive tags')
			console.log('res?.data : ', res?.data)
			setAllTags(res?.data)
			
		}).catch(err =>  console.warn(err || 'Failed to retreive tags'))

		if(!reduxAccount?.data?.profile?.account_holder) {
			JService.get(API_BASE_URL + `/api/v1/auth/retrieve-organization/`)
			.then(res => {
				if(!res?.success) throw new Error(res?.message || 'Could not retreive options for call reason and outcome')
				setOrgDetails(res?.data || {}) //  res?.data?.call_reasons
			}).catch(err => console.warn(err))
		}
		else {
			// if(reduxAccount?.allOrgs.selectedOrg === reduxAccount.allOrgs.find())
			// primary account details are used to set call reasons and outcomes
			setOrgDetails(reduxAccount?.allOrgs?.find(ao => ao?.primary_account === true))
		}
	}, [])

	const prevAutodialPayloadData = useRef(null)
	const browserCallsRef = useRef(false);
	const browserCallsAccess = reduxAccount?.data?.access_list?.includes('BROWSER CALLING');
	const browserCalls = reduxAccount?.data?.profile?.browser_calls;

	useEffect(()=>{
		if(browserCalls && browserCallsAccess){
			browserCallsRef.current = true;
			setAutodialType('browser');
		}
		else{
			browserCallsRef.current = false;
			setAutodialType('network')
		}
	},[browserCalls, browserCallsAccess]);

	useEffect(()=> {
		
		if(prevAutodialPayloadData.current == data) {
			console.log('duplicate payload for autodial in parent: ', prevAutodialPayloadData.current)
			return
		}

			// if(globalAutodialState?.minimized)
			if(!globalAutodialState?.maximized) return
					
			let contacts = {}
			let payload = {}

			if(globalAutodialState?.resume === 'resumed' && autodialType === 'network') {
				setAutodialPayload([])
				return
			}
			else if(globalAutodialState?.resume === 'resumed' && autodialType === 'browser'){
				setAutodialPayload(data);
				return;
			}
			prevAutodialPayloadData.current = data

			data?.map((d, index) => {
				contacts[(index+1).toString()] =  { // or use Object.defineProperty(targetObject, keyValue, params={value: '', writable: true,})
					id: (index+1),
					first_name: d?.first_name ?? d?.name,
					notes: d?.notes ?? '', 
					// phone_number: d?.phone_number?.slice(-10) ?? d?.number?.slice(-10),
					phone_number: `${autodialType === 'browser' ? d?.country_code ?? '+91' : ''}${d?.phone_number ?? d?.number}`
					// last_contacted: {},
				}
			})

			payload = {
				name: globalAutodialState?.name, // @change we dont have a name for the initiate api
				interval: parseInt(globalAutodialState?.interval) || 5,
				contacts: contacts,
			}
			setAutodialPayload(payload)
		}, [data, globalAutodialState, autodialType])	

		return ((autodialType === 'browser' && globalAutodialState?.resume === 'resumed') ? autodialPayload?.length > 0 ?
		<AutodialMaximized2 autodialType={autodialType} data={autodialPayload} contactListId={props?.contactListId} allTags={allTags} orgDetails={orgDetails}/> : null : 
		<AutodialMaximized2 autodialType={autodialType} data={autodialPayload} contactListId={props?.contactListId} allTags={allTags} orgDetails={orgDetails}/>)
	}
	
