import { createSlice } from '@reduxjs/toolkit'

let initial = null
try{
    initial = JSON.parse(localStorage.getItem('frejun-users'))
}catch(err){
    console.warn(err, ' Setting up local storage for first (re)use.')
}

initial = initial?initial:[]

export const usersSlice = createSlice({
    name:'users',
    initialState: {value:Object.keys(initial).length === 0?[]:initial},
    reducers:{
        storeUsers: (state, action) => {
            state.value = action.payload
            localStorage.setItem('frejun-users', JSON.stringify(state.value))
        }
    }
})

export const { storeUsers } = usersSlice.actions
export default usersSlice.reducer