// header format// [['NAME'], ['PHONE NUMBER'], ['STATUS']]
// downloadData format for 2 rows// ['SUNIL', 252, 31.7]
// ['test', 9, 1.38]
import clevertap from "clevertap-web-sdk";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";

export default function GCSVDownload({
  fromScreen,
  headers,
  downloadData,
  filename,
  body,
}) {
  const API_BASE_URL = process.env.REACT_APP_BETA;
  const reduxAccount = useSelector((state) => state?.account?.value);
  return (
    <>
      <div className="gcsv-container">
        <CSVLink
          // 	onClick={()=>{ clevertap.event.push("Clicked download", {
          // 		"Downloaded table": fromScreen,
          // 		"userEmail": reduxAccount?.data?.email,
          // 		"source": fromScreen,
          // 		"path": window.location.href,
          // 		"IDENTITY": clevertap.getCleverTapID(),
          // });}}
          filename={
            filename ||
            "reminder-calls-" +
              new Date().toLocaleString("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })
          }
          className="csv-link2"
          data={downloadData}
          headers={headers}
        >
          {body}
        </CSVLink>
      </div>
    </>
  );
}
