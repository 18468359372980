// # css
import "./dashboard.css";

// # lib
import { useEffect, useReducer, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  defaults,
} from "chart.js";
import { Line } from "react-chartjs-2";
import GModal from "../design/components/GModal";
// # assets
import addColumnClose from "../../assets/close.svg";
import { callIcon } from "../../utility-functions/assetLoader";
import tableArrowAscending from "../../assets/dashboard-table-arrow-asc.svg";
import tableArrowDescending from "../../assets/dashboard-table-arrow-desc.svg";

import tableDeleteColumn from "../../assets/dashboard-table-delete-column.svg";
import { UseWebAppPc } from "../../utility-functions/assetLoader";
// # components
import AnimationPage from "../shared/animation-page/AnimationPage";
import GHeader from "../design/components/GHeader";
import GTable from "../design/components/GTable";
import Button from "../design/Button";
import { Filters } from "./Filters";
import { JService } from "../api-service/ApiService";
import { useLocation } from "react-router-dom";
import PlatformRules from "../platform-rules/PlatformRules";
import WelcomeModal from "./WelcomeModal";
import { enqueueSnackbar } from 'notistack';
import { formatTime, getHMSfromMins, formatDate as calcDate } from '../../utility-functions';
import Pusher from "pusher-js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
    responsive: true,

    scales: {
      x: {
        grid: {
          display: false,
        },
      },
    },
    layout: {
      padding: {
        left: 32,
        // right:0,
        // bottom:0,
       right: 32,
        top: 0,
        bottom: 0,
      },
    },
    plugins: {
      legend: {
        display:false,
      },
      title: {
        display: true,
        text: "CALL VOLUME",
        font: {
          size: 16,
          weight: 400,
        },
        padding: 32,
        align: "start",
      },
    },
  };
const columnTypeMapping = {'Status':'string', 'Last activity':'date', 'User':'object'}
const sortableKeys = {'User':'name'}
export default function NewDashboard() {
  const location=useLocation();
  const isOneDayRef = useRef(false)
  const pusherRef = useRef({pusher: null, channelName: null})

  const _tableColumnClicked = useSelector((state) => state.dashboard.value);
  const reduxOrg = useSelector((state) => state?.account?.value);
  const reduxSubscription = useSelector(state=>state?.account?.value?.subscription)

  const isPro = reduxSubscription?.plan?.name?.includes('Professional')

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [gtableCols, setGtableCols] = useState([]);
  const [addColumnModalVisible, setAddColumnModalVisible] = useState(false);
  const [optionsPosition, setOptionsPosition] = useState("");
  const [columnClicked, setColumnClicked] = useState({field:'', headerName:'', type:'string'});
  const [incomingCalls, setIncomingCalls] = useState("");
  const [outgoingCalls, setOutgoingCalls] = useState("");
  const [rows, setRows] = useState([]);
  const [rowsToDisplay, setRowsToDisplay] = useState([]);
  const [webAppVisible, setWebAppVisible] = useState(false)
  const [chartData, setChartData] = useState({
    datasets: [],
  });
  const [csvData, setCSVData] = useState({
    headers: [],
    data: [],
    ids: [],
  });
  const [columns, setColumns] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  // ++controller to re-fetch "/get-call-analytics/org_id" (data used in line and table charts)
  const [controller, setController] = useState(0);
  // resize controls // ✅ resize controller for height of lineChart
  const [resizeController, setResizeController] = useState(0);
  const [errorBorder, setErrorBorder] = useState({
    colNameError: false,
  });
  const [addColumn, setAddColumn] = useState({
    name: "",
    data: "",
    call_type: "",
    call_status: "",
    contact_status:"",
    duration_filter: { filter: "none", start: "", end: "" },
  });
  const [uniqueCalls, setUniqueCalls] = useState(null);
  const [overviewData, setOverviewData] = useState({
    incomingCalls: "-",
    outgoingCalls: "-",
    uniqueCalls: "-",
    missedCalls:"-",
    avgDuration: "-",
    totalMinutes: "",
  });
  const [gtableData, setGtableData] = useState([]);
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [optFor, setOptFor] = useState("USER");
  const formatDate = (date) => {
    return `${new Date(date).getFullYear()}-${(
      "0" +
      (new Date(date).getMonth() + 1)
    ).slice(-2)}-${("0" + new Date(date).getDate()).slice(-2)}`;
  };

  const [calendar, setCalendar] = useState({
    // date_start: formatDate(new Date(new Date() - (new Date().getDate() - 1) * 24 * 60 * 60 * 1000)), // start of the month
    date_start: formatDate(Date.now() - 2 * 86400000), // 2 days data
    date_end: formatDate(new Date()),
    time_start: "00:00:00",
    time_end: "23:59:59",
  });

  const initialState = {
    users: { all: [], selected: [] },
    teams: { all: [], selected: {} },
    lists: { all: [], selected: []},
    searchText: "",
    searchFields: ["User"],
  };
  const [showPlatformRules,setShowPlatformRules]=useState(false);
  const [showWelcomeModal,setShowWelcomeModal]=useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(()=>{
    if(location.state && ['signup','invite'].includes(location.state?.from)){
      setShowPlatformRules(true);
    }
  },[]);
 
  const handlePlatformRulesClose=()=>{
    // setWebAppVisible(true)
    //setShowPlatformRules(false);
    if([1,4].includes(reduxOrg?.data?.profile?.role))
      setShowWelcomeModal(true);
    else 
      setWebAppVisible(true)
  }

  const filtersReducer = (state, action) => {
    switch (action.type) {
      // data from api response set to "all" of corresponding item
      case "setPayloadToAllOfType": {        
        const field = action.payload?.payloadFor
        return {
          ...state,
          [field]: {...state?.[field], all: action.payload?.payload}
        }
      }

      case "handleSelectedOfType": {
        const field = action.payload?.payloadFor
        return {
          ...state,
          currentSet: field,
          [field]: {...state?.[field], selected: action.payload?.payload},
        }
      }

      case "handleClearOfType": {
        if (action.payload?.payloadFor !== "teams") {
          const field = action.payload?.payloadFor
          return {
            ...state,
            currentSet: field,
            [field]: { ...state?.[field], selected: [] },
          };
        }
        if (action.payload?.payloadFor === "teams") {
          return {
            ...state,
            currentSet: action.payload?.payloadFor,
            teams: { ...state?.teams, selected: {} },
          };
        }
      }

      case "setSearchText": {
        if (typeof action.payload !== "string") return state;
        return {
          ...state,
          searchText: action.payload,
        };
      }

      case "clearAllFilters": {
        return {
          ...initialState,
          users: { ...state?.users, selected: [] },
          teams: { ...state?.teams, selected: {} },
          lists: { ...state?.lists, selected: []},
        };
      }
      default:
        return state;
    }
  };

  const [availableData, dispatchAvailableData] = useReducer(
    filtersReducer,
    initialState
  );
  defaults.font.family = "Inter";


  useEffect(() => {
    setCSVData((prevState) => {
      let newIds = [];
      let newData = [];
      const activityIdx = [];
      const newHeaders = gtableCols?.map((col, idx) => {
        if(col.type === 'dateTime'){
          activityIdx.push(idx)
        }
        return [col.headerName]
      });
      for (let i = 0; i < rowsToDisplay.length; i++) {
        let item = rowsToDisplay[i];
        if (!newIds?.includes(item?.id)) {
          newIds.push(item?.id);
          let temp = Object.keys(item)
            ?.slice(1)
            ?.map((key, idx) => {
              if(item[key] && typeof item[key] === "object")
                return Object.values(item[key])?.[0]
              else if(item[key] && activityIdx.includes(idx)){
                console.log(item[key]);
                return `${calcDate(item[key])} ${formatTime(item[key])}`;
              }
              return item[key]
            });
          newData.push(temp);
        }
      }
      return { ...prevState, headers: newHeaders, data: newData, ids: newIds };
    });
  }, [rowsToDisplay]);

  useEffect(() => {
    // simple search
    const searchTextLower = availableData?.searchText?.toLowerCase();
    let finalResult = [];
    let intermediateResult = []; // eslint-disable-line
    availableData?.searchFields?.forEach((searchField) => {
      let intermediateResult = rows?.filter((row) =>{
        if(typeof row[searchField] === "object")
         return Object.values(row[searchField])?.some(val => val.toLowerCase()?.includes(searchTextLower))
        else 
         return row[searchField]?.toLowerCase()?.includes(searchTextLower)
      }
      );
      finalResult = finalResult.concat(intermediateResult);
    });

    setRowsToDisplay(finalResult);
  }, [availableData?.searchText, rows]); //eslint-disable-line


//   window.onDOMContentLoaded = handleChartsSize();

  // // init & resize & retrieve users & storeCampaigns(from /retrieve-contacts-lists/)

  function handleWindowResize() {
    setResizeController((prevState) => ++prevState);
  }

  useEffect(() => {
    handleChartsSize();
  }, [resizeController]);

  function handleChartsSize() {
    // const __statsContainer = document.querySelector(
    //   ".dashboard-overview-stats-container"
    // );
    // if (__statsContainer)
    //   __statsContainer.style.width = `calc(${document.body.clientWidth}px - var(--side-nav-width) - 64px)`;

    //FIX://resize the plotly line chart
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    handleChartsSize();
    return () => {
      window.removeEventListener("resize", handleChartsSize);
    };
  }, []);

  // retrieve-call-analytics response cleaned
  const generateDataFromResponse = (responseData) => {
    const returnData = Object.values(responseData)?.reduce(
      (cumulative, key) => {
        let { id, email, first_name, last_name, calls, status, last_activity, assigned_contacts } = key;

        let row = {
          // candidate_number: candidate_number?.slice(-10), // only last 10 digits are checked @international
          creator: email, // email of call initiater
          creatorName: first_name + " " + last_name,
          calls,
          creatorId: id,
          status,
          last_activity,
          assigned_contacts
        };

        cumulative.push(row);
        return cumulative;
      },
      []
    );
    return returnData;
  };

  useEffect(() => {
    if (!data || !Array.isArray(data) || data.length === 0) return;
    getFilteredData(data);
  }, [availableData.users, availableData.teams, availableData.lists, data]);

  const getFilteredData = (data) => {
    if (
      availableData?.users?.selected?.length === 0 &&
      Object.keys(availableData?.teams?.selected)?.length === 0 
      && availableData?.lists?.selected?.length === 0
    ) {
      setFilteredData(data);
      setDataForChart(data);
      return;
    }
    let temp = [];
    const usersIds = new Set();
    if (availableData?.users?.selected?.length >= 1) {
      availableData?.users?.selected?.forEach((u) => usersIds.add(u));
    }

    const teamObj = availableData?.teams?.selected;

    if (Object.keys(teamObj)?.length >= 1) {
      Object.keys(teamObj)?.forEach((tid) => {
        teamObj[`${tid}`]?.forEach((u) => usersIds.add(u));
      });
    }

    const unselectedUsers=[]
    temp = data.filter((user) => {
      if (usersIds.has(user.creatorId))
        return true;
      unselectedUsers.push(user);
    });

    let temp2 = usersIds.length === 0 ? [...data] : [...unselectedUsers]
    if(availableData?.lists?.selected?.length > 0 ){
    
    temp2 = temp2.map((user) => {
      return {
        ...user,
        calls: user.calls?.filter((call) => availableData?.lists?.selected?.includes(call.contact_list_id)),
        assigned_contacts: user.assigned_contacts?.filter((contact) => availableData?.lists?.selected?.includes(contact.contact_list_id))
      }
    });
    setFilteredData([...temp,...temp2]);
    setDataForChart([...temp,...temp2]);
    return [...temp,...temp2];
  }

    setFilteredData(temp);
    setDataForChart(temp);
    return temp;
  };

  function getWeekDayLabel(data) {
	return data?.map((elem) => {
        let label = elem.date.toLocaleDateString("en-US", {
          weekday: "short",
        });
        if (label === "Sun") {
          label = `Sun \n ${elem.date.toLocaleDateString("en-US", {
            month: "numeric",
            day: "numeric",
          })}`;
        }
        return label;
    })
  }

  function getHoursLabel(data) {
	return data?.map((elem, i) => {
        let label = elem.date.toLocaleTimeString("en-US", {
			hour: "numeric",
			minute: "numeric",
			hour12: false,
		})
		if (!i) {
			label = `${elem.date.toLocaleDateString("en-US", {
				month: "numeric",
				day: "numeric",
          	})} \t ${label}`
		}
        return label;
    })
  }

  const setDataForChart = (data) => {
    const formattedData = formatDataForChart(data);

    setChartData({
      labels: isOneDayRef.current ? getHoursLabel(formattedData) : getWeekDayLabel(formattedData),
      datasets: [
        {
          label: "Total calls",
          data: formattedData?.map((elem) => elem.data.total),
          borderColor: "rgb(129, 139, 240)",
          backgroundColor: "rgba(129, 139, 240)",
        },
        {
          label: "Answered Calls",
          data: formattedData?.map((elem) => elem.data.answered),
          borderColor: "rgb(62, 207, 142)",
          backgroundColor: "rgba(62, 207, 142)",
        },
        {
          label: "Unanswered Calls",
          data: formattedData?.map((elem) => elem.data.unanswered),
          borderColor: "rgb(219,120,221)",
          backgroundColor: "rgba(219,120,221)",
        },
      ],
    });
  };

  useEffect(() => {
    fetchCallAnalytics(calendar);
  }, [controller]);
  const fetchCallAnalytics = (calendar) => {
    setData("");
    setGtableData("");
    setShowLoader(true);

    let orgUrlParam = "";
    if (reduxOrg?.data?.profile?.account_holder &&
        reduxOrg?.allOrgs?.length > 1) {
      orgUrlParam = reduxOrg?.selectedOrg + "/";
      if (!reduxOrg?.selectedOrg && reduxOrg?.selectedOrg !== 0)
        orgUrlParam = "";
    }

    JService.get(`/api/v1/analytics/retrieve-call-analytics/${orgUrlParam}?date_start=${calendar?.date_start}&date_end=${calendar?.date_end}&time_start=${calendar.time_start}&time_end=${calendar.time_end}`)
      .then((res) => {
        if (!res?.success || !Array.isArray(res?.data)) {
			  throw new Error(
				res?.message || res?.detail || "Error while fetching data for dashboard"
			  );
		}
        if (res?.data?.length === 0) {
		  enqueueSnackbar(res?.message +". No calls were made in the selected time range", { variant: "error"})
          
          setData([]);
          setGtableData([]);
          setShowLoader(false);
        }

        let temp = generateDataFromResponse(res?.data);
        setDataForChart(res.data);
        		// subscribe to pusher
        const channelName = orgUrlParam.length ? orgUrlParam.replace("/", "") :  String(reduxOrg?.data?.profile?.org)
        if(reduxSubscription?.plan?.name?.includes('Professional')){
        connectPusher(channelName)
        }
        setData(temp);
      })
      .catch((err) => enqueueSnackbar(err?.message || "Error occurred while fetching data", { variant: "error"}))
      .finally(() => handleChartsSize())
  };

  useEffect(()=>{
    return () => disconnectPusher()
  },[])
  const disconnectPusher = () =>{
    if(pusherRef.current?.pusher){
      console.log('disconnecting pusher')
      pusherRef.current.pusher.unsubscribe(pusherRef.current.channelName);
      pusherRef.current.pusher=null;
      pusherRef.current.channelName=null;
    }
  }
  const connectPusher = channelName => {
		if(pusherRef.current.pusher) {
			console.log("Pusher connection already established")
			return
		}
		pusherRef.current.pusher = new Pusher("498da914b14760d4c18c", {
			cluster: "ap2",
			encrypted: true,
		});
		pusherRef.current.channelName = channelName
			
		const channel = pusherRef.current.pusher.subscribe(channelName);
		channel.bind("user_status", (pusherUpdate) => {
			handlePusherUpdate(pusherUpdate);
		});
	}

	function handlePusherUpdate(pusherUpdate) {
    if(pusherUpdate.status?.toLowerCase() === "on a call"){
      setData(rows => {
        const updatedRows = rows.map(row => {
          return row?.creatorId === pusherUpdate?.user_id ? ({...row, status:pusherUpdate.status, last_activity:'--'}) : row
        })
        return updatedRows
      })
    }
    else if(pusherUpdate.status?.toLowerCase() === "available"){
      const updateCalls = new Date(pusherUpdate.call_data?.end) <= new Date(`${calendar?.date_end}:23:59:59`) && new Date(pusherUpdate.call_data?.end) >= new Date(`${calendar?.date_start}:00:00:01`)
      setData(rows => {
        const updatedRows = rows.map(row => {
          return row.creatorId === pusherUpdate.user_id ? ({...row,last_activity:pusherUpdate.call_data?.end, status:pusherUpdate.status, calls: updateCalls ? [...row.calls, pusherUpdate.call_data]: [...row.calls]}): row;
        })
        return updatedRows;
      })
    }
	}

  //To initially fetch columns metadata,users and teams
  useEffect(() => {
    let orgUrlParam = "";
    if (reduxOrg?.data?.profile?.account_holder) {
      orgUrlParam = reduxOrg?.selectedOrg + "/";
      if (!reduxOrg?.selectedOrg && reduxOrg?.selectedOrg !== 0)
        orgUrlParam = "";
    }

    const handleSetUsers = (data) => {
      dispatchAvailableData({
        type: "setPayloadToAllOfType",
        payload: {
          payloadFor: "users",
          payload: data?.map((u) => ({
            name: u?.first_name + " " + u?.last_name,
            id: u?.id,
          })),
        },
      });
    };
    const handleSetTeams = (data) => {
      dispatchAvailableData({
        type: "setPayloadToAllOfType",
        payload: {
          payloadFor: "teams",
          payload: data?.map((u) => ({
            name: u.name,
            id: u.id,
            users: u.users || [],
          })),
        },
      });
    };
    const handleSetLists = (data) => {
      dispatchAvailableData({
        type:'setPayloadToAllOfType',
        payload: {
          payloadFor: "lists",
          payload: data?.map((l) => ({
            name: l?.title,
            id: l?.id,
          })),
        },
      })
    }

    if(reduxSubscription?.plan?.name?.includes("Standard")) setColumns(defaultColData)
	else fetchColumns();
    fetchData(`/api/v1/auth/retrieve-users/${orgUrlParam}`,
      handleSetUsers,
      "users"
    );
    if(reduxOrg?.data?.access_list?.includes('TEAMS')){
    fetchData("/api/v1/auth/teams/", handleSetTeams, "teams");
    }
    fetchData("/api/v1/core/retrieve-contact-lists/", handleSetLists, "contact lists")
  }, []);
  const fetchData = (url, setterFn, resourceName) => {
    JService.get(url)
      .then((res) => {
        if(!res?.success) throw new Error(res?.message || `Could not retrieve ${resourceName}`);
        setterFn(res?.data);
      })
      .catch((err) => console.warn(`Could not retrieve ${resourceName}`, err));
  };
  const fetchColumns = () => {
    JService.get("/api/v1/auth/retrieve-account-profile/")
      .then((res) => {
        if (!res?.success) throw new Error(res?.message || "Could not retrieve account details");
        setColumns(
          res?.data?.profile?.analytics_metrics.length > 0
            ? res?.data?.profile?.analytics_metrics
            : defaultColData
        );
      })
      .catch((err) => console.warn("Could not retrieve account details", err));
  };

  useEffect(() => {
    if (!columns || !Array.isArray(columns) || columns.length === 0) return;
    if (!data || !Array.isArray(data) || data.length === 0) return;

    createGtableColMetadata(columns);

    getCustomColumnData(columns);
  }, [columns, data, gtableData, filteredData]);

  const formatDataForChart = (data) => {
    if (Array.isArray(data) && data.length !== 0) {
      let _lastDate = "";
      let unanswered = 0;
      let answered = 0;
      let lineTempData = data?.flatMap((aa) => aa?.calls);
      // this data has to be date-wise split and sorted
      lineTempData?.sort(
        (a, b) => new Date(a?.start)?.getTime() - new Date(b?.start)?.getTime()
      );

	  // check if end - start <= 24h
	  if(new Date(lineTempData.at(-1)?.start)?.getTime() - new Date(lineTempData.at(0)?.start)?.getTime() <= 86400 * 1000) {
		isOneDayRef.current =  true
	  } else {
		isOneDayRef.current =  false
	  }

      let cleanData = [];

		if(isOneDayRef.current) {
			let _currentDate = null;
			for(let i=0; i<lineTempData.length; i++) {
				_currentDate = new Date(lineTempData.at(i)?.start);
				if (i === 0) _lastDate = _currentDate;
				// check for same hour
				if(_currentDate.getFullYear() === _lastDate.getFullYear() &&
				_currentDate.getMonth() === _lastDate.getMonth() &&
				_currentDate.getDate() === _lastDate.getDate() &&
				_currentDate.getHours() === _lastDate.getHours()) {
					if (lineTempData.at(i)?.call_status === "answered") answered += 1;
					  else unanswered += 1;
				} else {
					const _pushData = {
					  date: new Date(new Date(_lastDate).setMinutes(0, 0)),
					  data: { answered, unanswered, total: answered + unanswered },
					};
	
					cleanData.push(_pushData);
	
					unanswered =
					lineTempData.at(i)?.call_status === "unanswered" ||
					lineTempData.at(i)?.call_status === "user-not-available/not-reachable" ||
					lineTempData.at(i)?.call_status === "user-busy" ||
					lineTempData.at(i)?.call_status === "not_available" ||
					lineTempData.at(i)?.call_status === "not-answered"
					  ? 1
					  : 0;
					  answered = lineTempData.at(i)?.call_status === "answered" ? 1 : 0;
					  _lastDate = _currentDate;
				}
			}
			if (lineTempData?.length !== 0)
			cleanData.push({
			  date: new Date(new Date(_lastDate).setMinutes(0, 0)),
			  data: { answered, unanswered, total: answered + unanswered },
			});
		}
		else {
			lineTempData?.map((c, _in) => {
				// ❗unhandled if start and end is different day
				// start is used

				let _currentDate = null;
				const __start = new Date(c?.start);
				const __end = new Date(c?.end);

				_currentDate = new Date(c?.start);

				if (_in === 0)
				_lastDate = _currentDate;

				if (
				_currentDate &&
				_lastDate &&
				_currentDate?.getDate() === _lastDate?.getDate() &&
				_currentDate?.getMonth() === _lastDate?.getMonth() &&
				_currentDate?.getFullYear() === _lastDate?.getFullYear()
				) {
				if (c?.call_status === "answered") answered += 1;
				else unanswered += 1;
				} else {
				const _pushData = {
					date: new Date(new Date(_lastDate).toDateString()),
					data: { answered, unanswered, total: answered + unanswered },
				};
				cleanData.push(_pushData);

				unanswered =
					c?.call_status === "unanswered" ||
					c?.call_status === "user-not-available/not-reachable" ||
					c?.call_status === "user-busy" ||
					c?.call_status === "not_available" ||
					c?.call_status === "not-answered"
					? 1
					: 0;
				answered = c?.call_status === "answered" ? 1 : 0;
				_lastDate = _currentDate;
				}
			});
			// pushing leftover item into cleanData
		    if (lineTempData?.length !== 0)
		      cleanData.push({
		        date: new Date(new Date(_lastDate).toDateString()),
		        data: { answered, unanswered, total: answered + unanswered },
		      });
		}

      const _uniqueCalls = [];
      let seenSet = new Set();
      const _incomingCalls = [];
      const _outgoingCalls = [];
      const _unknownCalls = [];
      let ___totalMinutes = 0;
      let _missedCalls=0;
      lineTempData?.map((aa) => {
        if (!seenSet.has(aa?.candidate_number)) {
          seenSet.add(aa?.candidate_number);
          _uniqueCalls.push(aa);
        }
        if (aa?.call_type?.toLowerCase() === "inbound") {
          _incomingCalls.push(aa);
          if(aa?.call_status !== "answered"){
            _missedCalls+=1;
          }
        }
        else if (aa?.call_type?.toLowerCase() === "outbound")
          _outgoingCalls.push(aa);
        else _unknownCalls.push(aa);

        if (
          aa?.duration &&
          (aa.duration instanceof Number || typeof aa.duration === "number")
        )
          ___totalMinutes += aa.duration;
      });

      setUniqueCalls(_uniqueCalls);
      setIncomingCalls(_incomingCalls);
      setOutgoingCalls(_outgoingCalls);

      setOverviewData((prevState) => ({
        ...prevState,
        totalMinutes: ___totalMinutes,
        missedCalls:_missedCalls
      }));
      setShowLoader(false);
      if (cleanData.length === 0 && reduxSubscription?.plan?.name?.includes('Standard')) {
       setFilteredData([]);
      }
      return cleanData;
    }
  };
  const getCustomColumnData = (columns) => {
    if (!data || !Array.isArray(data) || data.length === 0) return;
    const newdata = filteredData.map((u) => {
      const obj = {
        id: u.creatorId,
        User: {name: u.creatorName, email:u.creator},
      }
      return obj;
    });
    

    columns.forEach((column) => {
      let __data = filteredData;

      const {
        call_status,
        call_type,
        duration_filter,
        contact_status,
        data: column_data,
        name: column_name,
      } = column;

      if(['id','User'].includes(column_name)) return;

      if (call_type) {
        if (call_type === "outgoing")
          __data = __data?.map((u) => ({
            ...u,
            calls: u?.calls?.filter(
              (__d) => __d?.call_type.toLowerCase() === "outbound"
            ),
          }));
        else if (call_type === "incoming")
          __data = __data?.map((u) => ({
            ...u,
            calls: u?.calls?.filter(
              (__d) => __d?.call_type.toLowerCase() === "inbound"
            ),
          }));
      }

      if (duration_filter) {
        if (duration_filter.filter === "less than")
          __data = __data?.map((u) => ({
            ...u,
            calls: u?.calls?.filter(
              (__d) => __d?.duration < duration_filter.start
            ),
          }));
        else if (duration_filter.filter === "greater than")
          __data = __data?.map((u) => ({
            ...u,
            calls: u?.calls?.filter(
              (__d) => __d?.duration > duration_filter.start
            ),
          }));
        else if (duration_filter.filter === "between")
          __data = __data?.map((u) => ({
            ...u,
            calls: u?.calls?.filter(
              (__d) =>
                __d?.duration > duration_filter.start &&
                __d?.duration < duration_filter.end
            ),
          }));
      }
      if (call_status) {
        if (call_status === "answered") {
          __data = __data?.map((u) => ({
            ...u,
            calls: u?.calls?.filter((__d) =>
              ["call-initiating...", "answered", "ongoing.."].includes(
                __d?.call_status
              )
            ),
          }));
        }

        if (call_status === "unanswered") {
          __data = __data?.map((u) => ({
            ...u,
            calls: u?.calls?.filter((__d) =>
              [
                "not-answered",
                "user-busy",
                "busy",
                "not_available",
                "user-not-available/not-reachable",
                "user-not-answered",
              ].includes(__d?.call_status)
            ),
          })); // 'not-initiated'
        }

        if (call_status === "unique") {
          let seenSet;
          __data = __data.map((u) => {
            seenSet = new Set();
            return {
              ...u,
              calls: u?.calls?.filter((c) => {
                if (!seenSet.has(c.candidate_number)) {
                  seenSet.add(c.candidate_number);
                  return true;
                }
                return false;
              }),
            };
          });
        }
      }
      if(contact_status){
        __data = __data.map((u) => {
         let seenSet = new Set();
          u?.calls?.forEach((c) => {
              seenSet.add(c.contact_id);
            })
          return {...u, contacts_reached: seenSet}
        });
        if(contact_status === "reached"){
          __data = __data.map((u) => {
            return {
              ...u,
              assigned_contacts : u.assigned_contacts?.filter(c => u.contacts_reached.has(c.contact_id))
            }
          })
        }
        else if(contact_status === "not reached"){
          __data = __data.map((u) => {
            return {
              ...u,
              assigned_contacts : u.assigned_contacts.filter(c => !u.contacts_reached.has(c.contact_id))
            }
          })
        }
      }

      if (column_data === "total calls") {
        __data?.map((u, i) => (newdata[i][column_name] = u?.calls?.length));
      } else if (column_data === "total minutes")
        __data?.map(
          (u, i) =>
            (newdata[i][column_name] = u?.calls
              ?.reduce(
                (totalTillNow, currentKey) =>
                  (totalTillNow += currentKey?.duration || 0),
                0
              )
              .toFixed(2))
        );
      else if (column_data === "average minutes") {
        __data?.map((u, i) => {
          let uu = u?.calls?.length
            ? u?.calls?.reduce(
                (totalTillNow, currentKey) =>
                  (totalTillNow += currentKey?.duration || 0),
                0
              ) / u?.calls?.length
            : 0;
          if (uu === 0 || typeof uu === "number" || uu instanceof Number) {
            newdata[i][column_name] = uu?.toFixed(2);
          } else newdata[i][column_name] = uu;
        });
      }
      else if(column_data === "assigned contacts"){
        __data?.map((u, i) => {
          const uniqueContacts = new Set();
          u?.assigned_contacts?.forEach(contact=>{
            uniqueContacts.add(contact.contact_id)
          })
          newdata[i][column_name] = uniqueContacts.size;
        });
      }
      else if(column_data === "last activity"){
        __data?.map((u,i) => {
          let lastActivity = new Date(0);
          if(availableData?.lists?.selected?.length === 0){
            lastActivity = u.last_activity;
          }
          else { 
            u?.calls?.forEach(c => {
            lastActivity = Math.max(lastActivity, new Date(c.end));
            })
            if(String(lastActivity) == String(new Date(0))){
              lastActivity = '--'
            }
        }
          return newdata[i][column_name] = lastActivity

        })
      }
      else if(column_data==='status' && isPro){
        __data?.map((u,i)=>{
          return newdata[i]['Status'] = u.status;
        })
      }
    });

    setRowsToDisplay(newdata);
    setRows(newdata);
  };

  const clickCSVDownloadIcon = () => {
    console.log("data that will be downloaded: ", rowsToDisplay);
    // // @celvertapv1
    // clevertap.event.push("Clicked download", {
    //   "Downloaded Table": "Dashboard table report",
    //   userEmail: reduxOrg?.data?.email,
    //   source: "dashboard " + JSON.stringify(calendar),
    //   path: window.location.href,
    //   IDENTITY: clevertap.getCleverTapID(),
    // });
  };

  useEffect(() => {
    setOptFor(_tableColumnClicked);
  }, [_tableColumnClicked]);
  const sortRows = (rowsdata, order, fn=null) => {
    if (order === "sort-asc")
      rowsdata.sort((a, b) => {
        const aval = fn ? fn(a) : a[columnClicked.field]
        const bval = fn ? fn(b) : b[columnClicked.field];
        return aval > bval
          ? 1
          : aval === bval
          ? 0
          : -1
      }
      );
    else if (order === "sort-desc")
      rowsdata.sort((a, b) =>{
        const aval = fn ? fn(a) : a[columnClicked.field]
        const bval = fn ? fn(b) : b[columnClicked.field];
        return bval > aval
          ? 1
          : aval === bval
          ? 0
          : -1
      }
      );
    setRowsToDisplay([...rowsdata]);
  }
  // options controls
  const handleTableOptionsClick = (e) => {
    const _field = e.target.dataset.optionstype;
    setOptionsVisible(false);
    const rowsdata = [...rowsToDisplay];

    let _columns = [...columns];
    if (_field === "del" && !reduxSubscription?.plan?.name?.includes("Standard")) {
      _columns = _columns.filter((col) => col.name !== columnClicked.headerName);
      updateColumns(_columns);
    }
    if(columnTypeMapping[columnClicked.field] === 'object'){
      sortRows(rowsdata, _field, (val)=>val[columnClicked.field][sortableKeys[columnClicked.field]]);
    }
    else if(columnClicked.type === 'dateTime'){
      sortRows(rowsdata, _field, (val)=>new Date(val[columnClicked.field]))
    }
    else if (columnTypeMapping[columnClicked.field] === 'string') {
      sortRows(rowsdata, _field);
    }
    else {
      sortRows(rowsdata, _field)
    }
  };

  // click handler for Add Column button
  const handleAddColumn = (e) => {
	if(!reduxSubscription?.plan?.name?.includes("Standard")) {
		setAddColumn((prevState) => {
		  return {
			...prevState,
	
			name: "",
			data: "",
			call_type: "",
			call_status: "",
			duration_filter: { filter: "none", start: "", end: "" },
		  };
		});
		setAddColumnModalVisible(true);
		document.body.style.overflowY = "hidden";
	}
	else enqueueSnackbar("Customizable reports are not included in Standard plan", { variant: "error"})
  };

  const handleOverlayClick = (e) => {
    // setAddColumn(prevState => ({...prevState, data:{colName:'', colData:'', colCallType: '', colCallStatus:'', colDurationFilter:{filter:'none',start:'', end:''},}, isVisible: false, add:false}))
    setAddColumn({
      name: "",
      data: "",
      call_type: "",
      call_status: "",
      duration_filter: { filter: "none", start: "", end: "" },
    });
    setAddColumnModalVisible(false);
    document.body.style.overflowY = "auto";
  };

  const handleAddColumnCancelClick = (e) => {
    setAddColumn({
      name: "",
      data: "",
      call_type: "",
      call_status: "",
      duration_filter: { filter: "none", start: "", end: "" },
    });
    setAddColumnModalVisible(false);
    document.body.style.overflowY = "auto";
  };
  // click on close icon of add column
  const handleAddColumnCloseClick = (e) => {
    setAddColumn({
      name: "",
      data: "",
      call_type: "",
      call_status: "",
      duration_filter: { filter: "none", start: "", end: "" },
    });
    setAddColumnModalVisible(false);
    document.body.style.overflowY = "auto";
  };

  const handleTableDurationFilterChange = (e) => {
    const __filter = e.target.value;

    setAddColumn((prevState) => {
      return {
        ...prevState,
        duration_filter: {
          filter: __filter,
          start: __filter === "none" ? "" : 0,
          end: __filter === "none" ? "" : __filter === "between" ? 0 : null,
        },
      };
    });
  };

  const handleAddColumnDataChange = (e) => {
    const __field = e.target.dataset.coldatafield;
    setAddColumn((prevState) => {
      if (__field === "duration_filter") {
        const _prevFilter = prevState.duration_filter.filter;
        if (_prevFilter === "less than" || _prevFilter === "greater than")
          return {
            ...prevState,

            duration_filter: {
              ...prevState.duration_filter,
              start: e.target.value,
            },
          };
        else if (_prevFilter === "between") {
          if (e.target.dataset.coldurationminfield === "start")
            return {
              ...prevState,

              duration_filter: {
                ...prevState.duration_filter,
                start: e.target.value,
              },
            };
          if (e.target.dataset.coldurationminfield === "end")
            return {
              ...prevState,

              duration_filter: {
                ...prevState.duration_filter,
                end: e.target.value,
              },
            };
        }
      } else if (__field === "call_status")
        return {
          ...prevState,
          call_status: e.target.value,
        };
      else if (__field === "call_type")
        return {
          ...prevState,
          call_type: e.target.value,
        };
      else if (__field === "data")
        return {
          ...prevState,
          data: e.target.value,
        };
      else if (__field === "contact_status")
        return {
          ...prevState,
          "contact_status": e.target.value,
        };
      else if (__field === "name") {
        const _value = e.target.value;
        setErrorBorder((prevState) => ({
          ...prevState,
          colNameError: !columns.find((tc) => tc?.name === _value)
            ? false
            : true,
        }));
        return {
          ...prevState,
          name: _value,
        };
      }
    });
  };

  //checking validity of metadata of new custom column and req to save at backend.
  const handleAddColumnSave = (e) => {
    e.preventDefault();
    if (addColumn?.name?.trim().length === 0 || addColumn?.data?.length === 0) {
	  const message = addColumn?.name?.trim().length === 0
	  ? "Please give a valid name for the column."
	  : 'Please select the type of data you want in the column under "Column Data".'
	  enqueueSnackbar(message, { variant: "error"})
      return;
    }
    //getCustomColumnData();
    if (gtableCols.map((col) => col.headerName).includes(addColumn?.name)) {
      setErrorBorder((prevState) => ({ ...prevState, colNameError: true }));
	  enqueueSnackbar("Column name has already been used. Please choose another name", {variant: "error"})
      return;
    }
    setErrorBorder((prevState) => ({ ...prevState, colNameError: false }));
    const updatedColumns = [...columns, addColumn];

    updateColumns(updatedColumns);
    handleAddColumnCloseClick("a");
  };

  const updateColumns = (data) => {
    JService.patch("/api/v1/auth/update-userprofile/", {
      analytics_metrics: data,
    })
      .then((res) => {
        if (!res?.success)
          throw new Error(res?.message || "Could not add column");

		enqueueSnackbar(res?.message || "Custom column added", { variant: "success"})
        setColumns(
          res?.data?.data?.analytics_metrics?.length > 0
            ? res?.data?.data?.analytics_metrics
            : defaultColData
        );
		window.location.replace(window.location.pathname)
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error"}))
  };
  const averageMinutes = overviewData?.totalMinutes
  ? (overviewData?.totalMinutes || 0) /
    ((outgoingCalls?.length || 0) + (incomingCalls?.length || 0))
  : null;
  const convertToMinutesAndSeconds = (totalMinutes) => {
    const totalSeconds = Math.floor(totalMinutes * 60);

    if (totalSeconds === 0) {
        return '-';
    }

    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60

    if (seconds === 60) {
        return `${minutes + 1}m 0s`;
    } else if (minutes === 60) {
        return `${Math.floor(minutes / 60)}h 0m`;
    }

    const formattedMinutes = minutes > 0 ? `${minutes}m` : '';
    const formattedSeconds = seconds > 0 ? `${seconds}s` : '';

    return `${formattedMinutes} ${formattedSeconds}`.trim();
};

  //creating column metadata for gtable
  const createGtableColMetadata = (columns) => {
    if (!columns || !Array.isArray(columns) || columns.length <= 0) return;
    const columnsWithUsers = [{ name: "User", minWidth:256 }, ...columns];
    const gtableColMetadata = columnsWithUsers.map((col) => {
    const colname = col["name"];
	  let temp = ""
      return {
        field: col['headerName'] || colname,
        headerName: colname,
        type: col['data'] === "last activity" ? 'dateTime':'string',
        cellClassName: "gtable-cell",
        headerClassName: "dashboard-header",
        minWidth: col['minWidth'] ?? 220,
        flex:1,
        renderCell: (params) => {
          if (col["data"] === "total minutes") {
            const { hrs, mins, secs } = getHMSfromMins(params.row[colname]);
        
            if (hrs === 0 && mins === 0 && secs === 0) {
                temp = '-';
            } else {
                let formattedTime = '';
        
                if (hrs > 0) {
                    formattedTime += `${hrs}h `;
                }
        
                if (mins > 0) {
                    formattedTime += `${mins}m `;
                } else if (hrs === 0 && secs === 0) {
                    formattedTime = '';
                }
        
                if (secs > 0) {
                    formattedTime += `${secs}s`;
                }
        
                if (secs === 60) {
                    formattedTime = `${mins + 1}m 0s`;
                } else if (mins === 60) {
                    formattedTime = `${hrs + 1}h 0m`;
                }
        
                temp = formattedTime.trim();
            }
        }
        
        
        
              
        else if(col["data"] === "status"){
          return (
             <div className='status-container'>
                <span className={`status-circle ${params?.row?.Status}`}></span>
                <p className="t7 regular-font">{params?.row?.Status}</p>
              </div>
            )
        }
        else if(col['data'] === "last activity"){
          if(params?.row?.[colname] && params?.row?.[colname]!='--' && params?.row?.Status?.toLowerCase() !=='on a call')
            return <div style={{paddingLeft:'14px'}}>
              <p className="t7 regular-font">{calcDate(params.row[colname])}</p>
              <p className="t7 regular-font nc-gray-600">{formatTime(params.row[colname])}</p>
            </div>
          else 
            return <p style={{paddingLeft:'14px'}}>--</p>
        }
        else if(colname === "User"){
          return (
            <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              paddingLeft: "14px",
            }}
          >
            <span className="t7 regular-font nc-gray-900">
              {params?.row?.User?.name}
            </span>
            <span className="t7 regular-font nc-gray-600">
              {params?.row?.User?.email}
            </span>
          </div>
          )
        }
          return (
            <p className="regular-font b1" style={{ marginLeft: "14px" }}>
              {temp || params.row[colname]}
            </p>
          );
        },
        sortable: false,
      };
    });

    setGtableCols(gtableColMetadata);
  };

  // table edit-column controls
  // const handleEditColumnClick = (e, __clickedOn) => {
  //   setAddColumn((prevState) => {
  //     let _uneditable = ["USER", "MINUTES", "CALLS"];
  //     let _tableColumn = tableColumns.find((tc) =>
  //       tc.colData
  //         ? !_uneditable.includes(__clickedOn)
  //           ? __clickedOn.toLowerCase() === tc?.colName
  //           : null
  //         : null
  //     );
  //     console.log(_tableColumn, " _tableColumn");
  //     return {
  //       data: _tableColumn,
  //       colFor: __clickedOn,
  //       isVisible: true,
  //       add: false,
  //     };
  //   }); // data:{colName:'', colData:'', colCallType: '', colCallStatus:'', colDurationFilter:{filter:null,start:null, end:null},}, isVisible: true, add:false}))
  //   setOptionsVisible(false);
  //   document.body.style.overflowY = "hidden";
  // };

  // if there are calls only for one day
  // useEffect(()=>{
  //     if(Array.isArray(lineData) &&
  // 	//  calendar?.date_start === calendar?.date_end &&
  // 	cleanLineData?.length === 1 &&
  // 	  lineData?.length === 3 && lineData[1]?.x?.length === 1 && lineData[2]?.x.length === 1 && lineData[0]?.x.length === 1 && lineData[0]?.y.length === 1 && lineData[1]?.y.length === 1 && lineData[2]?.y.length === 1 && isAllCallsOnOneDay){

  //         setLineData(prevState => {
  //             return[{...prevState[0],x:[new Date(new Date(prevState[0]?.x).getTime() - 86400000), ...prevState[0]?.x], y:[0, ...prevState[0]?.y] }, {...prevState[1], x:[new Date(new Date(prevState[1]?.x).getTime() - 86400000), ...prevState[1]?.x], y:[0, ...prevState[1]?.y] }, {...prevState[2],x:[new Date(new Date(prevState[2]?.x).getTime() - 86400000), ...prevState[2]?.x], y:[0, ...prevState[2]?.y] }]
  //         })
  //     }
  // }, [lineData])

  const prevReduxOrg = useRef(reduxOrg?.selectedOrg);
  useEffect(() => {
    if (
      prevReduxOrg.current !== reduxOrg?.selectedOrg &&
      (Boolean(reduxOrg?.selectedOrg) || reduxOrg?.selectedOrg === 0)
    ) {
      // reduxOrg changed
      // setorgdetails again if allOrgs?.length > 1 && primaryAccount holder
      setShowLoader(false);
      prevReduxOrg.current = reduxOrg?.selectedOrg;
      setController((prevState) => ++prevState);
    }
  }, [reduxOrg]);
  const defaultColData = [
    {
      name: "Total calls",
      data: "total calls",
      call_type: "incoming and outgoing",
      call_status: "all",
      duration_filter: {
        filter: "none",
        start: "",
        end: "",
      },
    },
    {
      name: "Total duration",
      data: "total minutes",
      call_type: "incoming and outgoing",
      call_status: "all",
      duration_filter: {
        filter: "none",
        start: "",
        end: "",
      },
    },
  ];

  const onColumnHeaderClick = (params, event) => {
    setColumnClicked({field: params.field, headerName: params.colDef?.headerName, type: params.colDef?.type});
    setOptionsPosition(`translate(${event.pageX - 260 + 20}px, 80.5px)`);
    setOptionsVisible((prev) => !prev);
  };

  const handleFilterApply = (filterName, val) => {
    if (filterName === "Date") {
      setCalendar(val);
      fetchCallAnalytics(val);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const handleWebappPc =()=>{
   setWebAppVisible(false)
   }

    
  const body =<div className="useWeb"><div className="useweblogo"><UseWebAppPc/></div><div className="useWebDis">To unlock the full potential of our platform and enjoy an optimized experience, we recommend using the web app version on your PC.</div></div>
  return (
    <div className="overview-container">
      {showPlatformRules && <PlatformRules handleClose={handlePlatformRulesClose}/>}
      <WelcomeModal visible={showWelcomeModal} handleClose={()=>{setShowWelcomeModal(false); setWebAppVisible(true)}}/> 
      {isMobileView && <GModal visible={webAppVisible} closeModal={handleWebappPc} body={body} bodyWrapperStyle={{height:'268px', width:'312px'}} heading={""} bottomButtons={[]}/>}
      
      {/* <GHeader
        customStyle={{ paddingTop: 0 }}
        breadcrumbs={false}
        title="Dashboard"
        threeDots={false}
        topHeader={{}}
        bottomHeader={{
          contentTitle: " ",
          buttons: [],
        }}
      /> */}

      <Filters
        onApply={handleFilterApply}
        availableData={availableData}
        dispatchAvailableData={dispatchAvailableData}
        fromScreen="dashboard"
        // filtersList={[{ name: "Team" },{ name: "Users" },{name:"List"} ]}
        filtersList={[... reduxSubscription?.plan?.name?.includes("Professional") ? [{ name: "Team" }] : [],
        ,{ name: "Users" }, {name:"List"}]}
      />

      {showLoader ? (
        <AnimationPage />
      ) : !showLoader && filteredData?.length === 0 ? (
        <p
          className="t6 nc-gray-900 regular-font"
          style={{ textAlign: "center" }}
        >
          No call records to show for the selected date-range / search{" "}
        </p>
      ) : (
        resizeController > -1 && (
          <>
            <div className="dashboard-charts-container">
              <div className="dashboard-container">
              <div className="dashboard-stats-container">
              <div className="dashboard-overview-stats-container nc-gray-900 regular-font">
                <div className="dashboard-overview-stats">
                    <p className="title t6">Incoming calls</p>
                    <p className="t4 medium-font">
                      {incomingCalls?.length || "-"}
                    </p>
                </div>
                <div className="dashboard-overview-stats">
                  <p className="title t6">Outgoing calls</p>
                  <p className="t4 medium-font">
                    {outgoingCalls?.length || "-"}
                  </p>
                </div>
                </div>
                <div className="dashboard-overview-stats-container nc-gray-900 regular-font">
                  <div className="dashboard-overview-stats">
                    <p className="title t6">Missed calls</p>
                    <p className="t4 medium-font">{overviewData.missedCalls > 0 ? overviewData.missedCalls: "-"}</p>
                  </div>
                  <div className="dashboard-overview-stats">
                    <p className="title t6">Unique calls</p>
                    <p className="t4 medium-font">{uniqueCalls?.length || "-"}</p>
                  </div>
                </div>
                <div className="dashboard-overview-stats-container nc-gray-900 regular-font">
                <div className="dashboard-overview-stats">
                    <p className="title t6">Total duration</p>
                    <p className="t4 medium-font">
                    {overviewData?.totalMinutes? convertToMinutesAndSeconds(overviewData?.totalMinutes) : "-"}
                    </p>
                  </div>
                  <div className="dashboard-overview-stats">
                    <p className="title t6">Avg duration</p>
                    <p className="t4 medium-font">
                    {averageMinutes ? convertToMinutesAndSeconds(averageMinutes) : "-"}
                    </p>
                  </div>
                </div>
                
              </div>
              <div className="dashboard-linechart-container">
                {/* <div>CALL VOLUME</div> */}
                <div style={{height:"calc(100%)", width:'100%'}}>
                <Line options={options} data={chartData} />
                </div>
                <div className="legend-container">
                  <div className="legend">
                    <div className="legend-box-1"></div>
                    <p className="t7 regular-font">Total Calls</p>
                  </div>
                  <div className="legend">
                    <div className="legend-box-2"></div>
                    <p className="t7 regular-font">Answered Calls</p>
                  </div>
                  <div className="legend">
                    <div className="legend-box-3"></div>
                    <p className="t7 regular-font">Unanswered Calls</p>
                  </div>
                </div>
              </div>
              </div>
              <div className="dashboard-chart-container">
                {!showLoader && (
                  <div
                    style={{
                      height: "max-content",
                      maxWidth: `calc(${document.body.clientWidth}px - var(--side-nav-width) - 64px)`,
                    }}
                  >
                    {/* {columns &&
                      gtableCols &&
                      gtableCols.length === columns.length &&
                      rowsToDisplay &&
                      (rowsToDisplay.length > 0
                        ? Object.keys(rowsToDisplay[0])?.length > columns.length
                        : true) && ( */}
                    {
                      <GTable
                        className="dashboard-gtable"
                        leftHeader={{
                          filters: [],
                          isSearch: true,
                        }}
                        searchFields={["User"]}
                        rightHeader={{
                          isDownload: true,
                          isAddColumn: true,
                        }}
                        onColumnHeaderClick={onColumnHeaderClick}
                        addColumnHandler={handleAddColumn}
						addColumnHandlerDisabled={reduxSubscription?.plan?.name?.includes("Standard")}
                        rows={rowsToDisplay}
                        availableData={availableData}
                        hideHeader={false}
                        dispatchAvailableData={dispatchAvailableData}
                        download={{
                          data: csvData,
                          body: null,
                          filename:
                            "users-table-" +
                            new Date().toLocaleString("en-US", {
                              weekday: "long",
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            }),
                        }}
                        onClickDownloadIcon={clickCSVDownloadIcon}
                        fromScreen="analytics-dashboard"
                        columns={gtableCols}
                        useMuiTable={true}
                        isLoading={showLoader}
                      />
                    }
                  </div>
                )}

                {/* table dropdown */}
                <div
                  style={{
                    display: optionsVisible ? "block" : "none",
                    transform: optionsPosition,
                  }}
                  className="table-dropdown-container"
                >
                  <div
                    className="table-dropdown-item"
                    data-optionstype="sort-asc"
                    onClick={(e) => handleTableOptionsClick(e)}
                  >
                    <img
                      className="table-dropdown-item-icon"
                      data-optionstype="sort-asc"
                      src={tableArrowAscending}
                      alt="Sort ascending"
                    />
                    <span
                      className="nc-gray-900 regular-font t7"
                      data-optionstype="sort-asc"
                    >
                      Sort ascending
                    </span>
                  </div>
                  <div
                    className="table-dropdown-item"
                    data-optionstype="sort-desc"
                    onClick={(e) => handleTableOptionsClick(e)}
                  >
                    <img
                      className="table-dropdown-item-icon"
                      data-optionstype="sort-desc"
                      src={tableArrowDescending}
                      alt="Sort descending"
                    />
                    <span
                      className="nc-gray-900 regular-font"
                      data-optionstype="sort-desc"
                    >
                      Sort descending
                    </span>
                  </div>

                  <div className="table-dropdown-sep"></div>

                  <button
                    className="table-dropdown-item table-dropdown-item-del"
                    disabled={
						          reduxSubscription?.plan?.name?.includes("Standard") ||
                      //tableSearch?.isSearch
                      (availableData.searchText &&
                      availableData.searchText.trim()?.length > 0
                        ? true
                        : !['User'].includes(columnClicked.field)
                        ? columns.length > 1
                          ? false
                          : true
                        : true)
                    }
                    data-optionstype="del"
                    onClick={(e) => handleTableOptionsClick(e)}
                  >
                    <img
                      className="table-dropdown-item-icon"
                      data-optionstype="del"
                      src={tableDeleteColumn}
                      alt="Delete column"
                    />
                    <span
                      className="nc-gray-900 regular-font"
                      data-optionstype="del"
                    >
                      Delete Column
                    </span>
                  </button>
                </div>
              </div>
            </div>
            {/* NOTE: ADD COLUMN */}
            <div
              className="table-add-column-container"
              style={{ display: addColumnModalVisible ? "block" : "none" }}
            >
              <div className="header">
                <span className="medium-font t6 nc-gray-900">
                  {/* {addColumn?.add ? "Add Column" : "Edit Column"} */}
                  Add Column
                </span>
                <img
                  src={addColumnClose}
                  onClick={(e) => handleAddColumnCloseClick(e)}
                />
              </div>
              <div className="add-column-body nc-gray-900">
                <div className="item nc-gray-900">
                  <label>
                    <span className="medium-font t7">Column Name*</span>
                    <input
                      type="text"
                      className="regular-font t6 nc-gray-900"
                      style={{
                        border: errorBorder?.colNameError
                          ? "1px solid red"
                          : "1px solid #ddd",
                      }}
                      value={addColumn?.name}
                      onChange={(e) => handleAddColumnDataChange(e)}
                      data-coldatafield="name"
                    />
                  </label>
                </div>
                <div className="item nc-gray-900">
                  <label className="medium-font">
                    <span className="medium-font t7">Column Data*</span>
                    <select
                      onChange={(e) => handleAddColumnDataChange(e)}
                      data-coldatafield="data"
                      className="regular-font t6 nc-gray-900"
                      value={addColumn?.data}
                    >
                      <option value="">Select column data</option>
                      <option
                        value="total minutes"
                        disabled={addColumn?.call_status === "unique"}
                      >
                        Total duration
                      </option>
                      
                      <option
                        value="average minutes"
                        disabled={addColumn?.call_status === "unique"}
                      >
                        Average duration
                      </option>
                      <option value="total calls">Number of calls</option>
                      <option value="assigned contacts">Assigned contacts</option>
                      {isPro && <option value="last activity">Last activity</option>}
                      {isPro && <option value="status">Live status</option>}
                    </select>
                  </label>
                </div>
                {['total calls', 'average minutes','total minutes', 'assigned contacts'].includes(addColumn?.data) && <div className="add-column-sep"></div>}
                {addColumn?.data === "assigned contacts" && 
                <div className="item nc-gray-900">
                  <label  style={{flexDirection:'column', alignItems:'flex-start'}}>
                    <span className="medium-font t7">Contact status</span>
                    <select 
                      onChange={handleAddColumnDataChange}
                      data-coldatafield="contact_status"
                      className="regular-font t6 nc-gray-900"
                      value={addColumn?.contact_status}
                    >
                      <option value="both">Reached + Not reached</option>
                      <option value="reached">Reached</option>
                      <option value="not reached">Not Reached</option>
                    </select>
                  </label>
                </div>
                }
                
                {['total minutes','average minutes', 'total calls'].includes(addColumn?.data) &&
                  <>
                  <div className="item nc-gray-900">
                  <label>
                    <span className="medium-font t7">Call Type</span>
                    {/*  <input type="text" /> */}
                    <select
                      onChange={(e) => handleAddColumnDataChange(e)}
                      data-coldatafield="call_type"
                      className="regular-font t6 nc-gray-900"
                      value={addColumn?.call_type}
                    >
                      <option value="incoming and outgoing">
                        Incoming + Outgoing calls
                      </option>
                      <option value="incoming">Incoming Calls</option>
                      <option value="outgoing">Outgoing Calls</option>
                    </select>
                  </label>
                </div>
                <div className="item nc-gray-900">
                  <label>
                    <span className="medium-font t7 nc-gray-900">
                      Call Status
                    </span>
                    <select
                      onChange={(e) => handleAddColumnDataChange(e)}
                      data-coldatafield="call_status"
                      className="regular-font t6 nc-gray-900a"
                      value={addColumn?.call_status}
                    >
                      <option value="all">All Calls</option>
                      <option
                        value="unique"
                        disabled={
                          addColumn?.data === "total minutes" ||
                          addColumn?.data === "average minutes"
                        }
                      >
                        Unique
                      </option>
                      <option value="answered">Answered</option>
                      <option value="unanswered">Unanswered</option>
                    </select>
                  </label>
                </div>
                <div className="item nc-gray-900">
                  <span
                    style={{ marginBottom: "4px" }}
                    className="medium-font t7"
                  >
                    Duration Filter
                  </span>
                  <label className="duration-filter">
                    <select
                      onChange={(e) => handleTableDurationFilterChange(e)}
                      className="regular-font t6 nc-gray-900"
                      value={addColumn?.duration_filter?.filter}
                    >
                      <option value="none">None</option>
                      <option value="less than">Less than</option>
                      <option value="greater than">Greater than</option>
                      <option value="between">Between</option>
                    </select>
                    {addColumn?.duration_filter?.filter !== "none" &&
                      addColumn?.duration_filter?.filter !== null && (
                        <div className="duration-time-fields">
                          {addColumn?.duration_filter?.filter !== "none" &&
                            addColumn?.duration_filter?.filter !== null && (
                              <input
                                type="number"
                                className="regular-font t6 nc-gray-900"
                                placeholder="Mins"
                                onChange={(e) => handleAddColumnDataChange(e)}
                                data-coldatafield="duration_filter"
                                data-coldurationminfield="start"
                                value={addColumn?.duration_filter?.start}
                                min={0}
                                max={
                                  addColumn?.duration_filter?.end !== 0
                                    ? addColumn?.duration_filter?.end
                                    : 10000
                                }
                              />
                            )}
                          {addColumn?.duration_filter?.filter === "between" && (
                            <span className="regular-font t6">to</span>
                          )}
                          {addColumn?.duration_filter?.filter === "between" && (
                            <input
                              type="number"
                              className="regular-font t6 nc-gray-900 ui--input ui--between"
                              placeholder="Mins"
                              onChange={(e) => handleAddColumnDataChange(e)}
                              data-coldatafield="duration_filter"
                              data-coldurationminfield="end"
                              value={addColumn?.duration_filter?.end}
                              min={addColumn?.duration_filter?.start}
                            />
                          )}
                        </div>
                      )}
                  </label>
                </div>
                </>
                }
              </div>
              <div className="add-column-button-container">
                <input
                  onClick={handleAddColumnCancelClick}
                  type="button"
                  value="Cancel"
                  className="btn btn-gray medium-font t7 nc-gray-900 ui--button ui--cancel"
                />
                <Button
				  className="ui--button ui--save"
                  disabled={false}
                  hierarchy="green"
                  variant="contained"
                  buttonText="Create"
                  onClick={handleAddColumnSave}
                  styleOverride={{ marginRight: "32px" }}
                />
              </div>
            </div>
          </>
        )
      )}
      <div
        className="add-new-campaign-overlay-temp table-add-column-overlay"
        onClick={(e) => handleOverlayClick(e)}
        style={{
          display: addColumnModalVisible ? "block" : "none",
          width: "200vw",
          left: "-240px",
        }}
      ></div>
    </div>
  );
}
