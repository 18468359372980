// ✅ done
// ❗ better authentication check other than res?.detail === "Invalid token."

import dotsBottom from '../../assets/login-dots-bottom.svg'
import dotsTop from '../../assets/login-dots-top.svg'
import vectorArt from '../../assets/login-vector-art.png'
import logo from '../../assets/top-nav-logo.svg'

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { loginAttempt } from '../features/user/user'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { JService } from '../api-service/ApiService'
import { CCDropdown, CustomInput } from '../design/components/customComponents'
import Button from '../design/Button'
import { enqueueSnackbar } from 'notistack'
const emailRegex = new RegExp(/^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,15}$/)
const stringFieldValidation = (val) => Boolean(val) && !!String(val).trim().length
const validationMapping = {
	"email" : {
		validate: (val) => emailRegex.test(val),
		error: "Invalid email",
	},
	"first_name": {
		validate: (val) => stringFieldValidation(val) && val.trim().length > 0,
		error: "This is a required field"
	},
	"last_name": {
		validate: (val) => stringFieldValidation(val) && val.trim().length > 0,
		error: "This is a required field"
	},
	"phone_number": {
		validate: (val) => val?.length >= 0 ,
		error: "Invalid phone number, expected length atleast 10"
	},
	"password": {
		validate: (val) => val?.length >= 6,
		error: "Invite code too short, expected length 6"
	},
	"country_code": {
		validate: (val) => stringFieldValidation(val) && val.trim().length,
		error: "Invalid country code"
	}
}
function Invite()
{
    const API_BASE_URL = process.env.REACT_APP_BETA
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loginValues, setLoginValues] = useState({
        first_name: {value:'',error:null},
        last_name: {value:'',error:null},
        email: {value:'',error:null},
        password: {value:'',error:null},
        phone_number:{value:'',error:null},
        country_code:{value:'+91',error:null}
    })
	const [isDisabled, setIsDisabled] = useState(true)

    const handleLoginChange = (e, field, value) => {
        const _field = e?.target?.name ?? field;
        let _value = e?.target?.value ?? value;
		if(_field === "email") _value = _value.toLowerCase()
        const update = {
			...loginValues,
			[_field]: {error: validationMapping[_field].validate(_value) ? "" : validationMapping[_field].error, value: _value}
        }
		setIsDisabled(!loginValidation(update))
		setLoginValues(update)
    }

    const handleFocus=(e)=>{
        const _field = e.target.name
        const _value = e.target.value
        setLoginValues(prevState => {
			if(Object.keys(prevState).includes(_field)) {
				return {...prevState, [_field]: {...prevState[_field], error: null}}
			}
			return prevState
        })
    }
    const loginValidation=(update)=> {
		let isValid=true;
		let entries = [...Object.entries(update)]
		
		for(var [key, val] of entries) {
			if(!validationMapping[key].validate(val.value)) {
				isValid = false
				console.log(key, val, "*")
			}
		}
		
        return isValid;
    }
    const handleUserPhoneNumberUpdate=async()=>{
        try{
            const payload = {
                number: loginValues?.country_code?.value + loginValues?.phone_number?.value,
            };
            const response = await JService.patch("/api/v1/auth/update-userphonenumber/", JSON.stringify(payload));
            if (!response?.success)
			throw new Error(
				response?.message || "Failed to update phone number. "
			);
			enqueueSnackbar(response.message, { variant: "success"})
			
        }
        catch(err){
            throw new Error(err);
        }
    }
    
    const handleUserNameUpdate= async() => {
        try{
            const data = {
                first_name: loginValues?.first_name?.value,
                last_name: loginValues?.last_name?.value,
            };
            const response = await JService.patch(API_BASE_URL + "/api/v1/auth/update-user", JSON.stringify(data));
            console.log(response);
			if (!response?.success)
				throw new Error(
				response?.message || "Failed to update user name. "
				);
			
			enqueueSnackbar(response.message, { variant: "success"})
        }
        catch(err){
            console.log(err)
            throw new Error(err);
        }
    } 
    
    const handleLoginSubmit = async (e) => {
        try{
        e.preventDefault();
        console.log("in validate code")
        dispatch(loginAttempt(loginValues))

        const res = await fetch(API_BASE_URL + '/api/v1/auth/validate-code/?webapp=True', {
            method:'post',
            body: JSON.stringify({email:loginValues?.email?.value, code:loginValues?.password?.value, source: 'invite'}),
            headers: {
                'Content-Type': 'application/json',
            },
			credentials: "include",
        });
        if(res?.status === 500) throw new Error('Server error please try again or if issue persists contact FreJun.')
        const response = await res.json();
        if(!response?.success) throw new Error(response?.message || 'Failed to use entered invitation code. ')
                
				dispatch(loginAttempt({email: loginValues?.email?.value}))

				
                await handleUserNameUpdate()
                await handleUserPhoneNumberUpdate()
                navigate('/forgot-password/reset',{state:{from:'invite'}})
        }
        catch(err){
            console.log(err);
            enqueueSnackbar(err?.message || 'Failed to use entered invitation code. ', { variant: "error"})
        }
        finally{
                    setLoginValues({
                        first_name: {value:'',error:null},
                        last_name: {value:'',error:null},
                        email: {value:'',error:null},
                        password: {value:'',error:null},
                        phone_number:{value:'',error:null},
                        country_code:{value:'+91',error:null}
                        })
                }   
    }

    return(
		<div className='login-container'>
			<div className='login-art'>
			<a href="https://frejun.com/" target="_blank" rel="noopener noreferrer"> <img src={logo} alt="Logo" /></a>
				<img src={ dotsTop }/>
				<img src={ vectorArt }/>
				<img src={ dotsBottom }/>
				<p className='regular-font px-18'>Simplify and Automate<br/>Your Calling Process</p>
			</div>
			<div className='login-form signup-form'>
				<p style={{marginTop:0}} className='title medium-font px-22'>Log in with your invite code</p>
				<form onSubmit={e => handleLoginSubmit(e)}>
					<div className='name-container'>
					<CustomInput value={loginValues?.first_name} label="First name" handleChange={handleLoginChange} handleFocus={handleFocus} name="first_name" type="text"/>
					<CustomInput value={loginValues?.last_name} label="Last name" handleChange={handleLoginChange} handleFocus={handleFocus} name="last_name" type="text"/>
					</div>
					{/* <label className='bold-font-poppins px-14'>
						Email
						<input className='regular-font px-14' name='email' type='text' value={loginValues.email} onChange={e => handleLoginChange(e)} />
					</label> */}
					<CustomInput value={loginValues?.email} label="Email" handleFocus={handleFocus} handleChange={handleLoginChange} name="email" type="text"/>
					{/* <label className='medium-font t7'>
						Phone number
						<div className='phone-number'>
							<div>+91 <span>{dropdownIcon()}</span></div>
							<input onFocus={handleFocus} className={`regular-font t6 ${loginValues?.phone_number?.error?'input-error':''}`} name='phone_number' type='text' value={loginValues?.phone_number?.value || ''} onChange={handleLoginChange}/>
							{loginValues?.phone_number?.error && <div className='error-icon'>{errorIcon()}</div>}
						</div>
						{loginValues?.phone_number?.error && <p className="t7 regular-font">{loginValues.phone_number.error}</p>}
					</label> */}
					<CustomInput dropdown={<CCDropdown selectedCountryCode={loginValues.country_code?.value} handleCountrySelect={(val)=>handleLoginChange(null, 'country_code', val)}/>} value={loginValues?.phone_number} label="Phone number" handleFocus={handleFocus} handleChange={handleLoginChange} name="phone_number" type='tel' />

					{/* <label className='medium-font px-14'>
						Invitation code
						<input className='regular-font px-14' name='password' type='password' value={loginValues.password} onChange={e => handleLoginChange(e)} />
					</label> */}
					<CustomInput value={loginValues?.password} label="Invite code" handleFocus={handleFocus} handleChange={handleLoginChange} name="password" type="password"/>

					{/* <label className='to-forgot-password-container'>
						<a className='to-forgot-password normal-font-poppins px-14' onClick={e => handleForgotPasswordClick(e)}>Forgot Password?</a>
					</label> */}
					{/* <input id='login-button' className='btn login-btn px-14 medium-font' type='submit' value='Log in' onSubmit={e => handleLoginSubmit(e)}/> */}
					<Button disabled={isDisabled} icon={{startIcon: null, endIcon: null}}  hierarchy='green' variant='contained' buttonText='Log in' isLoading={false} onClick={handleLoginSubmit} styleOverride={{width: '100%',marginTop:'32px'}}/>
				</form>
				{/* <div className='regular-font px-14 to-sign-up-container'>Don't have an account?<a href='/signup' className='to-sign-up'>Sign up</a></div> */}
			</div>
		</div>
    )
}

export default Invite