import { useState } from "react"
import { errorIcon } from "../../../utility-functions/assetLoader"
import GModal from "../../design/components/GModal"
import { CustomInput } from "../../design/components/customComponents"
import styles from './ManageLicenseModal.module.css'
import Button from "../../design/Button"


const AddLicenseModal=({visible,handleClose,handleAddLicense,type})=>{
    const [quantity,setQuantity]=useState({value:null,error:null})

    const handleCloseModal=()=>{
        setQuantity({value:null,error:null})
        handleClose()
    }
    const handleUpdateLicense=()=>{
        handleAddLicense(quantity.value,type)
        setQuantity({value:null,error:null})
    }

    return (
        <GModal heading={`Add ${type} license`} visible={visible} closeModal={handleCloseModal}
        bottomButtons={[<Button styleOverride={btnStyle} hierarchy='white' variant='outlined' disabled={false} buttonText='Cancel' onClick={handleCloseModal}/>,
                        <Button styleOverride={btnStyle} hierarchy='green' buttonText='Add' onClick={handleUpdateLicense} disabled={!quantity.value || quantity.value<=0}/>]}
        modalStyle={{minWidth:'384px',width:'384px',padding:'40px 32px', marginBottom:'20px'}}
        bodyWrapperStyle={{paddingRight:'0'}}
        bottomButtonsStyle={{borderTop:'none'}}
        body={
            <div>
            <CustomInput placeholder="Enter quantity" label="Licenses" value={quantity} handleChange={(e)=>setQuantity(prev=>({...prev,value:e.target.value}))} handleFocus={()=>setQuantity(prev=>({...prev,error:null}))} name="license" type="number" inputProps={{min: 1}}/>
            {quantity.value && quantity.value>0 && <div className={styles.warningContainer}><span style={{marginTop:'4px'}}>{errorIcon('#464c48','16.67','16.67')}</span><p className="t7 regular-font">By clicking on add, you agree to purchase <span className="medium-font">{quantity.value} {type}</span> license. </p></div>}
            </div>
        }
        />
    )
}

const btnStyle={
    height:'36px',
    fontSize:'14px',
    lineHeight:'14px',
    fontWeight:'400',
    padding:'10px 16px'
}

export default AddLicenseModal