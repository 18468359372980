import React, { useCallback, useEffect, useState } from "react";
export const useHubspotChat = (portalId, shouldOpenWidget) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);
  const [activeConversation, setActiveConversation] = useState(false);
  const eventRef = React.useRef(null);

  useEffect(() => {
    window.hsConversationsSettings = {
      loadImmediately: false,
      inlineEmbedSelector: "#root",
      disableAttachment: false,
    };
    
    window.hsConversationsOnReady = [shouldOpenWidget]

    let script = document.createElement("script");
    script.src = `//js.hs-scripts.com/${portalId}.js`;
    script.async = true;
    script.defer = true;
    script.type = "text/javascript";
    script.id = "hs-script-loader";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      window.hsConversationsOnReady = [];
    };
  }, []);

  const handleToggleWidget = useCallback(() => {
    if (isWidgetOpen) {
      window.HubSpotConversations.widget.close();
      window.HubSpotConversations.widget.remove();
      setIsWidgetOpen(false);
    } else {
      window.HubSpotConversations.widget.load({ widgetOpen: true });
      setIsWidgetOpen(true);
    }
  });

  return {
    hasLoaded,
    isWidgetOpen,
    activeConversation,
    handleToggleWidget,
  };
};
