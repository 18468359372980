import { createSlice } from '@reduxjs/toolkit'

let initial = null
try{
    initial = JSON.parse(localStorage.getItem('frejun-calendar'))
}catch(err){
    console.warn(err, ' Setting up local storage for first (re)use.')
}

initial = initial?initial:{}

export const calendarSlice = createSlice({
    name:'calendar',
    initialState: {value:Object.keys(initial).length === 0?{}:initial},
    reducers:{
        saveCalendar: (state, action) => {
            state.value = action.payload
            localStorage.setItem('frejun-calendar', JSON.stringify(state.value))
        }
    }
})

export const { saveCalendar } = calendarSlice.actions
export default calendarSlice.reducer