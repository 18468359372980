import Colors from "../colors";
import { useCallback, useEffect, useState } from "react";
import filterDownArrow from "../../../assets/gusers-and-teams-down-arrow.svg";
import filterClearIcon from "../../../assets/gusers-and-teams-filter-clear-icon.svg";
import { FormControlLabel, ListItemText, Menu, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
// import ClickAwayListener from '@mui/material/ClickAwayListener';
import Checkbox from "@mui/material/Checkbox";
import checkboxCheckedIcon from "../../../assets/gusers-and-teams-checbox-checked-icon.svg";
import checkboxIcon from "../../../assets/gusers-and-teams-checbox-icon.svg";
import searchIcon from "../../../assets/gusers-and-teams-gtable-search-icon.svg";
import searchActiveIcon from "../../../assets/gusers-and-teams-gtable-search-active-icon.svg";
import { useSelector } from "react-redux";
import Button from "../Button";
import colors from "../colors";

const newScreens=['dashboard','call-logs','sms-logs','livestatus']

export default function GFilter({
  title,
  dispatchAvailableData,
  availableData,
  toggle,
  total,
  isDisabled,
  fromScreen,
  dateDropdownHandler, // {isDateDropdownVisible, dateFilterHandler}
}) {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);

  const [searchText, setSearchText] = useState("");
  const [selectedCount, setSelectedCount] = useState(0);
  const [availableTeamsToDisplay, setAvailableTeamsToDisplay] = useState([]);
  const [availableUsersToDisplay, setAvailableUsersToDisplay] = useState([]);
  const [availableListsToDisplay, setAvailableListsToDisplay] = useState([]);
  const [tagsToDisplay, setTagsToDisplay] = useState([]);

  useEffect(() => {
    if (fromScreen === "dashboard" || fromScreen === "call-logs" || fromScreen==='sms-logs') {
      setAvailableUsersToDisplay(availableData?.users?.all);
      setAvailableTeamsToDisplay(availableData?.teams?.all);
      setAvailableListsToDisplay(availableData?.lists?.all);
      setTagsToDisplay(availableData?.tags?.all);
    }
  }, [
	availableData,
	fromScreen]);

  useEffect(() => {
    if (!searchText || searchText.trim().length < 1) {
		if (title === "Users") setAvailableUsersToDisplay(availableData?.users?.all);
		else if (title === "Team") setAvailableTeamsToDisplay(availableData?.teams?.all);
		else if (title === "Tags") setTagsToDisplay(availableData?.tags?.all);
    else if (title === "List") setAvailableListsToDisplay(availableData?.lists?.all);
		return;
    }
    let searchedResult = [];

    if(title === "Users") {
      searchedResult = availableData?.users?.all?.filter(({ name }) =>
        name?.toLowerCase().includes(searchText.toLowerCase())
      );
      setAvailableUsersToDisplay(searchedResult);
    } 
	else if (title === "Team") {
      searchedResult = availableData?.teams?.all?.filter(({ name }) =>
        name?.toLowerCase().includes(searchText.toLowerCase())
      );
      setAvailableTeamsToDisplay(searchedResult);
    } 
	else if (title === "Tags") {
      searchedResult = availableData?.tags?.all?.filter(({ name }) =>
        name?.toLowerCase().includes(searchText.toLowerCase())
      );
      setTagsToDisplay(searchedResult);
    }
  else if (title === "List") {
    searchedResult = availableData?.lists?.all?.filter(({name}) => 
      name?.toLowerCase().includes(searchText.toLowerCase())
    );
    setAvailableListsToDisplay(searchedResult);
  }
  }, [searchText]);

  // ✅ use this
  // setting anchor element for menu and redirecting events from children
  const handleFilterClick = (e) => {
    if (e.nativeEvent.srcElement.dataset?.icontype === "close") {
      handleClearCurrentFilter(null);
      return;
    }
    setDropdownAnchorEl(e.currentTarget || null);
    setIsDropdownVisible((prevState) => !prevState);
  };

  const [maxFilters, setMaxFilters] = useState([]); // eslint-disable-line


  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState({});
  const [selectedCallTypes, setSelectedCallTypes] = useState([]);
  const [selectedMessageTypes, setSelectedMessageTypes]=useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedCategories,setSelectedCategories]=useState([])
  const [selectedStatuses, setSelectedStatuses] = useState([])
  const [selectedRoles, setSelectedRoles] = useState([])
  const [selectedLists, setSelectedLists] = useState([])

  const applyFilterHandler = () => {
    if(title === "Users" && (fromScreen === "dashboard" || fromScreen === "call-logs" || fromScreen==='sms-logs')) {
      setSelectedFilters(selectedUsers);
      dispatchAvailableData({
        type: "handleSelectedOfType",
        payload: { payloadFor: "users", payload: selectedUsers },
      });
    }
	else if (title === "Team" && (fromScreen === "dashboard" || fromScreen === "call-logs" || fromScreen==='sms-logs' || fromScreen==='livestatus')) {
      setSelectedFilters(Object.keys(selectedTeams));
	  console.log("selected items before dispatching teams after clicking 'Apply' ", selectedTeams)
    const users = new Set(availableData?.users?.selected ?? [])
    console.log(users);
    const usersInTeams = new Set()
    Object.keys(selectedTeams).forEach(teamid => {
        selectedTeams[teamid].forEach(user => {
          usersInTeams.add(user)
          if(!availableData?.teams?.selected?.[teamid]){
          users.add(user)
        }
      })
    })

    Object.keys(availableData?.teams?.selected).forEach(teamid => {
      if(!selectedTeams[teamid]){
        console.log('team removed', teamid);
        availableData?.teams?.selected?.[`${teamid}`]?.forEach(user => {
          if(!usersInTeams.has(user)){
            console.log('deleted user', user)
            users.delete(user)
          }
        })
      }
    })
      dispatchAvailableData({
        type: "handleSelectedOfType",
        payload: { payloadFor: "teams", payload: selectedTeams },
      });
      dispatchAvailableData({
        type: "handleSelectedOfType",
        payload: {payloadFor: "users", payload: [...users] }
      })
    }
	else if (title === "Call type" && fromScreen === "call-logs") {
      setSelectedFilters(selectedCallTypes);
      dispatchAvailableData({
        type: "handleSelectedOfType",
        payload: {
          payloadFor: "statusTypes",
          payload: selectedCallTypes,
        },
      });
    } 
    else if (title === "Message type" && fromScreen === "sms-logs") {
      setSelectedFilters(selectedMessageTypes);
      dispatchAvailableData({
        type: "handleSelectedOfType",
        payload: {
          payloadFor: "messageType",
          payload: selectedMessageTypes,
        },
      });
    } 
	else if (title === "Tags" && (fromScreen === "call-logs" || fromScreen==='sms-logs')) {
      setSelectedFilters(selectedTags);
      dispatchAvailableData({
        type: "handleSelectedOfType",
        payload: {
          payloadFor: "tags",
          payload: selectedTags,
        },
      });
    }
  else if(title==="Category" && fromScreen==="credit-history"){
    setSelectedFilters(selectedCategories);
    dispatchAvailableData({
      type: "handleSelectedOfType",
        payload: {
          payloadFor: "categories",
          payload: selectedCategories,
        },
    })
  }
  else if(title==="Role" && fromScreen==="livestatus") {
	setSelectedFilters(selectedRoles);
      dispatchAvailableData({
        type: "handleSelectedOfType",
        payload: {
          payloadFor: "roles",
          payload: selectedRoles,
        },
      });
  }
  else if(title==="Status" && fromScreen==="livestatus") {
	setSelectedFilters(selectedStatuses);
	dispatchAvailableData({
	  type: "handleSelectedOfType",
	  payload: {
		payloadFor: "statuses",
		payload: selectedStatuses,
	  },
	});
  }
  else if (title === "List") {
    setSelectedFilters(selectedLists);
    dispatchAvailableData({
      type:"handleSelectedOfType",
      payload:{
        payloadFor:"lists",
        payload: selectedLists
      }
    })
  }
    setTimeout(() => handleClose(), 100);
  };
  
  // ✅ use this
  const handleToggleableItemClick = (e, id, name, users) => {
    if(title === "Users" && (fromScreen === "dashboard" || fromScreen === "call-logs" || fromScreen==='sms-logs')) {
		if(selectedUsers.includes(id)){
      setSelectedUsers((prev) => prev.filter((elem) => elem != id));
    }
		else {
      setSelectedUsers((prev) => [...prev, id]);
    }
    } 
	else if(title === "Team" && (fromScreen === "dashboard" || fromScreen === "call-logs" || fromScreen==='sms-logs' || fromScreen==='livestatus')) {
		const temp = {...selectedTeams };
      	if(selectedTeams[`${id}`]) {
			delete temp[`${id}`];
      setSelectedTeams(temp);
      	}
		else {
        	temp[`${id}`] = [...users];
          setSelectedTeams(temp);
      	}
    }
	else if (title === "Call type" && fromScreen === "call-logs") {
		if(selectedCallTypes.includes(id)) setSelectedCallTypes((prev) => prev.filter((elem) => elem !== id));
		else setSelectedCallTypes((prev) => [...prev, id]);
    } 
  else if (title === "Message type" && fromScreen === "sms-logs") {
      if(selectedMessageTypes.includes(id)) setSelectedMessageTypes((prev) => prev.filter((elem) => elem !== id));
      else setSelectedMessageTypes((prev) => [...prev, id]);
      } 
	else if (title === "Tags" && (fromScreen === "call-logs"|| fromScreen==='sms-logs')) {
		if (selectedTags.includes(id)) setSelectedTags((prev) => prev.filter((elem) => elem !== id))
		else setSelectedTags((prev) => [...prev, id])
    } 
  else if (title === "List"){
    if(selectedLists.includes(id)) setSelectedLists((prev)=>prev.filter((elem) => elem !== id))
    else setSelectedLists((prev) => [...prev, id])
  }
	else if (title === "Team" && fromScreen === "virtual-number-outgoing") {
      dispatchAvailableData({type: "handleSelectedOfType", payload: { payloadFor: "teams", payload: id },});
    } 
	else if (title === "Role" && fromScreen === "virtual-number-outgoing") {
      dispatchAvailableData({
        type: "handleSelectedOfType",
        payload: { payloadFor: "roles", payload: id },
      });
    } 
	else if (title === "Team" && fromScreen === "users") {
      dispatchAvailableData({
        type: "handleSelectedOfType",
        payload: { payloadFor: "teams", payload: id },
      });
    } 
	else if (title === "Campaign status" && fromScreen === "autodialScreen") {
      dispatchAvailableData({
        type: "handleSelectedOfType",
        payload: { payloadFor: "campaignStatus", payload: id },
      });
    } 
	else if (title === "Contacted" && fromScreen === "autodialScreenView") {
      dispatchAvailableData({
        type: "handleSelectedOfType",
        payload: { payloadFor: "contacted", payload: id },
      });
    }
  else if(title==='Category' && fromScreen==="credit-history"){
    if(selectedCategories.includes(id)) setSelectedCategories((prev) => prev.filter((elem) => elem !== id));
		else setSelectedCategories((prev) => [...prev, id]);
  }
  else if(title === "Status" && fromScreen === "livestatus") {
	setSelectedStatuses(selectedStatuses.includes(id) ? selectedStatuses.filter(a => a !== id) : [...selectedStatuses, id] )
  }
  else if(title === "Role" && fromScreen === "livestatus") {
	setSelectedRoles(selectedRoles.includes(id) ? selectedRoles.filter(a => a !== id) : [...selectedRoles, id])
  }
    if (title === "Role"&& fromScreen !== "livestatus") {
      dispatchAvailableData({ type: "handleRoleChange", payload: id });
    }
	else if (title === "Status" && fromScreen !== "livestatus") {
      dispatchAvailableData({ type: "handleStatusChange", payload: id });
    } 
	else if (title === "Autodial Lists") {
      dispatchAvailableData({
        type: "handleSelectedOfType",
        payload: { payloadFor: "autodialLists", payload: id },
      });
    } 
	else if (
      title === "Users" &&
      fromScreen !== "dashboard" &&
      fromScreen !== "call-logs" &&
      fromScreen!=='sms-logs'
    ) {
      dispatchAvailableData({
        type: "handleSelectedOfType",
        payload: { payloadFor: "users", payload: id },
      });
    } 
	else if (title === "Tags" && (fromScreen !== "call-logs" && fromScreen!=='sms-logs')) {
      dispatchAvailableData({
        type: "handleSelectedOfType",
        payload: { payloadFor: "tags", payload: id },
      });
    }
    else if (
      title === "Message status" ||
      title === "Campaign status" ||
      title === "Call status"
    ) {
      dispatchAvailableData({
        type: "handleSelectedOfType",
        payload: { payloadFor: "statusTypes", payload: id },
      });
    } 
	else if (title === "Location") {
      dispatchAvailableData({
        type: "handleSelectedOfType",
        payload: { payloadFor: "locations", payload: id },
      });
    } 
	else if (title === "Number type") {
      dispatchAvailableData({
        type: "handleSelectedOfType",
        payload: { payloadFor: "numberTypes", payload: id },
      });
    } 
  else if(title === 'Assigned to'){
    dispatchAvailableData({type:"handleSelectedOfType",payload:{payloadFor:'assignedTo', payload:id}});
  }
	else return;
  };

    // if all in "new" are in "old" && check if same length => same elements
	// returns true if anew and aold arrays have the same elements
	function checkAllInArrayAreSame(anew, aold) {
		if(!(Array.isArray(anew) && Array.isArray(aold))) return true
		let aoldIdMap = aold.map(k => Boolean(k?.__tagId) && k.__tagId)
		return anew.entries(kk => Boolean(kk?.__tagId && aoldIdMap.includes(kk.__tagId))) && (anew.length === aold.length)
	}

  // ✅ use this
  useEffect(() => {
    if (availableData) {
      if (
        title === "Team" && !newScreens.includes(fromScreen) &&
        selectedFilters != availableData?.teams?.selected
      ) {
        //eslint-disable-line

        setSelectedFilters(availableData?.teams?.selected);
        setMaxFilters(availableData?.teams?.all);
      } else if (
        title === "Team" && newScreens.includes(fromScreen)
      ) {
        if (selectedFilters != Object.keys(availableData?.teams?.selected))
          setSelectedTeams(availableData?.teams?.selected);
        setSelectedFilters(Object.keys(availableData?.teams?.selected));
        setMaxFilters(availableData?.teams?.all);
      } else if (
        title === "Role" &&
        selectedFilters != availableData?.roles?.selected
      ) {
        //eslint-disable-line
        setSelectedFilters(availableData?.roles?.selected);
        setMaxFilters(availableData?.roles?.all);
      } else if (
        title === "Status" &&
        selectedFilters != availableData?.statuses?.selected
      ) {
        //eslint-disable-line
        setSelectedFilters(availableData?.statuses?.selected);
        setMaxFilters(availableData?.statuses?.all);
      } else if (
        title === "Users" &&
        selectedFilters != availableData?.users?.selected
      ) {
        //eslint-disable-line

        if (newScreens.includes(fromScreen)) {
          setSelectedUsers(availableData?.users?.selected);
        }
        setSelectedFilters(availableData?.users?.selected);
        setMaxFilters(availableData?.users?.all);
      } else if (
        title === "Tags" &&
        selectedFilters != availableData?.tags?.selected
      ) {
        //eslint-disable-line
        setSelectedFilters(availableData?.tags?.selected);
        setMaxFilters(availableData?.tags?.all);
      } else if (
        title === "Autodial Lists" &&
        selectedFilters != availableData?.autodialLists?.selected
      ) {
        //eslint-disable-line
        setSelectedFilters(availableData?.autodialLists?.selected);
        setMaxFilters(availableData?.autodialLists?.all);
      } else if (
        title === "Call type" &&
        fromScreen === "call-logs" &&
        selectedFilters != availableData?.statusTypes?.selected
      ) {
        //eslint-disable-line
        setSelectedFilters(availableData?.statusTypes?.selected);
        setMaxFilters(availableData?.statusTypes?.all);
      }else if (
        title === "Message type" &&
        fromScreen === "sms-logs" &&
        selectedFilters != availableData?.messageType?.selected
      ) {
        //eslint-disable-line
        setSelectedFilters(availableData?.messageType?.selected);
        setMaxFilters(availableData?.messageType?.all);
      }
       else if (
        title === "Campaign status" &&
        fromScreen === "autodialScreen" &&
        selectedFilters != availableData?.campaignStatus?.selected
      ) {
        // eslint-disable-line
        setSelectedFilters(availableData?.campaignStatus?.selected);
        setMaxFilters(availableData?.campaignStatus?.all);
      } else if (
        title === "Contacted" &&
        fromScreen === "autodialScreenView" &&
        selectedFilters != availableData?.contacted?.selected
      ) {
        // eslint-disable-line
        setSelectedFilters(availableData?.contacted?.selected);
        setMaxFilters(availableData?.contacted?.all);
      }
      // add_new_2
      else if (
        title === "Message status" &&
        selectedFilters != availableData?.statusTypes?.selected
      ) {
        //eslint-disable-line
        setSelectedFilters(availableData?.statusTypes?.selected);
        setMaxFilters(availableData?.statusTypes?.all);
      } else if (
        title === "Campaign status" &&
        selectedFilters != availableData?.statusTypes?.selected
      ) {
        //eslint-disable-line
        setSelectedFilters(availableData?.statusTypes?.selected);
        setMaxFilters(availableData?.statusTypes?.all);
      } else if (
        title === "Call status" &&
        selectedFilters != availableData?.statusTypes?.selected
      ) {
        //eslint-disable-line
        setSelectedFilters(availableData?.statusTypes?.selected);
        setMaxFilters(availableData?.statusTypes?.all);
      } else if (
        title === "Call type" &&
        selectedFilters != availableData?.statusTypes?.selected
      ) {
        //eslint-disable-line
        setSelectedFilters(availableData?.statusTypes?.selected);
        setMaxFilters(availableData?.statusTypes?.all);
      } else if (
        title === "Location" &&
        selectedFilters != availableData?.locations?.selected
      ) {
        //eslint-disable-line
        setSelectedFilters(availableData?.locations?.selected);
        setMaxFilters(availableData?.locations?.all);
      } else if (
        title === "Number type" &&
        selectedFilters != availableData?.locations?.numberTypes
      ) {
        //eslint-disable-line
        setSelectedFilters(availableData?.numberTypes?.selected);
        setMaxFilters(availableData?.numberTypes?.all);
      }else if (
        title === "Category" &&
        fromScreen === "credit-history" &&
        selectedFilters != availableData?.categories?.selected
      ) {
        //eslint-disable-line
        console.log("categories",availableData?.categories?.selected)
        setSelectedCategories(availableData?.categories?.selected)
        setSelectedFilters(availableData?.categories?.selected);
        setMaxFilters(availableData?.categories?.all);
      }
      else if (title==='Assigned to' && fromScreen==='voice' && selectedFilters != availableData?.assignedTo?.selected){
        setSelectedFilters(availableData?.assignedTo?.selected);
        setMaxFilters(availableData?.assignedTo?.all);
      }
      else if (title === "List" && selectedFilters != availableData?.lists?.selected){
        setSelectedFilters(availableData?.lists?.selected);
        setMaxFilters(availableData?.lists?.all);
        setSelectedLists(availableData?.lists?.selected)
      }
    }
  }, [availableData]); // eslint-disable-line

  useEffect(() => {
    setSelectedCount(Object.keys(selectedTeams)?.length);
  }, [selectedTeams]);

  useEffect(() => {
    setSelectedCount(selectedUsers.length);
  }, [selectedUsers]);

  useEffect(() => {
    setSelectedCount(selectedCallTypes.length);
  }, [selectedCallTypes]);

  useEffect(() => {
    setSelectedCount(selectedMessageTypes.length);
  }, [selectedMessageTypes]);

  useEffect(() => {
	setSelectedCount(selectedTags.length);
  }, [selectedTags]);

  useEffect(()=>{
    setSelectedCount(selectedCategories?.length);
  },[selectedCategories]);

  useEffect(() => {
    setSelectedCount(selectedStatuses.length);
  }, [selectedStatuses]);

  useEffect(() => {
    setSelectedCount(selectedRoles.length);
  }, [selectedRoles]);

  useEffect(()=>{
    setSelectedCount(selectedLists.length);
  },[selectedLists])

  const isSameElementsInArray = (a, b) => (Array.isArray(a) && Array.isArray(b)) && a.length === b.length && a.every(aa => b.includes(aa))

  // ✅ use this
  useEffect(() => {
	  if(fromScreen === "dashboard" || fromScreen == "call-logs" || fromScreen==="credit-history" || fromScreen==='sms-logs' ) {
		// close date dropdown
		if(dateDropdownHandler.isDateDropdownVisible) dateDropdownHandler.dateFilterHandler();

		if(selectedTags?.length && (!checkAllInArrayAreSame(selectedFilters, selectedTags)) ) {
			setSelectedTags(selectedFilters)
		}

		if(selectedCallTypes?.length && (!checkAllInArrayAreSame(selectedFilters, selectedCallTypes))) {
			setSelectedCallTypes(selectedFilters)
		}

    	if(selectedMessageTypes?.length && (!checkAllInArrayAreSame(selectedFilters, selectedMessageTypes))) {
			setSelectedMessageTypes(selectedFilters)
		}
	}
	else if(fromScreen === "livestatus") {
		
		if(!isSameElementsInArray(availableData?.statuses?.selected, selectedStatuses)) {
			setSelectedStatuses(availableData?.statuses?.selected)
		}
		if(!isSameElementsInArray(availableData?.roles?.selected, selectedRoles)) {
			setSelectedRoles(availableData?.roles?.selected)
		}
	}
	
	if(isDropdownVisible) window.addEventListener("click", filterAutoClose);
	else window.removeEventListener("click", filterAutoClose);

    return () => {
      window.removeEventListener("click", filterAutoClose);
    };
  }, [isDropdownVisible]);

  // ✅ use this
  // close handlers
  function handleClose() {
    setIsDropdownVisible(false);
    setDropdownAnchorEl(null);
  }

  // nothing to be done here
  // ClickAway listener-handler for each individual
  // filter component, <GFilter />.
  // Click event is attached when dropdown is visible,
  // e.composedPath() gives the bubbling path of the
  // event from srcElement to Window.
  function filterAutoClose(e) {
    const path = e.composedPath()?.map((pathItem) => {
      let newPathItem = null;
      newPathItem =
        pathItem?.tagName?.toLowerCase() +
        (pathItem?.className ? "." + pathItem?.className : "");
      return newPathItem;
    });

	// clicked outside
    if (!path?.length || path[0]?.includes("div.MuiBackdrop-root")) {
		// console.log("clicked outside")
	  // a checked checkbox should remain chekd when reopend after unchecking and not clicking on Apply/ Clear
	  if(fromScreen === "call-logs" || fromScreen==='sms-logs') {
		// let temp = structuredClone(selectedTags)
		let temp = selectedTags
		// before closing reset the selcted tags to selected filters
		setSelectedTags(selectedFilters)
		if(title === "Team" && (newScreens.includes(fromScreen))) {
			setSelectedTeams(selectedFilters)
		}
		else if(title === "Tags" && (newScreens.includes(fromScreen))) {
			setSelectedTags(selectedFilters)
		}
		else if(title === "Call type" && fromScreen === "call-logs") {
			setSelectedCallTypes(selectedFilters)
		}
		else if(title === "Users" && (newScreens.includes(fromScreen))) {
			setSelectedUsers(selectedFilters)
		}
		else if(title=== 'Message type' && fromScreen==='sms-logs'){
      setSelectedMessageTypes(selectedFilters)
    }
	  }else if(title==="Category" && fromScreen==="credit-history"){
      setSelectedCategories(selectedFilters)
    }
    else if(title === "List"){
      setSelectedLists(selectedFilters)
    }
      setIsDropdownVisible(false);
      setDropdownAnchorEl(null);
      return;
    }
  }

  // useEffect(()=>{.

  // ✅ use this
  const handleClearCurrentFilter = (e)=>{
    if (
      title === "Team" &&
      (newScreens.includes(fromScreen))
    ) {
      const usersInTeams = new Set();
      Object.values(availableData?.teams?.selected)?.forEach(users=>{
        users.forEach(user=> usersInTeams.add(user))
      });
      const users = new Set(availableData?.users?.selected ?? []);
      usersInTeams.forEach(user => users.delete(user))

      dispatchAvailableData({
        type: "handleClearOfType",
        payload: { payloadFor: "teams", payload: null },
      });
      setSelectedTeams({});
      dispatchAvailableData({
        type:"handleSelectedOfType",
        payload: {payloadFor: 'users', payload: [...users]}
      })

    } else if (
      title === "Users" &&
      (newScreens.includes(fromScreen))
    ) {
      dispatchAvailableData({
        type: "handleClearOfType",
        payload: { payloadFor: "users", payload: null },
      });
    }
	else if (title === "Call type") {
      dispatchAvailableData({
        type: "handleClearOfType",
        payload: { payloadFor: "statusTypes", payload: null },
      });
      setSelectedCallTypes([]);
    }
  else if (title === "List") {
    dispatchAvailableData({
      type:"handleClearOfType",
      payload: { payloadFor:"lists", payload: null},
    });
    setSelectedLists([]);
  }
  else if (title === "Team" && fromScreen === "users")
      dispatchAvailableData({
        type: "handleClearOfType",
        payload: { payloadFor: "teams", payload: null },
      });
    else if (title === "Role" && fromScreen === "users")
      dispatchAvailableData({ type: "clearSelectedRoles", payload: null });
    else if (title === "Campaign status" && fromScreen === "autodialScreen")
      dispatchAvailableData({
        type: "handleClearOfType",
        payload: { payloadFor: "campaignStatus", payload: null },
      });
    else if (title === "Contacted" && fromScreen === "autodialScreenView")
      dispatchAvailableData({
        type: "handleClearOfType",
        payload: { payloadFor: "contacted", payload: null },
      });
    else if (title === "Team" && fromScreen === "virtual-number-outgoing")
      dispatchAvailableData({
        type: "handleClearOfType",
        payload: { payloadFor: "teams", payload: null },
      });
    else if (title === "Role" && fromScreen === "virtual-number-outgoing")
      dispatchAvailableData({
        type: "handleClearOfType",
        payload: { payloadFor: "roles", payload: null },
      });
    else if (title === "Status" && fromScreen === "livestatus") {
		setSelectedStatuses([])
		dispatchAvailableData({
			type: "handleClearOfType",
			payload: { payloadFor: "statuses", payload: null },
		});
	}
    else if (title === "Role" && fromScreen === "livestatus") {
		setSelectedRoles([])
		dispatchAvailableData({
			type: "handleClearOfType",
			payload: { payloadFor: "roles", payload: null },
		});
	}
    else if (title === "Status")
      dispatchAvailableData({ type: "clearSelectedStatuses", payload: null });
    else if (title === "Call type" && fromScreen === "call-logs")
      dispatchAvailableData({
        type: "handleClearOfType",
        payload: { payloadFor: "statusTypes", payload: null },
      });
    else if (title === "Message type" && fromScreen === "sms-logs")
      dispatchAvailableData({
        type: "handleClearOfType",
        payload: { payloadFor: "messageType", payload: null },
      });
    else if (title === "Autodial Lists")
      dispatchAvailableData({
        type: "handleClearOfType",
        payload: { payloadFor: "autodialLists", payload: null },
      });
    else if (title === "Users")
      dispatchAvailableData({
        type: "handleClearOfType",
        payload: { payloadFor: "users", payload: null },
      });
    else if (title === "Tags") {
      dispatchAvailableData({
        type: "handleClearOfType",
        payload: { payloadFor: "tags", payload: null },
      });
      setSelectedTags([]);
    } else if (title === "Message status")
      dispatchAvailableData({
        type: "handleClearOfType",
        payload: { payloadFor: "statusTypes", payload: null },
      });
    else if (title === "Campaign status")
      dispatchAvailableData({
        type: "handleClearOfType",
        payload: { payloadFor: "statusTypes", payload: null },
      });
    else if (title === "Call status")
      dispatchAvailableData({
        type: "handleClearOfType",
        payload: { payloadFor: "statusTypes", payload: null },
      });
    // add_new_3
    else if (title === "Location")
      dispatchAvailableData({
        type: "handleClearOfType",
        payload: { payloadFor: "locations", payload: null },
      });
    else if (title === "Number type")
      dispatchAvailableData({
        type: "handleClearOfType",
        payload: { payloadFor: "numberTypes", payload: null },
      });
    else if(title==="Category"){
      dispatchAvailableData({
    type:"handleClearOfType",
    payload:{payloadFor:"categories",payload:null}
  })
  setSelectedCategories([])
}else if(title === "Assigned to"){
  dispatchAvailableData({type:'handleClearOfType',payload:{payloadFor:'assignedTo',payload:null}})
}
    // added to prevent re-opening of the menu after clearing the filter // reduce time, second arg.
    setTimeout(() => handleClose(), 100);
  }

  const localStyles = {
    titleText: {
      color:
        Array.isArray(selectedFilters) && selectedFilters.length
          ? Colors.ic_green_400
          : Colors.nc_gray_900,
      marginRight: "4px",
      lineHeight: "24px",
    },
    gfilterContainer: {
      border:
        Array.isArray(selectedFilters) &&
        selectedFilters.length &&
        (newScreens.includes(fromScreen))
          ? `1px solid ${Colors.ic_green_300}`
          : `1px solid ${Colors.nc_gray_300}`,
      marginRight: "12px",
      borderRadius: "4px",
      padding: "12px 10px 12px 12px",

      cursor: isDisabled ? "not-allowed" : "pointer",
      opacity: isDisabled ? "0.4" : "1",
      position: "relative",
      zIndex: 1,

      backgroundColor:
        Array.isArray(selectedFilters) && selectedFilters.length
          ? Colors.ic_green_100
          : Colors.ic_white,
      maxHeight: "40px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    badgeAndChipContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "-2px",
    },
    spanNoOverFlowStyle: {
      display: "inline-block",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "100%",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "24px",
    },

    selectedFiltersLengthText: {
      width: "30px",
      height: "20px",
      marginRight: "6px",
      display: "inline-flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10px",
      backgroundColor: Colors.ic_green_300,
      color: Colors?.ic_white,
    },
    clearSelectedFilterXIconContainer: {
      transform: "translateY(1px)",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
  };
  const reduxAccountRole = useSelector(
    (state) => state?.account?.value?.data?.profile?.role
  );

  if (![1, 4].includes(reduxAccountRole) && title === "Users") return null;

  return (
    <>
      <div
        className="filter"
        style={localStyles.gfilterContainer}
        onClick={(e) => handleFilterClick(e)}
      >
        <p className="b1 regular-font" style={localStyles.titleText}>
          {title}
        </p>

        {Array.isArray(selectedFilters) && selectedFilters.length ? (
          <div style={localStyles.badgeAndChipContainer}>
            {/* number of selected options in a filter */}
            <span
              className="b3 regular-font ic-white"
              style={localStyles.selectedFiltersLengthText}
            >
              {selectedFilters.length}
            </span>

            {/* close icon */}
            {!["dashboard","call-logs","credit-history","sms-logs"].includes(fromScreen) && (
              <div
                style={localStyles.clearSelectedFilterXIconContainer}
                onClick={(e) => handleClearCurrentFilter(e)}
              >
                <img
                  data-icontype="close"
                  alt={title + " filter clear icon"}
                  src={filterClearIcon}
                />
              </div>
            )}
          </div>
        ) : null}
        <img src={filterDownArrow} alt={title + " filter dropdown icon"} />
      </div>

      {/* filter dropdown menu */}
      {isDisabled ? null : (
        <Menu
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          className="gfilter-dropdown-menu"
          autoFocus={false}
          open={isDropdownVisible}
          anchorEl={dropdownAnchorEl}
          sx={{
            "& .MuiPaper-root::-webkit-scrollbar": {
              background: "transparent",
              width: "4px",
              opacity: 0,
              height: 0,
            },
            "& .MuiPaper-root::-webkit-scrollbar-track-piece": {
              background: "transparent",
            },
            "& .MuiPaper-root::-webkit-scrollbar-thumb": {
              /* Neutral Colors/Grey - 300 */
              backgroundColor: "transparent",
              width: "4px",
              borderRadius: "3px",
              transition: "all 0.3s ease-in-out",
            },
            "& .MuiPaper-root::-webkit-scrollbar-track": {
              background: "transparent",
              marginTop: "16px",
              marginBottom: "16px",
              width: "4px",
            },
            "& .MuiPaper-root::-webkit-scrollbar-corner": {
              display: "none",
            },

            "& .MuiPaper-root::-webkit-scrollbar-button": {
              display: "none",
            },

            "& .MuiPaper-root": {
              maxHeight: "300px",
              maxWidth: "300px",
              background: Colors.ic_white,
              border: `1px solid #E1E6E2`,
              boxShadow: "0px 4px 8px 8px rgba(95, 102, 97, 0.04)",
              borderRadius: "6px",
              marginTop: "-1px",
              width: "336px",
              "&:hover::-webkit-scrollbar-thumb": {
                backgroundColor: Colors.nc_gray_500,
              },
            },

			"& .MuiList-root" : {
				paddingTop: "4px",
				paddingBottom: "0px",
			}
          }}
        >
          {(fromScreen === "dashboard" || fromScreen === "call-logs") &&
            title != "Call type" && (
              <div
                style={{
                  backgroundColor: colors.nc_gray_100,
                  padding: "16px 20px",
                  borderBottom: "1px solid",
                  borderBottomColor: colors.nc_gray_300,
                  marginTop: "-8px",
				  position: "sticky",
				  zIndex: 3,
				  top: "0px",
                }}
              >
                <div
                  className="left-header-item search"
                  style={{
                    borderColor: Colors.nc_gray_300,
                    maxHeight: "36px",

                    cursor: "pointer",
                    borderRadius: "4px",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    padding: "8px",

                    display: "flex",
                    alignItems: "center",
                    backgroundColor: colors.ic_white,
                  }}
                >
                  <img
                    onClick={(e) =>
                      document.getElementById("gtable-search")?.focus()
                    }
                    style={{ marginRight: "5px" }}
                    src={searchText?.length > 0 ? searchActiveIcon : searchIcon}
                    alt="Search icon"
                  />
                  <input
                    id="gtable-search"
                    className="t6 regular-font"
                    type="search"
                    placeholder="Search"
                    value={searchText}
                    style={{ border: "1px solid #e1e5e2", borderRight:'none',borderLeft:'none', paddingLeft:'4px', width: "100%" }}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
              </div>
            )}
          {/* Autodial.js */}
          {title === "Campaign status" &&
            fromScreen === "autodialScreen" &&
            availableData?.campaignStatus?.all?.map(({ id, name }, index) => {
              return (
                <Box key={index} sx={{ maxHeight: "320px" }}>
                  <MenuItem
                    title={name}
                    onClick={(e) => handleToggleableItemClick(e, id, name)}
                  >
                    <Checkbox
                      disableRipple={true}
                      checkedIcon={
                        <img
                          src={checkboxCheckedIcon}
                          alt="Checked checkbox "
                        />
                      }
                      icon={
                        <img src={checkboxIcon} alt="Unchecked checkbox " />
                      }
                      sx={{
                        color: Colors.nc_gray_400,
                        marginRight: "4px",
                        "&.Mui-checked": {
                          color: Colors.ic_green_300,
                        },
                      }}
                      checked={availableData?.campaignStatus?.selected?.includes(
                        id?.toLowerCase()
                      )}
                    />
                    <span style={{ ...localStyles?.spanNoOverFlowStyle }}>
                      {name}
                    </span>
                  </MenuItem>
                </Box>
              );
            })}

          {/* AutodialView.js */}
          {title === "Contacted" &&
            fromScreen === "autodialScreenView" &&
            availableData?.contacted?.all?.map(({ id, name }, index) => {
              return (
                <Box key={index} sx={{ maxHeight: "320px" }}>
                  <MenuItem
                    title={name}
                    onClick={(e) => handleToggleableItemClick(e, id, name)}
                  >
                    <Checkbox
                      disableRipple={true}
                      checkedIcon={
                        <img
                          src={checkboxCheckedIcon}
                          alt="Checked checkbox "
                        />
                      }
                      icon={
                        <img src={checkboxIcon} alt="Unchecked checkbox " />
                      }
                      sx={{
                        color: Colors.nc_gray_400,
                        marginRight: "4px",
                        "&.Mui-checked": {
                          color: Colors.ic_green_300,
                        },
                      }}
                      checked={availableData?.contacted?.selected?.includes(
                        id?.toLowerCase()
                      )}
                    />
                    <span style={{ ...localStyles?.spanNoOverFlowStyle }}>
                      {name}
                    </span>
                  </MenuItem>
                </Box>
              );
            })}

          {/* Users.js */}
          {/* teams */}
          {title === "Team" &&
            fromScreen === "users" &&
            availableData?.teams?.all?.map(({ id, name }, index) => {
              return (
                <Box key={index} sx={{ maxHeight: "320px" }}>
                  <MenuItem
                    title={name}
                    onClick={(e) => handleToggleableItemClick(e, id, name)}
                  >
                    <Checkbox
                      disableRipple={true}
                      checkedIcon={
                        <img
                          src={checkboxCheckedIcon}
                          alt="Checked checkbox "
                        />
                      }
                      icon={
                        <img src={checkboxIcon} alt="Unchecked checkbox " />
                      }
                      sx={{
                        color: Colors.nc_gray_400,
                        marginRight: "4px",
                        "&.Mui-checked": {
                          color: Colors.ic_green_300,
                        },
                      }}
                      checked={availableData?.teams?.selected?.includes(id)}
                    />
                    <span style={{ ...localStyles?.spanNoOverFlowStyle }}>
                      {name}
                    </span>
                  </MenuItem>
                </Box>
              );
            })}
          {title === "Team" && fromScreen === "dashboard" && availableTeamsToDisplay?.map(({ id, name, users }, index) => {
              return (
                <Box key={index} sx={{ maxHeight: "320px" }}>
                  <MenuItem
                    title={name}
                    onClick={(e) =>
                      handleToggleableItemClick(e, id, name, users)
                    }
                  >
                    <Checkbox
                      disableRipple={true}
                      checkedIcon={
                        <img
                          src={checkboxCheckedIcon}
                          alt="Checked checkbox "
                        />
                      }
                      icon={
                        <img src={checkboxIcon} alt="Unchecked checkbox " />
                      }
                      sx={{
                        color: Colors.nc_gray_400,
                        marginRight: "4px",
                        "&.Mui-checked": {
                          color: Colors.ic_green_300,
                        },
                      }}
                      checked={
                        Object.keys(selectedTeams).includes(String(id))
                          ? true
                          : false
                      }
                      //checked={availableData?.teams?.selected?.includes(id)}
                    />
                    <span style={{ ...localStyles?.spanNoOverFlowStyle }}>
                      {name}
                    </span>
                  </MenuItem>
                </Box>
              );
            })}
          	
			{title === "Team" && (fromScreen === "call-logs" || fromScreen==='sms-logs') && availableTeamsToDisplay?.map(({ id, name, users }, index) => {
				return (
					<Box key={index} sx={{ maxHeight: "320px" }}>
						<MenuItem
							title={name}
							onClick={e => handleToggleableItemClick(e, id, name, users)}
						>
						<Checkbox
							disableRipple={true}
							checkedIcon={ <img src={checkboxCheckedIcon} alt="Checked checkbox "/> }
							icon={ <img src={checkboxIcon} alt="Unchecked checkbox " /> }
							sx={{
								color: Colors.nc_gray_400,
								marginRight: "4px",
								"&.Mui-checked": {
									color: Colors.ic_green_300,
								},
							}}
							checked={ Object.keys(selectedTeams).includes(String(id)) ? true : false}
						/>
						<span style={{ ...localStyles?.spanNoOverFlowStyle }}>
							{name}
						</span>
						</MenuItem>
					</Box>
				);
            })}



          {title === "Team" &&
            fromScreen === "virtual-number-outgoing" &&
            availableData?.teams?.all?.map(({ id, name }, index) => {
              return (
                <Box key={index} sx={{ maxHeight: "320px" }}>
                  <MenuItem
                    title={name}
                    onClick={(e) => handleToggleableItemClick(e, id, name)}
                  >
                    <Checkbox
                      disableRipple={true}
                      checkedIcon={
                        <img
                          src={checkboxCheckedIcon}
                          alt="Checked checkbox "
                        />
                      }
                      icon={
                        <img src={checkboxIcon} alt="Unchecked checkbox " />
                      }
                      sx={{
                        color: Colors.nc_gray_400,
                        marginRight: "4px",
                        "&.Mui-checked": {
                          color: Colors.ic_green_300,
                        },
                      }}
                      checked={availableData?.teams?.selected?.includes(id)}
                    />
                    <span style={{ ...localStyles?.spanNoOverFlowStyle }}>
                      {name}
                    </span>
                  </MenuItem>
                </Box>
              );
            })}

          {/* role new */}
          {title === "Role" &&
            availableData?.roles?.all?.map(({ id, name }, index) => {
              return (
                <Box key={index} sx={{ maxHeight: "320px" }}>
                  <MenuItem
                    title={name}
                    onClick={(e) => handleToggleableItemClick(e, id, name)}
                  >
                    <Checkbox
                      disableRipple={true}
                      checkedIcon={
                        <img
                          src={checkboxCheckedIcon}
                          alt="Checked checkbox "
                        />
                      }
                      icon={
                        <img src={checkboxIcon} alt="Unchecked checkbox " />
                      }
                      sx={{
                        color: Colors.nc_gray_400,
                        marginRight: "4px",
                        "&.Mui-checked": {
                          color: Colors.ic_green_300,
                        },
                      }}
                      checked={fromScreen === "livestatus" ? selectedRoles.includes(id) : availableData?.roles?.selected?.includes(id)}
                    />
                    <span style={{ ...localStyles?.spanNoOverFlowStyle }}>
                      {name}
                    </span>
                  </MenuItem>
                </Box>
              );
            })}

          {/* status new */}
          {title === "Status" &&
            availableData?.statuses?.all?.map(({ id, name }, index) => {
              return (
                <Box key={index} sx={{ maxHeight: "320px" }}>
                  <MenuItem
                    title={name}
                    onClick={(e) => handleToggleableItemClick(e, id, name)}
                  >
                    <Checkbox
                      disableRipple={true}
                      checkedIcon={
                        <img
                          src={checkboxCheckedIcon}
                          alt="Checked checkbox "
                        />
                      }
                      icon={
                        <img src={checkboxIcon} alt="Unchecked checkbox " />
                      }
                      sx={{
                        color: Colors.nc_gray_400,
                        marginRight: "4px",
                        "&.Mui-checked": {
                          color: Colors.ic_green_300,
                        },
                      }}
                      checked={fromScreen === "livestatus" ? selectedStatuses.includes(id) : availableData?.statuses?.selected?.includes(id)}
                    />
                    <span style={{ ...localStyles?.spanNoOverFlowStyle }}>
                      {name}
                    </span>
                  </MenuItem>
                </Box>
              );
            })}
          {title === "Users" &&
            (newScreens.includes(fromScreen)) &&
            availableUsersToDisplay?.map(({ id, name }, index) => {
              return (
                <Box key={index} sx={{ maxHeight: "320px" }}>
                  <MenuItem
                    title={name}
                    onClick={(e) => handleToggleableItemClick(e, id, name)}
                  >
                    <Checkbox
                      disableRipple={true}
                      checkedIcon={
                        <img
                          src={checkboxCheckedIcon}
                          alt="Checked checkbox "
                        />
                      }
                      icon={
                        <img src={checkboxIcon} alt="Unchecked checkbox " />
                      }
                      sx={{
                        color: Colors.nc_gray_400,
                        marginRight: "4px",
                        "&.Mui-checked": {
                          color: Colors.ic_green_300,
                        },
                      }}
                      checked={selectedUsers?.includes(id)}
                      //checked={availableData?.users?.selected?.includes(id)}
                    />
                    <span style={{ ...localStyles?.spanNoOverFlowStyle }}>
                      {name}
                    </span>
                  </MenuItem>
                </Box>
              );
            })}

          {/* CallLogs.js */}
          {/* users */}
          {title === "Users" &&
            !newScreens.includes(fromScreen) &&
            availableData?.users?.all?.map(({ id, name }, index) => {
              return (
                <Box key={index} sx={{ maxHeight: "320px" }}>
                  <MenuItem
                    title={name}
                    onClick={(e) => handleToggleableItemClick(e, id, name)}
                  >
                    <Checkbox
                      disableRipple={true}
                      checkedIcon={
                        <img
                          src={checkboxCheckedIcon}
                          alt="Checked checkbox "
                        />
                      }
                      icon={
                        <img src={checkboxIcon} alt="Unchecked checkbox " />
                      }
                      sx={{
                        color: Colors.nc_gray_400,
                        marginRight: "4px",
                        "&.Mui-checked": {
                          color: Colors.ic_green_300,
                        },
                      }}
                      checked={availableData?.users?.selected?.includes(id)}
                    />
                    <span style={{ ...localStyles?.spanNoOverFlowStyle }}>
                      {name}
                    </span>
                  </MenuItem>
                </Box>
              );
            })}
          
          {title === "List" &&
            availableListsToDisplay?.map(({ id, name }, index) => {
              return (
                <Box key={index} sx={{ maxHeight: "320px" }}>
                  <MenuItem
                    title={name}
                    onClick={(e) => handleToggleableItemClick(e, id, name)}
                  >
                    <Checkbox
                      disableRipple={true}
                      checkedIcon={
                        <img
                          src={checkboxCheckedIcon}
                          alt="Checked checkbox "
                        />
                      }
                      icon={
                        <img src={checkboxIcon} alt="Unchecked checkbox " />
                      }
                      sx={{
                        color: Colors.nc_gray_400,
                        marginRight: "4px",
                        "&.Mui-checked": {
                          color: Colors.ic_green_300,
                        },
                      }}
                      checked={selectedLists?.includes(id)}
                      //checked={availableData?.users?.selected?.includes(id)}
                    />
                    <span style={{ ...localStyles?.spanNoOverFlowStyle }}>
                      {name}
                    </span>
                  </MenuItem>
                </Box>
              );
            })}

          {title === "Tags" &&
            tagsToDisplay?.map(({ id, name }, index) => {
              return (
                <Box key={index} sx={{ maxHeight: "320px" }}>
                  <MenuItem
                    title={name}
                    onClick={(e) => handleToggleableItemClick(e, id, name)}
                  >
                    <Checkbox
                      disableRipple={true}
                      checkedIcon={
                        <img
                          src={checkboxCheckedIcon}
                          alt="Checked checkbox "
                        />
                      }
                      icon={
                        <img src={checkboxIcon} alt="Unchecked checkbox " />
                      }
                      sx={{
                        color: Colors.nc_gray_400,
                        marginRight: "4px",
                        "&.Mui-checked": {
                          color: Colors.ic_green_300,
                        },
                      }}
                      checked={selectedTags.includes(id)}
                    />
                    <span style={{ ...localStyles?.spanNoOverFlowStyle }}>
                      {name}
                    </span>
                  </MenuItem>
                </Box>
              );
            })}

          {/* autodialLists */}
          {title === "Autodial Lists" &&
            availableData?.autodialLists?.all?.map(({ id, name }, index) => {
              return (
                <Box key={index} sx={{ maxHeight: "320px" }}>
                  <MenuItem
                    title={name}
                    onClick={(e) => handleToggleableItemClick(e, id, name)}
                  >
                    <Checkbox
                      disableRipple={true}
                      checkedIcon={
                        <img
                          src={checkboxCheckedIcon}
                          alt="Checked checkbox "
                        />
                      }
                      icon={
                        <img src={checkboxIcon} alt="Unchecked checkbox " />
                      }
                      sx={{
                        color: Colors.nc_gray_400,
                        marginRight: "4px",
                        "&.Mui-checked": {
                          color: Colors.ic_green_300,
                        },
                      }}
                      checked={availableData?.autodialLists?.selected?.includes(
                        id
                      )}
                    />
                    <span style={{ ...localStyles?.spanNoOverFlowStyle }}>
                      {name}
                    </span>
                  </MenuItem>
                </Box>
              );
            })}

          {/* call types */}
          {title === "Call type" &&
            fromScreen === "call-logs" &&
            availableData?.statusTypes?.all?.map(({ id, name }, index) => {
              return (
                <Box key={index} sx={{ maxHeight: "320px" }}>
                  <MenuItem
                    title={name}
                    onClick={(e) => handleToggleableItemClick(e, id, name)}
                  >
                    <Checkbox
                      disableRipple={true}
                      checkedIcon={
                        <img
                          src={checkboxCheckedIcon}
                          alt="Checked checkbox "
                        />
                      }
                      icon={
                        <img src={checkboxIcon} alt="Unchecked checkbox " />
                      }
                      sx={{
                        color: Colors.nc_gray_400,
                        marginRight: "4px",
                        "&.Mui-checked": {
                          color: Colors.ic_green_300,
                        },
                      }}
                      checked={selectedCallTypes.includes(id)}
                    />
                    <span style={{ ...localStyles?.spanNoOverFlowStyle }}>
                      {name}
                    </span>
                  </MenuItem>
                </Box>
              );
            })}
          {/* message types */}
          {title === "Message type" &&
            fromScreen === "sms-logs" &&
            availableData?.messageType?.all?.map(({ id, name }, index) => {
              return (
                <Box key={index} sx={{ maxHeight: "320px" }}>
                  <MenuItem
                    title={name}
                    onClick={(e) => handleToggleableItemClick(e, id, name)}
                  >
                    <Checkbox
                      disableRipple={true}
                      checkedIcon={
                        <img
                          src={checkboxCheckedIcon}
                          alt="Checked checkbox "
                        />
                      }
                      icon={
                        <img src={checkboxIcon} alt="Unchecked checkbox " />
                      }
                      sx={{
                        color: Colors.nc_gray_400,
                        marginRight: "4px",
                        "&.Mui-checked": {
                          color: Colors.ic_green_300,
                        },
                      }}
                      checked={selectedMessageTypes.includes(id)}
                    />
                    <span style={{ ...localStyles?.spanNoOverFlowStyle }}>
                      {name}
                    </span>
                  </MenuItem>
                </Box>
              );
            })}
          

          {/* contact lists view */}
          {/* // add_new_4 (final) */}
          {title === "Message status" &&
            availableData?.statusTypes?.all?.map(({ id, name }, index) => {
              return (
                <Box key={index} sx={{ maxHeight: "320px" }}>
                  <MenuItem
                    title={name}
                    onClick={(e) => handleToggleableItemClick(e, id, name)}
                  >
                    <Checkbox
                      disableRipple={true}
                      checkedIcon={
                        <img
                          src={checkboxCheckedIcon}
                          alt="Checked checkbox "
                        />
                      }
                      icon={
                        <img src={checkboxIcon} alt="Unchecked checkbox " />
                      }
                      sx={{
                        color: Colors.nc_gray_400,
                        marginRight: "4px",
                        "&.Mui-checked": {
                          color: Colors.ic_green_300,
                        },
                      }}
                      checked={availableData?.statusTypes?.selected?.includes(
                        id
                      )}
                    />
                    <span style={{ ...localStyles?.spanNoOverFlowStyle }}>
                      {name}
                    </span>
                  </MenuItem>
                </Box>
              );
            })}

          {/* voice broadcast  */}
          {title === "Campaign status" &&
            availableData?.statusTypes?.all?.map(({ id, name }, index) => {
              return (
                <Box key={index} sx={{ maxHeight: "320px" }}>
                  <MenuItem
                    title={name}
                    onClick={(e) => handleToggleableItemClick(e, id, name)}
                  >
                    <Checkbox
                      disableRipple={true}
                      checkedIcon={
                        <img
                          src={checkboxCheckedIcon}
                          alt="Checked checkbox "
                        />
                      }
                      icon={
                        <img src={checkboxIcon} alt="Unchecked checkbox " />
                      }
                      sx={{
                        color: Colors.nc_gray_400,
                        marginRight: "4px",
                        "&.Mui-checked": {
                          color: Colors.ic_green_300,
                        },
                      }}
                      checked={availableData?.statusTypes?.selected?.includes(
                        id
                      )}
                    />
                    <span style={{ ...localStyles?.spanNoOverFlowStyle }}>
                      {name}
                    </span>
                  </MenuItem>
                </Box>
              );
            })}

          {/* contact list view */}
          {title === "Call status" &&
            availableData?.statusTypes?.all?.map(({ id, name }, index) => {
              return (
                <Box key={index} sx={{ maxHeight: "320px" }}>
                  <MenuItem
                    title={name}
                    onClick={(e) => handleToggleableItemClick(e, id, name)}
                  >
                    <Checkbox
                      disableRipple={true}
                      checkedIcon={
                        <img
                          src={checkboxCheckedIcon}
                          alt="Checked checkbox "
                        />
                      }
                      icon={
                        <img src={checkboxIcon} alt="Unchecked checkbox " />
                      }
                      sx={{
                        color: Colors.nc_gray_400,
                        marginRight: "4px",
                        "&.Mui-checked": {
                          color: Colors.ic_green_300,
                        },
                      }}
                      checked={availableData?.statusTypes?.selected?.includes(
                        id
                      )}
                    />
                    <span style={{ ...localStyles?.spanNoOverFlowStyle }}>
                      {name}
                    </span>
                  </MenuItem>
                </Box>
              );
            })}
            {title === "Assigned to" &&
            availableData?.assignedTo?.all?.map(({ id, name }, index) => {
              return (
                <Box key={index} sx={{ maxHeight: "320px" }}>
                  <MenuItem
                    title={name}
                    onClick={(e) => handleToggleableItemClick(e, id, name)}
                  >
                    <Checkbox
                      disableRipple={true}
                      checkedIcon={
                        <img
                          src={checkboxCheckedIcon}
                          alt="Checked checkbox "
                        />
                      }
                      icon={
                        <img src={checkboxIcon} alt="Unchecked checkbox " />
                      }
                      sx={{
                        color: Colors.nc_gray_400,
                        marginRight: "4px",
                        "&.Mui-checked": {
                          color: Colors.ic_green_300,
                        },
                      }}
                      checked={availableData?.assignedTo?.selected?.includes(id)}
                    />
                    <span style={{ ...localStyles?.spanNoOverFlowStyle }}>
                      {name}
                    </span>
                  </MenuItem>
                </Box>
              );
            })}

          {/* Virtual Numbers */}
          {title === "Location" &&
            availableData?.locations?.all?.map(({ id, name }, index) => {
              return (
                <Box key={index} sx={{ maxHeight: "320px" }}>
                  <MenuItem
                    title={name}
                    onClick={(e) => handleToggleableItemClick(e, id, name)}
                  >
                    <Checkbox
                      disableRipple={true}
                      checkedIcon={
                        <img
                          src={checkboxCheckedIcon}
                          alt="Checked checkbox "
                        />
                      }
                      icon={
                        <img src={checkboxIcon} alt="Unchecked checkbox " />
                      }
                      sx={{
                        color: Colors.nc_gray_400,
                        marginRight: "4px",
                        "&.Mui-checked": {
                          color: Colors.ic_green_300,
                        },
                      }}
                      checked={availableData?.locations?.selected?.includes(id)}
                    />
                    <span style={{ ...localStyles?.spanNoOverFlowStyle }}>
                      {name}
                    </span>
                  </MenuItem>
                </Box>
              );
            })}

          {/* Virtual Numbers */}
          {title === "Number type" &&
            availableData?.numberTypes?.all?.map(({ id, name }, index) => {
              return (
                <Box key={index} sx={{ maxHeight: "320px" }}>
                  <MenuItem
                    title={name}
                    onClick={(e) => handleToggleableItemClick(e, id, name)}
                  >
                    <Checkbox
                      disableRipple={true}
                      checkedIcon={
                        <img
                          src={checkboxCheckedIcon}
                          alt="Checked checkbox "
                        />
                      }
                      icon={
                        <img src={checkboxIcon} alt="Unchecked checkbox " />
                      }
                      sx={{
                        color: Colors.nc_gray_400,
                        marginRight: "4px",
                        "&.Mui-checked": {
                          color: Colors.ic_green_300,
                        },
                      }}
                      checked={availableData?.numberTypes?.selected?.includes(
                        id
                      )}
                    />
                    <span style={{ ...localStyles?.spanNoOverFlowStyle }}>
                      {name}
                    </span>
                  </MenuItem>
                </Box>
              );
            })}
          {/*Credit history*/}
          {title === "Category" &&
            fromScreen === "credit-history" &&
            availableData?.categories?.all?.map(({ id, name }, index) => {
              return (
                <Box key={index} sx={{ maxHeight: "320px" }}>
                  <MenuItem
                    title={name}
                    onClick={(e) => handleToggleableItemClick(e, id, name)}
                  >
                    <Checkbox
                      disableRipple={true}
                      checkedIcon={
                        <img
                          src={checkboxCheckedIcon}
                          alt="Checked checkbox "
                        />
                      }
                      icon={
                        <img src={checkboxIcon} alt="Unchecked checkbox " />
                      }
                      sx={{
                        color: Colors.nc_gray_400,
                        marginRight: "4px",
                        "&.Mui-checked": {
                          color: Colors.ic_green_300,
                        },
                      }}
                      checked={selectedCategories?.includes(id)}
                    />
                    <span style={{ ...localStyles?.spanNoOverFlowStyle }}>
                      {name}
                    </span>
                  </MenuItem>
                </Box>
              );
            })}
          {(["dashboard","call-logs","credit-history","sms-logs", "livestatus"].includes(fromScreen)) && (
            <div className="dashboard_date-menu-btn-container">
              <Button
                variant="outlined"
                buttonText="Clear"
                hierarchy="white"
                disabled={false}
                styleOverride={{
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  fontSize: "14px",
                }}
                onClick={(e)=>handleClearCurrentFilter(e)}
              />
              <Button
                variant="outlined"
                buttonText="Apply"
                disabled={selectedCount < 1}
                hierarchy="green"
                styleOverride={{
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  fontSize: "14px",
                }}
                onClick={applyFilterHandler}
              />
            </div>
          )}
        </Menu>
      )}
    </>
  );
}
