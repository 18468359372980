import { ListItem, ListItemText, List, Backdrop } from "@mui/material";
import filterDownArrow from "../../assets/gusers-and-teams-down-arrow.svg";
import filterDownArrowGreen from "../../assets/dashboard-filter-down-arrow-green.svg";
import { useState, useEffect } from "react";
import colors from "../design/colors";
import Button from "../design/Button";
import GHeader from "../design/components/GHeader";
import "./filters.css";
import GFilters from "../design/components/GFilters";
import { callLogsHeaderCalendar } from "../../utility-functions/assetLoader";

const formatDate = (date) => {
  return `${new Date(date).getFullYear()}-${(
    "0" +
    (new Date(date).getMonth() + 1)
  ).slice(-2)}-${("0" + new Date(date).getDate()).slice(-2)}`;
};


const defaultCalendarState = {
  // date_start is the date of the day 86,400s before currentTime
  // FIXME: calling Date.now() twice gives a chance for date_end to be the same day as that of date_start
  // to avoid this, set date_start first, and the based on that date_start, set date_end
  date_start: formatDate(Date.now() - 2*86400000), // 2 days data
    // new Date(new Date() - (new Date().getDate() - 1) * 24 * 60 * 60 * 1000)),
  date_end: formatDate(Date.now()),
  time_start: "00:00:00",
  time_end: "23:59:59",
  date_start_unformatted: Date.now() - 2 * 86400000,
  date_end_unformatted: Date.now(),
};

const defaultCallLogsCalendarState ={
  date_start: formatDate(Date.now() - 86400000), // 1 day data
  date_end: formatDate(Date.now()),
  time_start: "00:00:00",
  time_end: "23:59:59",
  date_start_unformatted: Date.now() - 86400000,
  date_end_unformatted: Date.now()
}

const defaultCreditHistoryCalendarState={
  date_start: formatDate(Date.now() - 7*86400000), 
  date_end: formatDate(Date.now()),
  time_start: "00:00:00",
  time_end: "23:59:59",
  date_start_unformatted:Date.now() - 7*86400000,
  date_end_unformatted:Date.now()
}

export const Filters = ({
  onApply,
  availableData=null,
  dispatchAvailableData=()=>null,
  fromScreen,
  filtersList,
  dateFilter=true,
  ...props
}) => {
  const [activeFilter, setActiveFilter] = useState(null);
  const dateRangeOptions = [
    "Today",
    "Yesterday",
    "This week",
    "Previous week",
    "Custom range",
  ];
  const [selectedRange, setSelectedRange] = useState(fromScreen==='billing'?props?.value?.range:'');
  const [isDateFilterApplied, setIsDateFilterApplied] = useState(false);
  const [calendarDisabled, setCalendarDisabled] = useState(true);
  let defaultBillingDate={}
  if(fromScreen==='billing' && props?.value?.calendar?.date_start){
    const {date_start,date_end}=props?.value?.calendar
    defaultBillingDate={
      date_start:formatDate(date_start),
      date_end:formatDate(date_end),
      date_end_unformatted:date_end,
      date_start_unformatted:date_start,
      time_start: "00:00:00",
      time_end: "23:59:59",
    }
  }
  const [calendar, setCalendar] = useState((fromScreen === "call-logs" || fromScreen==='sms-logs') ? defaultCallLogsCalendarState : (fromScreen==='billing' && props?.value?.calendar?.date_start) ?defaultBillingDate:fromScreen==='credit-history'?defaultCreditHistoryCalendarState:defaultCalendarState);
  const dateFilterHandler = () => {
	if(props.calendarDisabled) return
    if (activeFilter) {
      setActiveFilter(null);
      return;
    }
    setActiveFilter("Date");
  };

  useEffect(() => {
	if(props.calendarDisabled) return
    const todaysDayNumber = new Date().getDay();
	const one_day = 86400000; // 86,400 * 1,000 ms
	const today = Date.now();

    switch (selectedRange) {
      case "Today":
		const _date_start = new Date(today)
		_date_start.setHours(0, 0, 0);
        setCalendar((prev) => ({
          ...prev,
          date_start: formatDate(_date_start),
          date_end: formatDate(new Date(today)),
          date_start_unformatted:_date_start,
          date_end_unformatted:new Date(today)
        }));
        setCalendarDisabled(true);
        break;
      case "Yesterday":
        setCalendar((prev) => ({
          ...prev,
          date_start: formatDate(new Date(today - one_day)),
          date_end: formatDate(new Date(today - one_day)),
          date_start_unformatted:new Date(today - one_day),
          date_end_unformatted:new Date(today - one_day)
        }));
        setCalendarDisabled(true);
        break;
      case "This week":
		setCalendar((prev) => ({
			...prev,
			date_start: formatDate(
			  new Date(today - (todaysDayNumber - 1) * one_day)
			),
			date_end: formatDate(today),
      date_start_unformatted: new Date(today - (todaysDayNumber - 1) * one_day),
      date_end_unformatted:today
		}));
        setCalendarDisabled(true);
        break;
      case "Previous week":
		setCalendar((prev) => ({
			...prev,
			date_start: formatDate(today - 7 * one_day), // 7 days
			  //new Date(new Date() - (todaysDayNumber - 1) * 24 * 60 * 60 * 1000)
			//),
			date_end: formatDate(today),
      date_start_unformatted:today - 7 * one_day,
      date_end_unformatted:today
		}));
        setCalendarDisabled(true);
        break;
      case "Custom range":
        setCalendarDisabled(false);
        break;
      default:
        setCalendarDisabled(true);
        break;
    }
  }, [selectedRange]);

  function handleCalendarSet(calendarValueOnOk, startOrEnd) {
    if (startOrEnd === "start") {
      if (!calendarValueOnOk) return;

      setCalendar((prev) => ({
        ...prev,
        date_start: formatDate(calendarValueOnOk),
        date_start_unformatted:calendarValueOnOk
      }));
    } else if (startOrEnd === "end") {
      // see if start is set
      let timeEnd = "23:59:59";
      if (calendar?.date_start && calendar?.time_start) {
        setCalendar((prevState) => ({
          ...prevState,
          date_end: formatDate(calendarValueOnOk),
          date_end_unformatted:calendarValueOnOk,
          time_end: timeEnd,
        }));
      } else {
        // start not set
        if (!calendarValueOnOk) return;
        setCalendar((prevState) => ({
          ...prevState,
          date_end: formatDate(calendarValueOnOk),
          date_end_unformatted:calendarValueOnOk,
          time_end: "23:59:59",
        }));
      }
    }
  }
  function handleCalendarChange(calendarValueOnChange, startOrEnd) {
    // null check
    if (calendarValueOnChange === null) {
      if (startOrEnd === "start") {
        setCalendar((prevState) => ({
          ...prevState,
          date_start: null,
          date_start_unformatted:null,
          //time_start: null,
        }));
        // setStartDate(null);
      } else if (startOrEnd == "end") {
        setCalendar((prevState) => ({
          ...prevState,
          date_end: null,
          date_end_unformatted:null
          //time_end: null,
        }));
        //setEndDate(null);
      } else return;
    }
    return;
  }
  const clearDateFilterHandler = () => {
    setSelectedRange(null);
    setIsDateFilterApplied(false);
    //setCalendar(defaultCalendarState);
    setCalendar((fromScreen === "call-logs" || fromScreen==='sms-logs') ? defaultCallLogsCalendarState : ['billing','credit-history'].includes(fromScreen) ?defaultCreditHistoryCalendarState:defaultCalendarState)
    setActiveFilter(null);
    //onApply("Date", defaultCalendarState);
    onApply('Date',(fromScreen === "call-logs" || fromScreen==='sms-logs') ? defaultCallLogsCalendarState : ['billing','credit-history'].includes(fromScreen) ?defaultCreditHistoryCalendarState:defaultCalendarState,null)
  };
  const applyDateFilterHandler = () => {
    setIsDateFilterApplied(true);
    setActiveFilter(null);
    onApply("Date", calendar,selectedRange);
  };

  return (
    <div className="dashboard_filter-row-container" style={props?.style}>
      <div className="dashboard_filter-row">
      {/* <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <div
          className="filter_container"
          style={{
            backgroundColor: isDateFilterApplied ? "#f0fcf4" : "#fff",
            color: isDateFilterApplied
              ? colors.ic_green_300
              : colors.nc_gray_900,
            border: "1px solid ",
            borderColor: isDateFilterApplied
              ? colors.ic_green_300
              : colors.nc_gray_300,
          }}
          onClick={dateFilterHandler}
        >
          <p>Date</p>
          <img
            src={isDateFilterApplied ? filterDownArrowGreen : filterDownArrow}
            alt=""
          />
        </div>
       
      </div> */}
      <GFilters
        fromScreen={fromScreen}
        isDateFilterApplied={isDateFilterApplied}
        clearDateFilterHandler={clearDateFilterHandler}
        availableData={availableData}
        dispatchAvailableData={dispatchAvailableData}
        filters={filtersList}
		dateDropdownHandler={{
			isDateDropdownVisible: Boolean(activeFilter),
			dateFilterHandler,
		}}
		
      />
      </div>
      {dateFilter && <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <div
          className="filter_container"
          style={
            {
			  color: props.calendarDisabled ? colors.nc_gray_400 : colors.nc_gray_900,
			  userSelect: "none",
			  cursor: props.calendarDisabled ? "not-allowed" : "pointer"
              // backgroundColor: isDateFilterApplied ? "#f0fcf4" : "#fff",
              // color: isDateFilterApplied
              //   ? colors.ic_green_300
              //   : colors.nc_gray_900,
              // border: "1px solid ",
              // borderColor: isDateFilterApplied
              //   ? colors.ic_green_300
              //   : colors.nc_gray_300,
            }
          }
          onClick={dateFilterHandler}
        >
          <span style={{ marginRight: "6px" }}>{callLogsHeaderCalendar(props.calendarDisabled ? colors.nc_gray_400 : colors.nc_gray_900)}</span>
          <p className="t7 regular-font">
            {`  ${new Date(props.date || calendar.date_start_unformatted).toLocaleDateString(
              "en-IN",
              {
                day: "numeric",
                month:'short',
              }
            )} - ${new Date(props.date || calendar.date_end_unformatted).toLocaleDateString(
              "en-IN",
              {
                day: "numeric",
                month: 'short',
                year: "numeric",
              }
            )}`}
          </p>
        </div>
        {activeFilter === "Date" && (
			<>
			<Backdrop
			  sx={{ 
				zIndex: 5,
			  "&.MuiBackdrop-root": {
				backgroundColor: "rgba(0, 0, 0, 0)",
			  },
			}}
			  open={activeFilter}
			  onClick={dateFilterHandler}
		  	>
				
		 </Backdrop>
		 <div className="dashboard_date-filter-menu">
					<div
					style={{
						display: "flex",
						flexDirection: "row",
						height: "280px",
					}}
					>
					<div className="dashboard_filter-list-container">
						<List sx={{ paddingTop: "0" }}>
						{dateRangeOptions.map((range, i) => (
							<ListItem
							key={i}
							sx={{
								padding: "6px 20px 0 20px",

								cursor: "pointer",
								backgroundColor:
								selectedRange === range ? "#f7faf8" : "#fff",
								"&:hover": { backgroundColor: "#f7faf8" },
							}}
							onClick={() => setSelectedRange(range)}
							>
							<ListItemText sx={{ fontSize: "14px" }}>
								{range}
							</ListItemText>
							</ListItem>
						))}
						</List>
					</div>
					<div
						style={{
						padding: "10px",
						}}
					>
						<GHeader
						breadcrumbs={false}
						title={null}
						customstyle={{
							maxWidth: "370px",
							zIndex: 100,
							paddingRight: "20px",
						}}
						threeDots={false}
						topHeader={{}}
						gsep={false}
						bottomHeader={{
							contentTitle: " ",
							buttons: [],
							isCalendar: true,
							handleCalendarSet,
							calendar,
							handleCalendarChange,
							//calendarDisabled,
						}}
						/>
					</div>
					</div>
					<div className="dashboard_date-menu-btn-container">
					<Button
						variant="outlined"
						buttonText="Clear"
						hierarchy="white"
						disabled={false}
						onClick={clearDateFilterHandler}
					/>
					<Button
						variant="outlined"
						buttonText="Apply"
						//disabled={!selectedRange}
						hierarchy="green"
						onClick={applyDateFilterHandler}
					/>
					</div>
				</div>
			</>
			
        )}
    </div>}
    </div>
  );
};
