import { createSlice } from '@reduxjs/toolkit';

let initial = null
try{
    initial = JSON.parse(localStorage.getItem('frejun-virtual-number'))
}catch(err){
    console.warn(err, ' Setting up local storage for first (re)use.')
}

initial = initial?initial:{}

export const virtualNumberSlice = createSlice({
    name: 'virtual-number',
    initialState: {value:Object.keys(initial).length === 0?{}:initial},
    reducers:{
        setVirtualNumber: (state, action) => {
			let temp = state.value.outboundVirtualNumber
			if(!temp) {
				let outboundVnPayload = action.payload.outbound_virtual_numbers?.at(0)
				if(outboundVnPayload) state.value = {...state.value, ...action.payload, outboundVirtualNumber: outboundVnPayload}
			}
			else state.value = {...state.value, ...action.payload}
            localStorage.setItem('frejun-virtual-number', JSON.stringify(state.value))
        },
		reduxPersistSelectedVirtualNumber: (state, action) => {
			// validate payload if necessary
			if(state.value.outboundVirtualNumber) {
				console.log("from redux changing selected vn from ", state.value, ' to ', action.payload)
			}
			else console.log("persisting outbound vn ", action.payload)

			state.value = {...state.value, outboundVirtualNumber: action.payload}
			localStorage.setItem("frejun-virtual-number", JSON.stringify(state.value))
		},
		setVirtualNumbersCount:(state,action)=>{
			state.value={...state.value,vnCount:action.payload}
			localStorage.setItem('frejun-virtual-number', JSON.stringify(state.value))
		}
    }
})

export const { setVirtualNumber, reduxPersistSelectedVirtualNumber,setVirtualNumbersCount } = virtualNumberSlice.actions
export default virtualNumberSlice.reducer