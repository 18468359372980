import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Colors from "../colors";
import GFilter from "./GFilter.js";
import "./gfilterStyles.css";

export default function GFilters({
  filters,
  dispatchAvailableData,
  availableData,
  fromScreen,
  isDateFilterApplied = false,
  clearDateFilterHandler,
  dateDropdownHandler, // {isDateDropdownVisible, dateFilterHandler}
}) {
  const [isFilters, setIsFilters] = useState(false);
  const [autoSelectedUsers, setAutoSelectedUsers] = useState([])

  useEffect(() => {
    if (availableData) {
      switch (fromScreen) {
        case "team-view":
          if (availableData?.roles?.selected?.length && !isFilters)
            setIsFilters(true);
          else if (!availableData?.roles?.selected?.length && isFilters)
            setIsFilters(false);
          break;
        case "users":
          if (
            (availableData?.teams?.selected?.length ||
              availableData?.roles?.selected?.length ||
              availableData?.statuses?.selected?.length) &&
            !isFilters
          )
            setIsFilters(true);
          else if (
            !availableData?.teams?.selected?.length &&
            !availableData?.roles?.selected?.length &&
            !availableData?.statuses?.selected?.length &&
            isFilters
          )
            setIsFilters(false);
          break;
        case "dashboard":
          if (
            (Object.keys(availableData?.teams?.selected)?.length ||
              availableData?.users?.selected?.length ||
              availableData?.lists?.selected?.length ||
              isDateFilterApplied) &&
            !isFilters
          )
            setIsFilters(true);
          else if (
            !Object.keys(availableData?.teams?.selected)?.length &&
            !availableData?.users?.selected?.length &&
            !availableData?.lists?.selected?.length &&
            !isDateFilterApplied &&
            isFilters
          )
            setIsFilters(false);
          break;
        case "call-logs":
          if (
            ((availableData?.teams?.selected &&
              Object.keys(availableData?.teams?.selected)?.length) ||
              availableData?.tags?.selected?.length ||
              availableData?.users?.selected?.length ||
              availableData?.statusTypes?.selected?.length ||
              isDateFilterApplied) &&
            !isFilters
          )
            setIsFilters(true);
          else if (
            (!availableData?.teams?.selected ||
              !Object.keys(availableData?.teams?.selected)?.length) &&
            !availableData?.tags?.selected?.length &&
            !availableData?.users?.selected?.length &&
			!availableData?.statusTypes?.selected?.length &&
            !isDateFilterApplied &&
            !(
              // !availableData?.autodialLists?.selected?.length &&
              !availableData?.statusTypes?.selected?.length
            ) &&
            isFilters
          )
            setIsFilters(false);
          break;
          case "sms-logs":
            if (
              ((availableData?.teams?.selected &&
                Object.keys(availableData?.teams?.selected)?.length) ||
                availableData?.tags?.selected?.length ||
                availableData?.users?.selected?.length ||
                availableData?.messageType?.selected?.length ||
                isDateFilterApplied) &&
              !isFilters
            )
              setIsFilters(true);
            else if (
              (!availableData?.teams?.selected ||
                !Object.keys(availableData?.teams?.selected)?.length) &&
              !availableData?.tags?.selected?.length &&
              !availableData?.users?.selected?.length &&
        !availableData?.messageType?.selected?.length &&
              !isDateFilterApplied &&
              !(
                // !availableData?.autodialLists?.selected?.length &&
                !availableData?.messageType?.selected?.length
              ) &&
              isFilters
            )
              setIsFilters(false);
            break;
        case "autodialScreen":
          if (availableData?.campaignStatus?.selected?.length && !isFilters)
            setIsFilters(true);
          else if (
            !availableData?.campaignStatus?.selected?.length &&
            isFilters
          )
            setIsFilters(false);
          break;
        case "autodialScreenView":
          if (availableData?.contacted?.selected?.length && !isFilters)
            setIsFilters(true);
          else if (!availableData?.contacted?.selected?.length && isFilters)
            setIsFilters(false);
          break;
        //  add_new_1
        case "voice":
          if (
              (availableData?.statusTypes?.selected?.length || availableData?.assignedTo?.selected?.length) &&
              !isFilters) {
              setIsFilters(true);
            } else if (
              !availableData?.statusTypes?.selected?.length &&
              !availableData?.assignedTo?.selected?.length &&
              isFilters) {
              setIsFilters(false);
            }
            break;
        case "virtual-nums":
          if (
            (availableData?.locations?.selected?.length ||
              availableData?.numberTypes?.selected?.length) &&
            !isFilters
          )
            setIsFilters(true);
          else if (
            !availableData?.locations?.selected?.length &&
            !availableData?.numberTypes?.selected?.length &&
            isFilters
          )
            setIsFilters(false);
          break;
        case "virtual-number-outgoing":
          if (
            (availableData?.teams?.selected?.length ||
              availableData?.roles?.selected?.length) &&
            !isFilters
          )
            setIsFilters(true);
          else if (
            !availableData?.teams?.selected?.length &&
            !availableData?.roles?.selected?.length &&
            isFilters
          )
            setIsFilters(false);
          break;
        case "credit-history":
          if((availableData?.categories?.selected?.length || isDateFilterApplied) && !isFilters)
            setIsFilters(true);
          else if(!availableData?.categories?.selected?.length && !isDateFilterApplied && isFilters)
            setIsFilters(false);
        case "livestatus":
          if((availableData?.statuses?.selected?.length || availableData?.roles?.selected?.length || availableData?.teams?.selected?.length) && !isFilters)
            setIsFilters(true);
          else if ((!availableData?.statuses?.selected?.length && !availableData?.roles?.selected?.length && !availableData?.teams?.selected?.length) && isFilters)
            setIsFilters(false);
        default:
          break;
      }
    }
  }, [availableData, isDateFilterApplied]); // eslint-disable-line

  function determineDisabledState(title) {
    let returnValue = false;
    if (title === "Team" && availableData?.teams?.all?.length === 0) return true;
    
    if (title === "Call reason" && availableData?.callReasonsFilter?.all?.length === 0) return true;
    
    if (title === "Call outcome" && availableData?.callOutcomesFilter?.all?.length === 0) return true;
    
    if (title === "Role" && availableData?.roles?.all?.length === 0) return true;
    
	if (title === "Status" && availableData?.statuses?.all?.length === 0) return true;

    if (title === "Campaign status" && fromScreen === "autodialScreen" && availableData?.campaignStatus?.all?.length === 0) return true;
    if (
      title === "Contacted" &&
      fromScreen === "autodialScreenView" &&
      availableData?.contacted?.all?.length === 0
    )
      return true;

    if (title === "Users") {
      // if(![1, 4].includes(reduxAccountRole)) return true
      if (availableData?.users?.all?.length === 0) return true;
    }
    if (title === "Tags") {
      if (availableData?.tags?.all?.length === 0) return true;
    }
    if (
      title === "Autodial Lists" &&
      fromScreen === "logs" &&
      availableData?.autodialLists?.all?.length === 0
    )
      return true;
    if (
      fromScreen === "call-logs" &&
      title === "Call type" &&
      availableData?.statusTypes?.all?.length === 0
    )
      return true;
    if (
        fromScreen === "sms-logs" &&
        title === "Message type" &&
        availableData?.messageType?.all?.length === 0
      )
        return true;
    //  add_new_2 (final)
    if (
      title === "Message status" &&
      availableData?.statusTypes?.all?.length === 0
    )
      return true;
    if (
      ["Campaign status", "Call status", "Call type"].includes(title) &&
      availableData?.statusTypes?.all?.length === 0
    )
      return true;
    if (title === "Location" && availableData?.locations?.all?.length === 0)
      return true;
    if (
      title === "Number type" &&
      availableData?.numberTypes?.all?.length === 0
    )
      return true;
    if( title === "List" && availableData?.lists?.all?.length === 0)
      return true;
    return returnValue;
  }

  return (
    <>
      {filters.map((filter, index) => (
        <GFilter
          fromScreen={fromScreen}
          isDisabled={determineDisabledState(filter?.name)}
          total={filters?.length}
          key={index}
          title={filter.name || filter?.split(",")[0]}
          dispatchAvailableData={dispatchAvailableData}
          availableData={availableData}
          toggle={filter?.toggle}
		  dateDropdownHandler={dateDropdownHandler}
        />
      ))}
      {["dashboard", "call-logs","credit-history","sms-logs","livestatus"].includes(fromScreen) && (
          <div
            onClick={(e) => {
              if (!isFilters) return;
              dispatchAvailableData({ type: "clearAllFilters", payload: null });
              setAutoSelectedUsers([])
              if (fromScreen === "dashboard" || fromScreen === "call-logs" || fromScreen==="credit-history" || fromScreen==="sms-logs") clearDateFilterHandler();
              setIsFilters(false);
            }}
            className="regular-font t6 clear-gfilters"
            style={{
              color: isFilters ? Colors.ic_green_500 : Colors.nc_gray_400,
              //textDecoration: "underline",
              cursor: isFilters ? "pointer" : "not-allowed",
            }}
          >
            Clear all
          </div>
        )}
      {isFilters && !["dashboard","call-logs","credit-history","sms-logs","livestatus"].includes(fromScreen)? (
        <div
          onClick={(e) => {
            dispatchAvailableData({ type: "clearAllFilters", payload: null });
            // if (fromScreen === "dashboard") clearDateFilterHandler();
			setIsFilters(false)
          }}
          className="regular-font t6"
          style={{
            color: Colors.ic_green_500,
            textDecoration: "underline",
            cursor: "pointer",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Clear all filters
        </div>
      ) : null}
    </>
  );
}
