import React, { useRef, useState } from "react";
import Button from "@mui/material/Button";
import HubspotIcon from "../../assets/HubspotIcon.svg";
import PipedriveIcon from "../../assets/pipedrive-logo.svg";
import ZohoIcon from "../../assets/ZohoIcon.png";
import DeskeraIcon from "../../assets/DeskeraIcon.png";
import MondayIcon from "../../assets/MondayIcon.svg";
import { createPortal } from 'react-dom';
import Oauth from './Oauth.jsx';
import AnimationPage from '../shared/animation-page/AnimationPage';
import { JService } from '../api-service/ApiService';
import { useSelector } from 'react-redux';
import "./Integrations.css"

export default function IntegrationsTab(props) {
	const reduxAccount = useSelector((state) => state?.account?.value);

  return (
    <div className="integrations-container nc-gray-900 regular-font">
      <div className="crm-integrations">
        <div className="title medium-font t5">CRM Integrations</div>
        <div className="content">
          <div className="integration-card">
            <div className="upper-half">
              <img src={HubspotIcon} alt="" />
              {!props.hubspotAccess ? (
                <Button
                  variant="contained"
                  onClick={props.handleHubspotConnect}
                >
                  Connect
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className="disconnect-btn"
                  onClick={props.handleHubspotDisconnect}
                >
                  Disconnect
                </Button>
              )}
            </div>
            <div className="lower-half">
              <p className="title medium-font">Hubspot</p>
              <p className="description">
                Automatically log all calls along with call recording link in
                Hubspot.
              </p>
            </div>
          </div>
          <div className="integration-card">
            <div className="upper-half">
              <img src={PipedriveIcon} alt="" />
              {!props.pipedriveAccess ? (
                <Button
                  variant="contained"
                  onClick={props.handlePipedriveConnect}
                >
                  Connect
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className="disconnect-btn"
                  onClick={props.handlePipedriveDisconnect}
                >
                  Disconnect
                </Button>
              )}
            </div>
            <div className="lower-half">
              <p className="title medium-font">Pipedrive</p>
              <p className="description">
                Automatically log all calls along with call recording link in
                Pipedrive.
              </p>
            </div>
          </div>

		  <div className="integration-card">
            <div className="upper-half">
              <img className="zoho-img" src={ZohoIcon} alt="" style={{width: "90px", height: "90px", marginBottom: "-23px", marginTop: "-25px", }}/>
              {!props.zohoPhonebridgeAccess ? (
                <Button
                  variant="contained"
                  onClick={props.handleZohoPhoneBridgeConnect}
                >
                  Connect
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className="disconnect-btn"
                  onClick={props.handleZohoPhoneBridgeDisconnect}
                >
                  Disconnect
                </Button>
              )}
            </div>
            <div className="lower-half">
              <p className="title medium-font">Zoho Phonebridge</p>
              <p className="description">
                Make calls via FreJun across Zoho's suite of business
                applications
              </p>
            </div>
          </div>

          <div className="integration-card">
            <div className="upper-half">
              <img className="zoho-img" src={DeskeraIcon} alt="" style={{width: "calc(345px / 3)", height: "calc(74px / 3)" }}/>
              {!props.deskeraAccess ? (
                <Button
                  variant="contained"
                  onClick={props.handleDeskeraConnect}
                >
                  Connect
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className="disconnect-btn"
                  onClick={props.handleDeskeraDisconnect}
                >
                  Disconnect
                </Button>
              )}
            </div>
            <div className="lower-half">
              <p className="title medium-font">Deskera CRM</p>
              <p className="description">
                Automatically log all calls along with call recording link in
                Deskera CRM.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
