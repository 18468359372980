import { useState } from 'react'
import Button from '../../design/Button'
import GModal from '../../design/components/GModal'
import styles from './AutoRechargeModal.module.css'
const AutoRechargeModal=({visible,handleClose})=>{
    const [balance,setBalance]=useState('')
    const [rechargeAmount,setRechargeAmount]=useState('')
    return (
        <GModal
            visible={visible} 
            heading='Auto recharge' 
            closeModal={handleClose} 
            modalStyle={{minWidth:'384px', padding:'40px 32px',marginBottom:'20px'}}
            bodyWrapperStyle={{paddingRight:'0'}}
            bottomButtonsStyle={{borderTop:'none'}}
            bottomButtons={[
            <Button hierarchy='white' variant='outlined' disabled={false} buttonText='Cancel' onClick={handleClose}/>,
            <Button hierarchy='green' buttonText='Save' onClick={()=>null} disabled={!balance || !rechargeAmount}/>]}
            body={
            <form className={styles.autoRechargeForm}>
                <label>
                    <span className='t7 medium-font'>When balance falls below</span>
                    <input type='number' value={balance} onChange={e=>setBalance(e.target.value)} placeholder='$'/>
                </label>
                <label>
                    <span className='t7 medium-font'>Recharge for</span>
                    <input type='number' value={rechargeAmount} onChange={e=>setRechargeAmount(e.target.value)} placeholder='$'/>
                </label>
            </form>}
        />
     )

}

export default AutoRechargeModal