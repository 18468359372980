import { createSlice } from '@reduxjs/toolkit'

let _initial = null
try{
    _initial = localStorage.getItem('frejun-navbar-paths')
}catch(err){
    console.warn('Error related to navbar paths: ', err)
}

_initial = _initial === null?false:_initial

export const navbarPathsSlice = createSlice({
    name: 'navbar-paths',
    initialState: {value:Object.keys(_initial).length === 0?{current: '', previous: '', isGoodPath: true,}:_initial},
    reducers: {
        setNavbarPaths: (state, action) => {
            state.value = action.payload
            localStorage.setItem('frejun-navbar-paths', JSON.stringify(state.value))
        }
    }

})

export const { setNavbarPaths } = navbarPathsSlice.actions
export default navbarPathsSlice.reducer