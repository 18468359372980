import React, { useEffect, useState } from "react";
import ReactDragListView from "react-drag-listview";
import dragIcon from "../../assets/drag-icon.svg";
import { JService } from "../api-service/ApiService";
import Button from "../design/Button";
import GModal from "../design/components/GModal";
import { enqueueSnackbar } from 'notistack';

function EditOrderModal(props) {
  const {
    originalSettings,
    setOriginalSettings,
    onClose,
    connectToTeam,
    routingOrder,
    callDistribution,
  } = props;

  const [teamUsers, setTeamUsers] = useState([]);

  useEffect(() => {
    // if selected team is same as the team in original Settings
    if (
      originalSettings?.inbound_team &&
      connectToTeam === originalSettings?.inbound_team
    ) {
      // if routing order is already set
      if (originalSettings?.routing_order?.length !== 0) {
        const _temp = [];
        // this chunk has n square time complexity. Optimize later if required. Optimization will require backend changes.
        originalSettings?.routing_order?.forEach((userId) => {
          originalSettings?.all_users?.forEach((user) => {
            if (user.id === userId) _temp.push(user);
          });
        });
        setTeamUsers(_temp);
      } else {
        const selectedTeam = originalSettings?.all_teams?.filter(
          (team) => team.id === connectToTeam
        );
        setTeamUsers(selectedTeam[0].users);
      }
    }
    // else if selected team is not same as team in original settings set random order
    else {
      const selectedTeam = originalSettings?.all_teams?.filter(
        (team) => team.id === connectToTeam
      );
      setTeamUsers(selectedTeam[0].users);
    }
  }, [originalSettings, connectToTeam]);

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const _data = [...teamUsers];
      const item = _data.splice(fromIndex, 1)[0];
      _data.splice(toIndex, 0, item);
      setTeamUsers(_data);
    },
    nodeSelector: "li",
    handleSelector: "img",
  };

  const handleSave = () => {
    JService.patch(`/api/v1/auth/virtual-numbers/calling/${originalSettings.id}/`,
      {
        routing_order: teamUsers.map((user) => user.id),
        call_distribution: callDistribution,
      }
    )
      .then((res) => {
        if(!res?.success) throw new Error(res?.message || "Error while updating virtual number")
        
		enqueueSnackbar(res?.message, { variant: "success"})
		setOriginalSettings(res.data);
		onClose();
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error"}))
  };

  return (
    <>
      <GModal
        visible={true}
        closeModal={onClose}
        heading={"Edit order"}
        body={
          <ReactDragListView {...dragProps}>
            <ol>
              {!!teamUsers &&
                teamUsers.map((user, index) => (
                  <li key={user.id} className="draggable-user">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p
                        className="t6 regular-font nc-gray-900"
                        style={{ marginLeft: "24px" }}
                      >
                        {index + 1}
                      </p>
                      <div style={{ marginLeft: "20px" }}>
                        <p className="t6 regular-font nc-gray-900">
                          {user.first_name + " " + user.last_name}
                        </p>
                        <p className="t6 regular-font nc-gray-600">
                          {user.email}
                        </p>
                      </div>
                    </div>

                    <img
                      src={dragIcon}
                      style={{
                        marginRight: "9px",
                        cursor: "pointer",
                        padding: "10px",
                      }}
                    />
                  </li>
                ))}
            </ol>
          </ReactDragListView>
        }
        modalStyle={{
          maxHeight: "336px",
          width: "448px",
          padding: "32px 24px",
        }}
        bottomButtons={[
          <Button
            disabled={false}
            hierarchy="white"
            variant="contained"
            buttonText="Cancel"
            isLoading={false}
            onClick={onClose}
          />,
          <Button
            disabled={false}
            hierarchy="green"
            variant="contained"
            buttonText="Save"
            isLoading={false}
            onClick={handleSave}
          />,
        ]}
      />
    </>
  );
}

export default EditOrderModal;
