import colors from "../design/colors";
import { deleteIcon, dropdownIcon, topNavAccountPlusIcon, topNavAccountSelectedAccountTickIcon } from "../../utility-functions/assetLoader";
import { useEffect, useState, useCallback, useRef, useMemo } from "react";
import Button from "../design/Button";
import GModal from "../design/components/GModal";
import Tags from "@yaireo/tagify/dist/react.tagify";
import "./templateModal.css";
import { flushSync } from 'react-dom';

import { useSelector } from "react-redux";


const dropdownData = [
	{ id: 1, title: "{contact_name}", value: "Contact name", editable: false },
	{
	  id: 2,
	  title: "{contact_phone_number}",
	  value: "Contact phone number",
	  editable: false,
	},
	{ id: 3, title: "{agent_name}", value: "Agent name", editable: false },
	{
	  id: 4,
	  title: "{agent_virtual_number}",
	  value: "Agent virtual number",
	  editable: false,
	},
	{
	  id: 5,
	  title: "{organization_name}",
	  value: "Organization name",
	  editable: false,
	},
  ]
const settings = {
	pattern: '',//, // <- must define "patten" in mixed mode
	duplicates: true,
	dropdown: {
	  enabled: 0,
	  maxItems: 10,
	  includeSelectedTags: true,
	  position: "text",
	  closeOnSelect: true,
	  classname: "suggestion-list",
	  enforceWhiteList: true,
	  autoComplete: false,
	},
	whitelist: dropdownData,
	classNames: {
	  dropdownItem: "drop-down-item",
	  dropdown: "drop-down",
	},
	originalInputValueFormat: (item) => item.title,	
};
let previousTagsValueRef = []
let currentRangeRef = null
let cursorSaveState = {anchorNode: null, anchorOffset: null, range: {startContainer: null, endContainer: null, startOffset: null,}, reset: function () {
	Object.keys(this).map(k => {
		if(k !== "reset" ) this[k] = null
	})
}}
const TemplateModal = ({
  showModal,
  closeModalHandler,
  editableText,
  editableTitle,
  handleSaveTemplate,
  handleEditTemplate,
  handleCancel,
  isLoading,
}) => {

  const reduxOrg=useSelector((state)=>state?.account?.value)

  const [templateName, setTemplateName] = useState(editableTitle);
  const [message, setMessage] = useState();
  const [editableVal, setEditableVal] = useState(``);
  const [modalScrollable, setModalScrollable]=useState(true)
  const tagifyRef = useRef(null)
  const buttonRef = useRef(null)
  const userPlacedCursorLocationRef = useRef(null)

  const handleCancelTemplate = () => {
    setTemplateName("");
    setEditableVal(``);
    setMessage(``);
    handleCancel();
  };

  const onChange = (e) => {
	console.log("current message value", e.detail.value);
	flushSync(() => {
		setMessage(e.detail.value);
	})
	console.log(tagifyRef.current)
	const tagifyInput = tagifyRef.current.DOM.input
	let tagifyInputChildElements = Array.from(tagifyInput.childNodes)
	
	let caretAfterNode = null
	
	if(!tagifyInput.hasChildNodes) {
		// check if has focus // onChange
		if(tagifyRef.current.state.hasFocus) {
		  // toggleFocus x 2
		  tagifyRef.current.toggleFocusClass(true) // force: true
		  tagifyRef.current.toggleFocusClass(true) // force: true
		}
		else {
		  // toggleFocus x 1
		  tagifyRef.current.toggleFocusClass(true) // force: true
		}
		//FIX: after focussing, move the cursor ? (or try doing that before ?)
		//1. try moving cursor to after node using .placeCaretAfterNode
		// reset the saved cursor state as well
		placeCursor("end", true)
		return
	}

	console.log('caretAfterNode ', caretAfterNode)
	caretAfterNode = tagifyInputChildElements.at(-1)
	if(caretAfterNode.nodeName === "BR") {
		console.log("caretNode is a BR, place cursor after previousSibling")
		caretAfterNode = tagifyInputChildElements.at(-2)
	}

	console.log("final caretAfterNode, placing placeCaretAfterNode from onChange 'caretAfterNode' ", caretAfterNode)
	tagifyRef.current.placeCaretAfterNode(caretAfterNode)
}

  const formatText = () => {
    if (showModal.mode !== "edit") return;
    const vars = [
      "{contact_name}",
      "{contact_phone_number}",
      "{agent_name}",
      "{agent_virtual_number}",
      "{organization_name}",
    ];

    let str = ``;
    let msg = editableText;
    if (msg[0] === `"`) msg = msg.substring(1);

    if (msg.substring(msg.length - 3) === '\\n"')
      msg = msg.substring(0, msg.length - 3);
    const msgArray = msg?.split("");

    for (let i = 0; i < msgArray.length; ) {
      if (msgArray[i] !== "{") {
        str += msgArray[i];
        i++;
      } else if (msgArray[i] === "{") {
        let index = msgArray.slice(i).findIndex((ch) => ch === "}");
        let idx = vars.indexOf(msgArray.slice(i, i + index + 1).join(""));
        if (idx !== -1) {
          str += `[[{"id":"${settings.whitelist[idx].id}","title":"${settings.whitelist[idx].title}","value":"${settings.whitelist[idx].value}","prefix":"@"}]]`;

          i += index + 1;
        } else {
          str += msgArray[i];
          i++;
        }
      }
    }

    setEditableVal(str);
    setMessage(str);
  };

  useEffect(() => {
    formatText();
  }, [editableText]);


  // FIXME: check with intersection observer and toggle placeAbove as needed //QUICKFIX: dropdown height reduced

  function handleDropdownNoMatch(e) {
	console.log('dropdown:nomatch setting suggested list items')
	tagifyRef.current.suggestedListItems = dropdownData
  }

  function handleBtnContainerClickCapture(e) {
	console.log("handleBtnContainerClickCapture event phase ", e.eventPhase)
	if(!tagifyRef.current) {
		console.log("returning tagifyRef.current is 'falsy', got ", tagifyRef.current)
		return
	}
	
	
	// check dropdown state
	if(tagifyRef.current.state.dropdown?.visible) {
		// visible
		console.log('dropdown visible')
	}
	else console.log('dropdown hidden')

	let d = tagifyRef.current.state

	// save selection state 
	if(tagifyRef.current.state.selection) {
		console.log("selectionState d.selection.anchorNode ", tagifyRef.current.state.selection.anchorNode)
		console.log("selectionState d.selection.anchorOffset ",tagifyRef.current.state.selection.anchorOffset)
		console.log("range d.selection.range.collpased ", tagifyRef.current.state.selection.range.collpased)
		console.log("range d.selection.range.collpased ", tagifyRef.current.state.selection.range.collpased)
		console.log("range d.selection.range.endContainer ", tagifyRef.current.state.selection.range.endContainer)
		console.log("range d.selection.range.startContainer ", tagifyRef.current.state.selection.range.startContainer)


		cursorSaveState.range.startContainer = tagifyRef.current.state.selection.range.startContainer
		cursorSaveState.range.endContainer = tagifyRef.current.state.selection.range.endContainer

		// opt-2 with collapsed = true (implicit for TYPE caret)
		// cursorSaveState.anchorNode = tagifyRef.current.state.selection.anchorNode
		// cursorSaveState.anchorOffset = tagifyRef.current.state.selection.anchorOffset
	}
	
	const tagifyInputChildNodes = Array.from(tagifyRef.current.DOM.input.childNodes)
	if(!tagifyInputChildNodes.length) {
		// if empty only focus needs to be called
		if(tagifyRef.current.state.hasFocus) {
			// toggleFocus x 2
			tagifyRef.current.toggleFocusClass(true) // force: true
			tagifyRef.current.toggleFocusClass(true) // force: true
		}
		else {
			// toggleFocus x 1
			tagifyRef.current.toggleFocusClass(true) // force: true
		}
		placeCursor("end", true)
		return
	}

	let placeAfterNode = tagifyInputChildNodes.at(-1)
	if(placeAfterNode.nodeName === "BR") {
		placeAfterNode = tagifyInputChildNodes.at(-2)
	}
	console.log("placeAfterNode in clickCapture ", placeAfterNode)
	if(cursorSaveState.anchorNode !== null) {
		placeCursor("savestate", false)
	}
	else tagifyRef.current.placeCaretAfterNode(placeAfterNode)

	console.log('state.dropdown.visible ', d.dropdown.visible)
	console.log("clickCAPTURE e.currentTarget ", e.currentTarget)
	console.log("---------------------------handleBtnContainerClickCapture end logs")
  }

  function handleInsertVariable(e) {
	// check if tagify input has focus
	console.log("handleInsertVariable tagifyRef.current.state.hasFocus ",tagifyRef.current.state.hasFocus)
	console.log("handleInsertVariable e.currentTarget ", e.currentTarget)

	if(tagifyRef.current.state.dropdown.visible) {
	  //close the dropdown
	  console.log("safely CLOSE THE DROPDOWN")
	  setModalScrollable(true)
	  tagifyRef.current.dropdown.hide()
	}
	else {
	  console.log("opening the dropdown")
	  setModalScrollable(false)
	  tagifyRef.current.dropdown.show()
	  //transfer focus to this element
	  tagifyRef.current.DOM.dropdown.focus()
	}
  }

  function onTagSelect(e) {
	// after a tag is selected, call focus on the tag inp
	// first, check if the input actually has focus
	let currentCursorState = tagifyRef.current.state.selection
	if(!!currentCursorState) {
		if(currentCursorState.range) {
			let tagifyInputChildNodes = Array.from(tagifyRef.current.DOM.input.childNodes)
			let caretAfterNode = null
			//TODO: see if ".children" is better
			if(!tagifyInputChildNodes.length) {
				tagifyRef.current.DOM.input.focus()
			}

			// for a complete reset
			if(tagifyInputChildNodes.at(-1)?.nodeName === "BR" && tagifyInputChildNodes.length > 1) caretAfterNode = tagifyInputChildNodes.at(-2)
			tagifyRef.current.placeCaretAfterNode(tagifyInputChildNodes.at(-2) || tagifyRef.current.DOM.input.firstChild)
		}
	}
  }

  function onTagifyBlur(e) {
	// old start
	//0. update the node state // not quite the right time

	//1. save the current cursor state
	// let currentCursorState = tagifyRef.current.state.selection
	// console.log("currentCursorState tagifyRef.current.state.selection ", tagifyRef.current.state.selection)
	// if(!tagifyRef.current.state.selection) {
	//   tagifyRef.current.placeCaretAfterNode(tagifyRef.current.DOM.input.firstChild)
	// }
	// -- old end

	console.log("tagiyf blurred, current selection ", tagifyRef.current.state.selection)
	console.log("tagiyf blurred, current selection anchorNode ", tagifyRef.current.state.selection?.anchorNode)
	console.log("tagiyf blurred, current selection range startContainer ", tagifyRef.current.state.selection?.range?.startContainer)
	console.log("tagiyf blurred, current selection range startContainer ", tagifyRef.current.state.selection?.range?.startOffset)
	console.log("tagiyf blurred, current selection range endCntainer ", tagifyRef.current.state.selection?.range?.endContainer)
	console.log("tagiyf blurred, current selection range endCntainer ", tagifyRef.current.state.selection?.range?.endOffset)
	console.log("tagiyf blurred, current selection anchorOffset ", tagifyRef.current.state.selection?.anchorOffset)
  }

  // update selectionRange
  // cursor moves to final position when dropdown is opened
  function onTagifyFocus(e) {
	console.log("tagiyf focussed")
	
	// no input value
	if(!previousTagsValueRef.length) {
		console.log("earlier used to return")
	}

	const newTagsRef = structuredClone(tagifyRef.current.value)
	
	// checking if newTagsRef has changed
	if(checkAllInArrayAreSame(newTagsRef, previousTagsValueRef)) {
	  console.log("tags value has not changed from 'checkAllInArrayAreSame'")
	  return
	}

	//if tags have been added/removed;
	// 1. update cursor(cursor to last method | by updating cursor location)
	// 2. ...
	console.log("tags value has changed")
	previousTagsValueRef = newTagsRef || []
	// highest index out of these two is the answer
	// last text item, last br, last tag, null
	// use .children to get Element and not just a Node
  }
  
  // if all in "new" are in "old" && check if same length => same elements
  function checkAllInArrayAreSame(anew, aold) {
	if(!(Array.isArray(anew) && Array.isArray(aold))) return true
	let aoldIdMap = aold.map(k => Boolean(k?.__tagId) && k.__tagId)
	return anew.entries(kk => Boolean(kk?.__tagId && aoldIdMap.includes(kk.__tagId))) && (anew.length === aold.length)
  }

  function placeCursor(argPosition="end", argResetSave=false) {
	if(argResetSave) {
		// reset save position
		// cursorSaveState.reset()
		console.log("cursorSaveState.reset to be called ", cursorSaveState)
	}
	
	
	let lastTextItemNotBr = Array.from(tagifyRef.current.DOM.input.childNodes).findLast(k => k.nodeName !== "BR")
	let lastTextItemNotBrIndex = Array.from(tagifyRef.current.DOM.input.childNodes).findLastIndex(k => k.nodeName !== "BR")
	let lastTag = tagifyRef.current.getLastTag()
	let lastTagIndex = Object.values(tagifyRef.current.DOM.input.childNodes).findIndex(k => k === lastTag)
	
	console.log('for argPosition ', argPosition)
	console.log("lastTag: ", lastTag)
	console.log("lastTagIndex: ", lastTagIndex)
	console.log("lastTextItemNotBr: ", lastTextItemNotBr)
	console.log("lastTextItemNotBrIndex: ", lastTextItemNotBrIndex)

	let dh = lastTextItemNotBr

	console.log("initial dh", dh)
	if(lastTag && lastTagIndex >= lastTextItemNotBr) {
		console.log("updating dh", dh)
		dh = lastTag
	}
	console.log("*final dh, use dh maybe?", dh)

	// FIXME: use reset position
	if(argPosition === "savestate") {
	  //restore cursor position from cursorSaveState
	  //checking if 'tagify__input' 
	  console.log("cursorSaveState, anchorNode", cursorSaveState.anchorNode)
	  console.log("cursorSaveState, anchorOffset", cursorSaveState.anchorOffset)
	  console.log("cursorSaveState, startContainer", cursorSaveState.range?.startContainer)
	  console.log("cursorSaveState, endContainer", cursorSaveState.range?.endContainer)
	  let allKeysNull = cursorSaveState.anchorNode || cursorSaveState.anchorOffset || cursorSaveState.range?.startContainer || cursorSaveState.range?.endContainer
	  console.log("allKeysNull ", allKeysNull)
	  if(!allKeysNull){
		
		if([cursorSaveState.anchorNode,cursorSaveState.range.startContainer].includes(tagifyRef.current.DOM.input)) {
			console.log("cant use saved cursor state, startContainer", cursorSaveState.range?.startContainer)
			console.log("cant use saved cursor state, anchorNode", cursorSaveState.anchorNode )
			if(tagifyRef.current.DOM.input.hasChildNodes) {
				console.log('finally using dh')
				tagifyRef.current.placeCaretAfterNode(dh)
				return 
			} 
			else {
			   console.log("tagify input is empty")
			   tagifyRef.current.placeCaretAfterNode(tagifyRef.current.DOM.input)
			   return 
			}
		}
		console.log("using saved cursor state, startContainer", cursorSaveState.range?.startContainer)
		console.log("using saved cursor state, anchorNode", cursorSaveState.anchorNode )
		tagifyRef.current.placeCaretAfterNode(cursorSaveState.range?.startContainer || cursorSaveState.anchorNode)
	  }
	  else console.log("saveStae cannot be used since allKeys is null, bye")
	  return
	}

	if(argPosition === "end") {
	  // place cursor @ end
	  tagifyRef.current.placeCaretAfterNode(dh)
	}
  }

  function onHideDropdown(e) {
	// bring back focus to the inp and restore saved state of range if any
	// check if in between words/ text

	// FIXME: find a better way to force focus 
	if(!tagifyRef.current) return
	// Array.from(tagifyRef.current?.DOM.input.children).find(k => k)

	// bring focus to input
	if(!tagifyRef.current.state.hasFocus) {
	  tagifyRef.current.toggleFocusClass(true)
	}
	else {
	  tagifyRef.current.toggleFocusClass(true)
	  tagifyRef.current.toggleFocusClass(true)
	}

	// return if !selection
	if(!tagifyRef.current.selection) {
		console.log("selection is 'falsy', got ", tagifyRef.current.selection)
		return
	}

	let r = tagifyRef.current.selection.range
	
	let startContainerIndex = Array.from(tagifyRef.current.DOM.input).findIndex(k => k === r.startContainer)
	if(startContainerIndex < 0) {
	  // none found
	  console.log('startConainer not in DOM.input of tagify refrerence')
	  return
	}
	console.log("startContainerIndex ", startContainerIndex)

	//FIXME: check last saved state for the cursor
	placeCursor("end")
  }

  function onShowDropdown(e) {
	// showdropdown should run after tagify.onblur
	// place the cursor back to where it was if there was a saved location
	placeCursor("savestate", false)
  }

  //remove
  function onTagifyInputClick(e) {
	// save the cursor state here ?
	console.log("tagifyCLickRAN")
  }

 
//   const calcDropdownPosition=(e)=>{
//    console.log(e)
//    const root=document.querySelector(':root');
//    const insertVarBtnContainer=document.querySelector('.template-modal__btn-container')
//    if(insertVarBtnContainer){
// 	console.log('insertVarBtnContainer.getBoundingClientRect().bottom', insertVarBtnContainer.getBoundingClientRect().bottom)
// 	root.style.setProperty('--insertvar-btn-position', `${insertVarBtnContainer.getBoundingClientRect().bottom}px`);
// 	}
//   }

//  useEffect(()=>{
// 	if(tagifyRef?.current?.state?.dropdown?.visible){
// 		setModalScrollable(false)
// 	}
// 	else{
// 		setModalScrollable(true)
// 	}
//  },[tagifyRef.current])

//  useEffect(()=>{
// 	if(!showModal?.display) return;
// 	setTimeout(()=>{
// 	const modalContainer=document.querySelector('.gmodal-foreground')
// 	console.log(modalContainer)
// 	modalContainer?.addEventListener('scroll', calcDropdownPosition)
// 	}, 500)
	
//  },[showModal?.display])

  const modalBody = (
    <div>
      <form className="msg-template__form">
        <label className="t7 medium-font nc-gray-900">
          Template name
          <input
            type="text"
            className="t6 regular-font nc-gray-900"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
			autoFocus={false}
          />
        </label>
        <label
          className="t7 medium-font nc-gray-900"
          style={{ paddingTop: "20px" }}
        >
          Message
        </label>

        <Tags
          InputMode="textarea"
		  tagifyRef={tagifyRef}
          autoFocus={false}
          placeholder="Enter message or type '@' to insert variables"
          settings={settings}
          className="myTags"
          readOnly={false}
          onChange={onChange}
          value={showModal?.mode === "create" ? `` : editableVal}
		  onDropdownHide={onHideDropdown}
		  onDropdownShow={onShowDropdown}
		  onClickCapture={onTagifyInputClick}
		  onBlur={onTagifyBlur}
		  onFocus={onTagifyFocus}
        />
        <div
		  className="template-modal__btn-container"
		  //onClickCapture={handleBtnContainerClickCapture}
		>
          <button
		    ref={buttonRef}
            type="button"
            className="t6 regular-font ic-green-500"
            style={{
              backgroundColor: colors.ic_white,
			//   tagifyRef.current?.state?.dropdown.visible ? colors.nc_gray_200 :
			  position: 'relative',
			  cursor: 'pointer',
            }}
            id="insert-variable-button"
			onClick={handleInsertVariable}
          >
            Insert Variable {dropdownIcon(colors.ic_green_500)}
          </button>
        </div>
      </form>
    </div>
  );

  return (
    <GModal
      visible={showModal?.display}
      closeModal={closeModalHandler}
      heading={showModal?.mode === "create" ? "New template" : "Edit template"}
      modalStyle={{
        width: "500px",
        maxHeight: "60vh",
        padding: "40px 19px 40px 24px",
        overflowY:'hidden'
      }}
      bottomButtons={[
        <Button
          variant="outlined"
          buttonText="Cancel"
          icon={{}}
          disabled={isLoading}
          isLoading={false}
          hierarchy="white"
          onClick={handleCancelTemplate}
        />,
        <Button
          variant="outlined"
          buttonText="Create"
          icon={{}}
          disabled={
            !templateName || !templateName?.trim()?.length
            // !message ||
            // !message?.trim()?.length
          }
          isLoading={isLoading}
          hierarchy="green"
          onClick={
            showModal?.mode === "create"
              ? (e) => handleSaveTemplate(templateName, message)
              : (e) => handleEditTemplate(templateName, message)
          }
        />,
      ]}
      body={modalBody}
    />
  );
};
export default TemplateModal;
