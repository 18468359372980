import flag_USA from "./../../../assets/flags/flag_USA.svg";
import flag_IND from "./../../../assets/flags/flag_IND.svg";
import flag_UK from "./../../../assets/flags/flag_UK.svg";
import flag_AUS from "./../../../assets/flags/flag_AUS.svg";
import flag_BAHRAIN from "./../../../assets/flags/flag_BAHRAIN.svg";
import flag_CANADA from "./../../../assets/flags/flag_CANADA.svg";
import flag_FRANCE from "./../../../assets/flags/flag_FRANCE.svg";
import flag_GERMANY from "./../../../assets/flags/flag_GERMANY.svg";
import flag_INDONESIA from "./../../../assets/flags/flag_INDONESIA.svg";
import flag_MALAYSIA from "./../../../assets/flags/flag_MALAYSIA.svg";
import flag_NZ from "./../../../assets/flags/flag_NZ.svg";
import flag_SINGAPORE from "./../../../assets/flags/flag_SINGAPORE.svg";
import flag_SPAIN from "./../../../assets/flags/flag_SPAIN.svg";
import flag_THAILAND from "./../../../assets/flags/flag_THAILAND.svg";
import flag_UAE from "./../../../assets/flags/flag_UAE.svg";
import flag_BELGIUM from "./../../../assets/flags/flag_BELGIUM.svg";
// import flag_HONGKONG from "./../../../assets/flags/flag_HONGKONG.svg";
import flag_NETHERLANDS from "./../../../assets/flags/flag_NETHERLANDS.svg";
import flag_NORWAY from "./../../../assets/flags/flag_NORWAY.svg";
import flag_OMAN from "./../../../assets/flags/flag_OMAN.svg";
// import flag_PHILIPPINES from "./../../../assets/flags/flag_PHILIPPINES.svg";
import flag_PORTUGAL from "./../../../assets/flags/flag_PORTUGAL.svg";
import flag_QATAR from "./../../../assets/flags/flag_QATAR.svg";
import flag_SAUDIARABIA from "./../../../assets/flags/flag_SAUDIARABIA.svg";
import flag_SWEDEN from "./../../../assets/flags/flag_SWEDEN.svg";
import flag_AUSTRIA from "./../../../assets/flags/flag_AUSTRIA.svg";
import flag_AZERBAIJAN from "./../../../assets/flags/flag_AZERBAIJAN.svg";
import flag_BRAZIL from "./../../../assets/flags/flag_BRAZIL.svg";
import flag_CROATIA from "./../../../assets/flags/flag_CROATIA.svg";
import flag_CYPRUS from "./../../../assets/flags/flag_CYPRUS.svg";
import flag_DENMARK from "./../../../assets/flags/flag_DENMARK.svg";
import flag_GREECE from "./../../../assets/flags/flag_GREECE.svg";
import flag_IRAN from "./../../../assets/flags/flag_IRAN.svg";
import flag_IRAQ from "./../../../assets/flags/flag_IRAQ.svg";
import flag_IRELAND from "./../../../assets/flags/flag_IRELAND.svg";
import flag_ITALY from "./../../../assets/flags/flag_ITALY.svg";
import flag_JAPAN from "./../../../assets/flags/flag_JAPAN.svg";
import flag_KAZAKHSTAN from "./../../../assets/flags/flag_KAZAKHSTAN.svg";
import flag_KUWAIT from "./../../../assets/flags/flag_KUWAIT.svg";
import flag_POLAND from "./../../../assets/flags/flag_POLAND.svg";
import flag_ROMANIA from "./../../../assets/flags/flag_ROMANIA.svg";
import flag_RUSSIA from "./../../../assets/flags/flag_RUSSIA.svg";
import flag_SERBIA from "./../../../assets/flags/flag_SERBIA.svg";
import flag_SWITZERLAND from "./../../../assets/flags/flag_SWITZERLAND.svg";
import flag_TURKEY from "./../../../assets/flags/flag_TURKEY.svg";
import flag_UKRAINE from "./../../../assets/flags/flag_UKRAINE.svg";
import flag_CZECHREPUBLIC from "./../../../assets/flags/flag_CZECHREPUBLIC.svg";
import flag_HUNGARY from "./../../../assets/flags/flag_HUNGARY.svg";

// import {
//     FlagAUS,
//     FlagBahrain,
//     FlagBelgium,
//     FlagCanada,
//     FlagFrance,
//     FlagGermany,
//     FlagHongkong,
//     FlagIndia,
//     FlagIndonesia,
//     FlagMalaysia,
//     FlagNetherlands,
//     FlagNewzealand,
//     FlagNorway,
//     FlagPhilippines,
//     FlagPortugal,
//     FlagSingapore,
//     FlagSpain,
//     FlagSweden,
//     FlagThailand,
//     FlagUK,
//     FlagUSA,
//   } from "./assetLoader";

export const countriesData = {
  "+91": {
    label: "India",
    value: "IND",
    img: flag_IND,
  },
  "+1": {
    label: "United States",
    value: "USA",
    img: flag_USA,
  },
  "+61": {
    label: "Australia",
    value: "AU",
    img: flag_AUS,
  },
  "+973": {
    label: "Bahrain",
    value: "BH",
    img: flag_BAHRAIN,
  },
  "+32": {
    label: "Belgium",
    value: "BELGIUM",
    img: flag_BELGIUM,
  },
  "+33": {
    label: "France",
    value: "FR",
    img: flag_FRANCE,
  },
  "+49": {
    label: "Germany",
    value: "DE",
    img: flag_GERMANY,
  },

  "+62": {
    label: "Indonesia",
    value: "ID",
    img: flag_INDONESIA,
  },
  "+60": {
    label: "Malaysia",
    value: "MY",
    img: flag_MALAYSIA,
  },
  "+31": {
    label: "Netherlands",
    value: "NETHERLANDS",
    img: flag_NETHERLANDS,
  },
  "+64": {
    label: "New Zealand",
    value: "NZ",
    img: flag_NZ,
  },
  "+47": {
    label: "Norway",
    value: "NORWAY",
    img: flag_NORWAY,
  },

  "+351": {
    label: "Portugal",
    value: "PORTUGAL",
    img: flag_PORTUGAL,
  },

  "+966": {
    label: "Saudi Arabia",
    value: "SAUDI ARABIA",
    img: flag_SAUDIARABIA,
  },
  "+65": {
    label: "Singapore",
    value: "SG",
    img: flag_SINGAPORE,
  },
  "+34": {
    label: "Spain",
    value: "ES",
    img: flag_SPAIN,
  },
  "+46": {
    label: "Sweden",
    value: "SWEDEN",
    img: flag_SWEDEN,
  },

  "+66": {
    label: "Thailand",
    value: "TH",
    img: flag_THAILAND,
  },
  "+971": {
    label: "United Arab Emirates",
    value: "AE",
    img: flag_UAE,
  },
  "+44": {
    label: "United Kingdom",
    value: "UK",
    img: flag_UK,
  },
  "+43": {
    label: "Austria",
    img: flag_AUSTRIA,
  },
  "+994": {
    label: "Azerbaijan",
    img: flag_AZERBAIJAN,
  },
  "+55": {
    label: "Brazil",
    img: flag_BRAZIL,
  },
  "+385": {
    label: "Croatia",
    img: flag_CROATIA,
  },
  "+357": {
    label: "Cyprus",
    img: flag_CYPRUS,
  },
  "+45": {
    label: "Denmark",
    img: flag_DENMARK,
  },
  "+30": {
    label: "Greece",
    img: flag_GREECE,
  },
  "+98": {
    label: "Iran",
    img: flag_IRAN,
  },
  "+964": {
    label: "Iraq",
    img: flag_IRAQ,
  },
  "+353": {
    label: "Ireland",
    img: flag_IRELAND,
  },
  "+39": {
    label: "Italy",
    img: flag_ITALY,
  },
  "+81": {
    label: "Japan",
    img: flag_JAPAN,
  },
  "+7": {
    label: "Kazakhstan",
    img: flag_KAZAKHSTAN,
  },
  "+965": {
    label: "Kuwait",
    img: flag_KUWAIT,
  },
  "+968": {
    label: "Oman",
    value: "OMAN",
    img: flag_OMAN,
  },
  "+48": {
    label: "Poland",
    img: flag_POLAND,
  },
  "+974": {
    label: "Qatar",
    value: "QATAR",
    img: flag_QATAR,
  },
  "+40": {
    label: "Romania",
    img: flag_ROMANIA,
  },
  "+7": {
    label: "Russia",
    img: flag_RUSSIA,
  },
  "+381": {
    label: "Serbia",
    img: flag_SERBIA,
  },
  "+41": {
    label: "Switzerland",
    img: flag_SWITZERLAND,
  },
  "+90": {
    label: "Turkey",
    img: flag_TURKEY,
  },
  "+380": {
    label: "Ukraine",
    img: flag_UKRAINE,
  },
  "+420": {
    label: "Czech Republic",
    img: flag_CZECHREPUBLIC,
  },
  "+36": {
    label: "Hungary",
    img: flag_HUNGARY,
  },
};
