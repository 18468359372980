import { Autocomplete, Checkbox, TextField } from "@mui/material"
import GModal from "../../design/components/GModal"
import { useEffect, useReducer, useState } from "react"
import Button from "../../design/Button"
import { errorIcon } from "../../../utility-functions/assetLoader"
import styles from './AddonUsersModal.module.css'
import { Close } from "@mui/icons-material"
import checkboxCheckedIcon from "../../../assets/gusers-and-teams-checbox-checked-icon.svg";
import checkboxIcon from "../../../assets/gusers-and-teams-checbox-icon.svg";
import GTable from "../../design/components/GTable"

const emptyState={searchText:''}
const AddonUsersModal=({visible,handleClose, data,users,handleUpdateAddonUsers, addonName})=>{
    const [selectedUsers,setSelectedUsers]=useState([])
    //const [warning,setWarning]=useState({visible:false,extraLicense:0})
    const [disableAddBtn,setDisableAddBtn]=useState(false)
    const [rows,setRows]=useState(data?.users || [])
    const [isLoading,setIsLoading]=useState(false)

    const filtersReducer=(state,action)=>{
        switch(action.type){
            case "setSearchText": {
                // use useTransition or throttle search
                if (typeof action.payload !== "string") return state;
                return {
                  ...state,
                  searchText: action.payload,
                };
              }
        }
      }
      const [availableData, dispatchAvailableData] = useReducer(
        filtersReducer,
        emptyState
      );

    const handleSelectedUsers=(e,val)=>{
        setSelectedUsers(val)
        if(val?.length > data?.licenses?.free){
            if(!disableAddBtn) setDisableAddBtn(true)
            //setWarning({visible:true,extraLicense:val.length-(data?.licenses?.free)})
        }
        else {
            if(disableAddBtn) setDisableAddBtn(false)
            //setWarning({visible:false,extraLicense:0 })
        }
    }
    const onSearch = (searchText) => {
        if(!searchText || !searchText.trim()?.length){
           setRows(data?.users || []);
           return
         } 
         const searchTextLower = searchText?.toLowerCase();
         const temp = data?.users?.filter((row) =>
           `${row.first_name} ${row.last_name}`?.toLowerCase().includes(searchTextLower) ||
            row.email?.toLowerCase()?.includes(searchTextLower)
         )
         setRows(temp)
       };

    useEffect(() => {
        onSearch(availableData?.searchText);
      }, [availableData?.searchText]);

    const handleRemoveUser=(e,params)=>{
        e.stopPropagation()
        handleUpdateAddonUsers("remove",params?.row?.id,addonName)
    }
    const handleAddUser=()=>{
      setIsLoading(true)
      const idList=selectedUsers?.map(elem=>elem.id)
      handleUpdateAddonUsers("add",idList,addonName)
    }
    const cols=[
        {
            field:'user',
            headerName:'User',
            flex:4,
            headerClassName:styles.headerClass,
            renderCell:(params)=><div className={`t7 regular-font ${styles.tableCol1}`}><p>{params.row?.first_name+' '+params?.row?.last_name}</p><p style={{color:'#919994'}}>{params.row?.email}</p></div>
        },
        {
            field:'remove',
            headerName:'',
            flex:1,
            width:53,
            renderCell:(params)=><button onClick={(e)=>handleRemoveUser(e,params)} className={styles.tableCol2}>Remove</button>
        }
    ]
    return (
        <GModal 
            visible={visible}
            closeModal={handleClose}
            heading='Manage Users'
            modalStyle={{maxHeight:'80vh'}}
            bottomButtons={[]}
            body={
                <div className={styles.modalContainer}>
                    <div className={`${styles.licenseInfo} t7 medium-font`}>{addonName.slice(0,1)?.toUpperCase()+addonName.slice(1)?.toLowerCase()} license: <span className="regular-font">{data?.licenses?.total} License </span><span style={{color:'#5f6661'}}>({data?.licenses?.total-data?.licenses?.free} assigned)</span></div>
                    {/* {warning?.visible && <div className={styles.warningContainer}><span>{errorIcon('#464c48','16.67','16.67')}</span><p className="t7 regular-font">By adding, you agree to purchase <span  className="medium-font">{warning?.extraLicense} Addon</span> license for these users. </p></div>} */}
                    <form>
                    <Autocomplete
                    multiple
                    sx={{height:'36px', ".Mui-focused:": {
                        border: `1px solid green`,
                        
                      },'&.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input ':{padding:'5.5px 4px 5.5px 6px'},'.MuiOutlinedInput-root':{padding:"0px 12px"},'.MuiInputBase-root-MuiOutlinedInput-root':{}}}
                    ChipProps={{deleteIcon:<Close/>,sx:{'& .MuiChip-deleteIcon':{color:'#464d48',width:'12px',height:'12px'},'.MuiChip-label':{padding:'6px 10px'},backgroundColor:'#fff',border:'1px solid #e1e5e2',borderRadius:'4px',fontSize:'14px',lineHeight:'14px',fontFamily:'Inter',height:'26px'}}}
                    id="checkboxes-tags"
                    popupIcon={null}
                    value={selectedUsers}
                    onChange={handleSelectedUsers}
                    options={users?.filter((user)=>!data?.users?.map(elem=>elem.email).includes(user.email) && user?.status==='Active')}
                    disableCloseOnSelect
        
                    getOptionLabel={(option) => option.first_name+' '+option.last_name}
                    renderOption={(props, option, { selected }) => (
                        <li {...props} style={{display:'flex',alignItems:'flex-start',justifyContent:'flex-start'}}>
                        <Checkbox
                            // icon={icon}
                            // checkedIcon={checkedIcon}
                            style={{ marginRight: 8, }}
                            checked={selected}
                            disableRipple
                            checkedIcon={
                                <img
                                  src={checkboxCheckedIcon}
                                  alt="Checked checkbox "
                                />
                              }
                              icon={
                                <img src={checkboxIcon} alt="Unchecked checkbox " />
                              }
                        />
                        <div className={`t7 regular-font ${styles.menuItemText}`}>
                        <p style={{marginBottom:0}}>{option.first_name+' '+option.last_name}</p>
                        <p style={{color:'#919994',marginTop:'0'}}>{option.email}</p>
                        </div>
                        </li>
                    )}
                    style={{ width: 500 }}
                    renderInput={(params) => (
                        <TextField {...params} label="" InputLabelProps={{ shrink: false, sx:{border:'1px solid red'}}} placeholder="Search to add user" />
                    )}
                    />
                    <Button isLoading={isLoading} hierarchy='green' buttonText="Add" onClick={handleAddUser} disabled={data?.licenses?.free<=0 || disableAddBtn || !selectedUsers || !selectedUsers?.length} styleOverride={{height:'36px',padding:'10px 16px',fontSize:'14px',fontWeight:'500'}}/>
                    </form>
                    <GTable availableData={availableData} dispatchAvailableData={dispatchAvailableData} onSearch={onSearch} rowHeight={80} useMuiTable={true} leftHeader={{isSearch:true,filters:[]}} rightHeader={{}} fromScreen='addon-users' rows={rows} columns={cols} />
                </div>
            }
        />
    )
}
export default AddonUsersModal