import { ListItemIcon, MenuItem, Select } from '@mui/material';
import GTable from '../design/components/GTable';
import colors from "../design/colors";
import styles from "../design/styles";
import React, { useEffect, useRef,useReducer, useState } from 'react';
import countries from '../shared/countries/Countries.json'
import { JService } from '../api-service/ApiService';
import {images} from "./flagsImported"
import AnimationPage from '../shared/animation-page/AnimationPage';
import { useLocation } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

const india = countries[99].dialCode === "91" ? countries[99].dialCode : countries.find(c => c.dialCode === "91")
const API_BASE_URL = process.env.REACT_APP_BETA

const columns = [ 
	{
		field: "from",
		headerName: "From",
		flex: 0.75,
		headerClassName: "user-table-header-user-name",
		renderCell: (params) => {
			return <p style={{ marginLeft: "14px" }}>+{params?.row?.from}</p>;
		},
	},
	{
		field: "destination_prefix",
		headerName: "To",
		flex: 0.75,
		headerClassName: "user-table-header-user-name",
		renderCell: (params) => {
			return <p style={{ marginLeft: "14px" }}>+{params?.row?.destination_prefix}</p>;
		},
	},
	{
		field: "outbound_call_rate",
		headerName: "Call charges (USD/min)",
		flex: 1,
		headerClassName: "user-table-header-user-name",
		renderCell: (params) => {
			return <p style={{ marginLeft: "14px" }}>{params?.row?.outbound_call_rate}</p>;
		},
	  },
	{
		field: "billingInterval",
		headerName: "Billing interval (sec)",
		flex: 1,
		headerClassName: "user-table-header-user-name",
		renderCell: () => {
			return <p style={{ marginLeft: "14px" }}>60</p>;
		},
	  },
]
const initialState = {
	searchText: "",
  };
export default function OutgoingCallPricing() {
	const fromColRef = useRef(null)
	const [cols, setCols] = useState([])
	const [rows, setRows] = useState([])
	const [filters, setFilters] = useState({
		from: "91",
		fromName: "India",
		to: "Indonesia",
		currency: ""
	})
	const [isFetchPending, setIsFetchPending] = useState(false)

	const [gTablePageNumber, setGTablePageNumber] = useState(null)
	const [rowCount, setRowCount] = useState(0)
	const [pageSize, setPageSize] = useState(10)
	const [pricingInr, setPricingInr] = useState(null)
	const location = useLocation();

	const countriesSet = (from, to) => Boolean(from && to)

	const isString = val => val instanceof String || typeof(val) === "string"

	useEffect(() => {
		if(location?.pathname?.split("-")?.at(-1) === "inr") {
			setPricingInr(true)
			columns.at(2).headerName = "Call charges (INR/min)"
			setCols(columns)
			setFilters(prev => ({...prev, fromName: "India", from: "91"}))
			fromColRef.current = "91"
			fetchData(`/api/v1/auth/get-outbound-call-pricing/?to=${filters.to}&from=91&inr=true`)
		}
		else {
			setPricingInr(false)
			fromColRef.current = filters.from
			fetchData(`/api/v1/auth/get-outbound-call-pricing/?to=${filters.to}&from=${filters.from}`)
		}

		return () => {
			setPricingInr(null)
		}
	}, [])
	

	function onOriginCountryChange(value) {
		if(!isString(value)) value = value.target.value

		const fromCode = countries.find(c => c.name === value)?.dialCode || ""

		// update state
		setFilters(prev => ({...prev, from: fromCode, fromName: value}))
		fromColRef.current = fromCode

		if(!countriesSet(fromCode, filters.to)) {
			enqueueSnackbar("Select both from and to fields", { variant: "error"})
			return
		}

		if(filters.from === value) {
			console.warn("Same from chosen")
			return
		}

		let url = `/api/v1/auth/get-outbound-call-pricing/?from=${fromCode}&to=${filters.to}`
		if(pricingInr) url+= "&inr=true"
		fetchData(url)
	}

	function onToCountryChange(value) {
		if(!isString(value)) value = value.target.value

		// update state
		setFilters(prev => ({...prev, to: value}))

		if(!countriesSet(filters.fromName, value)) {
			enqueueSnackbar("Select both from and to fields", { variant: "error"})
			return
		}

		if(filters.to === value) {
			console.warn("Same to chosen")
			return
		}
		if(!filters.from || (!value)) return
		let url = `/api/v1/auth/get-outbound-call-pricing/?from=${filters.from}&to=${value}`
		if(pricingInr) url+= "&inr=true"
		fetchData(url)
	}

	const fetchData = async (url) => {
		try {
			setIsFetchPending(true)
			const response = await JService.get(API_BASE_URL + url)
			if(!response?.results.success) throw new Error(response?.message || "Failed to fetch pricing details")
			const data = response.results.data
			data.forEach((d, index) => {
				d.id = index
				return d
			})
			const seen = new Set()
			let finalData = []
			let pair = ""
			console.log(filters, "filters.from")
			for(let i=0; i<data.length; i++) {
				pair= `${data[i].origin_prefix}, ${data[i].destination_prefix}`
				if(!seen.has(pair)) {
					seen.add(pair)
					data[i].from = fromColRef.current
					finalData.push(data[i])
				}
			}
			setRowCount(response.count)
			setRows(finalData)
		}
		catch(error) {
			enqueueSnackbar(error?.message, { variant: "error"})
		}
		finally {
			setIsFetchPending(false)
		}
	}

	function handlePageSizeChange(val) {
		let url = `/api/v1/auth/get-outbound-call-pricing/?from=${filters.from}&to=${filters.to}&page=0&page-size=${val}`
		if(pricingInr) url+= "&inr=true"
		fetchData(url)
		setPageSize(val)
		setGTablePageNumber(0)
	}

	function handlePageChange(val) {
		let url = `/api/v1/auth/get-outbound-call-pricing/?from=${filters.from}&to=${filters.to}&page=${val+1}`
		if(pricingInr) url+= "&inr=true"
		fetchData(url)
		console.log(val)
	}
	  
	const filtersReducer = (state, action) => {
		switch (action.type) {
			case 'setSearchText':
			return {
				...state,
				searchText: action.payload,
			};
			default:
			return state;
		}
	};
	  
	const [availableData, dispatchAvailableData] = useReducer(
		filtersReducer,
		initialState
	);
	
	useEffect(() => {
		if(!availableData) return;
		if (availableData?.searchText) {
			console.log(availableData.searchText)
			const isINR = location?.pathname?.split("-")?.at(-1) === "inr";
			const searchQuery = availableData.searchText;
			let url = `/api/v1/auth/get-outbound-call-pricing/?to=${filters.to}&from=${filters.from}&search=${searchQuery}`;
			if (isINR) url += "&inr=true";
			setIsFetchPending(true);
			const timeoutId = setTimeout(() => fetchData(url), 500);
			return() => clearTimeout(timeoutId);
		}
	}, [availableData]);

	return <>
		<div className='general-tab-vn-gtable-height-setter call-pricing-container' style={localStyles.tableContainer}>
			<div style={localStyles.filtersContainer}>
			
				{/* from filter */}
				<label 
					id="from--country" 
					data-field="from" 
				>
					<span style={localStyles.inputLabel} className="t7 nc-gray-900 medium-font">From</span>
					<Select
						data-field="from"
						title={pricingInr === null || pricingInr ? "Read only field" : "Select from"}
						labelId="from--country"
						readOnly={pricingInr === null || pricingInr}
						value={pricingInr === null || pricingInr ? "India" : filters.fromName}
						onChange={onOriginCountryChange}
						sx={{...localStyles.filter, ...localStyles.filterReadOnly}}
						placeholder="Select From"
						renderValue={(selected) => {
							if(!selected) return <p className="regular-font t6 nc-gray-400">Select From</p>
							return <p className={`regular-font t6 ${pricingInr === null || pricingInr ? "nc-gray-500" : "nc-gray-900"}`} style={localStyles.selectedContainer}>
								<img src={images[filters.fromName]} style={localStyles.flagIcon}/>
								{selected}
							</p>;
						}}
						displayEmpty
						inputProps={{ "aria-label": "Without label", "data-field": "from" }}
					>
						{countries.map(({ name }, i) => (
							<MenuItem value={name} key={i}>
								<ListItemIcon style={localStyles.flagIconContainer} >
									<img src={images[name]} style={localStyles.flagIcon}/>
								</ListItemIcon>
								{name}
							</MenuItem>
						))}
					</Select>
				</label>

				{/* to filter */}
				<label id="to--country" data-field="to">
					<span style={localStyles.inputLabel} className="t7 nc-gray-900 medium-font">To</span>

					<Select
						title="Select to"
						labelId="to--country"
						value={filters.to}
						onChange={onToCountryChange}
						sx={localStyles.filter}
						placeholder="Select To"
						renderValue={(selected) => {
							if(!selected) return <p className="regular-font t6 nc-gray-400">Select To</p>
							return <p className="regular-font t6 nc-gray-900" style={localStyles.selectedContainer}>
								<img src={images[filters.to]} style={localStyles.flagIcon}/>
								{selected}
							</p>
						}}
						displayEmpty
						inputProps={{ "aria-label": "Without label" }}
					>
						{countries.map(({ name }, index) => (
							<MenuItem value={name} key={index}>
								<ListItemIcon style={localStyles.flagIconContainer}>
									<img src={images[name]} style={localStyles.flagIcon} />
								</ListItemIcon>
								{name}
							</MenuItem>
						))}
					</Select>
				</label>
			</div>

			{isFetchPending ? <AnimationPage /> : (
				<GTable
					columns={columns}
					rows={rows}
					leftHeader={{ filters: {}, isSearch: true }}
					rightHeader={{isRefresh:true}}
					useMuiTable={true}
					isLoading={isFetchPending}
					getTableRowClassName={() => `teams-table-row`}
					rowHeight={72}
					additionalProps={{ bgHover: false }}
					onSearch={(e)=>{
						dispatchAvailableData({ type: 'searchText', payload: e });
					}}
					getRowId={(row) => Object.values(row).join()}
					hidePagination={false}
					paginationMode='server'
					fromScreen="call-pricing"
					onPageSizeChange={handlePageSizeChange}
					handlePageChange={handlePageChange}
					rowCount={rowCount}
					setGTablePageNumber={setGTablePageNumber}
					gTablePageNumber={gTablePageNumber}
					dispatchAvailableData={dispatchAvailableData}
					availableData={availableData}
				/>
			)}
		</div>
	</>
}

const localStyles = {
	filtersContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		columnGap: "24px",
		placeItems: "center",
		marginBottom: "40px",
	},

	filter: {
		alignSelf: "flex-start",
		minWidth: 230,
		width: "100%",
		height: "40px",
		outline: "none",
		borderRadius: "4px",
		marginTop: "4px",
		padding: 0,
		...styles.t6,
		...styles.regular_font,
		color: colors.nc_gray_900,
		
		"&:before": {
			outline: "none",
			border: "none",
		},
		"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
			border: "1px solid #34CB65",
			boxShadow: "0px 0px 0px 4px #F0FCF4",
		},
	},

	filterReadOnly: {
		"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
			border: `1px solid ${colors.nc_gray_500}`,
		},
	},

	inputLabel: {
		display: "block",
		textAlign: "left",
		userSelect: "none",
	},
	
	tableContainer: {
		height: "100%", 
		width: "60%",
		position: 'relative',
		left: "50%",
		transform: "translateX(-50%)",
		paddingBottom: "40px",
	},

	flagIcon: {
		borderRadius: "2px",
		width: "24px",
		height: "16px",
		filter: `drop-shadow(0px 1px 1px ${colors.nc_gray_400})`,
	},

	flagIconContainer: {
		marginRight: "8px",
	},

	selectedContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "start",
		columnGap: "16px",
		paddingLeft: "8px",
	},
}