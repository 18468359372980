import { createSlice } from "@reduxjs/toolkit";

let _initial = null;
try {
  _initial = localStorage.getItem("frejun-global-status");
} catch (err) {
  console.warn("Error related to global error message(Status): ", err);
}

_initial = _initial === null ? {} : JSON.parse(_initial);

// globalStatusValue: {
//     isVisible: true/false
//     messageQueue: [
//         {
//             message:"bla bla bla"        /Required
//             type:"ERROR/SUCCESS"         /Required
//             supportingDetails: "string"  /Optional
//         }
//     ]
// }

export const globalStatusSlice = createSlice({
  name: "globalStatus",
  initialState: {
    value:
      Object.keys(_initial).length === 0
        ? {
            isVisible: true,
            messageQueue: [],
          }
        : _initial,
  },
  reducers: {
    setGlobalStatusVisibility: (state, action) => {
      const _state = { ...state.value, isVisible: action.payload.isVisible };
      state.value = _state;
      localStorage.setItem("frejun-global-status", JSON.stringify(state.value));
    },
    enqueueStatusMessage: (state, action) => {
      // Payload must be of the format:
      // {   message:"bla bla bla"        /Required
      //     type:"ERROR/SUCCESS"         /Required
      //     supportingDetails: "string"  /Optional
      // }

      state.value.messageQueue.push(action.payload);
      localStorage.setItem("frejun-global-status", JSON.stringify(state.value));
    },
    dequeueStatusMessage: (state, action) => {
      if (state.value.messageQueue.length !== 0) {
        state.value.messageQueue.shift();
        localStorage.setItem(
          "frejun-global-status",
          JSON.stringify(state.value)
        );
      }

      // const dequeuedMessage = newMessageQueue.shift();
      // state.value = { ...state.value, messageQueue: newMessageQueue };
    },
    // payload.action as Boolean
    // setGlobalStatusVisibility: (state, action) => {
    //     //TODO: if messages || list is not empty and in that mode then return
    //     const _state = {...state.value, isVisible: action.payload}
    // 	state.value = _state
    //     localStorage.setItem('frejun-global-status', JSON.stringify(state.value))
    // },
    // saveGlobalStatus: (state, action) => {
    // 	state.value = action.payload
    // 	localStorage.setItem('frejun-global-status', JSON.stringify(state.value))
    // },
    // // send action as {type: 'next', payload: _}
    // displayNextInArrayMode: (state, action) => {
    //     if(action.type === 'next' ) {
    //         if(state.value.arrayMode.array.length === 0) {
    //             state.value = {...state.value, isVisible: false, array: {isArray: false, messages: []}}
    //         }
    //         else {
    //             state.value.arrayMode.array.splice(0, 1)
    //             state.value = {...state.value, messageFor: state.value.arrayMode.array[0].messageFor}
    //         }
    //     }
    //     localStorage.setItem('frejun-global-status', JSON.stringify(state.value))
    // },
    // // send action.payload as [{message: '', messageFor: ''}]
    // dispatchAddArrayMessage: (state, action) => {
    //     state.value = {...state.value, arrayMode:{...state.arrayMode, messages: [...state.value.arrayMode.messages, ...action.payload.messages]}}
    //     localStorage.setItem('frejun-global-status', JSON.stringify(state.value))
    // },
    // dispatchAddToList: (state, action) => {
    //     state.value = {...state.value, listMode: {list: state.value.listMode.list.concat(`\n${action.payload}`)}}
    //     localStorage.setItem('frejun-global-status', JSON.stringify(state.value))
    // }
  },
});

// export const { toggleGlobalStatusVisibility, setGlobalStatusVisibility, saveGlobalStatus, displayNextInArrayMode, dispatchAddArrayMessage, dispatchAddToList } = globalStatusSlice.actions
export const {
  setGlobalStatusVisibility,
  enqueueStatusMessage,
  dequeueStatusMessage,
} = globalStatusSlice.actions;
export default globalStatusSlice.reducer;

/**
 * {
 * 	isVisible: Boolean,
 *	success: Boolean,
 *	detail: String,
 *	messageFor: Boolean,
 *  arrayMode: {
 *    messages: [],
 *    isArray: Boolean
 *  } // sets timing function to display multiples message
 *  listMode: {
 *    list: String,
 *    isList: Boolean
 *  }
 * }
 *
 * ***
 * toggleGlobalStatusVisibility : isVisible toggler
 *
 *
 */
