import { createSlice } from '@reduxjs/toolkit'

let initial = null
try{
    initial = JSON.parse(localStorage.getItem('frejun-dashboard-column-for'))
}catch(err){
    console.warn(err, ' Setting up local storage for first (re)use.')
}

initial = initial?initial:{}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {value:Object.keys(initial).length === 0?{}:initial},
    reducers:{
        tableColumnClicked : (state , action)  => {
            state.value = action.payload
            localStorage.setItem("frejun-dashboard-column-for", JSON.stringify(state.value))
        }
    }
})

export const { tableColumnClicked} = dashboardSlice.actions
export default dashboardSlice.reducer