import React, { useEffect, useState } from "react";
import { CheckPicker, DatePicker } from "rsuite";
import Button from "../design/Button";

function GeneralTab(props) {
  
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [workingDays, setWorkingDays] = useState([]);
  const [workTimeStart, setWorkTimeStart] = useState(new Date());
  const [workTimeEnd, setWorkTimeEnd] = useState(new Date());
  const [breakTimeStart, setBreakTimeStart] = useState(new Date());
  const [breakTimeEnd, setBreakTimeEnd] = useState(new Date());
  const [businessHoursChanged, setBusinessHoursChanged] = useState(false);

 

  const hasProfileInfoChanged = () => {
    return (
      (firstName !== props.userProfile.first_name ||
        lastName !== props.userProfile.last_name) &&
      firstName.length &&
      lastName.length
    );
  };

  const hasPasswordInfoChanged = () => {
    return (
      currentPassword.length &&
      newPassword.length >= 8 &&
      confirmNewPassword.length >= 8
    );
  };

  const handlePasswordChange = () => {
    props.handlePasswordChange(
      currentPassword,
      newPassword,
      confirmNewPassword
    );
    setTimeout(() => {
      setCurrentPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
    }, 1000);
  };

  const handleBusinessHoursChange = () => {
    props.handleBusinessHoursChange(
      workingDays,
      workTimeStart,
      workTimeEnd,
      breakTimeStart,
      breakTimeEnd
    );

    setTimeout(() => {
      setBusinessHoursChanged(false);
    }, 1000);
  };

  useEffect(() => {
    if (props.userProfile) {
      
      setFirstName(props.userProfile.first_name);
      setLastName(props.userProfile.last_name);
      setEmail(props.userProfile.email);
      if (props.userProfile?.business_hours) {
        const businessHours = props.userProfile.business_hours;

        let wkDays = [];
        for (const key in businessHours.working_days) {
          if (businessHours.working_days[key]) wkDays.push(key.toString());
        }
        setWorkingDays(wkDays);
        setWorkTimeStart(
          new Date("2022-09-01T" + businessHours.work_time_start)
        );
        setWorkTimeEnd(new Date("2022-09-01T" + businessHours.work_time_end));
        setBreakTimeStart(
          new Date("2022-09-01T" + businessHours.break_time_start)
        );
        setBreakTimeEnd(new Date("2022-09-01T" + businessHours.break_time_end));
      }
    }
  }, [props.userProfile]);

  

  const workingDaysOptions = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  return (
    <div className="general-tab">
      <div className="section">
        <div className="t5 medium-font nc-gray-900">Profile</div>

        <label style={{ marginTop: "40px" }}>
          First name
          <input
            defaultValue={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </label>
        <label>
          Last name
          <input
            defaultValue={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </label>
        <label>
          Email
          <input defaultValue={email} disabled />
        </label>

        <div className="section-btn">
          <Button
            variant="filled"
            buttonText="Save"
            icon={{}}
            disabled={!hasProfileInfoChanged()}
            isLoading={false}
            hierarchy="green"
            onClick={() => props.handleUserNameUpdate(firstName, lastName)}
          />
        </div>
      </div>
      <div className="settings-sep"></div>
      
        
	
      <div className="section">
        <div className="t5 medium-font nc-gray-900">Business Hours</div>
        <label style={{ marginTop: "40px" }}>Working Days</label>
        <div style={{ marginTop: "12px" }}>
          <CheckPicker
            data={workingDaysOptions.map((item) => ({
              label: item,
              value: item,
            }))}
            style={{ width: 224 }}
            onChange={(wkDays) => {
              setWorkingDays(wkDays);
              setBusinessHoursChanged(true);
            }}
            value={workingDays}
            placement={"autoVertical"}
          />
        </div>
        <label>Work Timings</label>
        <div className="time-slot-container">
          <DatePicker
            format="HH:mm"
            value={workTimeStart}
            onChange={(dateObj) => {
              setWorkTimeStart(dateObj);
              setBusinessHoursChanged(true);
            }}
            ranges={[]}
            cleanable={false}
            placement={"auto"}
          />
          <p> to </p>
          <DatePicker
            format="HH:mm"
            value={workTimeEnd}
            onChange={(dateObj) => {
              setWorkTimeEnd(dateObj);
              setBusinessHoursChanged(true);
            }}
            ranges={[]}
            cleanable={false}
            placement={"auto"}
          />
        </div>
        <label>Break Timings</label>
        <div className="time-slot-container">
          <DatePicker
            format="HH:mm"
            ranges={[]}
            value={breakTimeStart}
            onChange={(dateObj) => {
              setBreakTimeStart(dateObj);
              setBusinessHoursChanged(true);
            }}
            cleanable={false}
            placement={"auto"}
          />
          <p> to </p>
          <DatePicker
            format="HH:mm"
            ranges={[]}
            value={breakTimeEnd}
            onChange={(dateObj) => {
              setBreakTimeEnd(dateObj);
              setBusinessHoursChanged(true);
            }}
            cleanable={false}
            placement={"auto"}
          />
        </div>
        <div className="section-btn">
          <Button
            variant="filled"
            buttonText="Save"
            icon={{}}
            disabled={!businessHoursChanged}
            isLoading={false}
            hierarchy="green"
            onClick={handleBusinessHoursChange}
          />
        </div>
      </div>
      <div className="settings-sep"></div>
      <div className="section">
        <div className="t5 medium-font nc-gray-900">Password</div>

        <label style={{ marginTop: "40px" }}>
          Current password
          <input
            type="password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </label>
        <label>
          New Password
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </label>
        <label>
          Confirm Password
          <input
            type="password"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
        </label>
        <div className="section-btn">
          <Button
            variant="filled"
            buttonText="Save"
            icon={{}}
            disabled={!hasPasswordInfoChanged()}
            isLoading={false}
            hierarchy="green"
            onClick={handlePasswordChange}
          />
        </div>
      </div>
    </div>
  );
}

export default GeneralTab;
