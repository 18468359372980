import './forgotPasswordStyles.css'

import dotsBottom from '../../assets/login-dots-bottom.svg'
import dotsTop from '../../assets/login-dots-top.svg'
import vectorArt from '../../assets/login-vector-art.png'
import logo from '../../assets/top-nav-logo.svg'
import { useEffect, useState } from 'react'
import { resetPasswordValidation } from '../../request-handlers/validation/request-validation'
import { useDispatch } from 'react-redux'
import { loginAttempt } from '../features/user/user'
import { enqueueSnackbar } from 'notistack'

const API_BASE_URL = process.env.REACT_APP_BETA
function ForgotPasswordReset() {
    const dispatch = useDispatch()
    
	const [email, setEmail] = useState("")

    const handleEmailChange = e => setEmail(e.target.value)

    const  handleSubmit = e => {
        e.preventDefault()
        fetch(API_BASE_URL + '/api/v1/auth/send-code/', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({email, source: 'reset'})
        })
		.then(res => {
            if(res?.status === 500) throw new Error('Server error please try again or if issue persists contact FreJun')
            return res?.json()  
        }).then(res => {
            if(!res?.success) throw new Error(res?.message || `Error occurred while sending reset password to ${email}`)
			enqueueSnackbar(res?.message + " to " + email || `Sent reset code to ${email}`, { variant: "success"})
			
            dispatch(loginAttempt({email, password: ''}))
            window.location.replace('/forgot-password/verification')
        }).catch(err => enqueueSnackbar(err?.message, { variant: "error"}))
    }

    useEffect(()=>{
        const _resetPasswordButton = document.querySelector('#reset-password-button')
        _resetPasswordButton.disabled = true
        _resetPasswordButton.classList.remove('btn-green')
    },[])

    useEffect(()=>{
        if(resetPasswordValidation({email}).isValid){
            document.querySelector('#reset-password-button').disabled = false
            document.querySelector('#reset-password-button').classList.add('btn-green')
        }
        else{
            document.querySelector('#reset-password-button').classList.remove('btn-green')
            document.querySelector('#reset-password-button').disabled = true
        }
    }, [email])

    return(
		<div className='login-container'>
			<div className='login-art'>
            <a href="https://frejun.com/" target="_blank" rel="noopener noreferrer"> <img src={logo} alt="Logo" /></a>
			<img src={ dotsTop }/>
			<img src={ vectorArt }/>
			<img src={ dotsBottom }/>
			<p className='regular-font t5'>Simplify and Automate<br/>Your Calling Process</p>
			</div>
			<div className='login-form'>
				<p className='title medium-font t5'>Reset Password</p>
				<form onSubmit={handleSubmit}>
					<label className='medium-font t7'>
						Email
						<input className='regular-font t6' name='email' type='text' value={email} onChange={handleEmailChange} />
					</label>
					<input id='reset-password-button' className='btn reset-password-btn login-btn t6 medium-font' type='submit' value='Reset Password' onSubmit={handleSubmit} onClick={handleSubmit}/>
					<a href='/login' className='to-login regular-font t7 color-green to-sign-up'>Return to login</a>
				</form>
			</div>
		</div>
    )
}
export default ForgotPasswordReset