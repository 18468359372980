import colors from "./colors";
import styles from "./styles";

export default {
  select: {
    alignSelf: "flex-start",
    // minWidth: 200,
    width: "100%",
    maxWidth: "100%",
    outline: "none",
    borderRadius: "4px",
    padding: 0,
    marginBottom: "0px",
    ...styles.t6,
    ...styles.regular_font,
    ...colors.nc_gray_900,
    "&:before": {
      outline: "none",
      border: "none",
    },
    "&:focus .MuiOutlinedInput-input": {
      border: "none",
      outline: "none",
    },
    "& .MuiOutlinedInput-input": {
      padding: "8px 12px 8px 12px",
      border: "none",
      outline: "none",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #34CB65",
      boxShadow: "0px 0px 0px 4px #F0FCF4",
    },
  },
  menu_item: {
    "&.MuiMenuItem-root.Mui-selected": {
      background: "#F0FCF4",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  btnStyle:{
    height:'36px',
    fontSize:'14px',
    lineHeight:'14px',
    fontWeight:'400',
    padding:'10px 16px'
  }
};
