import { createSlice } from '@reduxjs/toolkit'

let _initial = null
try{
    _initial = localStorage.getItem('frejun-global-dailer')
}catch(err){
    console.warn('Error related to global dailer: ', err)
}

_initial = _initial === null?false:_initial

export const globalDialerSlice = createSlice({
    name: 'global-dialer',
    initialState: {value:Object.keys(_initial).length === 0?{phone_number:'', isVisible: false}:_initial},
    reducers: {
        setDialer: (state, action) => {
            state.value = action.payload
            localStorage.setItem('frejun-global-dialer', JSON.stringify(state.value))
        },
    }

})

export const { setDialer } = globalDialerSlice.actions
export default globalDialerSlice.reducer