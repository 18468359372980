import CardDetails from "../stripe/cardDetails"
import vectorArt from '../../assets/signup-screen-cards.svg';
import dotsBottom from "../../assets/login-dots-bottom.svg";
import dotsTop from "../../assets/login-dots-top.svg";
import logo from "../../assets/top-nav-logo.svg";
import './commonStyles.css';
import  "../signup/signupscreen7.css";


const SignupScreen7=({handleToggleWidget, isWidgetOpen})=>{
    return (
        <div className='signup-screen2-container signup-screen-container color-2d '>
        <div className='signup-art'>
        <a href="https://frejun.com/" target="_blank" rel="noopener noreferrer"> <img src={logo} alt="Logo" /></a>
            <img src={ dotsTop }/>
            <img src={ vectorArt }/>
            <img src={ dotsBottom }/>
        </div>
        <div className='signup-screen2-form signup-screen-form s7form s7'>
            <p className='title t4 medium-font'>Card details</p>
            <CardDetails handleToggleWidget={handleToggleWidget} isWidgetOpen={isWidgetOpen}/>
        </div>
        </div>
    )
}
export default SignupScreen7;