import {
	Box,
	Select,
	MenuItem,
	TextField,
	Chip,
	Autocomplete,
  } from "@mui/material";
  import Modal from "@mui/material/Modal";
  import { useEffect, useRef, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import {
	modalCloseIcon,
	muteCallIcon,
	holdCallIcon,
	topNavDialPad,
	endCallIcon,
	playIcon,
	completedIcon
  } from "../../../utility-functions/assetLoader";
  import Button from "../../design/Button";
  import colors from "../../design/colors";
  import styles from "../../design/styles";
  import { setReduxAutodialState } from "../../features/autodial/autodial";
  import AnimationPage from "../../shared/animation-page/AnimationPage";
  import ongoingContactIcon from "../../../assets/gautodial-ongoing-contact-icon.svg";
  import Pusher from "pusher-js";
  import "./autodialMaximized2Styles.css";
  import { useNavigate } from "react-router";
  import chipDeleteIcon from "../../../assets/gusers-and-teams-chip-delete-icon.svg";
  import { JService } from "../../api-service/ApiService";
import { enqueueSnackbar } from 'notistack';
import { Inviter, SessionState, UserAgent } from "sip.js";
import { sipUserAgent } from "../../shared/navbar/TopNav";
import Dialpad from "../../shared/navbar/Dialpad";
import { CustomTooltip } from "../../design/components/customComponents";
  // closing browser tab pauses the autodial
  
  let timeoutId = null;
  let intervalId = null;

  const SESSION_STATES = [
	SessionState.Initial,
	SessionState.Establishing,
	SessionState.Established,
	SessionState.Terminating,
	SessionState.Terminated,
  ];
  
  // if isLoading data to initiate is not set, => initial
  // message should be "Initiating autodial"
  const API_BASE_URL = process.env.REACT_APP_BETA;
  export default function AutodialMaximized2({ data, autodialType, allTags, orgDetails, ...props }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const globalAutodialState = useSelector((state) => state?.autodial?.value);
	const reduxAccount = useSelector((state) => state?.account?.value)
	const maskNumbers = reduxAccount?.data?.number_masking && reduxAccount?.data?.profile?.role !== 1
	
	const dev_autodialID = useRef(-1);
	const autodialPausedRef = useRef(null);
	const responseRef = useRef(null);
	const prevAutodialInitiateData = useRef(null);
	const callNowRef = useRef(false);
  
	// states
	const [showLoader, setShowLoader] = useState(false);
  
	const [saveNotesValues, setSaveNotesValues] = useState({
	  callReason: "",
	  callOutcome: "",
	  notes: "",
	  tags: [],
	});

	const resetSaveNotesValues = () => setSaveNotesValues({
		callReason: "",
		callOutcome: "",
		notes: "",
		tags: [],
	})
	const [saveNotes, setSaveNotes] = useState(false);
	const [autodialResponse, setAutodialResponse] = useState([]);
	const [inQ, setInQ] = useState([]);
	const [inSkip, setInSkip] = useState([]);
	const [inComplete, setInComplete] = useState([]);
	const [allContacts, setAllContacts] = useState([]);
	const [currentOngoingContact, setCurrentOngoingContact] = useState([]);
	const [timeToNextCall, setTimeToNextCall] = useState(null);
	const [initiateNextCall, setInitiateNextCall] = useState({type: 'initiate', initiate:false});
	const [autodialState, setAutodialState] = useState('');

	const [isMute, setIsMute] = useState(false);
	const [isHold, setIsHold] = useState(false);
	const [isDialpadVisible, setIsDialpadVisible] = useState(false);
	const [inviteSession, setInviteSession] = useState({
		to: "",
		isCallOngoing: false,
		session: null,
		isCallStarted: false
	  });
  
	useEffect(() => {  
	  const noResume = localStorage.getItem("frejun-autodial-no-resume") || false;
  
	  if ( globalAutodialState?.resume === "resumed" && globalAutodialState?.resumeRead == true && noResume ) {
		console.log(
		  "resumed so not initiating",
		  globalAutodialState,
		  " and noresume: ",
		  noResume
		);

		// @resumed
		// if(autodialType === 'browser') {
		  if(autodialType === 'browser'){
			console.log('resuming bb autodial');
			localStorage.removeItem("frejun-autodial-no-resume");
			
			const autodialData = data?.find(d => d.id === parseInt(globalAutodialState?.guid));
			dev_autodialID.current = globalAutodialState?.guid;
	
			const inQArray = Object.values(autodialData?.contacts_remaining ?? {});
			const currentContact = Object.values(autodialData.current_contact ?? {})?.[0];

			if(!currentContact && (!inQArray || !inQArray.length)){
				dev_autodialID.current = -1;
				dispatch(setReduxAutodialState({minimized: false, maximized: false}));
				return;
			}
			
			setAllContacts(autodialData.contacts ?? []);
			setInQ(inQArray);
			setInSkip(Object.values(autodialData?.contacts_skipped))
			setInComplete(Object.values(autodialData.contacts ?? {})?.filter(ac => 
				inQArray?.findIndex((q) => q?.id === ac?.id) === -1 &&
				currentContact?.id !== ac?.id
			))
			updateBBAutodialContactStatus({status:"Resume"})
			dispatch(setReduxAutodialState({
				...globalAutodialState,
				resumeRead: false,
				resumed: "",
			}));
			
			return setInitiateNextCall({type:'resume-session', initiate: true, currContact: currentContact})
		}
  
		try {
		  console.log('resuming network based autodial');
		  var xhr = new XMLHttpRequest();
		  localStorage.removeItem("frejun-autodial-no-resume");
		  xhr.open("PATCH", API_BASE_URL + `/api/v1/core/update-autodial-session/${globalAutodialState?.guid}/`);
		  xhr.withCredentials = true;
		  xhr.setRequestHeader("Accept", "application/json");
		  xhr.setRequestHeader("Content-Type", "application/json");
  
		  xhr.onreadystatechange = async function () {
			if (xhr.readyState === 4) {
				try {
					const res = await JSON.parse(xhr.response);
					if (!res?.success) throw new Error(res?.message || "Failed to update autodial");
	
					let rem = Object.values(res?.data?.contacts_remaining);
					let currentContact = Object.values(res?.data?.current_contact);
					if (currentContact?.length == 0) currentContact = null;
					else currentContact = currentContact[0];
					let allC = Object.values(res?.data?.contacts);
				 
					setInQ(rem || []);
					setCurrentOngoingContact({
						callId: "",
						call_status: "ongoing...",
						contact_details: {
							name: currentContact?.first_name,
							phone_number: currentContact?.phone_number,
							candidate_id: currentContact?.id,
							notes:currentContact?.notes
						},
					});
					setInComplete(allC?.filter((a) => a?.id > currentContact?.id));
					//  all contacts id-name,details mapping
					responseRef.current = res;
	
					dispatch(setReduxAutodialState({
						...globalAutodialState,
						resumeRead: false,
						resumed: "",
					}));
	
					// subscribe to pusher
					const pusher = new Pusher("498da914b14760d4c18c", {
						cluster: "ap2",
						encrypted: true,
					});
	
					const channel = pusher.subscribe( globalAutodialState?.guid?.toString() );
					channel.bind("autodial", (pusherUpdate) => {
						handleRESUMEDPusherUpdate(pusherUpdate, res?.data?.interval);
					});
				} catch (_err) {
					console.warn(_err?.message);
					
				} finally {
					return;
				}
			}
		  };
		  xhr.send(JSON.stringify({ status: "Resume" }));
		} catch (err) {
		  console.warn(err?.message);
		}
		return;
	  }

	  if ( prevAutodialInitiateData.current == data || !globalAutodialState?.maximized || data?.length === 0 ) {
		// TODO: prevent-re-render
		console.log(
		  "caught in duplicate run: ",
		  prevAutodialInitiateData.current == data,
		  !globalAutodialState?.maximized,
		  data?.length === 0
		);
		return;
	  }
  
	  
	  console.log(
		'globalAutodialState?.maximized: ', globalAutodialState?.maximized,
		'browserCallsRef.current: ', autodialType,
	  )
		if(globalAutodialState?.maximized && autodialType === 'browser' && Object?.keys(data.contacts ?? {})?.length) {
		console.log('starting bb autodial');
		setAllContacts(data.contacts ?? {});
		// to prevent multiple api calls
		prevAutodialInitiateData.current = data;
		return setInitiateNextCall({type: 'initiate', initiate: true});
	  }
	  
		if(autodialType === 'network'){
		console.log('starting network based autodial');
		// every value in data has keys contacts?.every(c => c.id == !null && c.id > 0) && phoneNumber validation(if needed) name and
		if (!showLoader) setShowLoader(true);
		let sendData = {...data};
		if(props?.contactListId) sendData['contact_list_id'] =  Number(props?.contactListId);
		else sendData['contact_list_id'] =  null;
	
		JService.post(
		API_BASE_URL + `/api/v1/core/initiate-autodial-session/`,
		sendData
		)
		.then((res) => {
			if (!res?.success)
			throw new Error(res?.message || "Could not initiate autodial");

			setAutodialState('initiated');
			// success => connect to pusher and bind channel
			const pusher = new Pusher("498da914b14760d4c18c", {
			cluster: "ap2",
			encrypted: true,
			});
	
			const channel = pusher.subscribe(res?.data?.id?.toString());
			channel.bind("autodial", (pusherUpdate) => {
			handlePusherUpdate(pusherUpdate);
			});
	
			// to prevent multiple api calls
			prevAutodialInitiateData.current = data;
	
			// autodial ui and data
			responseRef.current = res;
			setAutodialResponse(res);
	
			let allContactsArray = Object.values(res?.data?.contacts);
			setAllContacts(allContactsArray);
	
			let inQArray = Object.values(res?.data?.contacts_remaining);
			setInQ(inQArray);
	
			let currentContact = Object.values(res?.data?.current_contact);
			if (currentContact?.length == 0) currentContact = null;
			else currentContact = currentContact[0];
			setCurrentOngoingContact({
				callId: "",
				call_status: "ongoing...",
				contact_details: {
					name: currentContact?.first_name,
					phone_number: currentContact?.phone_number,
					candidate_id: currentContact?.id,
					notes:  currentContact?.notes
				},
				})
			let inSkipArray = Object.values(res?.data?.contacts_skipped);

			setInComplete([
			...allContactsArray?.filter(
				(allc) =>
				inQArray?.findIndex((q) => q?.id === allc?.id) === -1 &&
				currentContact?.id !== allc?.id
			),
			]);
			setInSkip(inSkipArray);
		})
		.catch((err) => {
			console.warn(err);
			enqueueSnackbar(err?.message, { variant: "error"})
			dispatch(setReduxAutodialState({minimized: false, maximized: false}));
		})
		.finally(()=>{
			setShowLoader(false)
		})
	  }
  
	  return () => {
	  };
	}, [data, globalAutodialState]);

	async function initiatebbAutodial() {
		try {
			const payload = {
				...data,
				contact_list_id: props?.contactListId
			};
			if (!showLoader) setShowLoader(true);
			const res = await JService.post(API_BASE_URL + `/api/v1/core/initiate-autodial-session/`, payload);
			if(!res.success){
				throw new Error(res?.message || 'Failed to initiate autodial');
			}
		
			setAutodialState('initiated')

			const allContactsArray = Object.values(res?.data?.contacts);
			const currentContact = typeof (res?.data?.current_contact) === 'object' ? Object.values(res?.data?.current_contact)?.[0] : res?.data?.current_contact;
			const inQArray = Object.values(res?.data?.contacts_remaining ?? {})
			setInQ(inQArray);
			setAllContacts(allContactsArray)
			setInSkip(Object.values(res?.data?.contacts_skipped ?? {}));
			setInComplete([
				...allContactsArray?.filter(
					(allc) =>inQArray?.findIndex((q) => q?.id === allc?.id) === -1 &&
					currentContact?.id !== allc?.id
				),
			]);
			dev_autodialID.current = res.data.id;
			return res;
		}
		catch(error) {
			console.error(error);
			enqueueSnackbar(error?.message || 'Failed to initiate autodial', {variant:'error'});
		
			dispatch(setReduxAutodialState({minimized: false, maximized: false}));
			dev_autodialID.current = -1;
			return false;
		}
		finally{
			setShowLoader(false);
		}
	}
	const handleInitiateNextCall = async (type, nextContact, updatedQueue, updatedComplete) => {
		if(nextContact && ['continue', 'resume', 'call-now'].includes(type)){
			if(timeoutId) clearTimeout(timeoutId);
			if(intervalId) clearInterval(intervalId)
			const timeOut = type === 'call-now' ? 0 : type === 'resume' ? timeToNextCall ? timeToNextCall*1000 : data.interval*1000 ?? 5000 : data.interval*1000 ?? 5000
		    timeoutId = setTimeout(async ()=>{
				await handleSaveNotes();
					if(updatedQueue) setInQ(updatedQueue);
					if(updatedComplete) setInComplete(updatedComplete);
				startBrowserBasedAutodial(nextContact, 'continue')
			}, timeOut);
			if(type === 'call-now') setTimeToNextCall(0);
			if(!timeToNextCall) setTimeToNextCall(data?.interval ?? 5);
			intervalId = setInterval(()=>setTimeToNextCall(prev => {
				if(prev <= 0){
					clearInterval(intervalId);
					return 0;
				}
				else return prev - 1;
		  }), 1000)
		  }
		  else if(nextContact){
			startBrowserBasedAutodial(nextContact, 'initiate')
		  }
		  else{
			console.log('No nextContact found');
		  }
	}

	useEffect(()=>{
		(async()=>{
		if(!initiateNextCall.initiate || autodialPausedRef.current){
			return;
		}
		setInitiateNextCall(prev => ({...prev, initiate: false}));
		let queue = [...inQ];
		let currContact = null;
		let updatedQueue = [];
		let updatedComplete = [];

		if(initiateNextCall.type === 'initiate'){
			console.log('type initiate')
			const initiateResponse = await initiatebbAutodial();
			if(!initiateResponse) {
				console.log("autodial init failed");
				enqueueSnackbar('Failed to initiate autodial', {variant:'error'})
				return;
			}
			else{
				queue = Object.values(initiateResponse?.data?.contacts_remaining);
				currContact = {...Object.values(initiateResponse?.data?.current_contact ?? {})?.[0]}
			}
		}
		else if(initiateNextCall.type === 'continue'){
			console.log('type continue');
			currContact = inQ[0];
			const completedContact = {
				id: currentOngoingContact?.contact_details?.candidate_id,
				first_name: currentOngoingContact?.contact_details?.name,
				phone_number: currentOngoingContact?.contact_details?.phone_number
			};
			updatedQueue = [...inQ];
			updatedQueue.shift();
			updatedComplete = [...inComplete];
			updatedComplete.push(completedContact);
		}
		else if(initiateNextCall.type === 'resume-session'){
			console.log('type resume-session');
			currContact = initiateNextCall.currContact;

			updatedQueue = [...inQ];
			updatedComplete = [...inComplete];
		}
		else if(initiateNextCall.type === 'resume' || initiateNextCall.type === 'call-now'){
			const completedContact = {
				id: currentOngoingContact?.contact_details?.candidate_id,
				first_name: currentOngoingContact?.contact_details?.name,
				phone_number: currentOngoingContact?.contact_details?.phone_number
			};
			updatedQueue = [...inQ];
			updatedQueue.shift();
			currContact = inQ[0];
			updatedComplete = [...inComplete];
			updatedComplete.push(completedContact);
		}
		if(!currContact){
			console.log('queue is empty');
			const completedContact = {
				id: currentOngoingContact?.contact_details?.candidate_id,
				first_name: currentOngoingContact?.contact_details?.name,
				phone_number: currentOngoingContact?.contact_details?.phone_number
			};
			setInComplete(prev => {
				let temp = [...prev];
				temp.push(completedContact);
				return temp;
			})
			await updateBBAutodialContactStatus({status:'Finished'})
			setAutodialState('completed');
			console.log('removing listener')
			window.removeEventListener('beforeunload', handleWindowBeforeUnload);
			return;
		}
		await handleInitiateNextCall(initiateNextCall.type, currContact, updatedQueue, updatedComplete);
		})()
		
	},[initiateNextCall, inQ, inComplete, currentOngoingContact])
	
	// bbAutodial
	async function startBrowserBasedAutodial(currentContact, type = 'continue') {
		try {
			// initiate softphone call
			setInviteSession((prev) => ({...prev, isCallStarted: true}))
			// get contact details
			let payload = new URLSearchParams({ phone_number: currentContact?.phone_number });
			const response = await JService.get(`/api/v1/core/get-contact-details/?${payload}`);
			if (!response?.success) throw new Error(response?.message || 'Error while fetching "candidate_name"');
			// update contact and reset call details
			setCurrentOngoingContact({
				callId: "",
				call_status: "ongoing...",
				contact_details: {
					name: currentContact?.first_name,
					phone_number: currentContact?.phone_number,
					candidate_id: currentContact?.id,
					notes:currentContact?.notes,
				},
			});
			resetSaveNotesValues();
			if(type !== 'initiate'){
				updateBBAutodialContactStatus({contact: currentContact.id, contact_status: "Ongoing"});
			}

			let dev_start = true;
			if(dev_start) {
				// start Call
				const candidate = UserAgent.makeURI(
					"sip:" + currentContact?.phone_number + "@call.bistux.com:9080"
				);
				const userAgent = sipUserAgent;
				const token = localStorage.getItem("sipToken");
				const username = localStorage.getItem("sipUsername");
				
				const inviter = new Inviter(userAgent, candidate, {
					sessionDescriptionHandlerOptions: {
					  constraints: { audio: true, video: false },
					},
					extraHeaders: token ? [`token: ${token}`] : [],
				});
				setIsMute(false); 
				setIsHold(false); 
				setIsDialpadVisible(false); 
				inviter.stateChange.addListener((newState) => {
					try {
					  if (!inviter) throw new Error(`Invalid value for "inviter". Expected of type Invitation, recieved "${inviter?.toString()}" of type "${typeof inviter}"`);
					  if (!SESSION_STATES.includes(newState)) throw new Error(`Invalid value for "newSessionState". Expected one of ${SESSION_STATES.join(", ")}, recieved "${newState}"`);
		  
					  setupLocalMedia(inviter); // FIXME: move this inside ?
					  switch (newState) {
						case SessionState.Initial:
						  console.log(
							"inviter, state",
							inviter,
							newState
						  );
						  
						  break;
						case SessionState.Establishing:
						  console.log(
							"inviter, state",
							inviter,
							newState
						  ); // ✅
						//   setSip((prevState) => ({ ...prevState, isCall: true }));
						  
						  break;
						case SessionState.Established:
						  console.log(
							"inviter, state",
							inviter,
							newState
						  );
						  let localContact =
							inviter.outgoingInviteRequest._transaction._request.from;
						  let remoteContact =
							inviter.outgoingInviteRequest._transaction._request.to;
						  if (inviteSession?.session === null)
							setInviteSession({
							  to: remoteContact?.uri?.normal?.user,
							  isCallOngoing: true,
							  session: inviter,
							  isCallStarted: true
							});
						  
						  let remoteStream = new MediaStream();
						  const remoteMediaElement = document.querySelector("#sip-remote-audio");
		  
						  let recs =
							inviter.sessionDescriptionHandler.peerConnection.getReceivers();
		  
						  recs.forEach((rec) => {
							if (rec.track) remoteStream.addTrack(rec.track);
						  });
						  remoteMediaElement.srcObject = remoteStream;
		  
						  remoteMediaElement.onloadedmetadata = (e) => {
							remoteMediaElement
							  .play()
							  .then(() => {
							
							  })
							  .catch((_err) => console.error(_err?.message));
						  };
						  break;
						case SessionState.Terminating:
						  console.log(
							"inviter, state ",
							inviter,
							newState
						  );
						  break
						// fall through
						case SessionState.Terminated:
						  console.log(
							"inviter, state",
							inviter,
							newState
						  );
						  setInviteSession(prev => ({...prev, isCallOngoing: false, session: null, isCallStarted: false}));
						  console.log('setting initiateNextCall to true')
						  setInitiateNextCall({type: 'continue', initiate: true});
						  callNowRef.current = false;
						  break;
						default:
						  throw new Error("Unknown session state.");
					  }
					} catch (err) {
					  console.warn(err);
					  
					} finally {
					}
				});
				const inviteOptions = {
					requestDelegate: {
						onAccept: (data) => {
							console.log("inviter req delegate: ", data);
						},
					},
				};
				
				inviter?.invite(inviteOptions)
				.then(() => {
					// call is ringing
					console.log("started call");
					setInviteSession(prev => ({
						...prev,
						isCallStarted: true
					  }));
				})
				.catch(err => {
					//update failed contact
					updateBBAutodialContactStatus({contact: currentContact.id, contact_status: 'Failed'})
					console.log('setting initiateNextCall to true')
					setInitiateNextCall({type:'continue', initiate: true});
					console.error("error while inviting candidate", err);
					setInviteSession(prev => ({...prev, isCallOngoing: false, inviteSession: null, isCallStarted: false}));
					callNowRef.current = false;
				})
			} 
		}
		catch(error) {
			console.error(error)
			enqueueSnackbar(error?.message, { variant: "error"})
			callNowRef.current = false;
		}
	}

	async function handleCallNow(){
		if(inviteSession?.session || inviteSession?.isCallOngoing || callNowRef.current) return;
		callNowRef.current = true;
		setInitiateNextCall({type: 'call-now', initiate: true})
	}

	async function handlePauseBBAutodial(){
		autodialPausedRef.current = true;
		if(timeoutId) clearTimeout(timeoutId);
		if(intervalId) clearInterval(intervalId);
		setAutodialActionButton((prevState) => ({
			...prevState,
			isLoading: false,
			disabled: false,
			buttonText: 'Resume',
		  }));
		const res = await updateBBAutodialContactStatus({status: 'Paused'})
		if(res){
			enqueueSnackbar('Autodial session is paused', {variant:'success'});
		}
		else{
			console.warn('Backend not in sync with paused state of autodial on frontend')
		}
	}

	async function handleResumeBBAutodial(){
		autodialPausedRef.current = false;
		setInitiateNextCall({type: 'resume', initiate: true});
		setAutodialActionButton((prevState) => ({
			...prevState,
			isLoading: false,
			disabled: false,
			buttonText: 'Pause',
		}));
			const res = await updateBBAutodialContactStatus({status: 'Resume'});
			if(res){
				enqueueSnackbar('Autodial session is resumed', {variant:'success'});	
			}
			else{
				console.warn('Backend not in sync with resumed state of autodial on frontend')
			}
	}

	async function handleCancelBBAutodial(){
		if(timeoutId) clearTimeout(timeoutId);
		if(intervalId) clearInterval(intervalId);
		setInQ([]);
		setInComplete([]);
		setInSkip([]);
		setAllContacts([]);
		const res = await updateBBAutodialContactStatus({status: 'Cancelled'});
		if(res){
			enqueueSnackbar('Autodial session is cancelled', {variant:'success'});
		}
		else{
			console.warn('Backend not in sync with cancelled state of autodial on frontend')
		}
	}

	function getCurrentTrackState(senders) {
		const testStream = senders.at(0)
		let currentTrackState = isMute
		if(testStream.track && testStream.transport) {
			currentTrackState = testStream.track.enabled
		}
		return currentTrackState
	  }

	const handleMute = () => {
		if (!inviteSession?.session) return;
		if(!isMute) setIsDialpadVisible(false);
		const senders = inviteSession.session.sessionDescriptionHandler.peerConnection.getSenders()
		senders.forEach((sender) => {
			if (sender?.track && sender?.track?.kind === "audio") {
			  sender.track.enabled = isMute;
			}
		  });
		setIsMute(!isMute);
	};
	const handleHold = () => {
		try {
		  if (!inviteSession?.session) return;
		  const sessionDescriptionHandlerOptions =
			inviteSession.session?.sessionDescriptionHandlerOptionsReInvite;
		  // adding hold
		  console.log(
			"sessionDescriptionHandlerOptions",
			sessionDescriptionHandlerOptions
		  );
		  console.log("session", inviteSession.session);
	
		  // to hold/un-hold
		  sessionDescriptionHandlerOptions.hold = !isHold;

		  if(!isHold){
			setIsDialpadVisible(false);
		  }
	
		  let peerConnection =
			inviteSession.session?.sessionDescriptionHandler?.peerConnection;
	
		  let senders = peerConnection.getSenders();
	
		  const currentTrackState = getCurrentTrackState(senders)
	
		  // check current state, i.e.muted/unmuted
		  // let isEnabled = null;
		  // console.log("sender BEFORE toggling hold:", sender);
		  // sender.forEach(function (stream) {
		  //   if (stream.track && stream.transport) {
		  //   if (isEnabled === null) isEnabled = stream.track.enabled;
		  //   }
		  // });
		  // isMuted = !isEnabled;
	
		  const inviteOptions = {
			requestDelegate: {
			  onAccept: () => {
				const sessionDescriptionHandler =
				  inviteSession.session.sessionDescriptionHandler;
				sessionDescriptionHandler?.enableSenderTracks(!currentTrackState);
				sessionDescriptionHandler?.enableReceiverTracks(isHold);
			  },
			  onReject: () => {
				const sessionDescriptionHandler =
				  inviteSession.session.sessionDescriptionHandler;
				sessionDescriptionHandler?.enableReceiverTracks(false);
				sessionDescriptionHandler?.enableSenderTracks(false);
			  },
			},
		  };
	
		  // inviting
		  (async function () {
			try {
			  const curSession = inviteSession.session;
			  curSession.sessionDescriptionHandlerOptionsReInvite =
				sessionDescriptionHandlerOptions;
			  const inviteResponse = await curSession.invite(inviteOptions);
	
			  setIsHold((prevState) => !prevState);
			} catch (error) {
			  // onError check if actually muted or not
			  let isEnabled = null;
			  senders.forEach(function (stream) {
				if (stream.track && stream.transport) {
				  if (isEnabled === null) isEnabled = stream.track.enabled;
				}
			  });
	
			  setIsHold(isEnabled);
			  console.error("reinvite for hold error:", error);
			}
		  })();
		} catch (err) {
		  console.warn("hold error:", err);
		}
		return true;
	  };
	
	  const handleDialpad = () => {
		setIsDialpadVisible((prevState) => !prevState);
	  };
	
	const endCall = () => {
		if(!inviteSession?.session || !inviteSession?.isCallOngoing){
			console.warn('failed to end call, no ongoing session found');
			return;
		}
		inviteSession.session.bye();
		setInviteSession(prev => ({...prev, isCallOngoing: false, session: null, isCallStarted: false}));
	}

	function setupLocalMedia(arg) {
		try {
		  let browserCompatibile = !!(
			navigator.getUserMedia ||
			navigator.webkitGetUserMedia ||
			navigator.mozGetUserMedia ||
			navigator.msGetUserMedia
		  );
		  if (!browserCompatibile)
			throw new Error(`Your browser does not support sip services`);
	
		  const localMediaElement = document.querySelector("#sip-local-audio");
	
		  let localStream = new MediaStream();
		  let senders = arg.sessionDescriptionHandler.peerConnection.getSenders();
		  senders.forEach((rec) => {
			if (rec.track && rec.transport) localStream.addTrack(rec.track);
		  });
		  localMediaElement.srcObject = localStream;
		  localMediaElement.onloadedmetadata = (e) => {
			localMediaElement.setAttribute(
			  "title",
			  "Only for testing, play to recieve own audio feedback"
			);
		  };
	
		  console.warn("***<FOR-VISIBILITY>localStream", localStream);
		} catch (error) {
			console.error(error.message)
		}
	}

	async function updateBBAutodialContactStatus(payload) {
		console.log('update bb autodial status');
		try {
			const response = await JService.patch(`/api/v1/core/update-bb-autodial/${dev_autodialID.current}/`, payload);
			if(!response.success) throw new Error(response.message)
			return true;
		}
		catch(error) {
			console.error(error)
			return false;
		}
	}
  
	// closing the autodial
	async function handleCloseAutodialByIcon(e) {
	  // @note cancel if End autodial or close icon is clicked 

	await handleSaveNotes();
    if(autodialState !== 'completed'){
	  if(autodialType === 'browser'){
		handleCancelBBAutodial();
	  }
	  else{
	    handleAutodialAction("Cancelled");
	  }
	}
	  setTimeout(() => {
		dispatch(setReduxAutodialState({ minimized: false, maximized: false }));
		setSaveNotesValues({
		  callReason: "",
		  callOutcome: "",
		  notes: "",
		});
		navigate(0);
	  }, 500);
	  // reset selectedRows (to be done in ContactListsView)
	}
  
	// unload handler
	async function handleWindowBeforeUnload(e) {
	  e.preventDefault();
	  console.log("Closing window");
	
	  console.log('autodialState', autodialState);
	  if(autodialState !== 'completed'){
		if(autodialType === 'browser'){
			await handlePauseBBAutodial();
		}
		else{
			var xhr = new XMLHttpRequest();		
			e.returnValue = "";
			xhr.open(
				"PATCH",
				API_BASE_URL +
				`/api/v1/core/update-autodial-session/${responseRef.current?.data?.id}/`
			);
			xhr.withCredentials = true;
			xhr.setRequestHeader("Accept", "application/json");
			xhr.setRequestHeader("Content-Type", "application/json");
			xhr.send(JSON.stringify({ status: "Paused" }));
		}
	}
	  dispatch(
		setReduxAutodialState({
		  ...globalAutodialState,
		  minimized: false,
		  maximized: false,
		})
	  );
	}
  
	const [autodialActionButton, setAutodialActionButton] = useState({
	  disabled: false,
	  buttonText: "Pause",
	  isLoading: false,
	});
  
	// autodial action handler
	function handleAutodialActionButtonClick(e) {
	  // console.log('action button clicked')
  
	  let payload = "";
	  if (autodialActionButton?.buttonText?.toLowerCase() === "pause") {
		// if button reads pause payload is Paused
		payload = "Paused";
	  } else if (autodialActionButton?.buttonText?.toLowerCase() === "resume") {
		// pause
		payload = "Resume";
	  }
  
	  if (!["Paused", "Resume"].includes(payload)) return;
  
	  // TODO: check for success only then call update OR have an onClose / terminate for error
  
	  setAutodialActionButton((prevState) => ({
		...prevState,
		isLoading: true,
		disabled: true,
	  }));

	  if(autodialType === 'browser'){
		if(payload === 'Paused'){
			handlePauseBBAutodial();
			return;
		}
		else if(payload === 'Resume'){
			handleResumeBBAutodial();
			return;
		}
		return;
	  }
	  // let token = "";
	  let isResponseSuccessful = false;
  
	  if (showLoader) setShowLoader(true);
	  var xhr = new XMLHttpRequest();
	  try {
		xhr.open(
		  "PATCH",
		  API_BASE_URL +
			`/api/v1/core/update-autodial-session/${autodialResponse?.data?.id}/`
		);
        xhr.withCredentials = true;
		xhr.setRequestHeader("Accept", "application/json");
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.onreadystatechange = async function () {
		  if (xhr.readyState === 4) {
			if(xhr?.status === 500) throw new Error('Server error please try again or if issue persists contact FreJun')
			try {
				const res = await JSON.parse(xhr.response);
				if(!res?.success) throw new Error(res?.message || "Failed to update autodial");

				if(payload === 'Paused' && intervalId) clearInterval(intervalId);
				else if(payload === 'Resume'){
					if(intervalId) clearInterval(intervalId);
					if(!timeToNextCall) setTimeToNextCall(data?.interval ?? 5);
					setInterval(()=>setTimeToNextCall(prev => {
						if(prev <= 0){
							clearInterval(intervalId);
							return 0;
						}
						else return prev - 1;
				  }), 1000)
				}
				enqueueSnackbar(res?.message || `Updated autodial to ${payload}`, { variant: "success"})
				isResponseSuccessful = true;
			} catch (_err) {
				enqueueSnackbar(_err?.message || `Error occurred while trying update autodial to ${payload}`, { variant: "error"})
			  	isResponseSuccessful = false;
			} finally {
			  setAutodialActionButton((prevState) => ({
				...prevState,
				isLoading: false,
				disabled: false,
				buttonText: isResponseSuccessful
				  ? payload?.toLowerCase() === "paused"
					? "Resume"
					: "Pause"
				  : prevState.buttonText,
			  }));
			}
		  }
		};
  
		xhr.send(JSON.stringify({ status: payload }));
	  } catch (err) {
		enqueueSnackbar(err?.message || `Error occurred while trying update autodial to ${payload}`, { variant: "error"})
	  } finally {
		setShowLoader(false);
	  }
	}
  
	// if action needs to be called programatically
	function handleAutodialAction(action) {
	  if (!["Cancelled", "Resume", "Paused"].includes(action)) return;

	  if(autodialType === 'browser'){
		if(action === 'Paused'){
			handlePauseBBAutodial();
			return;
		}
		else if(action === 'Resume'){
			handleResumeBBAutodial();
			return;
		}
		else if(action === 'Cancelled'){
			handleCancelBBAutodial();
			return;
		}
		return;
	  }
  
	  if (showLoader) setShowLoader(true);
	  var xhr = new XMLHttpRequest();
	  try {
		xhr.open(
		  "PATCH",
		  API_BASE_URL +
			`/api/v1/core/update-autodial-session/${responseRef.current?.data?.id}/`
		);
		xhr.withCredentials = true;
		xhr.setRequestHeader("Accept", "application/json");
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.onreadystatechange = async function () {
		  if (xhr.readyState === 4) {
			if(xhr?.status === 500) throw new Error('Server error please try again or if issue persists contact FreJun')
			const res = await JSON.parse(xhr.response);
			if (!res?.success) throw new Error(res?.message || "Failed to update autodial");
  
			let finalButtonText = autodialActionButton?.buttonText;
			let actionLower = action?.toLowerCase();
			if (actionLower === "paused") finalButtonText = "Resume";
			if (actionLower === "resume") finalButtonText = "Pause";
			enqueueSnackbar(res?.message || `Updated autodial to ${action}`, { variant: "success"})
			setAutodialActionButton((prevState) => ({
			  ...prevState,
			  buttonText: finalButtonText,
			}));
		  }
		};
  
		xhr.send(JSON.stringify({ status: action }));
	  } catch (err) {
		enqueueSnackbar(err?.message || `Error occurred while updating autodial to ${action}`, { variant: "error"})
		
	  } finally {
		setShowLoader(false);
	  }
	}
  
	// update call record handlers
	async function handleSaveNotes(_currentOngoingContact = currentOngoingContact, _saveNotesValues = saveNotesValues) {
	  
	  if(autodialType === 'network' && !_currentOngoingContact?.call_id){
		console.log('no call id, returning from handleSaveNotes');
		return;
	  }
	  if (!showLoader) setShowLoader(true);
  
	  let tag_ids = _saveNotesValues?.tags?.map((t) => t?.id) || [];
	  try {
		//let xhr = new XMLHttpRequest();
		let url = API_BASE_URL + `/api/v1/core/update-call-record/`;
		const payload = {
			call_reason: _saveNotesValues?.callReason,
			call_outcome: _saveNotesValues?.callOutcome,
			notes: _saveNotesValues?.notes,
			tag_ids: tag_ids,
		  }
		if(autodialType === 'network'){
			payload['call_id'] = _currentOngoingContact?.call_id || ""
		}
		if(autodialType === 'browser'){
			const queryParam = new URLSearchParams({
				candidate_number: _currentOngoingContact?.contact_details?.phone_number,
			  });
			  url += "?" + queryParam; 
			payload['contact_list'] = Number(props?.contactListId)
		}
		// xhr.open("PATCH", url);
		// xhr.withCredentials = true;
		// xhr.setRequestHeader("Accept", "application/json");
		// xhr.setRequestHeader("Content-Type", "application/json");
		// xhr.onreadystatechange = function () {
		//   if (xhr.readyState === 4) {
		// 	try {
		// 	  const res = JSON.parse(xhr.response);
		// 	  if(xhr?.status === 500) throw new Error('Server error please try again or if issue persists contact FreJun')
		
		const res = await JService.patch(url, payload);
		if (!res?.success)
			throw new Error(
				res?.message || "Error occurred while trying to save notes"
			);
  
		setSaveNotesValues({
			callReason: "",
			callOutcome: "",
			notes: "",
		});
		setIsNotesBeingEdited(false);
  
			  //enqueueSnackbar("Click on the Resume button to continue the session", { variant: "success"})
			 
			} catch (_err) {
				enqueueSnackbar(_err?.message || `Error occurred while trying to update call record`, { variant: "error"})
			} finally {
			  setShowLoader(false);
			}
		  }
		//};
		// xhr.send(
		//   JSON.stringify({
		// 	call_id: currentOngoingContact?.call_id || "",
		// 	call_reason: saveNotesValues?.callReason,
		// 	call_outcome: saveNotesValues?.callOutcome,
		// 	notes: saveNotesValues?.notes,
		// 	tag_ids: tag_ids,
		//   })
		// );
	//   } catch (err) {
	// 	enqueueSnackbar(err?.message || `Error occurred while trying to save notes`, { variant: "error"})
	//   }
	//}
  
	async function handlePusherUpdate(pusherUpdate) {
	  // if status is finished end
	  // at the end of autodial
	  if (pusherUpdate?.status?.toLowerCase() === "finished") {
		setAutodialState('completed');
		console.log('removing listener')
		window.removeEventListener('beforeunload', handleWindowBeforeUnload);
		window.removeEventListener('visibilitychange', handleWindowBeforeUnload)
		return;
	  }
	  else if('contact_status' in pusherUpdate && pusherUpdate.contact_status === 'Call ended'){
		if(intervalId) clearInterval(intervalId);

		setInviteSession({isCallOngoing: false, isCallStarted: false})
		if(!timeToNextCall) setTimeToNextCall(data?.interval ?? 5);
			intervalId = setInterval(()=>setTimeToNextCall(prev => {
				if(prev <= 0){
					clearInterval(intervalId);
					return 0;
				}
				else return prev - 1;
		  }), 1000)
		return;
	  }
  
	  if(intervalId) clearInterval(intervalId);
	  setTimeToNextCall(0)
	  setInviteSession({isCallOngoing : true, isCallStarted: true});
	  setSaveNotes(true);
	  // when status is not finished
	  let {
		contacts_remaining,
		contacts_skipped,
		current_call_details,
		current_contact,
		status,
		contacts_completed,
		notes
	  } = pusherUpdate;
	  if(parseInt(current_contact) != 1){
	  	setSaveNotes(true);
	  }
	
	  let currentContact = current_call_details;
	  setCurrentOngoingContact({
		contact_details: {
			...currentContact.contact_details, 
			notes: notes,
		},
	});
	  if (!showLoader && parseInt(current_contact) == 1) setShowLoader(false);
	  setSaveNotesValues({
		callReason: "",
		callOutcome: "",
		notes: "",
	  });
	  // allcontacts from autodial response
	  // console.log('autodialResponse: ', responseRef.current)
	  let responseAllContacts = Object.values(
		responseRef.current?.data?.contacts
	  );
  
	  let arrayForQ = contacts_remaining?.map((cr) =>
		responseAllContacts?.find((c) => c?.id == parseInt(cr))
	  ); // does not give ongoing contact
  
	  let arrayForComplete = contacts_completed?.map((cc) =>
		responseAllContacts?.find((rc) => rc?.id == parseInt(cc))
	  ); // complete gives completed and ongoing contact
	  let arrayForSkip = contacts_skipped?.map((c) =>
		responseAllContacts?.find((ac) => ac?.id == c)
	  );
	  // console.log('arrayForQ: ', arrayForQ)
	  // console.log('arrayForComplete: ', arrayForComplete)
	  // console.log('arrayForSkip: ', arrayForSkip)
	  setInQ(arrayForQ);
	  setInComplete(arrayForComplete);
	  setInSkip(arrayForSkip);
	}
	
	useEffect(()=>{
		if(!saveNotes){
			return;
		}
		setSaveNotes(false);
		handleSaveNotes(currentOngoingContact, saveNotesValues);
	},[saveNotes, currentOngoingContact, saveNotesValues])

	useEffect(()=>{
		if(autodialType === 'browser' || autodialState !== 'completed') return;
		const completedContact = {
			id: currentOngoingContact?.contact_details?.candidate_id,
			first_name: currentOngoingContact?.contact_details?.name,
			phone_number: currentOngoingContact?.contact_details?.phone_number
		};
		setInComplete(prev => {
			let temp = [...prev];
			if(!temp.find(c => c.id == completedContact.id)){
				temp.push(completedContact);
			}
			return temp;
		})
	},[autodialState, currentOngoingContact])
  
	async function handleRESUMEDPusherUpdate(pusherUpdate, interval) {
	  // if status is finished end
	  // at the end of autodial
	  if (showLoader) setShowLoader(false);
	  if (pusherUpdate?.status?.toLowerCase() === "finished") {
		setAutodialState('completed');
		console.log('removing listener')
		window.removeEventListener('beforeunload', handleWindowBeforeUnload);
		window.removeEventListener('visibilitychange', handleWindowBeforeUnload)
		return;
	  }

	  else if('contact_status' in pusherUpdate && pusherUpdate.contact_status === 'Call ended'){
		if(intervalId) clearInterval(intervalId);

		setInviteSession({isCallOngoing: false, isCallStarted: false})
		if(!timeToNextCall) setTimeToNextCall(interval ?? 5);
			intervalId = setInterval(()=>setTimeToNextCall(prev => {
				if(prev <= 0){
					clearInterval(intervalId);
					return 0;
				}
				else return prev - 1;
		  }), 1000)
		return;
	  }
  
	  if(intervalId) clearInterval(intervalId);
	  setTimeToNextCall(0)
	  setInviteSession({isCallOngoing : true, isCallStarted: true});
	  setSaveNotes(true);
	  
  
	  // when status is not finished
	  let {
		contacts_remaining,
		contacts_skipped,
		current_call_details,
		current_contact,
		status,
		contacts_completed,
	  } = pusherUpdate;

	  if(parseInt(current_contact) != 1){
	  	setSaveNotes(true);
	  }
	  setSaveNotesValues({
		callReason: "",
		callOutcome: "",
		notes: "",
	  });
	  // console.log('allContacts: ', allContacts)
	  let currentContact = current_call_details;
	  setCurrentOngoingContact(currentContact);
	  if (!showLoader && parseInt(current_contact) == 1) setShowLoader(false);
  
	  // allcontacts from autodial response
	  // console.log('autodialResponse: ', responseRef.current)
	  let responseAllContacts = Object.values(
		responseRef.current?.data?.contacts
	  );
  
	  let arrayForQ = contacts_remaining?.map((cr) =>
		responseAllContacts?.find((c) => c?.id == parseInt(cr))
	  ); // does not give ongoing contact
  
	  let arrayForComplete = contacts_completed?.map((cc) =>
		responseAllContacts?.find((rc) => rc?.id == parseInt(cc))
	  ); // complete gives completed and ongoing contact
	  let arrayForSkip = contacts_skipped?.map((c) =>
		responseAllContacts?.find((ac) => ac?.id == c)
	  );
	  setInQ(arrayForQ);
	  setInComplete(arrayForComplete);
	  setInSkip(arrayForSkip);
	}
  
	// When edit of any field starts
	// autodial is paused and resumed on save notes
	const [isNotesBeingEdited, setIsNotesBeingEdited] = useState(false);
	function handleSaveNotesValuesChange(e, saveNotesItem) {
	  let value = e.target.value;
	  // console.log('from from on change for : ', saveNotesItem, ' is: ', value)
	  if (saveNotesItem === "callReason") {
		setIsNotesBeingEdited(true);
		setSaveNotesValues((prevState) => ({ ...prevState, callReason: value }));
	  } else if (saveNotesItem === "callOutcome") {
		setIsNotesBeingEdited(true);
		setSaveNotesValues((prevState) => ({ ...prevState, callOutcome: value }));
	  } else if (saveNotesItem === "notes") {
		setIsNotesBeingEdited(true);
		setSaveNotesValues((prevState) => ({ ...prevState, notes: value }));
	  }
	}
  
	// useEffect(() => {
	 
	//   if (
	// 	isNotesBeingEdited &&
	// 	autodialActionButton?.buttonText?.toLowerCase() !== "resume"
	//   ) {
	// 	// pause the autodial because notes are being edited
	// 	handleAutodialAction("Paused");
	//   }
	// }, [isNotesBeingEdited]);
  
	// skip complete
	function handleSkipContact(e, contactId) {
	  if (showLoader) setShowLoader(true);

	  let responseAllContacts = Object.values(
		responseRef.current?.data?.contacts ?? {}
	  );

	  if(autodialType === 'browser'){
		updateBBAutodialContactStatus({contact: contactId, contact_status: 'Skipped'});

		setInQ((prevState) => {
			let temp =[...prevState]
			temp = temp?.filter((p) => p?.id != parseInt(contactId))
			return temp;
		  });
		setInSkip((prevState) => {
			let temp = [...prevState];
			temp = [...prevState, ...allContacts?.filter((r) => r?.id == parseInt(contactId))]
			return temp;
	 	 });
		return;
	  }
  
	  JService.post(
		API_BASE_URL +
		  `/api/v1/core/skip-contact/${responseRef.current?.data?.id}/`,
		JSON.stringify({ skipped_contact: contactId?.toString() })
	  )
		.then((res) => {
		  if (!res?.success)
			throw new Error(res?.message || `Error while skipping contact`);
  
		  // success
		  enqueueSnackbar(res?.message || `Skipped contact`, { variant: "success"})
  
		  setInQ((prevState) => [
			...prevState?.filter((p) => p?.id != parseInt(contactId)),
		  ]);
		  setInSkip((prevState) => [
			...prevState,
			...responseAllContacts?.filter((r) => r?.id == parseInt(contactId)),
		  ]);
  
		})
		.catch((err) => {
		  enqueueSnackbar(err?.message || `Error occurred while skipping contact`, { variant: "error"})
		})
		.finally(() => setShowLoader(false));
	}

	useEffect(()=>{
		if(!globalAutodialState?.maximized){
			console.log('removing listener from useEffect');
			window.removeEventListener('beforeunload', handleWindowBeforeUnload);
			setCurrentOngoingContact([]);
			setInQ([]);
			setInComplete([]);
			setInSkip([]);
			setSaveNotesValues({callReason: '', callOutcome: '', tags: []});
			setAllContacts([]);
			setTimeToNextCall(0);
			setIsMute(false);
			setIsHold(false);
			setIsDialpadVisible(false);
			setAutodialState('');
			dev_autodialID.current = -1;
		}
		else if (globalAutodialState.maximized){
			console.log('adding listener in useEffect')
			window.addEventListener('beforeunload', handleWindowBeforeUnload);
		}

		return () => window.removeEventListener('beforeunload', handleWindowBeforeUnload);
	},[globalAutodialState?.maximized]);


	   // const seenNames = {};
		// const filteredInComplete = inComplete.filter(q => {
		// const name = q?.first_name;
		// if (seenNames[name]) {
		// 	return false; 
		// }
		// seenNames[name] = true; 
		// return true; 
		// });
	  
	return (
	  <div>
		<Modal
		  disableEscapeKeyDown={true}
		  open={globalAutodialState?.maximized}
		// open={true}
		  aria-labelledby="transition-modal-title"
		  aria-describedby="transition-modal-description"
		  closeAfterTransition
		  sx={{
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		  }}
		>
		  {showLoader ? (
			<AnimationPage />
		  ) : (
			<Box
			  // foreground
			  sx={{
				background: colors.ic_white,
				minHeight: "610px",
				// minWidth: calcMinWidth(850, 900, 750, 1000),
				// minWidth: (0.7 * window.innerWidth)?.toFixed(0) + "px",
				width: `min(826px, ${(0.7 * window.innerWidth)?.toFixed(0) + "px"})`,
				borderRadius: "10px 10px 0px 0px",
				maxHeight: 0.90 * window.innerHeight,
				overflow: "hidden",
				display: "flex",
				flexDirection: "column",
				justifyContent: "stretch",
				borderRadius: '6px',
			  }}
			>
			  {/* header */}
			  <Box
				sx={{
				  borderRadius: "10px 10px 0px 0px",
				  height: "56px",
				  display: "flex",
				  flexDirection: "row",
				  alignItems: "center",
				  paddingLeft: "24px",
				  paddingRight: "24px",
				  borderBottom: `0.75px solid ${colors.nc_gray_300}`,
				  background: colors.nc_gray_100,
				  justifyContent: "space-between",
				}}
			  >
				<span className="t7 semibold-font nc-gray-900">
				  Autodial session
				</span>
				<span
				  title="Close to cancel autodial session"
				  onClick={handleCloseAutodialByIcon}
				  style={{
					cursor: "pointer",
					display: "inline-flex",
					flexDirection: "row",
					alignItems: "flex-end",
				  }}
				>
				  {modalCloseIcon()}
				</span>
			  </Box>
  
			  {/* body */}
			  <Box
				sx={{
				  display: "flex",
				  flexDirection: "column",
				  alignItems: "stretch",
				  justifyContent: "stretch",
				  minHeight: "560px",
  
				  // minHeight: 'inherit',
				  // maxHeight: 'inherit',
				  // overflow: 'hidden',
				}}
			  >
				{/* top */}
				<Box
				  sx={{
					flex: 57,
					height: "100%",
  
					display: "flex",
					flexDirection: "row",
					alignItems: "stretch",
					minHeight: "436px",
					// overflow: 'hidden',
					// maxHeight: 'inherit',
				  }}
				>
				  {/* left */}
				  <Box
					className="gautodial-left-container-forscroll"
					sx={{
					  width: "100%",
					//   flex: 1,
					  flex: 0.3946,
					  height: "100%",
					  minHeight: "inherit",
					  minHeight: "530px",
					  maxHeight: "530px",
					  overflow: "scroll",
					  // // height: '100%',
					  // // maxHeight: `calc(${window.innerHeight}px - 80px)`,
					  // maxHeight: `calc(${0.75 * window.innerHeight}px - 80px - 56px)`,
					}}
				  >
					{/* in queue */}
					<Box
					  sx={
						{
						  // minHeight: '320px',
						}
					  }
					>
					  {/* title */}
					  <Box
						sx={{
						  display: "flex",
						  flexDirection: "row",
						  alignItems: "center",
						  justifyContent: "center",
						  height: "52px",
						  borderBottom: `1px solid ${colors.nc_gray_300}`,
						  background: colors.nc_gray_200,
						}}
					  >
						<span
						  style={{ userSelect: "none", cursor: "default" }}
						  className="t7 medium-font nc-gray-900"
						>
						  In Queue({((autodialState === 'completed' && inQ?.length === 0) ? 0: inQ?.length + 1) || 0})
						</span>
					  </Box>
  
					  {/* currentContact */}
					  {autodialState !== 'completed' && <Box
						sx={{
						  display: "flex",
						  flexDirection: "row",
						  alignItems: "center",
						  height:'81px',
						  justifyContent: "flex-start",
						  padding:'12px 24px',
						  background: colors.ic_green_100,
						  borderBottom: `1px solid ${colors.nc_gray_300}`,
						}}
					  >
						{inviteSession?.isCallOngoing ? <img
						  src={ongoingContactIcon}
						  alt="current ongoing contact"
						/>  : playIcon()
						}
  
						<Box
						  sx={{
							display: "flex",
							flexDirection: "column",
							marginLeft: "16px",
						  }}
						>
						  <div
							  style={{
								marginBottom: "1px",
								whiteSpace: "nowrap",
								overflow: "hidden",
								textOverflow: "ellipsis",
								maxWidth: "200px" 
							  }}
							className="t7 regular-font nc-gray-900"
						  >
							<CustomTooltip tooltipTittle={currentOngoingContact?.contact_details?.name} tooltipText={currentOngoingContact?.contact_details?.name} />
						  </div>
						  {
						  maskNumbers ? null : <div className="t7 regular-font nc-gray-600">
							{currentOngoingContact?.contact_details?.phone_number}
						  </div>
						  }
						</Box>
					  </Box>}
  
					  {/* @add-update map data */}
					  {inQ?.map((q, index) => (
						<Box key={q?.id}>
						  <Box
							 sx={{
								display: 'flex',
								flexDirection: 'row',
								height:'81px',
								alignItems: 'center',
								justifyContent: 'space-between',
								padding:'12px 24px',
								background: colors.ic_white,
								borderBottom: `1px solid ${colors.nc_gray_300}`,
								position: 'relative',
								'&:hover .skip-button': {
								  opacity: 1, 
								},
							  }}
							>
							<Box
							  sx={{
								// border: '1px solid black',
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
							  }}
							>
							  <div
								style={{
								  marginRight: "16px",
								  height: "20px",
								  width: "20px",
								}}
							  >
								{index + 1}
							  </div>
  
							  <Box
								sx={{
								  display: "flex",
								  flexDirection: "column",
								  alignItems: "flex-start",
								}}
							  >
								<CustomTooltip tooltipTittle={q?.first_name} tooltipText={q?.first_name} />
								{
								maskNumbers ? null : <div className="t7 regular-font nc-gray-600">
								  {q?.phone_number}
								</div>
								}
							  </Box>
							</Box>
  
							{/* skip button */}
							<Box
							  sx={{
								height: "56px",
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-end",
								padding: "10px 0px",
							  }}
							>
							  <Button
							      className="skip-button"

								styleOverride={{
								  height: "36px",
								  width: "65px",
								  border: `1px solid ${colors.nc_gray_300}`,
								  opacity: 0,
								  fontSize:'14px'
								}}
								icon={{ startIcon: null, endIcon: null }}
								disabled={false}
								hierarchy="white"
								variant="contained"
								buttonText="Skip"
								isLoading={false}
								onClick={(e) => handleSkipContact(e, q?.id)}
							  />
							</Box>
						  </Box>
						</Box>
					  ))}
					</Box>
					{/* -- in queue end */}
  
					{/* completed */}
					<Box
					  sx={
						{
						  // minHeight: '320px',
						}
					  }
					>
					  {/* title */}
					  <Box
						sx={{
						  display: "flex",
						  flexDirection: "row",
						  alignItems: "center",
						  justifyContent: "center",
						  height: "52px",
						  borderBottom: `1px solid ${colors.nc_gray_300}`,
						  background: colors.nc_gray_200,
						}}
					  >
						<span className="t7 medium-font nc-gray-900">
						  Completed({inComplete?.length || 0})
						</span>
					  </Box>
  
					  {/* @add-update map data */}
					  {/* {filteredInComplete && Array.isArray(filteredInComplete) && filteredInComplete.map((q, index) => ( */}
					  {inComplete && Array.isArray(inComplete) && inComplete.map((q, index) => (
						<Box key={q?.id}>
						  <Box
							sx={{
							  display: "flex",
							  flexDirection: "row",
							  height:'81px',
							  alignItems: "center",
							  justifyContent: "flex-start",
							  padding: "12px 24px",
							  background: colors.ic_white,
							  borderBottom: `1px solid ${colors.nc_gray_300}`,
							}}
						  >
							<div
							  style={{
								marginRight: "16px",
								height: "20px",
								width: "20px",
							  }}
							>
							  {currentOngoingContact?.contact_details?.candidate_id === q?.id ? playIcon() : index + 1}
							</div>
  
							<Box
							  sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "flex-start",
							  }}
							>
							  <div
								style={{
									marginBottom: "1px",
									whiteSpace: "nowrap",
									overflow: "hidden",
									textOverflow: "ellipsis",
									maxWidth: "200px" 
								  }}
								className="t7 regular-font nc-gray-900"
							  >
								<CustomTooltip tooltipText={q?.first_name} tooltipTittle={q?.first_name} />
							  </div>
							  {maskNumbers ? null : <div className="t7 regular-font nc-gray-600">
								{q?.phone_number}
							  </div>}
							</Box>
						  </Box>
						</Box>
					  ))}
					</Box>
  
					{/* skipped */}
					<Box
					  sx={
						{
						  // minHeight: '320px',
						}
					  }
					>
					  {/* title */}
					  <Box
						sx={{
						  display: "flex",
						  flexDirection: "row",
						  alignItems: "center",
						  justifyContent: "center",
						  height: "52px",
						  borderBottom: `1px solid ${colors.nc_gray_300}`,
						  background: colors.nc_gray_200,
						}}
					  >
						<span className="t7 medium-font nc-gray-900">
						  Skipped({inSkip?.length || 0})
						</span>
					  </Box>
  
					  {/* @add-update map data */}
					  {inSkip?.map((q, index) => (
						<Box key={q?.id}>
						  <Box
							sx={{
							  minHeight: '54px',
							  maxHeight: "80px",
							  display: "flex",
							  height:'81px',
							  flexDirection: "row",
							  alignItems: "center",
							  justifyContent: "flex-start",
							  padding: "12px 24px",
							  background: colors.ic_white,
							  borderBottom: `1px solid ${colors.nc_gray_300}`,
							}}
						  >
							<div
							  style={{
								marginRight: "16px",
								height: "20px",
								width: "20px",
							  }}
							>
							  {index + 1}
							</div>
  
							<Box
							  sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "flex-start",
							  }}
							>
							  <div
								style={{ marginBottom: "1px" }}
								className="t7 regular-font nc-gray-900"
							  >
								<CustomTooltip tooltipText={q?.first_name} tooltipTittle={q?.first_name}/>
							  </div>
							  {
							  maskNumbers ? null : <div className="t7 regular-font nc-gray-600">
								{q?.phone_number}
							  </div>
							  }
							</Box>
						  </Box>
						</Box>
					  ))}
					</Box>
				  </Box>
  
				  {/* right */}
				  <Box
					sx={{
					  width: "100%",
					//   flex: 2,
					  flex: 0.6053,
					  // border: '1px dotted purple',
					}}
				  >
					{/* current call details */}
					{/* avatar, username and phoneNumber  */}
					<Box
					  sx={{
						borderBottom: `1px solid ${colors.nc_gray_300}`,
						background: colors.ic_white,
						minHeight: "96px",
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						paddingLeft: "24px",
						paddingRight: "24px",
					  }}
					>
					  <Box
						sx={{
						  borderRadius: "50%",
						  border: `1.5px solid ${colors.nc_gray_300}`,
						  width: "52px",
						  height: "52px",
						  background: colors.nc_gray_100,
						  display: "flex",
						  flexDirection: "row",
						  alignItems: "center",
						  justifyContent: "center",
						}}
					  >
						<span className="t3 medium-font nc-gray-700">
						  {currentOngoingContact?.contact_details?.name?.slice(
							0,
							1
						  ) || "-"}
						</span>
					  </Box>
  
					  {/* name and phone number */}
					  <Box
						sx={{
						  display: "flex",
						  flexDirection: "column",
						  // border: '1px solid red',
						  alignItems: "flex-start",
						  marginLeft: "12px",
						}}
					  >
						<CustomTooltip tooltipText={currentOngoingContact?.contact_details?.name}  tooltipTittle={currentOngoingContact?.contact_details?.name} />
						{maskNumbers ? null : <div
						  style={{ textAlign: "left" }}
						  className="t7 nc-gray-600 regular-font"
						>
						  {currentOngoingContact?.contact_details?.phone_number}
						</div>}
					  </Box>
					</Box>
					
  
					{/* call reason and call outcome container */}
					<Box
					  sx={{
						display: "flex",
						flexDirection: "column",
						// padding: "20px 16px",
						// gap: '16px',
						// borderBottom: `1px solid ${colors.nc_gray_300}`
					  }}
					>

								{currentOngoingContact?.contact_details?.notes && ( 
								<Box 
									sx={{
									display: 'flex',
									padding: "20px",
									borderBottom: `1px solid ${colors.nc_gray_300}`
									}}
								>
									<div className="contact-notes">
									<label
										style={{ marginBottom: "4px" }}
										className="t7 medium-font nc-gray-900"
									>
										Details
									</label>
									</div>
									<div style={{width:'100%',marginLeft:"60px",maxHeight: "4.5em",overflowY: "auto"}}>
									<p>{currentOngoingContact?.contact_details?.notes}
									</p>
									</div>
								</Box>
								)}

					 <Box 
					
					  sx={{
						display: "flex",
						flexDirection: "column",
						padding: "20px 24px",
						gap: '16px',
						borderBottom: `1px solid ${colors.nc_gray_300}`
					  }}>
						
					
					  {/* tags */}
					  <Autocomplete
						disabled={
						  !allTags ||
						  !Array.isArray(allTags) ||
						  allTags?.length === 0
						}
						onChange={(e, value, reason, details) => {
						  if (!value) return;
						  if (!isNotesBeingEdited) setIsNotesBeingEdited(true);
						  setSaveNotesValues((prevState) => ({
							...prevState,
							tags: value,
						  }));
						}}
						disableClearable
						filterSelectedOptions
						multiple={true}
						options={allTags}
						limitTags={2}
						// onOpen={e => selectedRowTagRef.current = params?.row?.logsTags}
						defaultValue={[]}
						renderTags={(valueArray, getTagsProps, state) => {
						  return saveNotesValues?.tags?.map((tag, index) =>
							index > 1 ? (
							  index === 2 ? (
								"..."
							  ) : null
							) : (
							  // index not greater than one
							  <Chip
								key={index}
								label={tag?.name}
								deleteIcon={
								  <img
									src={chipDeleteIcon}
									alt="Delete tag icon"
								  />
								}
								// onMouseDown={e => e.stopPropagation()}
								onDelete={(e) => {
								  e.stopPropagation();
								  setSaveNotesValues((prevState) => ({
									...prevState,
									tags: prevState?.tags?.filter(
									  (pt) => tag?.id?.toString() != pt?.id
									),
								  }));
								}}
								sx={{
								  cursor: "pointer",
								  borderRadius: "4px",
								  border: "1px solid #E1E6E2",
								  marginRight: "8px",
								  height: "26px",
								  background: colors.ic_white,
								  ...styles.b2,
								  ...styles.regular_font,
								  color: colors.nc_gray_900,
								  lineHeight: "auto",
								}}
							  />
							)
						  );
						}}
						getOptionLabel={(option) => option?.name}
						// dropdown
						renderOption={(props, option, { selected }) => {
						  let isSelected = selected;
						  // || tagIdArray?.includes(option?.id)
						  return (
							<li
							  style={{
								backgroundColor: isSelected
								  ? colors.ic_green_100
								  : colors.ic_white,
								pointerEvents: isSelected ? "none" : "all",
							  }}
							  key={option?.id}
							  {...props}
							>
							  <Chip
								label={option?.name}
								onMouseDown={(e) => null}
								sx={{
								  cursor: "pointer",
								  borderRadius: "4px",
								  border: "1px solid #E1E6E2",
								  marginRight: "8px",
								  height: "26px",
								  background: colors.ic_white,
								  ...styles.b2,
								  ...styles.regular_font,
								  color: colors.nc_gray_900,
								  lineHeight: "auto",
								  pointerEvents: isSelected ? "none" : "all",
								}}
							  />
							</li>
						  );
						}}
						sx={{
						  ...styles.b2,
						  ...styles.regular_font,
						  color: colors.nc_gray_900,
						  width: "100%",
						  height: "40px",
						//   marginBottom: "24px",
						  "& .MuiFormControl-root": {
							height: "100%",
						  },
						  "& .MuiInputBase-root": {
							height: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-start",
							flexWrap: "nowrap",
							alignItems: "center",
							maxWidth: "unset",
							".Mui-focused:": {
							  border: `1px solid ${colors.ic_green_500}`,
							  boxShadow: "0px 0px 0px 4px #F0FCF4",
							},
						  },
						  "& .MuiOutlinedInput-notchedOutline": {
							border: `1px solid ${colors.nc_gray_300}`,
						  },
						  "&:hover .MuiOutlinedInput-notchedOutline": {
							border: `1px solid ${colors.nc_gray_300}`,
						  },
						  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
							border: `1px solid ${colors.ic_green_300}`,
							boxShadow: "0px 0px 0px 4px #F0FCF4",
						  },
  
						  // '& .MuiInputBase-root-MuiOutlinedInput-root': {
						  // 	border: `1px solid ${colors.nc_gray_300}`,
						  // },
						  // '&:hover .MuiInputBase-root-MuiOutlinedInput-root': {
						  // 	border: `1px solid ${colors.nc_gray_300}`,
						  // },
						  // '& .MuiInputBase-root-MuiOutlinedInput-root': {
						  // 	border: `1px solid ${colors.ic_green_300}`,
						  // 	boxShadow: '0px 0px 0px 4px #F0FCF4',
						  // },
						}}
						renderInput={(_params) => (
						  // no tags for call record
						  // isUpdatingTags ? <LinearProgress color="success" /> :
						  //  <Button isLoading={true} disabled={true} variant='contained' hierarchy='white' buttonText='loading...' styleOverride={{maxWidth: '360px', border: 'none', outline: 'none'}}/> :
						  <TextField
							onMouseDown={(e) => e.stopPropagation()}
							onClick={(e) => e.stopPropagation()}
							// title={allTags?.length === 0 ? 'Add some tags in the settings page' : `${ params?.row?.logsTags?.length || selectedRowTagRef.current?.length || 0} tags applied to this call record`}
							{..._params}
							label={""}
							placeholder={
							  saveNotesValues?.tags?.length ? "" : "+ Add Tags"
							}
							maxRows={1}
							multiline={false}
							sx={{
								"& .MuiInputBase-input::placeholder": {
									fontSize: "14px",
								  },
							  maxWidth: "unset",
							  color: colors.nc_gray_900,
							  ...styles.b2,
							  ...styles.regular_font,
							}}
							// disabled={isUpdatingTags}
						  />
						)}
					  />
  
					  {/* call reason */}
					  {/* <Box
						sx={{
						  display: "flex",
						  flexDirection: "column",
						  gap:'16px',
						  borderBottom: `1px solid ${colors.nc_gray_300}`,
						  padding: '20px 16px'
						}}
					  > */}
						<Box
						  sx={{
							display: "flex",
							flexDirection: "row",
							alignItems: 'center',
							justifyContent:'space-between',
							flex: 1
						  }}
						>
						  <label
							style={{ marginBottom: "4px" }}
							className="t7 medium-font nc-gray-900"
						  >
							Call stage
						  </label>
						  <Select
							value={saveNotesValues?.callReason}
							sx={{
							  height: "40px",
							  width: '340px',
							  ...styles.t6,
							  ...styles.regular_font,
							  color: colors.nc_gray_900,
							  borderRadius: "4px",
							  "& .MuiOutlinedInput-notchedOutline": {
								border: `1px solid ${colors.nc_gray_300}`,
							  },
							  "&:hover .MuiOutlinedInput-notchedOutline": {
								border: `1px solid ${colors.nc_gray_300}`,
							  },
							  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
								border: `1px solid ${colors.ic_green_300}`,
								boxShadow: "0px 0px 0px 4px #F0FCF4",
							  },
							}}
							onChange={(e) =>
							  handleSaveNotesValuesChange(e, "callReason")
							}
							native={false}
							renderValue={(selected) => {
							  // console.log(selected, '***')
							  if (!selected) return <span>Select stage</span>;
							  else return <span style={{fontSize:'14px'}}>{selected}</span>;
							}}
						  >
							{orgDetails?.call_reasons?.map((co, index) => (
							  <MenuItem key={index} value={co}>
								{co}
							  </MenuItem>
							))}
							<MenuItem value="">Select stage</MenuItem>
						  </Select>
						</Box>
  
						<Box
						  sx={{
							display: "flex",
							flexDirection: "row",
							alignItems: 'center',
							justifyContent:'space-between',
							flex: 1,
						  }}
						>
						  <label
							style={{ marginBottom: "4px" }}
							className="t7 medium-font nc-gray-900"
						  >
							Call outcome
						  </label>
						  <Select
							value={saveNotesValues?.callOutcome}
							onChange={(e) =>
							  handleSaveNotesValuesChange(e, "callOutcome")
							}
							sx={{
							  height: "40px",
							  width: '340px',
							  ...styles.t7,
							  ...styles.regular_font,
							  color: colors.nc_gray_900,
							  borderRadius: "4px",
							  "& .MuiOutlinedInput-notchedOutline": {
								border: `1px solid ${colors.nc_gray_300}`,
							  },
							  "&:hover .MuiOutlinedInput-notchedOutline": {
								border: `1px solid ${colors.nc_gray_300}`,
							  },
							  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
								border: `1px solid ${colors.ic_green_300}`,
								boxShadow: "0px 0px 0px 4px #F0FCF4",
							  },
							}}
							native={false}
							renderValue={(selected) => {
							  // console.log(selected, '***')
							  if (!selected) return <span>Select outcome</span>;
							  else return <span>{selected}</span>;
							}}
						  >
							{orgDetails?.call_outcomes?.map((co, index) => (
							  <MenuItem key={index} value={co}>
								{co}
							  </MenuItem>
							))}
							<MenuItem value="">Select outcome</MenuItem>
						  </Select>
						</Box>
						</Box>
  
					  {/* call outcome */}
					  <Box
						sx={{
						//   marginTop: "24px",
						  padding: '20px 24px',
						  display: "flex",
						  flexDirection: "column",
						}}
					  >
						
						<textarea
						  onChange={(e) =>
							handleSaveNotesValuesChange(e, "notes")
						  }
						  value={saveNotesValues?.notes}
						  placeholder="Enter notes"
						  style={{
							padding: "8px 12px",
							borderRadius: "4px",
							border: `1px solid ${colors.nc_gray_300}`,
							minHeight: "100px",
							...styles.t7,
							...styles.regular_font,
							color: colors.nc_gray_900,
						  }}
						></textarea>
					  </Box>
  
					  {/* save button container */}
					  
					</Box>
  
					{/* @add-update attached notification / alert */}
					{/* <Snackbar
									  open={open}
									  role='alert'
									  onClose={() => setOpen(false)}
									  autoHideDuration={2000}
								  >
									  <Box
										  sx={{
											  border: '1px solid purple',
											  // background: autodialMessageObject?.data?.type === 'success' ? colors.ic_green_100 : autodialMessageObject?.data?.type === 'error' ? colors.ic_green_100 : colors.nc_gray_200,
											  // color: autodialMessageObject?.data?.type === 'success' ? colors.ic_green_400 : autodialMessageObject?.data?.type === 'error' ? colors.ic_green_400 : colors.nc_gray_900,
											  background: colors.ic_green_100,
											  color: colors.ic_green_400,
										  }}
									  >
										  
										  'Error / Success'
									  </Box>
								  </Snackbar> */}
				  </Box>
				</Box>
  
				{/* bottom */}
				{(!inviteSession.isCallOngoing) &&
				 <Box sx={{
					display:'flex',
					alignItems:'center',
					justifyContent: autodialState === 'completed' ? 'center':'space-between',
					height:'46px',
					backgroundColor: colors.ic_green_100,
					padding:'12px 24px',
					zIndex: 10
				}}>
					<Box>
						{autodialActionButton?.buttonText === 'Resume' && autodialState !== 'completed' && 
						<p className="t7 regular-font">Click ‘Resume’ to continue Autodial session.</p>
						}

						{autodialActionButton?.buttonText === 'Pause' && timeToNextCall > 0 && autodialState !== 'completed' &&
						<p className="t7 regular-font">Initiating next call in {" "}
							<span className="t7 medium-font">{timeToNextCall > 0 ? timeToNextCall : 0}</span>
						</p>
						}
						{autodialState === 'completed' &&
						<p className="t7 regular-font" style={{display: 'flex', gap: '5px'}}>
							<span>{completedIcon()}</span>
							Autodial session completed.
						</p>
						}
					</Box>
					<Box>
						{autodialState !== 'completed' && 
						<Button
							styleOverride={{color: colors.ic_green_400, fontSize: '14px', width: '41px'}}
							icon={{ startIcon: null, endIcon: null }}
							disabled={autodialActionButton?.disabled || inviteSession?.isCallStarted}
							hierarchy="white"
							variant="contained"
							buttonText={autodialActionButton?.buttonText}
							isLoading={autodialActionButton?.isLoading}
							onClick={handleAutodialActionButtonClick}
						/>}	
						{autodialActionButton?.buttonText === 'Pause' && 
						 autodialType === 'browser' && autodialState !== 'completed' &&
						<Button
							icon={{}}
							styleOverride={{color: colors.ic_green_400, fontSize: '14px', width:'58px', padding: 0}}
							disabled={inviteSession?.isCallStarted}
							hierarchy='white'
							variant='contained'
							buttonText= 'Call now'
							isLoading={false}
							onClick={handleCallNow}
						/>}
					</Box>
				</Box>}
				<Box
				  sx={{
					flex: 1,
					// height: '100%',
					minHeight: "70px",
					// border: '1px solid red',
					/* Neutral Colors/Grey - 300 */
					borderTop: "0.75px solid #E1E6E2",
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "flex-end",
					// paddingRight: "24px",
					position: "relative",
					zIndex: 10,
				  }}
				>
				{
					autodialType === 'browser' && inviteSession?.isCallOngoing && isDialpadVisible &&
					<Box sx={{
						display:'flex',
						flexDirection: 'column',
						width: '264px',
						height: '364px',
						position:'absolute',
						bottom: '70px',
						right:'24px',
						backgroundColor: '#fff',
						zIndex: '1303',
						border: `1px solid ${colors.nc_gray_300}`,
						boxShadow: '0px 0px 10px 0px #5F666114',
						borderRadius: '6px 6px 0 0'
					}}>
						<Dialpad btnStyle={{margin: '6px'}} isHeaderVisible = {false} session={inviteSession?.session} handleBack={()=>null}/>
					</Box>
				}
				 <Box
						sx={{
						  height: "56px",
						  // border: '1px solid green',
						  display: "flex",
						  flexDirection: "row",
						  justifyContent: "flex-end",
						  padding: "8px 16px",
						  gap: '10px',
						  borderRadius: "0px 0px 6px 6px",
						}}
					  >
					
				  {autodialType === 'browser' && inviteSession?.isCallOngoing && <>
				    <Button
                      onClick={handleMute}
                      buttonText={muteCallIcon(
                        inviteSession?.isCallOngoing
                          ? isMute
                            ? colors.ic_green_300
                            : colors.nc_gray_900
                          : colors.nc_gray_400
                      )}
                      icon={{
                        startIcon: null,
                        endIcon: null,
                      }}
                      hierarchy="white"
                      variant="contained"
                      isLoading={false}
                      disabled={!inviteSession?.isCallOngoing}
                      styleOverride={{
                        width: "80px",
                        height: "36px",
                        border: `1px solid ${
                          isMute ? colors.ic_green_300 : colors.nc_gray_300
                        }`,
                        backgroundColor: isMute
                          ? colors.ic_green_100
                          : colors.ic_white,
                        "&:hover": {
                          border: inviteSession?.isCallOngoing
                            ? !isMute
                              ? "1px solid #e1e5e2"
                              : "1px solid #34cb65"
                            : "",
                        },
                      }}
                    />
                    <Button
                      onClick={handleHold}
                      buttonText={holdCallIcon(
                        inviteSession?.isCallOngoing
                          ? isHold
                            ? colors.ic_green_300
                            : colors.nc_gray_900
                          : colors.nc_gray_400
                      )}
                      icon={{
                        startIcon: null,
                        endIcon: null,
                      }}
                      hierarchy="white"
                      variant="contained"
                      isLoading={false}
                      disabled={!inviteSession?.isCallOngoing}
                      styleOverride={{
                        width: "80px",
                        height: "36px",
                        border: `1px solid ${colors.nc_gray_300}`,
                        border: `1px solid ${
                          isHold ? colors.ic_green_300 : colors.nc_gray_300
                        }`,
                        backgroundColor: isHold
                          ? colors.ic_green_100
                          : colors.ic_white,
                        "&:hover": {
                          border: inviteSession?.isCallOngoing
                            ? !isHold
                              ? "1px solid #e1e5e2"
                              : "1px solid #34cb65"
                            : "",
                        },
                      }}
                    />
                    <Button
                      onClick={handleDialpad}
                      buttonText={topNavDialPad(
                        inviteSession?.isCallOngoing ? colors.nc_gray_900 : colors.nc_gray_400
                      )}
                      icon={{
                        startIcon: null,
                        endIcon: null,
                      }}
                      hierarchy="white"
                      variant="contained"
                      isLoading={false}
                      disabled={!inviteSession?.isCallOngoing || isMute || isHold}
                      styleOverride={{
                        width: "80px",
                        height: "36px",
                        border: `1px solid ${colors.nc_gray_300}`,
                        "&:hover": {
                          border: inviteSession?.isCallOngoing ? "1px solid #e1e5e2" : "",
                        },
                      }}
                    />
						<Button styleOverride={{width: "80px", height: "36px"}} hierarchy = 'red' variant = 'contained' isLoading={false} disabled={!inviteSession?.isCallOngoing} icon={{}} buttonText={endCallIcon()} onClick={endCall}/>
					</>}
					{(autodialType === 'network' || !inviteSession?.isCallOngoing) && 
					<Button
						  styleOverride={{
							height: "36px",
							fontSize:'14px',
							border: `1px solid ${colors.nc_gray_300}`,
						  }}
						  icon={{ startIcon: null, endIcon: null }}
						  disabled={false}
						  hierarchy="white"
						  variant="contained"
						  buttonText="End autodial"
						  isLoading={false}
						  onClick={handleCloseAutodialByIcon}
						/>}
				 				
					  </Box>
				</Box>
			  </Box>
			</Box>
		  )}
  
		</Modal>
	  </div>
	);
  }
  