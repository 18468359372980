import './signupScreen3Styles.css'

import vectorArt from '../../assets/signup-screen3.svg'
import dotsBottom from '../../assets/login-dots-bottom.svg'
import dotsTop from '../../assets/login-dots-top.svg'
import logo from '../../assets/top-nav-logo.svg'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
// TODO: form validation and button disabling

import AnimationPage from '../shared/animation-page/AnimationPage'
import colors from '../design/colors'
import { MenuItem, Select } from '@mui/material'
import Button from '../design/Button'
import { signupScreen3ButtonBackIcon } from '../../utility-functions/assetLoader'
import { JService } from '../api-service/ApiService'
import { enqueueSnackbar } from 'notistack'

const phoneRegExp = new RegExp(/^(([+][\d]{1,4})?([\d]{10,12}))/gm)

const API_BASE_URL = process.env.REACT_APP_BETA
export default function SignupScreen3() {
    const navigate = useNavigate()

    const [virtualNumbersStatus, setVirtualNumbersStatus] = useState([])
    const [virtualNumberLocation, setVirtualNumberLocation] = useState('')
    const [virtualNumberLocations, setVirtualNumberLocations] = useState([])
    const [phoneNumber, setPhoneNumber] = useState('')

    const [showLoader, setShowLoader] = useState(false)
    const [userPhoneNumberSet, setUserPhoneNumberSet] = useState(false)

    const handleMobileNumberChange = e => setPhoneNumber(e.target.value || '')

	//@change throw error in runSetVirtualNumberLocation function, catch the error is try catch around function call
    const handleSubmit = e => {
        e.preventDefault()
        
        // adding user phone number
        if(userPhoneNumberSet) {
            runSetVirtualNumberLocation()
        }
        JService.patch('/api/v1/auth/update-userphonenumber/', JSON.stringify({number: phoneNumber}))
        .then(res => {
            if(!res?.success) throw new Error(res?.message || 'Failed to add user phone number')
            setUserPhoneNumberSet(true) 
			if(phoneNumber?.length)runSetVirtualNumberLocation()
        })
        .catch(err => enqueueSnackbar(err?.message, { variant: "error"}))
		// .finally(()=> setPhoneNumber(''))
    }

    // navigation control in response
    function runSetVirtualNumberLocation() {
        if(!virtualNumberLocation) {
			enqueueSnackbar('Please choose a valid location', { variant: "error"})
            return
        }

        JService.post(API_BASE_URL + '/api/v1/auth/add-virtual-number/', JSON.stringify({location: virtualNumberLocation}))
        .then(res => {
            if(!res?.success) throw new Error(res?.message || 'Failed to add virtual number')
            navigate('/signup/company-details')
        })
		.catch(err => enqueueSnackbar(err?.message, { variant: "error"}))
    }

	const handleGoBack = e => navigate('/signup')	

    return(
		<div className='signup-screen3-container color-2d'>
			<div className='signup-art'>
			<a href="https://frejun.com/" target="_blank" rel="noopener noreferrer"> <img src={logo} alt="Logo" /></a>
				<img src={ dotsTop }/>
				<img src={ vectorArt }/>
				<img src={ dotsBottom }/>
			</div>
			{ showLoader ? <AnimationPage /> : <div className='signup-screen3-form'>
				<p className='title color-green'>Step 1 of 3</p>
				<p className='sub-title bold-font-poppins t5'>Connect your mobile number<br/> to your virtual number</p>
				<form onSubmit={e => handleSubmit(e)}>
					<label className='t7 medium-font nc-gray-900'>
						Mobile Number
						<input type='tel' name='mobile' value={ phoneNumber } onChange={(e) => handleMobileNumberChange(e)} className='t6 regular-font'/>
					</label>
					<label className='t7 medium-font nc-gray-900'>
						Select city for virtual number

						<Select
							disabled={virtualNumberLocations?.length  === 0}
							value={virtualNumberLocation || ''}
							displayEmpty
							onChange={e => {
								setVirtualNumberLocation(e.target.value || '')
							}}
							renderValue={selected => {
								if(virtualNumberLocations?.length === 0 || !Array.isArray(virtualNumberLocations)) return <p aria-label='' className='regular-font t6 ic-red-400'>None available, contact FreJun</p>
								if(!selected) return <p aria-label='' className='regular-font t6 nc-gray-400'>Select</p>
								return <p title={selected} className='regular-font t6 nc-gray-900'>{selected}</p>
							}}
							placeholder='Select'
							autoFocus={false}
								sx={{
									height: '36px',
									'& .MuiOutlinedInput-notchedOutline': {
										border: `1px solid ${colors.nc_gray_300}`,
									},
									'& .MuiSelect-nativeInput': {
										cursor: 'pointer'
									},
									'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
										border: `1px solid ${colors.ic_green_400}`,
									},
									width: '100%',
									background: colors.ic_white,
									marginBottom: '24px',
									marginTop: '6px',
								}}
							>
							{virtualNumberLocations?.map((vn, index) => vn?.count ? <MenuItem value={vn?.location} key={index}>
								{vn?.location}
							</MenuItem> : (
								<MenuItem sx={{
									pointerEvents: 'none',
									userSelect: 'none',
									color: colors.nc_gray_500,
								}} value={vn?.location} key={index} disabled>
									{vn?.location}
								</MenuItem>
							))}
						</Select>
					</label>
				
					<div className='buttons-container'>
						<Button disabled={false} icon={{startIcon: signupScreen3ButtonBackIcon(), endIcon: null}}  hierarchy='white' variant='contained' buttonText='Back' isLoading={false} onClick={handleGoBack} styleOverride={{border: `1px solid ${colors.nc_gray_400}`,}}/>
						<Button disabled={!Array.isArray(phoneNumber?.match(phoneRegExp))} icon={{startIcon: null, endIcon: null}}  hierarchy='green' variant='contained' buttonText='Next' isLoading={showLoader} onClick={handleSubmit}/>
					</div>
				</form>
			</div>}
		</div>
    )
}
