import { useNavigate } from "react-router-dom";
import { dropdownIcon, errorIcon } from "../../../utility-functions/assetLoader"
import { playButtonIcon } from "../../../utility-functions/assetLoader"
import GModal from "./GModal";
import './customComponents.css'
import { useState } from "react";
import Button from "../Button";
import { ExpandMore, Visibility, VisibilityOff } from '@mui/icons-material';
import { useEffect } from "react";
import searchIcon from "../../../assets/SearchIcon.svg";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import AnimationPage from "../../shared/animation-page/AnimationPage";
import { topNavAccountSelectedAccountTickIcon } from "../../../utility-functions/assetLoader";

export const CustomInput=({value,handleChange,handleFocus,label,CustomError,name,type, children,style,inputProps,...props})=>{
	const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

	

    return (
        <label className='medium-font t7 textfield-label' style={props.style}>
            {label}
			<div className={`${props.dropdown ? 'phone-number' : ''}`} style={{ position: 'relative' }}>

                {props?.dropdown && props.dropdown}
                <input placeholder={props.placeholder} onFocus={handleFocus} onBlur={props?.handleBlur} className={`regular-font t7 ${value?.error?'input-error':''}`} name={name}  type={showPassword ? 'text' : type} value={value?.value || ''} onChange={handleChange} style={style} {...inputProps}/>
                {CustomError && <span className="error-message">{CustomError}</span>}
				{type === 'password' && value.value && ( 
                    <span style={{position: 'absolute',top: '55%',transform: 'translateY(-50%)',right: '8px',cursor: 'pointer'}} onClick={togglePasswordVisibility}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </span>
                )}

                
                {value?.error && <div className='error-icon'>{errorIcon()}</div>}
            </div>
            {value.error && <p style={props.errorStyle} className="t7 regular-font">{value.error}</p>}
            {children}
        </label>
    )
}

export const CCDropdown = ({ handleCountrySelect, selectedCountryCode,CCDropdownstyle }) => {
    const [ccData, setCCData] = useState([]);
    const [searchedCountryCode, setSearchedCountryCode] = useState('');
    // const [selectedCountryCode, setSelectedCountryCode] = useState("");
    const [countryCodeList, setCountryCodeList] = useState([]);
    const [dropdownVisible, setDropdownVisible] = useState(false);

    useEffect(() => {
        fetchCountries();
    }, []);

    const fetchCountries = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_BETA + "/api/v1/auth/locations/");
            const data = await response.json();
            setCCData(data);
            setCountryCodeList(data);
        } catch (error) {
            console.error("Error fetching countries:", error);
        }
    };

    function handleCCSearchChange(e) {
        const search = e.target.value;
        setSearchedCountryCode(search);
        // filter from ccData and update ccList
        if (!search || search?.trim()?.length === 0) {
            setCountryCodeList(ccData);
            return;
        }
        const lowercaseSearch = search.toLowerCase();
        const temp = ccData.filter(cc => `${cc.country.toLowerCase()} +${cc.country_code} ${cc.iso_code.toLowerCase()}`.includes(lowercaseSearch) || lowercaseSearch.includes(cc.iso_code.toLowerCase()));
        setCountryCodeList(temp);
    }

    const handleCountryCodeSelect = (countryCode) => () => {
        // setSelectedCountryCode(countryCode);
        handleCountrySelect(countryCode);
        setDropdownVisible(false);
    };

    const uniqueCountryCodes = [...new Set(countryCodeList.map(cc => cc.country_code))];

    return (
        <><div className="cc-container" onClick={()=>setDropdownVisible(prev=>!prev)}>
            {selectedCountryCode} <ExpandMore/>
        </div>
        {dropdownVisible &&
            <div className="dialer--cc_dropdown_container cc-dropdown">
                <div className="dialer--cc_search" >
                    <img src={searchIcon} alt="" />
                    <input
                       style={CCDropdownstyle}
                        type="search"
                        value={searchedCountryCode}
                        onChange={handleCCSearchChange}
                        placeholder="Search"
                        title="Enter country name to search"
                        disabled={!ccData.length}
                    />
                </div>
                <ul className="dialer--cc_options">
                    {ccData.length > 0 ? uniqueCountryCodes.map(countryCode => {
                        const ccObj = countryCodeList.find(cc => cc.country_code === countryCode);
                        return (
                            <li
                                key={ccObj.id}
                                value={ccObj.country_code}
                                onClick={handleCountryCodeSelect(ccObj.country_code)}
                                style={{
                                    backgroundColor:
                                        selectedCountryCode === ccObj.country_code ? "#f0fcf4" : "",
                                }}
                            >
                                <div>
                                    <span
                                        className={
                                            selectedCountryCode === ccObj.country
                                                ? "dialer--cc_name"
                                                : ""
                                        }
                                    >
                                        {`${ccObj.country} (${ccObj.country_code})`}
                                    </span>
                                </div>
                                {selectedCountryCode === ccObj.country_code && (
                                    <span style={{ float: "right" }}>
                                        {topNavAccountSelectedAccountTickIcon()}
                                    </span>
                                )}
                            </li>
                        );
                    }) : <AnimationPage />}
                </ul>
            </div>}
        </>
    );
}



export const CustomSwitch=({checked,onChange,label,description, labelStyles={}})=>{
    return (
        <div className="switch custom-switch" style={{marginTop:'32px'}}>
			<label>
				<input
				type="checkbox"
				checked={checked}
				onChange={onChange}
				style={{ cursor: "pointer", marginTop: "0" }}
				/>
				<span className="slider round"></span>
			</label>
				<p
				style={{
					marginTop: "-45px",
					marginLeft: "60px",
					width:'555px',
					...labelStyles
				}}
				className="t7 medium-font"
				>
				{label}
				</p>
				<p
				className="t7 regular-font"
				style={{  width:'555px',marginLeft: "60px", marginTop: "0" }}
				>
				{description}
				</p>
			</div>
    )
}


export const VideoLinkModal=({visible, handleClose,heading,text,url,...props})=>{
return (
<GModal
     visible={visible} 
     closeModal={handleClose} 
     bottomButtons={[]}
     heading={heading}
     headingStyle={{backgroundColor:'#fff',borderBottom:'none',marginLeft:'25%',paddingTop:'8px',...props.headingStyle}}
     modalStyle={{paddingTop:'0px'}}
     bodyWrapperStyle={{maxWidth:'448px'}}
     body={
        <div>
            <p className="t7 regular-font" style={{textAlign:'center', margin:'8px auto 16px',color:'#000',lineHeight:'180%'}}>{text}</p>
            <div style={{borderRadius: '10px', background:  '#CAFCDA', display:'flex', alignItems:'center', justifyContent:'center', height:'413px', width:'400px'}}>
                <a href={url || ''}><span>{playButtonIcon()}</span></a>
            </div>
        </div>
     }/>
)
}

export const InsufficientCreditsModal=({visible,handleClose, handlePayWithCard,handleAddCredits})=>{
	const navigate=useNavigate()
	const handleNavigateToCredits=()=>{
		handleClose()
		navigate('/billing',{state:{tab:'Credits'}})
	}
	return <GModal heading="Insufficient credits" visible={visible} closeModal={handleClose} 
			bottomButtons={[<Button styleOverride={btnStyle} hierarchy='green' buttonText='Pay with card' onClick={handlePayWithCard} disabled={false}/>,
							<Button styleOverride={btnStyle} hierarchy='green' buttonText='Add credits' onClick={handleAddCredits || handleNavigateToCredits} disabled={false}/>,]}
			body={
				<p className="t7 regular-font">Add account credits or pay with Debit/Credit card.</p>
			}
			modalStyle={{width:'430px',minHeight:'auto',padding:'32px'}}
			bottomButtonsStyle={{borderTop:'none',paddingTop:'8px'}}
			bodyWrapperStyle={{paddingRight:0}}
			/>
}

export const PaymentMethodModal=({visible,handleClose, handlePayWithCard,handlePayWithCredits})=>{
	return <GModal heading="Select payment method" visible={visible} closeModal={handleClose} 
			bottomButtons={[<Button styleOverride={btnStyle} hierarchy='white' buttonText='Pay with credits' onClick={handlePayWithCredits} disabled={false}/>,
							<Button styleOverride={btnStyle} hierarchy='green' buttonText='Pay with card' onClick={handlePayWithCard} disabled={false}/>,]}
			body={
				<p className="t7 regular-font">Pay using account credits or Debit/Credit card.</p>
			}
			modalStyle={{width:'430px',minHeight:'auto',padding:'32px'}}
			bottomButtonsStyle={{borderTop:'none',paddingTop:'8px'}}
			bodyWrapperStyle={{paddingRight:0}}
			/>
}
const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#F5F7F6",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#F5F7F6",
      color:'#464D48',
      boxShadow: `0 4px 8px rgba(0, 0, 0, 0.1)`,
      padding:'12px',
      fontSize:'14px',
      fontWeight:'500px'
    }
    }));
    export const CustomTooltip= ({tooltipText,tooltipTittle})=>(
        <BootstrapTooltip  title={tooltipTittle} arrow placement="top-start">
        <Typography className="toolTip"style={{fontSize:'14px',color:'#464D48',fontFamily:'inter'}}>
          {tooltipText}
          </Typography>
          </BootstrapTooltip>
    )
const btnStyle={
	height:'36px',
	fontSize:'14px',
	lineHeight:'14px',
	fontWeight:'400',
	padding:'10px 16px',
    border:'1px solid lightgray',
  }