import clevertap from "clevertap-web-sdk";
import { formatCurrentDate } from "./index";
export const pushToClevertap = (
  eventName,
  success,
  payload,
  error,
  errorMessage
) => {
  eventName = success ? eventName : `ERROR on ${eventName}`;
  const reduxAccount = JSON.parse(
    localStorage.getItem("frejun-current-account")
  );
  const details = {
    userEmail: reduxAccount?.data?.email,
    name: reduxAccount?.data?.first_name + " " + reduxAccount?.data?.last_name,
    accountName: reduxAccount?.data?.profile?.org_name,
    date: formatCurrentDate(),
    source: "Webapp",
    IDENTITY: clevertap.getCleverTapID(),
  };
  if (payload && Object.keys(payload)?.length > 0) {
    details.payload = JSON.stringify(payload, null, " ");
  }
  if (!success && error) {
    details.error = JSON.stringify(
      {
        name: error?.name,
        stack: error?.stack,
        message: error?.message,
      },
      null,
      " "
    );
  } else if (!success && errorMessage) {
    if(typeof errorMessage === "object")
      details.error = JSON.stringify(errorMessage, null, " ");
    else
      details.error = errorMessage;
  }
  clevertap.event.push(eventName, details);
};
