import { ListItemIcon, MenuItem, Select } from "@mui/material";
import GTable from "../design/components/GTable";
import colors from "../design/colors";
import styles from "../design/styles";
import { useEffect, useRef, useState } from "react";
import { JService } from "../api-service/ApiService";
import AnimationPage from "../shared/animation-page/AnimationPage";
import countries from "../shared/countries/Countries.json";
import { images } from "./flagsImported";
import { useLocation } from "react-router-dom";
import { BorderTop } from "@mui/icons-material";

const API_BASE_URL = process.env.REACT_APP_BETA;

const columns = [
  {
    field: "number_rental",
    headerName: "Number rental",
    flex: 1.2,
    headerClassName: "user-table-header-user-name",
    renderCell: (params) => {
      return <p style={{ marginLeft: "14px" }}>{params?.row?.number_rental}</p>;
    },
  },
  {
    field: "one_time_fee",
    headerName: "One-time setup fee",
    flex: 0.8,
    headerClassName: "user-table-header-user-name",
    renderCell: (params) => {
      return (
        <p style={{ marginLeft: "14px" }}>
          {params?.row?.one_time_fee || "Nil"}
        </p>
      );
    },
  },
  {
    field: "inbound_call_rate",
    headerName: "Incoming call charges",
    flex: 1,
    headerClassName: "user-table-header-user-name",
    renderCell: (params) => {
      return (
        <p style={{ marginLeft: "14px" }}>
          {params?.row?.inbound_call_rate || "Nil"}
        </p>
      );
    },
  },
];

const india =
  countries[99].dialCode === "91"
    ? countries[99].dialCode
    : countries.find((c) => c.dialCode === "91");

export default function IncomingCallPricing() {
  const location = useLocation();
  const toCountryCodeRef = useRef("");
  const [cols, setCols] = useState([]);
  const [rows, setRows] = useState([]);
  const [filters, setFilters] = useState({
    country: "India",
    currency: "",
  });
  const [rowCount, setRowCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pricingInr, setPricingInr] = useState(null);
  const [isFetchPending, setIsFetchPending] = useState(false);
  const [gTablePageNumber, setGTablePageNumber] = useState(null);
  const isString = (val) => val instanceof String || typeof val === "string";

  useEffect(() => {
    // to always india
    if (location?.pathname?.split("-")?.at(-1) === "inr") {
      columns[2].headerName = "Incoming call charges";
      columns[0].headerName = "Number rental";
      setCols(columns);
      setPricingInr(true);

      const data = {
        id: 0,
        origin_prefix: "91",
        to: "91",
        number_rental: "₹500 (per number/month)",
        inbound_call_rate: "₹1.00",
      };
      setRows([data]);
      setRowCount(1);
    } else {
      setPricingInr(false);
      if (filters.country === "India") {
        fetchData(
          `/api/v1/auth/get-inbound-call-pricing/?country=${filters.country}`
        );
        setCols(columns);
        const data = {
          id: 0,
          origin_prefix: "91",
          to: "91",
          number_rental: "6",
          inbound_call_rate: "0.012",
        };
        setRows([data]);
        setRowCount(1);
        return;
      }

      fetchData(
        `/api/v1/auth/get-inbound-call-pricing/?country=${filters.country}`
      );
    }
  }, []);

  const onCountryFilterChange = async (e) => {
    const value = e.target.value;

    if (!isString(value)) value = value.target.value;

    toCountryCodeRef.current =
      countries.find((c) => c.name === value)?.dialCode || "";
    setFilters((prev) => ({ ...prev, country: value }));

    if (filters.country === value) {
      console.warn("same to country chosen");
      return;
    }
    if (!value) return;
    let url = `/api/v1/auth/get-inbound-call-pricing/?country=${value}`;
    if (pricingInr) url += "&inr=true";
    fetchData(url);
  };

  const fetchData = async (url) => {
    try {
      setIsFetchPending(true);
      const pathname = window.location.pathname;
      const isINR = pathname.split("-").includes("inr");
      const currencySymbol = isINR ? "₹" : "$";

      const response = await JService.get(API_BASE_URL + url);

      if (!response?.success)
        throw new Error(response?.message || "Failed to fetch pricing details");

      let data = response.data;

      const formatValue = (value) => {
        return value > 0
          ? `${currencySymbol} ${value} (per number/month)`
          : ` ${value}`;
      };

      data = data.map((elem) => {
        return {
          id: elem.id,
          number_rental: elem.number_rental
            ? `${formatValue(elem.number_rental)}`
            : "Nil",
          one_time_fee: elem.one_time_fee
            ? `${currencySymbol} ${elem.one_time_fee}`
            : "Nil",
          inbound_call_rate: elem.inbound_call_rate
            ? `${currencySymbol} ${elem.inbound_call_rate}`
            : "Nil",
        };
      });
      setRows(data);
      setRowCount(1);
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsFetchPending(false);
    }
  };

  function handlePageSizeChange(val) {
    let url = `/api/v1/auth/get-call-pricing/?to=${filters.country}&page=0&page_size=${val}`;
    if (pricingInr) url += "&inr=true";
    fetchData(url);
    setPageSize(val);
    setGTablePageNumber(0);
  }

  function handlePageChange(val) {
    let url = `/api/v1/auth/get-call-pricing/?to=${filters.country.trim()}&page=${
      val + 1
    }`;
    if (pricingInr) url += "&inr=true";
    fetchData(url);
    console.log(val);
  }

  return (
    <>
      <div
        className="general-tab-vn-gtable-height-setter call-pricing-container"
        style={localStyles.tableContainer}
      >
        <div style={localStyles.filtersContainer}>
          <label id="to--country" data-field="to">
            <span
              style={localStyles.inputLabel}
              className="t7 nc-gray-900 medium-font"
            >
              Country
            </span>

            <Select
              labelId="to--country"
              title={pricingInr ? "Read only" : "Select To"}
              value={
                pricingInr === null || pricingInr ? "India" : filters.country
              }
              onChange={onCountryFilterChange}
              disabled={isFetchPending || pricingInr === null || pricingInr}
              sx={{ ...localStyles.filter, ...localStyles.filterReadOnly }}
              placeholder="Select Country"
              renderValue={(selected) => {
                if (!selected)
                  return (
                    <p className="regular-font t6 nc-gray-400">From country</p>
                  );
                return (
                  <p
                    className="regular-font t6 nc-gray-900"
                    style={localStyles.selectedContainer}
                  >
                    <img
                      src={images[filters.country]}
                      style={localStyles.flagIcon}
                    />
                    {selected}
                  </p>
                );
              }}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              {countries.map(({ name }, index) => (
                <MenuItem value={name} key={index}>
                  <ListItemIcon style={localStyles.flagIconContainer}>
                    <img src={images[name]} style={localStyles.flagIcon} />
                  </ListItemIcon>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </label>
        </div>
        {/* {isFetchPending ? <AnimationPage /> : 
			<GTable
                columns={cols}
                rows={rows}
                leftHeader={{
                    filters: [],
                }}
                rightHeader={{}}
                useMuiTable={true}
                isLoading={false}
                getTableRowClassName={() => `teams-table-row`}
                additionalProps={{ bgHover: false }}
                hideHeader
                hidePagination={false}
                paginationMode='server'
                fromScreen="call-pricing"
                onPageSizeChange={handlePageSizeChange}
                handlePageChange={handlePageChange}
                rowCount={rowCount}
                setGTablePageNumber={setGTablePageNumber}
                gTablePageNumber={gTablePageNumber}
            />
			} */}
        {isFetchPending ? (
          <AnimationPage />
        ) : (
          <div className="table-container">
            <table
              className="pricing-table"
              style={{ borderCollapse: "revert" }}
            >
              <thead>
                <tr>
                  <th></th>
                  <th
                    style={{
                      borderTopLeftRadius: "10px",
                      backgroundColor: "#F7FAF8",
                      border: "1px solid #E1E5E2",
                      padding: "27px",
                      fontWeight: "500",
                      textAlign: "left",
                      width: "250px",
                    }}
                  >
                    Standard 10 digit number
                  </th>
                  <th
                    style={{
                      backgroundColor: "#F7FAF8",
                      border: "1px solid #E1E5E2",
                      borderBottom: "1px solid #E1E5E2",
                      padding: "27px",
                      fontWeight: "500",
                      textAlign: "left",
                      width: "250px",
                    }}
                  >
                    Mobile number
                  </th>
                  <th
                    style={{
                      borderTopRightRadius: "10px",
                      backgroundColor: "#F7FAF8",
                      border: "1px solid #E1E5E2",
                      borderBottom: "1px solid #E1E5E2",
                      padding: "27px",
                      fontWeight: "500",
                      textAlign: "left",
                      width: "250px",
                    }}
                  >
                    Toll-free number
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    style={{
                      borderTopLeftRadius: "10px",
                      backgroundColor: "#F7FAF8",
                      border: "1px solid #E1E5E2",
                      padding: "27px",
                      fontSize: "14px",
                      fontWeight: "400px",
                      textAlign: "left",
                      width: "250px",
                    }}
                  >
                    Number rental
                  </td>
                  <td
                    style={{
                      border: "1px solid #E1E5E2",
                      padding: "27px",
                      fontSize: "14px",
                      fontWeight: "400px",
                      textAlign: "left",
                    }}
                  >
                    {rows[0]?.number_rental || "Nil"}
                  </td>
                  <td
                    style={{
                      border: "1px solid #E1E5E2",
                      padding: "27px",
                      fontSize: "14px",
                      fontWeight: "400px",
                      textAlign: "left",
                    }}
                  >
                    {rows[1]?.number_rental || "Nil"}
                  </td>
                  <td
                    style={{
                      border: "1px solid #E1E5E2",
                      padding: "27px",
                      fontSize: "14px",
                      fontWeight: "400px",
                      textAlign: "left",
                    }}
                  >
                    {rows[2]?.number_rental || "Nil"}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "#F7FAF8",
                      border: "1px solid #E1E5E2",
                      padding: "27px",
                      fontSize: "14px",
                      fontWeight: "400px",
                      textAlign: "left",
                      width: "250px",
                    }}
                  >
                    One-time setup fee
                  </td>
                  <td
                    style={{
                      border: "1px solid #E1E5E2",
                      padding: "27px",
                      fontSize: "14px",
                      fontWeight: "400px",
                      textAlign: "left",
                    }}
                  >
                    {rows[0]?.one_time_fee || "Nil"}
                  </td>
                  <td
                    style={{
                      border: "1px solid #E1E5E2",
                      padding: "27px",
                      fontSize: "14px",
                      fontWeight: "400px",
                      textAlign: "left",
                    }}
                  >
                    {rows[1]?.one_time_fee || "Nil"}
                  </td>
                  <td
                    style={{
                      border: "1px solid #E1E5E2",
                      padding: "27px",
                      fontSize: "14px",
                      fontWeight: "400px",
                      textAlign: "left",
                    }}
                  >
                    {rows[2]?.one_time_fee || "Nil"}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderBottomLeftRadius: "10px",
                      backgroundColor: "#F7FAF8",
                      border: "1px solid #E1E5E2",
                      padding: "27px",
                      fontSize: "14px",
                      fontWeight: "400px",
                      textAlign: "left",
                      width: "250px",
                    }}
                  >
                    Incoming call (
                    {location?.pathname?.split("-")?.at(-1) === "inr"
                      ? "INR"
                      : "USD"}
                    /min)
                  </td>
                  <td
                    style={{
                      border: "1px solid #E1E5E2",
                      padding: "27px",
                      fontSize: "14px",
                      fontWeight: "400px",
                      textAlign: "left",
                    }}
                  >
                    {rows[0]?.inbound_call_rate || "Nil"}
                  </td>
                  <td
                    style={{
                      border: "1px solid #E1E5E2",
                      padding: "27px",
                      fontSize: "14px",
                      fontWeight: "400px",
                      textAlign: "left",
                    }}
                  >
                    {rows[1]?.inbound_call_rate || "Nil"}
                  </td>
                  <td
                    style={{
                      borderBottomRightRadius: "10px",
                      border: "1px solid #E1E5E2",
                      padding: "27px",
                      fontSize: "14px",
                      fontWeight: "400px",
                      textAlign: "left",
                    }}
                  >
                    {rows[2]?.inbound_call_rate || "Nil"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        <div style={localStyles.BottomText}>
          <li style={localStyles.listItem}>
            One India virtual number/user included in Indian plans.
          </li>
          <li style={localStyles.listItem}>
            One US, UK or Canada virtual number/user included in other plans.
          </li>
          <li style={localStyles.listItem}>
            Extra charges applicable for additional numbers.
          </li>
          <li style={localStyles.lastListItem}>
            Billing interval for incoming calls is 60 seconds
          </li>
        </div>
      </div>
    </>
  );
}
const localStyles = {
  filtersContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "40px",
  },
  BottomText: {
    width: "100%",
    height: "108px",
    size: "54px",
    marginTop: "40px",
  },
  listItem: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22.4px",
    textAlign: "center",
  },
  lastListItem: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    marginTop: "22px",
    textAlign: "center",
  },
  filter: {
    alignSelf: "flex-start",
    minWidth: 230,
    height: "40px",
    outline: "none",
    borderRadius: "4px",
    padding: 0,
    ...styles.t6,
    ...styles.regular_font,
    ...colors.nc_gray_900,

    "&:before": {
      outline: "none",
      border: "none",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #34CB65",
      boxShadow: "0px 0px 0px 4px #F0FCF4",
    },
  },

  filterReadOnly: {
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${colors.nc_gray_500}`,
    },
  },

  inputLabel: {
    display: "block",
    textAlign: "left",
    userSelect: "none",
    marginBottom: "4px",
  },

  tableContainer: {
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  flagIcon: {
    borderRadius: "2px",
    width: "24px",
    height: "16px",
    filter: `drop-shadow(0px 1px 1px ${colors.nc_gray_400})`,
  },

  flagIconContainer: {
    marginRight: "8px",
  },

  selectedContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
    columnGap: "16px",
    paddingLeft: "8px",
  },
};
