import af from "../../assets/pricing-flags/af.svg"
import al from "../../assets/pricing-flags/al.svg"
import dz from "../../assets/pricing-flags/dz.svg"
import as from "../../assets/pricing-flags/as.svg"
import ad from "../../assets/pricing-flags/ad.svg"
import ao from "../../assets/pricing-flags/ao.svg"
import ai from "../../assets/pricing-flags/ai.svg" 
import ag from "../../assets/pricing-flags/ag.svg" 
import ar from "../../assets/pricing-flags/ar.svg" 
import am from "../../assets/pricing-flags/am.svg" 
import aw from "../../assets/pricing-flags/aw.svg" 
import ac from "../../assets/pricing-flags/ac.svg" 
import au from "../../assets/pricing-flags/au.svg" 
import at from "../../assets/pricing-flags/at.svg" 
import az from "../../assets/pricing-flags/az.svg" 
import bs from "../../assets/pricing-flags/bs.svg" 
import bh from "../../assets/pricing-flags/bh.svg" 
import bd from "../../assets/pricing-flags/bd.svg" 
import bb from "../../assets/pricing-flags/bb.svg" 
import by from "../../assets/pricing-flags/by.svg" 
import be from "../../assets/pricing-flags/be.svg" 
import bz from "../../assets/pricing-flags/bz.svg" 
import bj from "../../assets/pricing-flags/bj.svg" 
import bm from "../../assets/pricing-flags/bm.svg" 
import bt from "../../assets/pricing-flags/bt.svg" 
import bo from "../../assets/pricing-flags/bo.svg" 
import ba from "../../assets/pricing-flags/ba.svg" 
import bw from "../../assets/pricing-flags/bw.svg" 
import br from "../../assets/pricing-flags/br.svg" 
import io from "../../assets/pricing-flags/io.svg" 
import vg from "../../assets/pricing-flags/vg.svg" 
import bn from "../../assets/pricing-flags/bn.svg" 
import bg from "../../assets/pricing-flags/bg.svg" 
import bf from "../../assets/pricing-flags/bf.svg" 
import bi from "../../assets/pricing-flags/bi.svg" 
import kh from "../../assets/pricing-flags/kh.svg" 
import cm from "../../assets/pricing-flags/cm.svg" 
import ca from "../../assets/pricing-flags/ca.svg" 
import cv from "../../assets/pricing-flags/cv.svg" 
import bq from "../../assets/pricing-flags/bq.svg" 
import ky from "../../assets/pricing-flags/ky.svg" 
import cf from "../../assets/pricing-flags/cf.svg" 
import td from "../../assets/pricing-flags/td.svg" 
import cl from "../../assets/pricing-flags/cl.svg" 
import cn from "../../assets/pricing-flags/cn.svg" 
import cx from "../../assets/pricing-flags/cx.svg" 
import cc from "../../assets/pricing-flags/cc.svg" 
import co from "../../assets/pricing-flags/co.svg" 
import km from "../../assets/pricing-flags/km.svg" 
import cd from "../../assets/pricing-flags/cd.svg" 
import cg from "../../assets/pricing-flags/cg.svg" 
import ck from "../../assets/pricing-flags/ck.svg" 
import cr from "../../assets/pricing-flags/cr.svg" 
import ci from "../../assets/pricing-flags/ci.svg" 
import hr from "../../assets/pricing-flags/hr.svg" 
import cu from "../../assets/pricing-flags/cu.svg" 
import cw from "../../assets/pricing-flags/cw.svg" 
import cy from "../../assets/pricing-flags/cy.svg" 
import cz from "../../assets/pricing-flags/cz.svg" 
import dk from "../../assets/pricing-flags/dk.svg" 
import dj from "../../assets/pricing-flags/dj.svg" 
import dm from "../../assets/pricing-flags/dm.svg" 
import doFile from "../../assets/pricing-flags/do.svg" 
import ec from "../../assets/pricing-flags/ec.svg" 
import eg from "../../assets/pricing-flags/eg.svg" 
import sv from "../../assets/pricing-flags/sv.svg" 
import gq from "../../assets/pricing-flags/gq.svg" 
import er from "../../assets/pricing-flags/er.svg" 
import ee from "../../assets/pricing-flags/ee.svg" 
import sz from "../../assets/pricing-flags/sz.svg" 
import et from "../../assets/pricing-flags/et.svg" 
import fk from "../../assets/pricing-flags/fk.svg" 
import fo from "../../assets/pricing-flags/fo.svg" 
import fj from "../../assets/pricing-flags/fj.svg" 
import fi from "../../assets/pricing-flags/fi.svg" 
import fr from "../../assets/pricing-flags/fr.svg" 
import gf from "../../assets/pricing-flags/gf.svg" 
import pf from "../../assets/pricing-flags/pf.svg" 
import ga from "../../assets/pricing-flags/ga.svg" 
import gm from "../../assets/pricing-flags/gm.svg" 
import ge from "../../assets/pricing-flags/ge.svg" 
import de from "../../assets/pricing-flags/de.svg" 
import gh from "../../assets/pricing-flags/gh.svg" 
import gi from "../../assets/pricing-flags/gi.svg" 
import gr from "../../assets/pricing-flags/gr.svg" 
import gl from "../../assets/pricing-flags/gl.svg" 
import gd from "../../assets/pricing-flags/gd.svg" 
import gp from "../../assets/pricing-flags/gp.svg" 

import gu from "../../assets/pricing-flags/gu.svg" 
import gt from "../../assets/pricing-flags/gt.svg" 
import gg from "../../assets/pricing-flags/gg.svg" 
import gn from "../../assets/pricing-flags/gn.svg" 
import gw from "../../assets/pricing-flags/gw.svg" 
import gy from "../../assets/pricing-flags/gy.svg" 
import ht from "../../assets/pricing-flags/ht.svg" 
import hn from "../../assets/pricing-flags/hn.svg" 
import hk from "../../assets/pricing-flags/hk.svg" 
import hu from "../../assets/pricing-flags/hu.svg" 
import is from "../../assets/pricing-flags/is.svg" 
import inFile from "../../assets/pricing-flags/in.svg" 
import id from "../../assets/pricing-flags/id.svg" 
import ir from "../../assets/pricing-flags/ir.svg" 
import iq from "../../assets/pricing-flags/iq.svg" 
import ie from "../../assets/pricing-flags/ie.svg" 
import im from "../../assets/pricing-flags/im.svg" 
import il from "../../assets/pricing-flags/il.svg" 
import it from "../../assets/pricing-flags/it.svg" 
import jm from "../../assets/pricing-flags/jm.svg" 
import jp from "../../assets/pricing-flags/jp.svg" 
import je from "../../assets/pricing-flags/je.svg" 
import jo from "../../assets/pricing-flags/jo.svg" 
import kz from "../../assets/pricing-flags/kz.svg" 
import ke from "../../assets/pricing-flags/ke.svg" 
import ki from "../../assets/pricing-flags/ki.svg" 
import xk from "../../assets/pricing-flags/xk.svg" 
import kw from "../../assets/pricing-flags/kw.svg" 
import kg from "../../assets/pricing-flags/kg.svg" 
import la from "../../assets/pricing-flags/la.svg" 
import lv from "../../assets/pricing-flags/lv.svg" 
import lb from "../../assets/pricing-flags/lb.svg" 
import ls from "../../assets/pricing-flags/ls.svg" 
import lr from "../../assets/pricing-flags/lr.svg" 
import ly from "../../assets/pricing-flags/ly.svg" 
import li from "../../assets/pricing-flags/li.svg" 
import lt from "../../assets/pricing-flags/lt.svg" 
import lu from "../../assets/pricing-flags/lu.svg" 
import mo from "../../assets/pricing-flags/mo.svg" 
import mg from "../../assets/pricing-flags/mg.svg" 
import mw from "../../assets/pricing-flags/mw.svg" 
import my from "../../assets/pricing-flags/my.svg" 
import mv from "../../assets/pricing-flags/mv.svg" 
import ml from "../../assets/pricing-flags/ml.svg" 
import mt from "../../assets/pricing-flags/mt.svg" 
import mh from "../../assets/pricing-flags/mh.svg" 
import mq from "../../assets/pricing-flags/mq.svg" 
import mr from "../../assets/pricing-flags/mr.svg" 
import mu from "../../assets/pricing-flags/mu.svg" 
import yt from "../../assets/pricing-flags/yt.svg" 
import mx from "../../assets/pricing-flags/mx.svg" 
import fm from "../../assets/pricing-flags/fm.svg" 
import md from "../../assets/pricing-flags/md.svg" 
import mc from "../../assets/pricing-flags/mc.svg" 
import mn from "../../assets/pricing-flags/mn.svg" 
import me from "../../assets/pricing-flags/me.svg" 
import ms from "../../assets/pricing-flags/ms.svg" 
import ma from "../../assets/pricing-flags/ma.svg" 
import mz from "../../assets/pricing-flags/mz.svg" 
import mm from "../../assets/pricing-flags/mm.svg" 
import na from "../../assets/pricing-flags/na.svg" 
import nr from "../../assets/pricing-flags/nr.svg" 
import np from "../../assets/pricing-flags/np.svg" 
import nl from "../../assets/pricing-flags/nl.svg" 
import nc from "../../assets/pricing-flags/nc.svg" 
import nz from "../../assets/pricing-flags/nz.svg" 
import ni from "../../assets/pricing-flags/ni.svg" 
import ne from "../../assets/pricing-flags/ne.svg" 
import ng from "../../assets/pricing-flags/ng.svg" 
import nu from "../../assets/pricing-flags/nu.svg" 
import nf from "../../assets/pricing-flags/nf.svg" 
import kp from "../../assets/pricing-flags/kp.svg" 
import mk from "../../assets/pricing-flags/mk.svg" 
import mp from "../../assets/pricing-flags/mp.svg" 
import no from "../../assets/pricing-flags/no.svg" 
import om from "../../assets/pricing-flags/om.svg" 
import pk from "../../assets/pricing-flags/pk.svg" 
import pw from "../../assets/pricing-flags/pw.svg" 
import ps from "../../assets/pricing-flags/ps.svg" 
import pa from "../../assets/pricing-flags/pa.svg" 
import pg from "../../assets/pricing-flags/pg.svg" 
import py from "../../assets/pricing-flags/py.svg" 
import pe from "../../assets/pricing-flags/pe.svg" 
import ph from "../../assets/pricing-flags/ph.svg" 
import pl from "../../assets/pricing-flags/pl.svg" 
import pt from "../../assets/pricing-flags/pt.svg" 
import pr from "../../assets/pricing-flags/pr.svg" 
import qa from "../../assets/pricing-flags/qa.svg" 
import re from "../../assets/pricing-flags/re.svg" 
import ro from "../../assets/pricing-flags/ro.svg" 
import ru from "../../assets/pricing-flags/ru.svg" 
import rw from "../../assets/pricing-flags/rw.svg" 
import bl from "../../assets/pricing-flags/bl.svg" 
import sh from "../../assets/pricing-flags/sh.svg" 
import kn from "../../assets/pricing-flags/kn.svg" 
import lc from "../../assets/pricing-flags/lc.svg" 
import mf from "../../assets/pricing-flags/mf.svg" 
import pm from "../../assets/pricing-flags/pm.svg" 
import vc from "../../assets/pricing-flags/vc.svg" 
import ws from "../../assets/pricing-flags/ws.svg" 
import sm from "../../assets/pricing-flags/sm.svg" 
import st from "../../assets/pricing-flags/st.svg" 
import sa from "../../assets/pricing-flags/sa.svg" 
import sn from "../../assets/pricing-flags/sn.svg" 
import rs from "../../assets/pricing-flags/rs.svg" 
import sc from "../../assets/pricing-flags/sc.svg" 
import sl from "../../assets/pricing-flags/sl.svg" 
import sg from "../../assets/pricing-flags/sg.svg" 
import sx from "../../assets/pricing-flags/sx.svg" 
import sk from "../../assets/pricing-flags/sk.svg" 
import si from "../../assets/pricing-flags/si.svg" 
import sb from "../../assets/pricing-flags/sb.svg" 
import so from "../../assets/pricing-flags/so.svg" 
import za from "../../assets/pricing-flags/za.svg" 
import kr from "../../assets/pricing-flags/kr.svg" 
import ss from "../../assets/pricing-flags/ss.svg" 
import es from "../../assets/pricing-flags/es.svg" 
import lk from "../../assets/pricing-flags/lk.svg" 
import sd from "../../assets/pricing-flags/sd.svg" 
import sr from "../../assets/pricing-flags/sr.svg" 
import sj from "../../assets/pricing-flags/sj.svg" 
import se from "../../assets/pricing-flags/se.svg" 
import ch from "../../assets/pricing-flags/ch.svg" 
import sy from "../../assets/pricing-flags/sy.svg" 
import tw from "../../assets/pricing-flags/tw.svg" 
import tj from "../../assets/pricing-flags/tj.svg" 
import tz from "../../assets/pricing-flags/tz.svg" 
import th from "../../assets/pricing-flags/th.svg" 
import tl from "../../assets/pricing-flags/tl.svg" 
import tg from "../../assets/pricing-flags/tg.svg" 
import tk from "../../assets/pricing-flags/tk.svg" 
import to from "../../assets/pricing-flags/to.svg" 
import tt from "../../assets/pricing-flags/tt.svg" 
import tn from "../../assets/pricing-flags/tn.svg" 
import tr from "../../assets/pricing-flags/tr.svg" 
import tm from "../../assets/pricing-flags/tm.svg" 
import tc from "../../assets/pricing-flags/tc.svg" 
import tv from "../../assets/pricing-flags/tv.svg" 
import vi from "../../assets/pricing-flags/vi.svg" 
import ug from "../../assets/pricing-flags/ug.svg" 
import ua from "../../assets/pricing-flags/ua.svg" 
import ae from "../../assets/pricing-flags/ae.svg" 
import gb from "../../assets/pricing-flags/gb.svg" 
import us from "../../assets/pricing-flags/us.svg" 
import uy from "../../assets/pricing-flags/uy.svg" 
import uz from "../../assets/pricing-flags/uz.svg" 
import vu from "../../assets/pricing-flags/vu.svg" 
import va from "../../assets/pricing-flags/va.svg" 
import ve from "../../assets/pricing-flags/ve.svg" 
import vn from "../../assets/pricing-flags/vn.svg" 
import wf from "../../assets/pricing-flags/wf.svg" 
import eh from "../../assets/pricing-flags/eh.svg" 
import ye from "../../assets/pricing-flags/ye.svg" 
import zm from "../../assets/pricing-flags/zm.svg" 
import zw from "../../assets/pricing-flags/zw.svg" 
import ax from "../../assets/pricing-flags/ax.svg"

export const images = {
	
		"Afghanistan": af
	,
	
		"Albania": al
	,
	
		"Algeria": dz
	,
	
		"American Samoa": as
	,
	
		"Andorra": ad
	,
	
		"Angola": ao
	,
	
		"Anguilla": ai
	,
	
		"Antigua and Barbuda": ag
	,
	
		"Argentina": ar
	,
	
		"Armenia": am
	,
	
		"Aruba": aw
	,
	
		"Ascension Island": ac
	,
	
		"Australia": au
	,
	
		"Austria": at
	,
	
		"Azerbaijan": az
	,
	
		"Bahamas": bs
	,
	
		"Bahrain": bh
	,
	
		"Bangladesh": bd
	,
	
		"Barbados": bb
	,
	
		"Belarus": by
	,
	
		"Belgium": be
	,
	
		"Belize": bz
	,
	
		"Benin": bj
	,
	
		"Bermuda": bm
	,
	
		"Bhutan": bt
	,
	
		"Bolivia": bo
	,
	
		"Bosnia and Herzegovina": ba
	,
	
		"Botswana": bw
	,
	
		"Brazil": br
	,
	
		"British Indian Ocean Territory": io
	,
	
		"British Virgin Islands": vg
	,
	
		"Brunei": bn
	,
	
		"Bulgaria": bg
	,
	
		"Burkina Faso": bf
	,
	
		"Burundi": bi
	,
	
		"Cambodia": kh
	,
	
		"Cameroon": cm
	,
	
		"Canada": ca
	,
	
		"Cape Verde": cv
	,
	
		"Caribbean Netherlands": bq
	,
	
		"Cayman Islands": ky
	,
	
		"Central African Republic": cf
	,
	
		"Chad": td
	,
	
		"Chile": cl
	,
	
		"China": cn
	,
	
		"Christmas Island": cx
	,
	
		"Cocos": cc
	,
	
		"Colombia": co
	,
	
		"Comoros": km
	,
	
		"Congo": cd
	,
	
		"Congo": cg
	,
	
		"Cook Islands": ck
	,
	
		"Costa Rica": cr
	,
	
		"Côte d’Ivoire": ci
	,
	
		"Croatia": hr
	,
	
		"Cuba": cu
	,
	
		"Curaçao": cw
	,
	
		"Cyprus": cy
	,
	
		"Czech Republic": cz
	,
	
		"Denmark": dk
	,
	
		"Djibouti": dj
	,
	
		"Dominica": dm
	,
	
		"Dominican Republic": doFile
	,
	
		"Ecuador": ec
	,
	
		"Egypt": eg
	,
	
		"El Salvador": sv
	,
	
		"Equatorial Guinea": gq
	,
	
		"Eritrea": er
	,
	
		"Estonia": ee
	,
	
		"Eswatini": sz
	,
	
		"Ethiopia": et
	,
	
		"Falkland Islands": fk
	,
	
		"Faroe Islands": fo
	,
	
		"Fiji": fj
	,
	
		"Finland": fi
	,
	
		"France": fr
	,
	
		"French Guiana": gf
	,
	
		"French Polynesia": pf
	,
	
		"Gabon": ga
	,
	
		"Gambia": gm
	,
	
		"Georgia": ge
	,
	
		"Germany": de
	,
	
		"Ghana": gh
	,
	
		"Gibraltar": gi
	,
	
		"Greece": gr
	,
	
		"Greenland": gl
	,
	
		"Grenada": gd
	,
	
		"Guadeloupe": gp
	,
	
		"Guam": gu
	,
	
		"Guatemala": gt
	,
	
		"Guernsey": gg
	,
	
		"Guinea": gn
	,
	
		"Guinea-Bissau": gw
	,
	
		"Guyana": gy
	,
	
		"Haiti": ht
	,
	
		"Honduras": hn
	,
	
		"Hong Kong": hk
	,
	
		"Hungary": hu
	,
	
		"Iceland": is
	,
	
		"India": inFile
	,
	
		"Indonesia": id
	,
	
		"Iran": ir
	,
	
		"Iraq": iq
	,
	
		"Ireland": ie
	,
	
		"Isle of Man": im
	,
	
		"Israel": il
	,
	
		"Italy": it
	,
	
		"Jamaica": jm
	,
	
		"Japan": jp
	,
	
		"Jersey": je
	,
	
		"Jordan": jo
	,
	
		"Kazakhstan": kz
	,
	
		"Kenya": ke
	,
	
		"Kiribati": ki
	,
	
		"Kosovo": xk
	,
	
		"Kuwait": kw
	,
	
		"Kyrgyzstan": kg
	,
	
		"Laos": la
	,
	
		"Latvia": lv
	,
	
		"Lebanon": lb
	,
	
		"Lesotho": ls
	,
	
		"Liberia": lr
	,
	
		"Libya": ly
	,
	
		"Liechtenstein": li
	,
	
		"Lithuania": lt
	,
	
		"Luxembourg": lu
	,
	
		"Macau": mo
	,
	
		"Madagascar": mg
	,
	
		"Malawi": mw
	,
	
		"Malaysia": my
	,
	
		"Maldives": mv
	,
	
		"Mali": ml
	,
	
		"Malta": mt
	,
	
		"Marshall Islands": mh
	,
	
		"Martinique": mq
	,
	
		"Mauritania": mr
	,
	
		"Mauritius": mu
	,
	
		"Mayotte": yt
	,
	
		"Mexico": mx
	,
	
		"Micronesia": fm
	,
	
		"Moldova": md
	,
	
		"Monaco": mc
	,
	
		"Mongolia": mn
	,
	
		"Montenegro": me
	,
	
		"Montserrat": ms
	,
	
		"Morocco": ma
	,
	
		"Mozambique": mz
	,
	
		"Myanmar": mm
	,
	
		"Namibia": na
	,
	
		"Nauru": nr
	,
	
		"Nepal": np
	,
	
		"Netherlands": nl
	,
	
		"New Caledonia": nc
	,
	
		"New Zealand": nz
	,
	
		"Nicaragua": ni
	,
	
		"Niger": ne
	,
	
		"Nigeria": ng
	,
	
		"Niue": nu
	,
	
		"Norfolk Island": nf
	,
	
		"North Korea": kp
	,
	
		"North Macedonia": mk
	,
	
		"Northern Mariana Islands": mp
	,
	
		"Norway": no
	,
	
		"Oman": om
	,
	
		"Pakistan": pk
	,
	
		"Palau": pw
	,
	
		"Palestine": ps
	,
	
		"Panama": pa
	,
	
		"Papua New Guinea": pg
	,
	
		"Paraguay": py
	,
	
		"Peru": pe
	,
	
		"Philippines": ph
	,
	
		"Poland": pl
	,
	
		"Portugal": pt
	,
	
		"Puerto Rico": pr
	,
	
		"Qatar": qa
	,
	
		"Réunion": re
	,
	
		"Romania": ro
	,
	
		"Russia": ru
	,
	
		"Rwanda": rw
	,
	
		"Saint Barthélemy": bl
	,
	
		"Saint Helena": sh
	,
	
		"Saint Kitts and Nevis": kn
	,
	
		"Saint Lucia": lc
	,
	
		"Saint Martin": mf
	,
	
		"Saint Pierre and Miquelon": pm
	,
	
		"Saint Vincent and the Grenadines": vc
	,
	
		"Samoa": ws
	,
	
		"San Marino": sm
	,
	
		"São Tomé and Príncipe": st
	,
	
		"Saudi Arabia": sa
	,
	
		"Senegal": sn
	,
	
		"Serbia": rs
	,
	
		"Seychelles": sc
	,
	
		"Sierra Leone": sl
	,
	
		"Singapore": sg
	,
	
		"Sint Maarten": sx
	,
	
		"Slovakia": sk
	,
	
		"Slovenia": si
	,
	
		"Solomon Islands": sb
	,
	
		"Somalia": so
	,
	
		"South Africa": za
	,
	
		"South Korea": kr
	,
	
		"South Sudan": ss
	,
	
		"Spain": es
	,
	
		"Sri Lanka": lk
	,
	
		"Sudan": sd
	,
	
		"Suriname": sr
	,
	
		"Svalbard and Jan Mayen": sj
	,
	
		"Sweden": se
	,
	
		"Switzerland": ch
	,
	
		"Syria": sy
	,
	
		"Taiwan": tw
	,
	
		"Tajikistan": tj
	,
	
		"Tanzania": tz
	,
	
		"Thailand": th
	,
	
		"Timor-Leste": tl
	,
	
		"Togo": tg
	,
	
		"Tokelau": tk
	,
	
		"Tonga": to
	,
	
		"Trinidad and Tobago": tt
	,
	
		"Tunisia": tn
	,
	
		"Turkey": tr
	,
	
		"Turkmenistan": tm
	,
	
		"Turks and Caicos Islands": tc
	,
	
		"Tuvalu": tv
	,
	
		"U.S. Virgin Islands": vi
	,
	
		"Uganda": ug
	,
	
		"Ukraine": ua
	,
	
		"United Arab Emirates": ae
	,
	
		"United Kingdom": gb
	,
	
		"United States": us
	,
	
		"Uruguay": uy
	,
	
		"Uzbekistan": uz
	,
	
		"Vanuatu": vu
	,
	
		"Vatican City": va
	,
	
		"Venezuela": ve
	,
	
		"Vietnam": vn
	,
	
		"Wallis and Futuna": wf
	,
	
		"Western Sahara": eh
	,
	
		"Yemen": ye
	,
	
		"Zambia": zm
	,
	
		"Zimbabwe": zw
	,
	
		"Åland Islands": ax
	
}