import { useRef, useEffect, useState, useCallback } from "react";
import './styles.css'

export const ScrollContainer = ({ children, viewedMsgRef, displayForm }) => {
  const outerDiv = useRef(null);
  const innerDiv = useRef(null);

  const [showMessages, setShowMessages] = useState(false);

  useEffect(() => {
    if(children){
    setShowMessages(true)
    handleScrollButtonClick()
    }
  }, [children, viewedMsgRef]);

  const handleScrollButtonClick = useCallback(() => {
    const outerDivHeight = outerDiv.current.clientHeight;
    const innerDivHeight = innerDiv.current.clientHeight;
    const innerDivEndPosition = innerDiv.current.getBoundingClientRect().bottom;
    const viewedMsgEndPosition = viewedMsgRef?.current?.getBoundingClientRect()?.bottom;
    outerDiv.current.scrollTo({
      top: viewedMsgRef?.current ? (innerDivHeight - (outerDivHeight + innerDivEndPosition - viewedMsgEndPosition)): (innerDivHeight - outerDivHeight) ,
      left: 0,
      behavior: "smooth"
    });

  }, []);

  return (
    <div style={{height:'100%',position:'relative'}}>
      <div className="outer-div" style={{height:displayForm? 'calc(100% - 145px)':'calc(100% - 68px)', position:'relative', overflowY:'scroll'}} ref={outerDiv}>
        <div
          style={{ opacity: showMessages ? 1 : 0, position:'relative', transition:'all 0.3s' }}
          ref={innerDiv}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
