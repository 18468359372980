import { SnackbarContent, closeSnackbar } from 'notistack';
import colors from '../../design/colors';
import successCloseIcon from '../../../assets/status-success-close-icon.svg'
import errorCloseIcon from '../../../assets/status-error-close-icon.svg'
import React from 'react';

const commonStyles = {
	bg: {
		cursor: "default",
		borderRadius: "6px",
		padding: '16px',
		textAlign: "center",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		userSelect: "text",
	},
	text: {
		lineHeight: "24px",
		marginRight: '8px',
		userSelect: "all",
		flex: 1,
		textAlign: "left",
	}
}

const snackbarStyles = {
	error: {
		text: {
			color: colors.ic_red_400,
			...commonStyles.text,
		},
		bg: {
			backgroundColor:  colors.ic_red_100,
			borderWidth: "1px",
			borderStyle: "solid",
			borderColor: colors.ic_red_400,
			...commonStyles.bg
		}
	},
	success: {
		text: {
			color: colors.ic_green_400,
			...commonStyles.text,

		},
		bg: {
			backgroundColor:  colors.ic_green_100,
			borderWidth: "1px",
			borderStyle: "solid",
			borderColor: colors.ic_green_400,
			...commonStyles.bg,
		}
	},
	closeIconContainer: {
		paddingInline: "5px",
		cursor: "pointer",
	}
}

const iconMapping = {
	success: successCloseIcon,
	error: errorCloseIcon,
}

const handleDismiss = () => closeSnackbar()

const CustomSnackbar = React.forwardRef(function ({ message, variant, id, title='Click to copy entire message', styleOverride={} }, ref) {
	return <SnackbarContent style={{...snackbarStyles[variant].bg, ...styleOverride}} ref={ref}>
		<div title={title} className="medium-font t6" style={snackbarStyles[variant].text}>{message}</div>
		<div style={snackbarStyles.closeIconContainer} onClick={handleDismiss}>
			<img title='Click to dismiss' src={iconMapping[variant]} alt='close icon'/>
		</div>
	</SnackbarContent>
})

export default CustomSnackbar