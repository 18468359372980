import { createSlice } from '@reduxjs/toolkit'

let initial = null
try{
    initial = JSON.parse(localStorage.getItem('frejun-campaigns'))
}catch(err){
    console.warn(err, ' Setting up local storage for first (re)use.')
}

initial = initial?initial:[]

export const campaignSlice = createSlice({
    name:'campaign',
    initialState: {value:Object.keys(initial).length === 0?[]:initial},
    reducers:{
        storeCampaigns: (state, action) => {
            state.value = action.payload
            localStorage.setItem('frejun-campaigns', JSON.stringify(state.value))
            // TODO: logout needs to lcear out this state.value as well
        }
    }
})

export const { storeCampaigns } = campaignSlice.actions
export default campaignSlice.reducer