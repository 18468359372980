import { Box } from "@mui/material";
import Button from "../../design/Button";
import "./topNavStyles.css";
import AnimationPage from "../animation-page/AnimationPage";
import { useState } from "react";
const DialerErrorScreen = ({ title, img, refreshFn }) => {
  const [loading, setLoading] = useState(false);
  const texts = {
    "No virtual number": (
      <p>
        Contact <a href="hello@frejun.com">hello@frejun.com</a> for virtual
        number.
      </p>
    ),
    "Error : Call not initiated": (
      <p>
        Contact <a href="hello@frejun.com">hello@frejun.com</a>
      </p>
    ),
    "No internet": <p>Check your internet connection and try again.</p>,
  };
  return (
    <Box
      sx={{
        height: "fit-content",
        width: "312px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItem: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "48px 16px",
          height: "399px",
          width: "312px",
        }}
      >
        {loading && (
          <AnimationPage styles={{ width: "312px", margin: "0 auto" }} />
        )}
        {!loading && (
          <>
            <img
              style={{
                marginBottom: "32px",
                width: "182px",
                height: "135px",
              }}
              src={img}
            />
            <div
              style={{ marginBottom: "8px" }}
              className="t6 medium-font nc-gray-900"
            >
              {title}
            </div>
            <div
              className="t7 regular-font nc-gray-900"
              style={{
                textAlign: "center",
                lineHeight: "22px",
                width: "174px",
                marginBottom: "24px",
              }}
            >
              {texts[title]}
            </div>
            <Button
              hierarchy="green"
              variant="contained"
              buttonText="Refresh"
              onClick={() => {
                setLoading(true);
                refreshFn();
              }}
              disabled={false}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default DialerErrorScreen;
