import { createSlice } from '@reduxjs/toolkit'

export const settingsOverlaySlice = createSlice({
    name: 'globalErrorMessage',
    initialState: {value: false},
    reducers: {
        toggleSettingsOverlay: (state, action) => {
            state.value = action.payload
            localStorage.setItem('frejun-settings-overlay', state.value)
        }
    }

})

export const { toggleSettingsOverlay } = settingsOverlaySlice.actions
export default settingsOverlaySlice.reducer