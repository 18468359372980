import { useSelector } from "react-redux";
import { CustomInput } from "../design/components/customComponents"
import { useEffect, useMemo, useState } from "react";
import styles from './BillingAddressForm.module.css'
import axios from "axios";
import { Autocomplete, Box, TextField } from "@mui/material";
import colors from "../design/colors";
import AnimationPage from "../shared/animation-page/AnimationPage";
import { topNavAccountSelectedAccountTickIcon } from "../../utility-functions/assetLoader";
import { enqueueSnackbar } from 'notistack';

const BillingAddressForm=({companyName,address,gstNumber,handleGstNumberChange,handleCompanyNameChange,handleAddressChange,gstOptional,...props})=>{
    const [countries,setCountries]=useState([])
    const [value, setValue] = useState({label_en:'India',iso2_code:'IN'});
    const [inputValue, setInputValue] = useState('India');

    const [showLoader,setShowLoader]=useState(false)

    useEffect(()=>{
      if(props.activeTab!=='Billing' && !props.visible) return;
        (async()=>{
            // const res1=await axios.get(`
            // https://public.opendatasoft.com/api/explore/v2.1/catalog/datasets/countries-codes/records?select=iso2_code%2C%20label_en&limit=100`)
            
            // const res2=await axios.get(`https://public.opendatasoft.com/api/explore/v2.1/catalog/datasets/countries-codes/records?select=iso2_code%2C%20label_en&limit=100&offset=100`)
            // const res3=await axios.get(`https://public.opendatasoft.com/api/explore/v2.1/catalog/datasets/countries-codes/records?select=iso2_code%2C%20label_en&limit=100&offset=200`)
            // setCountries([...res1,...res2,...res3])
            // if(res?.['status-code']===200){
            //     setCountries(res.data)
            // }
            try{
            setShowLoader(true)
            let url=`https://public.opendatasoft.com/api/explore/v2.1/catalog/datasets/countries-codes/records?select=iso2_code%2C%20label_en&limit=100`
            const res=await Promise.all([axios.get(url),axios.get(url+'&offset=100'),axios.get(url+'&offset=200')])
            const data = res.map((res) => res.data.results);
            setCountries(data.flat())
            }catch{
                setCountries([])
				enqueueSnackbar('Could not retrieve list of countries', { variant: "error"})
            }finally{
                setShowLoader(false)
            }

        })()
        
    },[props.activeTab,props.visible])

    useEffect(()=>{
      if(!countries || !countries.length || !address || !address.country) return;

      const country=countries.find(elem=>elem.iso2_code==address.country)
      if(country){
      setValue(country)
      setInputValue(country.label_en)
      }
    },[countries,address])

    const reduxAccount=useSelector((state)=>state?.account?.value)
    useEffect(()=>{
        if(!reduxAccount) return;
        const selectedOrg=reduxAccount?.selectedOrg
        if(selectedOrg){
        const selectedOrgDetails=reduxAccount.allOrgs?.find(org=>org.id===selectedOrg)
        handleCompanyNameChange('value',selectedOrgDetails?.company)
        }else{
            handleCompanyNameChange('value',reduxAccount?.allOrgs[0]?.company || '')
        }

    },[reduxAccount])

    // const selectedCountry = useMemo(
    //     () => countries?.find((elem) => address?.country===elem?.iso2_code) || ''
    //     [countries],
    //   );

    if(showLoader)
    return <AnimationPage/>

    return (
        <div className={styles.billingInfoForm} style={{...props.style}}>
        <CustomInput label='Company name' name='company' type='text' value={companyName} handleChange={handleCompanyNameChange} handleFocus={()=>handleCompanyNameChange('focus')} style={{width:'101%'}}/>
                <label>
                <span className='t7 medium-font'>Address</span>
                    <input style={{...props.style}} type='text' placeholder='Address line 1' value={address.line1} name='line1' onChange={handleAddressChange}/> 
                    <input style={{...props.style}} type='text' placeholder='Address line 2' value={address.line2} name='line2' onChange={handleAddressChange}/> 
                    <div>
                        <input type='text' placeholder='City' value={address.city} name='city' onChange={handleAddressChange}/> 
                        <input type='text' placeholder='ZIP Code' value={address.zip} name='zip' onChange={handleAddressChange}/>   
                    </div>
                    <div className={styles.flexContainer}>
                        <input type='text' placeholder='State' value={address.state} name='state' onChange={handleAddressChange}/> 
                        {/* <input disabled type='text' placeholder='Country' value={'India'} name='country' onChange={()=>null}/>    */}
                        <Autocomplete
                        id="country-select"
                        sx={{
                            fontSize:'14px',
                            fontWeight:'400',
                            fontFamily:'Inter',
                            color: colors.nc_gray_900,
                            width: 179,
                            height: "36px",
                            display:"inline-block",
                            marginTop:0,
                            "& .MuiFormControl-root": {
                              height: "100%",
                            },
                            "& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.MuiAutocomplete-input.MuiAutocomplete-inputFocused.MuiInputBase-input-MuiOutlinedInput-input":{
                              fontFamily:'Inter',
                              fontWeight:'400'
                            },
                            "& .MuiInputBase-root": {
                              height: "100%",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              flexWrap: "nowrap",
                              alignItems: "center",
                              maxWidth: "360px",
                              fontFamily:'Inter',
                              ".Mui-focused:": {
                                border: `1px solid ${colors.ic_green_500}`,
                                boxShadow: "0px 0px 0px 4px #F0FCF4",
                              },
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: `1px solid ${colors.nc_gray_300}`,
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              border: `1px solid ${colors.nc_gray_300}`,
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              border: `1px solid ${colors.ic_green_300}`,
                            },
                            ".Mui-focused":{
                              border: `1px solid ${colors.ic_green_300}`,
                              outline:'none'
                            },
                            
                          }}
                        
                        options={countries}
                        value={value}
                        onChange={(event, newValue) => {
                          handleAddressChange(newValue?.iso2_code,'country');
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue)
                          }}
                        autoHighlight
                        getOptionLabel={(option) => option.label_en}
                        renderOption={(props, option) => (
                            <Box component="div"  {...props} sx={{position:'relative',display:'flex',alignItems:'center',justifyContent:'space-between',fontSize:'14px',}}>
                            
                            <span>{option.label_en}</span>
                            <div style={{position:"absolute", right:'6px'}}>{value?.iso2_code===option?.iso2_code && topNavAccountSelectedAccountTickIcon()}</div>
                            </Box>
                        )}
                        selectOnFocus={false}
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            placeholder="Country"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                            InputLabelProps={{ shrink: false}}
                            />
                        )}
                        />
                    </div>
                </label>
                <label style={{...props.style}}>
                <span className='t7 medium-font'>GST number <span className="t7 regular-font" style={{color:colors.nc_gray_700}}>{gstOptional && '(Optional)'}</span></span>
                    <input style={{...props.style}} type='text' value={gstNumber} onChange={(e)=>handleGstNumberChange(e.target.value)}/>
                </label>
        </div>
    )
}

export default BillingAddressForm