import { useEffect, useState } from 'react'
import Button from '../design/Button'
import { CustomInput, CustomSwitch } from '../design/components/customComponents'
import styles from './CreditsTab.module.css'
import AutoRechargeModal from './Modals/AutoRechargeModal'
import CreditHistoryModal from './Modals/CreditHistoryModal'
import { JService } from '../api-service/ApiService'
import { useDispatch, useSelector } from 'react-redux'
import AnimationPage from '../shared/animation-page/AnimationPage'
import { setCredits } from '../features/account/account'
import { enqueueSnackbar } from 'notistack'

const CreditsTab=({setActiveTab})=>{
    const API_BASE_URL=process.env.REACT_APP_BETA
    // const reduxCredits=useSelector(state=>state?.account?.credits)
    const reduxSubscription=useSelector((state)=>state?.account?.value?.subscription)
    const reduxAccount = useSelector((state) => state?.account?.value);
    
    const dispatch=useDispatch()

    const [data,setData]=useState({})
    const [addCredits,setAddCredits]=useState({value:null,error:null})
    const [subscriptionPayments,setSubscriptionPayments]=useState(data?.subscription_payments)
    const [notifyLowBalance,setNotifyLowBalance]=useState(Number(data?.notification_threshold)>0)
    const [autoRecharge,setAutoRecharge]=useState(false)
    const [notifyValue,setNotifyValue]=useState(Number(data?.notification_threshold)>0?data?.notification_threshold:0)
    const [notifyBtnDisabled,setNotifyBtnDisabled]=useState(true)
    const [autoRechargeModalVisible, setAutoRechargeModalVisible]=useState(false)
    const [creditHistoryModalVisible,setCreditHistoryModalVisible]=useState(false)
    const [showLoader,setShowLoader]=useState(false)
    const [isLoading,setIsLoading]=useState(false)
   
    const checkBillingAddress=()=>{
        if(!reduxSubscription || !reduxSubscription?.billing_address || !Object.keys(reduxSubscription.billing_address).length)
            return false
        const {billing_address}=reduxSubscription
        for(let key in billing_address){
            if(key=='line2') continue;
            if(!billing_address[key] || !billing_address[key]?.trim()?.length){
                return false
            }
        }
        return true
    }
    const fetchCreditDetails=()=>{
        setShowLoader(true)
        let orgUrlParam = "";
        if (
          reduxAccount?.data?.profile?.account_holder &&
          reduxAccount?.allOrgs?.length > 1
        ) {
          orgUrlParam = reduxAccount?.selectedOrg + "/";
          if (!reduxAccount?.selectedOrg && reduxAccount?.selectedOrg !== 0)
            orgUrlParam = "";
        }
        JService.get(API_BASE_URL+`/api/v1/subscriptions/credit/${orgUrlParam}`)
        .then((res)=>{
            if(!res?.success) throw new Error(res?.message || 'Failed to retrieve credit details')
            setData(res?.data)
            dispatch(setCredits(res?.data))
            setSubscriptionPayments(res?.data?.subscription_payments)
            setNotifyLowBalance(res?.data?.notification_threshold>0)
            setNotifyValue(Number(res?.data?.notification_threshold)>0?res?.data?.notification_threshold:0)
            setAutoRecharge(res?.data?.auto_recharge)
        })
        .catch((err)=>{
            setData({})
			enqueueSnackbar(err?.message, { variant: "error"})
        })
        .finally(()=>{
            setShowLoader(false)
        })
    }

    const handleUpdateCredit=(updateField,event)=>{
        let payload={}
        if(updateField==='subscription_payments') 
            payload={ "subscription_payments": event.target.checked}
        else if(updateField==='notification_threshold'){
            payload['notification_threshold']=notifyValue
            setNotifyBtnDisabled(true)
        }
        else if (updateField==='notify_low_balance'){
            if(!event.target.checked){
                payload['notification_threshold']= -9e5;
            }else{
                setNotifyLowBalance(true)
                return;
            }
        }
        let orgUrlParam = "";
        if (
          reduxAccount?.data?.profile?.account_holder &&
          reduxAccount?.allOrgs?.length > 1
        ) {
          orgUrlParam = reduxAccount?.selectedOrg + "/";
          if (!reduxAccount?.selectedOrg && reduxAccount?.selectedOrg !== 0)
            orgUrlParam = "";
        }
            
        JService.patch(API_BASE_URL+`/api/v1/subscriptions/credit/${orgUrlParam}`,JSON.stringify(payload))
        .then((res)=>{
            if(!res?.success)
                throw new Error(res?.message || 'Failed to update the credit details')
            dispatch(setCredits(res?.data))
            setSubscriptionPayments(res?.data?.subscription_payments)
            setNotifyValue(Number(res?.data?.notification_threshold)>0?res?.data?.notification_threshold:0)
            setNotifyLowBalance(res?.data?.notification_threshold>0)
			enqueueSnackbar(res?.message || 'Successfully updated the credit details', { variant: "success"})
        })
        .catch(err=>{
			enqueueSnackbar(err?.message, { variant: "error"})
        })
    }

    const handleAddCredits=()=>{
         let isValid=checkBillingAddress()
        
        if(!isValid){
            setAddCredits(prev=>({...prev,error:<span>Please enter <span onClick={()=>{setActiveTab('Billing')}} style={{cursor:'pointer',textDecoration:'underline'}}>billing information</span> to add credits</span>}))
            return
        }
        if(!addCredits.value || addCredits.value<=0) return;
        let orgUrlParam = "";
        if (
          reduxAccount?.data?.profile?.account_holder &&
          reduxAccount?.allOrgs?.length > 1
        ) {
          orgUrlParam = reduxAccount?.selectedOrg + "/";
          if (!reduxAccount?.selectedOrg && reduxAccount?.selectedOrg !== 0)
            orgUrlParam = "";
        }
        setIsLoading(true)
        JService.post(`/api/v1/subscriptions/top-up-checkout/${orgUrlParam}`,JSON.stringify({
            "amount": parseInt(addCredits.value, 10)
        })).then((res)=>{
            if(res?.url){
                window.location.href=res?.url
            }else{
                throw new Error(res?.message || 'Failed to add credits')
            }
        }).catch(err=>{
			enqueueSnackbar(err?.message, { variant: "error"})
        }).finally(()=>{
            setAddCredits({value:null,error:null})
            setIsLoading(false)
        })

    }   
    const handleCreditHistoryModalClose=(event,reason)=>{

            if (reason !== 'backdropClick') {
              setCreditHistoryModalVisible(false)
            }
         
    }
    useEffect(()=>{
        fetchCreditDetails()
    },[reduxAccount?.selectedOrg])
    return (
        <div className={styles.container}>
            {showLoader && <AnimationPage/>}
            {!showLoader && <><AutoRechargeModal visible={autoRechargeModalVisible} handleClose={()=>setAutoRechargeModalVisible(false)}/>
             <CreditHistoryModal currency={data?.currency==="USD"?'$':'₹'} visible={creditHistoryModalVisible} handleClose={handleCreditHistoryModalClose}/> 
            <h2 className='t5 medium-font'>Credits ({data?.currency})</h2>
			<div className={styles.credits}>{`${data?.balance<0?'-':''}${data?.currency==="USD"?'$':'₹'}`}{!isNaN(Number(data?.balance))? Math.abs(Number(data?.balance)).toLocaleString(data?.currency==='INR'?'en-IN':"en-US"):0}</div>
            <Button buttonText="View usage" variant="outlined" onClick={()=>setCreditHistoryModalVisible(true)} disabled={false} hierarchy="white" styleOverride={{padding:'10px 16px',margin:'16px 0', fontSize:'14px', lineHeight:'14px',fontWeight:'500',height:'36px'}}/>
            <p className='t7 regular-font'>Credits are used for calling and transcription. It can also be used for subscription <br/> payments.</p>
            <form className={styles.addCreditForm}>
                <CustomInput children={<div className={styles.inputgroup}><span  style={{position: 'absolute',transform: 'translateY(-130%)',right: '8px'}}>{data?.currency==="USD"?'$':'₹'}</span></div>} errorStyle={{width:'300px',position:'absolute',bottom:'5%'}} style={{paddingLeft:'20px',marginBottom:'0',width:'200px'}} label='Add credits' type='number' value={addCredits} handleChange={e=>setAddCredits(prev=>({...prev,value:e.target.value}))} handleFocus={e=>setAddCredits(prev=>({...prev,error:null}))} name='credits' />
                <Button hierarchy='green' disabled={!addCredits.value} buttonText='Add' onClick={handleAddCredits} styleOverride={{height:'36px',position:'absolute',top:'24px',left:addCredits.error?'248px':'208px'}} isLoading={isLoading}/>
            </form>
            <form className={styles.switchForm}>
                <CustomSwitch label='Subscription payments' checked={subscriptionPayments} onChange={(e)=>handleUpdateCredit('subscription_payments',e)} description='Automatically pay for your subscriptions using credits.'/> 
                <CustomSwitch label='Notify low balance' checked={notifyLowBalance} onChange={(e)=>handleUpdateCredit('notify_low_balance',e)} description='Receive email notification when balance falls below:'/> 
                <label className={styles.inputLabel}>
                    <div className={styles.inputgroup}>
                        <span >{data?.currency==="USD"?'$':'₹'}</span>
                    <input disabled={!notifyLowBalance} className={styles.textInput} type='number' value={notifyValue} onChange={e=>{setNotifyValue(e.target.value);setNotifyBtnDisabled(false)}}/>

                    </div>
                    <Button hierarchy='green' disabled={!notifyValue || notifyValue<=0 || notifyBtnDisabled} buttonText='Save' onClick={()=>handleUpdateCredit('notification_threshold')} styleOverride={{height:'36px'}}/>
                </label>
               {/* {data?.currency==='USD' && <CustomSwitch label='Auto recharge' checked={autoRecharge} onChange={(e)=>setAutoRecharge(e.target.checked)} description={<p>Automatically recharge <span className='medium-font'>{'$100'}</span> when balance falls below <span className='medium-font'>{'$10'}</span>.<button type="button" className={`b2 regular-font ${styles.editBtn}`} onClick={()=>setAutoRechargeModalVisible(true)}>Edit</button></p>}/> } */}
            </form>
            </>}
        </div>
    )
}


export default CreditsTab