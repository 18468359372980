export default {
	/* interface colors */

		/* green */
		ic_green_500: '#28994B',
		ic_green_400: '#2EB358',
		ic_green_300: '#34CB65',
		ic_green_200: '#CAFCDA',
		ic_green_100: '#F0FCF4',

		/* red */
		ic_red_500: '#B2433D',
		ic_red_400: '#CC4C45',
		ic_red_300: '#E0544C',
		ic_red_200: '#FCCCCA',
		ic_red_100: '#FCF0F0',
		ic_white: '#ffffff',
	
		/* neutral colors */
		nc_gray_1000: '#2E3330',
		nc_gray_900: '#464D48',
		nc_gray_800: '#5F6661',
		nc_gray_700: '#78807A',
		nc_gray_600: '#919994',
		nc_gray_500: '#ABB3AE',
		nc_gray_400: '#C6CCC8',
		nc_gray_300: '#E1E5E2',
		nc_gray_200: '#F5F7F6',
		nc_gray_100: '#F7FAF8',
	
		/* tag colors */
		tc_green: '#EDFCF2',
		tc_border_green: '1px solid #D3F8DF',
		tc_red: '#FFF1F3',
		tc_border_red: '1px solid #FFE4E8',
		tc_blue: '#F0F9FF',
		tc_border_blue: '1px solid #E0F2FE',
		tc_gray: '#F8FAFC',
		tc_border_gray: '1px solid #EEF2F6',
		tc_deepblue: '#EEF4FF',
		tc_border_deepblue: '1px solid #E0EAFF',
		tc_violet: '#F4F3FF',
		tc_border_violet: '1px solid #EBE9FE',
		tc_pink: '#FDF4FF',
		tc_border_pink: '1px solid #FBE8FF',
		tc_bluegreen: '#F0FDF9',
		tc_border_bluegreen: '1px solid #CCFBEF',
		tc_orange: '#FFF4ED',
		tc_border_orange: '1px solid #FFE6D5',
		tc_yellow: '#FEFBE8',
		tc_border_yellow: '1px solid #FEF7C3',
}