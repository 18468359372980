import { useMemo } from "react";
import GTable from "../design/components/GTable";
import { Box, Menu, MenuItem } from "@mui/material";
import { threeDotsIcon } from "../../utility-functions/assetLoader";
import { capitalizeString, } from "../../utility-functions";
import { useState, useEffect } from "react";
import Button from "../design/Button";
import { useSelector } from "react-redux";
import { CustomSwitch } from "../design/components/customComponents";
import { CustomInput } from "../design/components/customComponents";
import { CCDropdown } from "../design/components/customComponents";
import { Height, Margin } from "@mui/icons-material";
const CallingSMSTab=(props)=>{

const reduxSubscription = useSelector((state)=>state?.account?.value?.subscription)
const reduxAccount = useSelector((state)=>state?.account?.value)

const [userProfile, setUserProfile]=useState();
const [currentVn, setCurrentVn]=useState({});
const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);
const [isDropdownVisible, setIsDropdownVisible] = useState(false);
const [userPhoneNumber, setUserPhoneNumber] = useState({value:null});
const [browserCallToggleVisible, setBrowserCallToggleVisible]=useState(false);
const [countryCodeDrpdown,setCountryCodeDropdown]=useState(null)

useEffect(()=>{
    if(props.userProfile){
        if (props?.userProfile.number) {
            const phoneNumber = props.userProfile.number?.phone_number;
            setUserPhoneNumber({value:phoneNumber.substring(phoneNumber.length - 10)});
            setCountryCodeDropdown(props.userProfile.number?.country_code)
          }
    }
},[props.userProfile])
useEffect(()=>{
  if(!reduxSubscription || !reduxAccount) return;
  if(!reduxSubscription?.plan?.name?.includes('INR')){
    setBrowserCallToggleVisible(false)
    return;
  }
 if(reduxAccount?.data?.access_list?.includes('BROWSER CALLING')){
  setBrowserCallToggleVisible(true)
 }
},[reduxSubscription, reduxAccount])

useEffect(() => {
  if (props.userProfile) {
    setUserProfile(props.userProfile.profile);
  }
}, [props.userProfile]);


// useEffect(() => {
//   (async()=>{
//     if (!props.userProfile?.profile || !userProfile) return;
//   const toggleFields = [
//     "forward_calls",
//     "browser_calls"
//   ];
//   for (const field of toggleFields) {
//     if (props.userProfile.profile[field] !== userProfile[field]) {
//      const res= await props.handleUpdateCallSettings({[field]:userProfile[field]});
//      if('success' in res && !res.success){
//       setUserProfile(res.profile?.profile)
//      }
//       break;
//     }
//   }
//   })()
  
// }, [props.userProfile?.profile, userProfile, props.handleUpdateCallSettings]);
const handleUpdateCallSettings= async (e, field)=>{
  const value=e.target.checked;
  setUserProfile(prev=>({...prev, [field]:value}))
  const res = await props.handleUpdateCallSettings({[field]:value})
  if(res && 'success'in res && !res.success){
  setUserProfile(prev=>(res?.profile?.profile || {...prev,[field]:!value}))
  }
}

// const hasPhoneNumberInfoChanged = () => {
//   let phoneNumber = "";
//   if (props.userProfile?.number) {
//     phoneNumber = props.userProfile?.number?.phone_number;
//   }
//   return (
//     userPhoneNumber !== phoneNumber.substring(phoneNumber.length - 10) &&
//     userPhoneNumber.length === 10
//   );
// };

function threeDotsAutoClose(e) {
    const path = e.composedPath()?.map((pathItem) => {
      let newPathItem = null;
      newPathItem =
        pathItem?.tagName?.toLowerCase() +
        (pathItem?.className ? "." + pathItem?.className : "");
      return newPathItem;
    });

    if (!path?.length || path[0]?.includes("div.MuiBackdrop-root")) {
      // clicked outside
      setIsDropdownVisible(false);
      setDropdownAnchorEl(null);
      return;
    }
  }
  function handleThreeDotsClick(e, data) {
    e.stopPropagation();
    setCurrentVn(data);
    setIsDropdownVisible(true);
    setDropdownAnchorEl(e.currentTarget || null);
  }



const handleDefaultNumberUpdate=(data, defaultFor)=>{
        setIsDropdownVisible(false);
        setDropdownAnchorEl(null); 
    
        props.handleDefaultNumberUpdate(data,defaultFor)
      }
    
      useEffect(() => {
        if (isDropdownVisible) window.addEventListener("click", threeDotsAutoClose);
        else window.removeEventListener("click", threeDotsAutoClose);
    
        return () => {
          window.removeEventListener("click", threeDotsAutoClose);
        };
      }, [isDropdownVisible]);

    const cols = useMemo(()=>[
        {
          field: "number_name",
          headerName: "Number name",
          flex: 1.8,
          headerClassName: "user-table-header-user-name",
          renderCell: (params) => {
            return <p style={{ marginLeft: "14px" }}>{params?.row?.name}</p>;
          },
        },
        {
          field: "number",
          headerName: "Virtual number",
          flex: 1.86,
          renderCell: (params) => {
            return <p>{params?.row?.country_code + " " + params?.row?.number}</p>;
          },
        },
        {
          field: "location",
          headerName: "Location",
          flex: 1.86,
          renderCell: (params) => {
            return <p>{params?.row?.location}</p>;
          },
        },
        {
          field: "calls",
          headerName: "Calls",
          flex: 1.86,
          renderCell: (params) => {
            let calls = "";
            if (params?.row?.incoming) {
              calls = "Incoming";
            }
    
            if (params?.row?.outgoing) {
              calls += calls.length > 0 ? ", Outgoing" : "Outgoing";
            }
    
            return <p>{calls}</p>;
          },
        },
        {
          field: "type",
          headerName: "Type",
          flex: 1.9,
          renderCell: (params)=>{
              const {default_calling_number, default_sms_number}=params?.row
              return <Box sx={{display:'flex', alignItems:"center", justifyContent:'space-between'}}>
                {(params?.row?.default_calling_number || params?.row?.default_sms_number) ?
                <span style={localStyles.numberTag}>Default - {default_calling_number && 'Calling'}{default_calling_number && default_sms_number && ' & '}{default_sms_number && 'SMS'}</span>
                 :
                 <span>{params?.row?.type}</span>
                }
                </Box>
          }
        },
        {
          field: "vnOption",
          headerName: "",
          flex: 0.5,
          cellClassName: "table-cell-edit", // @important add this field to your threedots component
          renderCell: (params) => {
            return (
              <Box
                style={{
                  border: `1px solid #e1e5e2`,
                  borderRadius: "4px",
                  padding: "0 8px 4px 8px",
                }}
                onClick={(e) => handleThreeDotsClick(e, params?.row)}
              >
                {threeDotsIcon()}
              </Box>
            );
          },
        },
       
      ],[]);
    return (
        <div className="call-sms-tab">
        <div className="section">
        <div className="t5 medium-font nc-gray-900">Virtual Numbers</div>
        
        	{/* check Settings.css for the styles applied to the container */}
		<div className='general-tab-vn-gtable-height-setter'>
        <GTable
        leftHeader={{
            filters: [],
        }}
        rightHeader={{}}
        rows={!!props.userVirtualNumber ? props.userVirtualNumber : []}
        columns={cols}
        useMuiTable={true}
        isLoading={false}
        getTableRowClassName={() => `teams-table-row`}
        additionalProps={{ bgHover: false }}
        hideHeader
        onRowClick={()=>null}
        />
  <Menu
    sx={{ "& .MuiList-root": { width: "260px" } }}
    open={isDropdownVisible}
    anchorEl={dropdownAnchorEl}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
  >
    <MenuItem
      onClick={(e) =>handleDefaultNumberUpdate(currentVn, 'calling') }
      sx={{ cursor: "pointer" }}
    >
     Set as Default for calling
    </MenuItem>

   {reduxSubscription?.plan?.name?.includes('USD') && <MenuItem
      onClick={(e) =>handleDefaultNumberUpdate(currentVn, 'sms')}
      sx={{ cursor: "pointer" }}
    >
     Set as Default for SMS
    </MenuItem>}

    {reduxSubscription?.plan?.name?.includes('USD') && <MenuItem
      onClick={(e) =>handleDefaultNumberUpdate(currentVn, 'both')}
      sx={{ cursor: "pointer" }}
    >
     Set as Default for Calling & SMS
    </MenuItem>}
  </Menu>
    </div>
    <label >
      <div style={{display:'flex'}}>
      Connected to
      </div>
      
   <div style={{ width:'200px'}}>
    <CustomInput
     style={{marginTop:'0px',height:'36px'}} 
     dropdown={<CCDropdown CCDropdownstyle={{width:'250px',marginTop:'0px',border:'none'}} 
     selectedCountryCode={countryCodeDrpdown} handleCountrySelect={(val)=>setCountryCodeDropdown( val)}/>} 
     value={userPhoneNumber} 
     handleChange={(e) => setUserPhoneNumber({value:e.target.value})} />
     </div> 

    </label>
    <div className="section-btn">
      <Button
        variant="filled"
        buttonText="Save"
        icon={{}}
        isLoading={false}
        hierarchy="green"
        disabled={userPhoneNumber.value === props.userProfile?.number?.phone_number.substring(props.userProfile?.number?.phone_number.length - 15) && countryCodeDrpdown === props.userProfile?.number?.country_code}
        onClick={() => props.handleUserPhoneNumberUpdate(userPhoneNumber.value, countryCodeDrpdown)}
      />
    </div>
  </div>
  <div className="settings-sep" />
 {browserCallToggleVisible && <div className="section">
    <div className="t5 medium-font nc-gray-900">Call settings</div>
    <form className="switch-form">
    <label className="switch" style={{ marginTop: "40px" }}>
				<input
				type="checkbox"
				checked={userProfile?.browser_calls}
				onChange={(e)=>handleUpdateCallSettings(e,'browser_calls')}
				style={{ cursor: "pointer", marginTop: "0" }}
				/>
				<span className="slider round"></span>
				<p
				style={{
					marginTop: "-45px",
					marginLeft: "60px",
					width: "400px",
				}}
				className="t6 regular-font nc-gray-900"
				>
				Browser calling
				</p>
				<p
				className="t6 regular-font nc-gray-600"
				style={{ width: "505px", marginLeft: "60px", marginTop: "0" }}
				>
				Make calls directly from desktop, without involving mobile phone.
				</p>
			</label>
      {/* <label className="switch" style={{ marginTop: "40px" }}>
				<input
				type="checkbox"
				checked={userProfile?.forward_calls}
				onChange={(e)=>handleUpdateCallSettings(e,'forward_calls')}
				style={{ cursor: "pointer", marginTop: "0" }}
				/>
				<span className="slider round"></span>
				<p
				style={{
					marginTop: "-45px",
					marginLeft: "60px",
					width: "400px",
				}}
				className="t6 regular-font nc-gray-900"
				>
				Forward calls to mobile <span style={{fontStyle:'italic'}}>(Indian numbers)</span>
				</p>
				<p
				className="t6 regular-font nc-gray-600"
				style={{ width: "505px", marginLeft: "60px", marginTop: "0" }}
				>
				Auto-forward calls from Indian virtual number to mobile when "Away" during business hours.
				</p>
			</label> */}
    {/* {browserCallToggleVisible && <CustomSwitch labelStyles={{marginTop:'-65px'}} checked={props.userProfile?.profile?.browser_calls} onChange={props.handleBrowserCallingToggle} label="Browser calling" description="Make calls directly from desktop, without involving mobile phone."/>} */}
    {/* <CustomSwitch labelStyles={{marginTop:'-65px'}} checked={autoforward} onChange={props.handleAutoForwardToggle} label="Forward calls to mobile (Indian numbers)" description='Auto-forward calls from Indian virtual number to mobile when "Away" during business hours.'/> */}
    </form>
    </div>}
  </div>
    )
}

export default CallingSMSTab;

const localStyles={
    numberTag:{
      borderRadius: '4px',
      border: '1px solid #D3F8DF',
      background:'#EDFCF2',
      padding:'6px 10px'
    }
  }