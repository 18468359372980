import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import playIcon from "../../assets/playIcon.svg";
import pauseIcon from "../../assets/pauseIcon.svg";
import accessDeniedArt from "../../assets/access-denied-art.svg";
import {
  capitalizeString,
  formatDate,
  getHMSfromMins,
} from "../../utility-functions";
import wavesurfer from "wavesurfer.js";
import colors from "../design/colors";
import PageNotFound from '../page-not-found/PageNotFound';
import { ListItemIcon, Menu, MenuItem } from '@mui/material';
import { callRecordingDownloadIcon, callRecordingSettingsIcon, callRecordingTickIcon } from '../../utility-functions/assetLoader';
import styles from '../design/styles';
import { useSelector } from 'react-redux';

const PLAYBACK_SPEEDS = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 2]

function SharedCallRecording() {
  const reduxAccount = useSelector(state => state?.account?.value)
  const API_BASE_URL = process.env.REACT_APP_BETA;
  const { id: guid } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams()
  const ws = useRef();
  const audioRef = useRef();

  const maskNumbers = reduxAccount?.data?.number_masking && reduxAccount?.data?.profile?.role !== 1

  const [recordingData, setRecordingData] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  const [accessDenied, setAccessDenied] = useState(false);
  const [outOfOrg, setOutOfOrg] = useState(false);
  const [linkNotFound, setLinkNotFound] = useState(false); // very old links give res.success == false which was not handled // blank page issue

  const [playbackSpeed, setPlaybackSpeed] = useState(1.0)
  const [isPlaybackMenuVisible, setIsPlaybackMenuVisible] = useState(false)
  const plabackMenuAnchorRef = useRef()
  const downloadRef = useRef()

  useEffect(() => {
    let token = localStorage.getItem("frejun-temp-jwt")
    if(token) token = JSON.parse(token).access_token;

    // const requestHeaders =
    //   !token
    //     ? {
		// 	// headers: { Authorization: "Bearer "}, // if uncommented public url demands login
		// }
    //     : {
    //         headers: { Authorization: "Bearer " + token },
    //       };
	
	let searchQuery = searchParams.get('signature')
	let queryParams = searchQuery ? `?signature=${searchQuery}` : ''
	
	let url = API_BASE_URL + `/api/v1/core/shared-record/${guid}/${queryParams}`
	console.log("url", url)
	
    axios
      .get(url, {withCredentials:true})
      .then((response) => {
        const res = response?.data;
        if (res?.success) {
          setRecordingData(res?.data);
        } else setLinkNotFound(true)
      })
      .catch((err) => {
        if (err?.request?.status === 401) {
          if (token === null) setAccessDenied(true);
          else setOutOfOrg(true);
        }
		if (err?.request?.status === 403) {
			if (token === null) setAccessDenied(true);
			setOutOfOrg(true)
		}
        console.log("ERROR", err);
        console.warn(err?.message);
        console.log(
          "Failed to access call record associated with the link, please request for a new link or contact Frejun"
        );
      }).finally(()=>{
        localStorage.removeItem('frejun-login-redirect')
      })
  }, []);

  useEffect(() => {
    if (audioRef.current && !ws?.current && recordingData?.recording_url) {
      ws.current = wavesurfer.create({
        container: audioRef.current,
        mediaType: "audio",
        backend: "MediaElement",
        xhr: {
          cache: "default",
          mode: "no-cors",
          method: "GET",
          credentials: "include",
          headers: [
            { key: "cache-control", value: "no-cache" },
            { key: "pragma", value: "no-cache" },
          ],
        },
        barWidth: 4,
        barRadius: 3,
        barGap: 0,
        cursorWidth: 1,
        barMinHeight: 12,
        height: 80,
        barHeight: 90,
        waveColor: colors.ic_green_300,
        progressColor: colors.nc_gray_800,
        responsive: true,
      });
      console.log("created", recordingData?.recording_url, ws);
      ws.current.load(recordingData?.recording_url);
      ws.current.drawBuffer();
      // setup onReady handler for getting peaks
      ws.current.on("ready", function () {
        // get peaks
        const peaks = ws.current.backend.getPeaks(300, 0, 300);
        const duration = ws.current.backend.getDuration();
        // console.log({ peaks: JSON.stringify(peaks), duration, bpm: bpm.current });
        // save peaks to DB
      });
    }
  }, [recordingData]);

  const RecordingDetails = () => {
    const { hrs, mins, secs } = getHMSfromMins(recordingData.duration);
    return (
      <form
        className="new-contact-list-form  recording-details"
        style={{ marginTop: "40px" }}
      >
        <label className="t7 medium-font nc-gray-900">
          Date
          <input
            className="t6 regular-font nc-gray-900"
            type="text"
            value={formatDate(recordingData.start)}
            disabled
          />
        </label>
        <label className="t7 medium-font nc-gray-900">
          Call status
          <input
            className="t6 regular-font nc-gray-900"
            type="text"
            value={
              // capitalizeString(recordingData.call_type) +
              // " " +
              capitalizeString(recordingData.call_status)
            }
            disabled
          />
        </label>
        <label className="t7 medium-font nc-gray-900">
          Duration
          <input
            className="t6 regular-font nc-gray-900"
            type="text"
            value={`${hrs ? hrs + "h" : ""} ${mins}m ${secs}s`}
            disabled
          />
        </label>
        <label className="t7 medium-font nc-gray-900">
          Contacted by
          <input
            className="t6 regular-font nc-gray-900"
            type="text"
            value={recordingData.creator}
            disabled
          />
        </label>
        <label className="t7 medium-font nc-gray-900">
          Virtual number
          <input
            className="t6 regular-font nc-gray-900"
            type="text"
            value={
              recordingData?.virtual_number?.country_code +
              " " +
              recordingData?.virtual_number?.number
            }
            disabled
          />
        </label>
      </form>
    );
  };

  const RecordingNotes = () => {
    return (
      <form
        className="new-contact-list-form recording-details"
        style={{ marginTop: "40px" }}
      >
        <label className="t7 medium-font nc-gray-900">
          Call stage
          <input
            style={{ width: "320px" }}
            className="t6 regular-font nc-gray-900"
            type="text"
            value={recordingData?.call_reason}
            disabled
          />
        </label>
        <label className="t7 medium-font nc-gray-900">
          Call outcome
          <input
            style={{ width: "320px" }}
            className="t6 regular-font nc-gray-900"
            type="text"
            value={recordingData?.call_outcome}
            disabled
          />
        </label>
        <label className="t7 medium-font nc-gray-900">
          Call notes
          <textarea
            className="t6 regular-font nc-gray-900 call-notes-textarea"
            type="text"
            value={recordingData?.notes}
            disabled
          />
        </label>
      </form>
    );
  };

  const Denied = () => {
    const handleLoginClick = (e) => {
	  console.log('searchParams:', searchParams.get("signature"))
      localStorage.setItem(
        "frejun-login-redirect",
        JSON.stringify({ url: window.location.pathname +  '?signature=' + searchParams.get("signature"), isRead: false })
      );
      navigate("/login");
    };
    return (
      <div className="access-denied-container nc-gray-900 regular-font t6">
        <img src={accessDeniedArt} />
        <p className="medium-font t5 ">Login to access</p>
        <p className="medium-font t6">
          Log in to your FreJun account to access recording.
        </p>
        <button
          onClick={(e) => handleLoginClick(e)}
          className="btn btn-green medium-font t6"
        >
          Login
        </button>
      </div>
    );
  };

  function OutOfOrg() {
    const navigate = useNavigate();
    const handleSwitchAccountClick = (e) => {
      localStorage.removeItem("frejun-token");
      localStorage.setItem(
        "frejun-login-redirect",
        JSON.stringify({ url: window.location.pathname +  '?signature=' + searchParams.get("signature"), isRead: false })
      );
      navigate("/login");
    };
    return (
      <div className="access-denied-container regular-font nc-gray-900 t6">
        <img src={accessDeniedArt} />
        <p className="medium-font t5">Access Denied</p>
        <p style={{ textAlign: "center" }} className="t6">
          You don’t have permission to access the recording.
          <br />
          Switch to an account with permission.
        </p>
        <button
          onClick={(e) => handleSwitchAccountClick(e)}
          style={{
            width: "50%",
            minWidth: "100px",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
          className="btn btn-green medium-font t6"
        >
          Switch Account
        </button>
      </div>
    );
  }

  function handlePlaybackSpeedChange(speed) {
	console.log(speed)
	setPlaybackSpeed(speed)
	ws.current.setPlaybackRate(speed)
	handlePlaybackPopoverClose()
  }

  function handleOpenSettingsMenu() {
	setIsPlaybackMenuVisible(prev => !prev)
  }

  function handlePlaybackPopoverClose() {
	setIsPlaybackMenuVisible(false)
  }

  function handleDownload() {
	console.log("download container clicked")
	downloadRef.current.dispatchEvent(
		new MouseEvent('click', { 
		bubbles: true, 
		cancelable: true, 
		view: window 
		})
	)
  }

  function handleAnchorClick(e) {
	console.log("downloading recording")
	e.stopPropagation()
	return true
  }

  return ( linkNotFound ? <PageNotFound /> : (
		<div style={{ width: "1136px", margin: "42px auto" }}>
		{accessDenied ? (
			<Denied />
		) : outOfOrg ? (
			<OutOfOrg />
		) : (
			<>
				{!!Object.keys(recordingData).length != 0 && (
					<div className="shared-call-recording-insights-container insights-container">
						<div
							style={{
							padding: "32px",
							borderBottom: "1px solid #E1E6E2",
							}}
						>
							<p className="t4 medium-font nc-gray-900">
							{recordingData?.candidate_details?.name}
							</p>
							{(maskNumbers && recordingData?.candidate_details?.name?.trim()?.length) ? null : <p className="t5 regular-font nc-gray-900">
							{recordingData?.candidate_details?.phone_number}
							</p>}
						</div>
						{!!recordingData?.recording_url && recordingData?.duration !== 0 && (
							<div className="recording">
							<p className="t5 medium-font nc-gray-900">Recording</p>
							<div
								style={{
								display: "flex",
								marginTop: "37px",
								flexDirection: "row",
								alignItems: "center",
								}}
							>
								<div
								style={{ cursor: "pointer" }}
								onClick={(e) => {
									console.log(ws.current);
									if (isPlaying) {
									ws.current.pause();
									setIsPlaying(false);
									} else {
									ws.current.play();
									setIsPlaying(true);
									}
								}}
								className="pause-play-btn"
								>
								<img
									src={ws.current?.isPlaying() ? pauseIcon : playIcon}
									alt="play-pause-icon"
								/>
								</div>

								<div
								className='audio-recording-container'
								ref={audioRef}
								style={{
									position: "relative",
									minWidth: "250px",
									width: "100%",
									zIndex: 2,
									flex: 1,
									marginLeft: "16px",
								}}
								/>
								{/* speed */}
								<div ref={plabackMenuAnchorRef} className="settings-icon-container" onClick={handleOpenSettingsMenu} style={{
									background: isPlaybackMenuVisible ? colors.nc_gray_100 : colors.ic_white, marginLeft: "16px",
								}}>
									{callRecordingSettingsIcon()}
								</div>

								{/* download */}
								{[1, 4].includes(reduxAccount?.data?.profile?.role) ? <div onClick={handleDownload} className='download-icon-container' style={{marginLeft: "12px"}}>
									<a ref={downloadRef} onClick={handleAnchorClick} onMouseDown={handleAnchorClick} download target="_blank" href={recordingData?.recording_url}> {callRecordingDownloadIcon()} </a> 
								</div>
								: null}
							</div>
							</div>
						)}

						<div style={{ display: "flex" }}>
							{/* SECTION 1  */}
							<div className="section_1">
							<p className="t5 medium-font nc-gray-900">Details</p>
							<RecordingDetails />
							</div>
							{/* SECTION 2  */}
							<div className="section_2">
							<p className="t5 medium-font nc-gray-900">Notes</p>
							<RecordingNotes />
							</div>
						</div>
					</div>
				)}

				{/* popover for playbackSpeed using Menu */}
				<Menu
					anchorOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
					transformOrigin={{
						vertical: "bottom",
						horizontal: "right",
					}}
					autoFocus={false}
					open={isPlaybackMenuVisible}
					anchorEl={plabackMenuAnchorRef?.current || null}
					onClose={handlePlaybackPopoverClose}
					sx={{
						"& .MuiPaper-root": {
							width: "160px",
							border: `1px solid ${colors.nc_gray_300}`,
							borderRadius: "6px",
						}
					}}
				>
					<p style={{color: colors.nc_gray_900, ...styles.bold_font, textAlign: "left", paddingLeft: "16px"}}>Speed</p>
					{PLAYBACK_SPEEDS.map(speed => (
						<MenuItem 
							key={speed} 
							sx={{
								paddingRight: "0px",
								display: "flex",
								flexDirection: "row", 
								alignItems: "center", 
								justifyContent: "space-between", 
								backgroundColor: speed === playbackSpeed ? colors.ic_green_100 : colors.ic_white,
								"&:hover": {
									background: colors.nc_gray_100,
								}
							}}
							onClick={() => handlePlaybackSpeedChange(speed)}
							value={speed} 
						>
							{speed === 1 ? "Normal" : speed + "x"}
							<ListItemIcon style={{marginLeft: "12px", paddingRight: "12px"}}>
								{speed === playbackSpeed && callRecordingTickIcon()}
							</ListItemIcon>
						</MenuItem>
					))}
				</Menu>
			</>

		)}
		</div>
  ));
}

export default SharedCallRecording;
