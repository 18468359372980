import { Box } from '@mui/system';
import noRowsIcon from '../../../assets/gusers-and-teams-norows-state-icon.svg'
// import gusers-and-teams-norows-state-icon

export default function GTableNoRowsState({fromScreen}) {
	const generateMessage = () => {
		let messageComponent = null
		
		let mainClassName = 'medium-font t6 nc-gray-900'
		let supportingClassName= 'regular-font t7 nc-gray-900'
		switch (fromScreen) {
			case 'users': 
				messageComponent = <>
					<p  className={mainClassName}>No users</p>
					<p className={supportingClassName}>No users found.</p>
				</>
				break;
			case 'teams':
				messageComponent = <>
					<p className={mainClassName}>No teams</p>
					<p className={supportingClassName}>No teams found.</p>
				</>
				break;
			case 'team-view': 
				messageComponent = <>
					<p className={mainClassName}>No members</p>
					<p className={supportingClassName}>No members found in team.</p>
				</>
				break;
			case 'logs': 
				messageComponent = <>
					<p className={mainClassName}>No call records</p>
					<p className={supportingClassName}>No call records found for selected date range.</p>
				</>
				break;
			// case 'voice': 
			// 	messageComponent = <>
			// 		<p className={mainClassName}>No data </p>
			// 		<p className={supportingClassName}>No records found for selected item.</p>
			// 	</>
			// 	break;
			case 'contact-list': 
				messageComponent = <>
					<p className={mainClassName}>No campaigns </p>
					<p className={supportingClassName}>No campaigns found.</p>
				</>
				break;
			default:
				messageComponent = <>
					<p className={mainClassName}>No data </p>
					<p className={supportingClassName}>No records found for selection.</p>
				</>
				break;
		}
		return messageComponent
	}
	return <div style={{
		width: 'inherit',
		height: '100%',
		marginTop: '4px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		aignItems: 'center',
	}}>
		<img src={noRowsIcon} style={{height: '88px'}} alt='No rows vector art'/>
		<div style={{
			display: 'flex',
			flexDirection: 'column',
			
			width: 'fit-content',
			height: 'fit-content',
			alignSelf: 'center',
			marginTop: '12px',
			marginBottom: '12px',
			textAlign: 'center'

		}}>

			{generateMessage()}
		</div>
	</div>
}