import { Link, useNavigate } from "react-router-dom"
import colors from "../design/colors"
import breadcrumbsSeparatorIcon from "../../assets/gusers-and-teams-breadcrumbs-separtor.svg";
import GBreadcrumbs from "../design/components/GBreadcrumbs"
import { CustomInput, InsufficientCreditsModal } from "../design/components/customComponents";
import { commonStyles } from "../signup/commonStyles";
import { MenuItem, Select } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from "react";
import { topNavAccountSelectedAccountTickIcon } from "../../utility-functions/assetLoader";
import styles from './VirtualNumber.module.css'
import Button from "../design/Button";
import { JService } from "../api-service/ApiService";
import GTable from "../design/components/GTable";
import GModal from "../design/components/GModal";
import { useDispatch, useSelector } from "react-redux";
import { setVirtualNumbersCount } from "../features/virtual-number/virtualNumber";
import { setCredits } from "../features/account/account";
import AnimationPage from "../shared/animation-page/AnimationPage";
import { enqueueSnackbar } from 'notistack';

const cities=['Bengaluru','Hyderabad','Mumbai','Vijayawada']

const AddVirtualNumber=()=>{
    const API_BASE_URL=process.env.REACT_APP_BETA

    const navigate=useNavigate()
    const dispatch=useDispatch()

    const reduxVn=useSelector(state=>state?.virtualNumber?.value?.vnCount)
    const reduxSubscription=useSelector(state=>state?.account?.value?.subscription)
    const reduxCredits=useSelector(state=>state?.account?.value?.credits)
    const reduxAccount = useSelector((state) => state?.account?.value);

    const [searchBy,setSearchBy]=useState('City')
    const [searchValue,setSearchValue]=useState('')
    const [data,setData]=useState([])
    const [vnBuyModalVisible,setVnBuyModalVisible]=useState(false)
    const [vnBuyModalValues,setVnBuyModalValues]=useState({number:'',price:'',location:'',countryCode:''})
    const [showLoader,setShowLoader]=useState(false)
    const [isLoading,setIsLoading]=useState(false)
    const [pageData,setPageData]=useState({rowCount:0,prev:null,next:null,curPage:1,pageSize:10})
    const [gTablePageNumber, setGTablePageNumber] = useState(1);
    const [insufficientCreditsModalVisible,setInsufficientCreditsModalVisible]=useState(false)
    const [remainingFreeNumbers,setRemainingFreeNumbers]=useState()
    const [columns,setColumns]=useState([])

    const handlePageChange=(val)=>{
        setPageData(prev=>({...prev,curPage:val+1}))
    }
    const handlePageSizeChange=(pageSize)=>{
        setPageData(prev=>({...prev,pageSize:pageSize,curPage:1}))
        setGTablePageNumber(0)
    }
    const handleSearchBtnClick=(e)=>{
        e.preventDefault()
        setPageData(prev=>({...prev,curPage:1}))
        setGTablePageNumber(0)
        handleSearch(1)
    }
    const updateCreditsData=()=>{
        let orgUrlParam = "";
        if (
          reduxAccount?.data?.profile?.account_holder &&
          reduxAccount?.allOrgs?.length > 1
        ) {
          orgUrlParam = reduxAccount?.selectedOrg + "/";
          if (!reduxAccount?.selectedOrg && reduxAccount?.selectedOrg !== 0)
            orgUrlParam = "";
        }
        JService.get(API_BASE_URL+`/api/v1/subscriptions/credit/${orgUrlParam}`)
        .then((res)=>{
          if(!res?.success)
            throw new Error(res?.message || 'Failed to retrieve credits')
          dispatch(setCredits(res?.data))
        }).catch(err=>{
        })
        }
    const handleSearch=(pageNo)=>{
        if(!searchValue) return;
        setShowLoader(true)
        
        JService.get(API_BASE_URL + `/api/v1/auth/get-available-vns/?location=${searchValue}&page=${pageNo || pageData?.curPage}&page_size=${pageData?.pageSize}`)
        .then((res)=>{
            if(!res?.success) 
                throw new Error(res?.message || 'Could not search virtual numbers')
            setData(res?.data?.results?.map((elem,index)=>({...elem,id:index})) || [])
            setPageData(prev=>({...prev,rowCount:res?.data?.count || 0,next:res?.data?.next,prev:res?.data?.prev}))
        })
        .catch(err=> enqueueSnackbar(err?.message || 'Could not search virtual numbers', { variant: "error"}))
        .finally(()=> setShowLoader(false))
    }

    const handleBuyVn=(payWithCredits,number,location)=>{
        let orgUrlParam = "";
        if (
          reduxAccount?.data?.profile?.account_holder &&
          reduxAccount?.allOrgs?.length > 1
        ) {
          orgUrlParam = reduxAccount?.selectedOrg + "/";
          if (!reduxAccount?.selectedOrg && reduxAccount?.selectedOrg !== 0)
            orgUrlParam = "";
        }
        let url=`/api/v1/subscriptions/buy-vn/${orgUrlParam}`
        if(payWithCredits){
            url+='?pay_with_credits=true'
        }
        setIsLoading(true)
        JService.post(url,JSON.stringify({vn:number || vnBuyModalValues?.number}))
        .then((res)=>{
            if(payWithCredits && res?.message==='Insufficient balance'){
                setInsufficientCreditsModalVisible(true)
                return;
            }
            if(!res?.success)
                throw new Error(res?.message || 'Failed to buy the virtual number')
            if(res?.url){
                    window.location.href=res?.url
                    return;
            }
            //setData(prev=>prev.filter(vn=>vn.number!==vnBuyModalValues.number))
            handleSearch()
            if(res?.success){
                let _location=location || vnBuyModalValues.location
                if(['Hyderabad','Bengaluru','Noida','Mumbai'].includes(_location)) _location='India'
                let temp={...reduxVn}
               
                if(temp[_location]){
                let temp2={...temp[_location]}
                if(remainingFreeNumbers>0 && !payWithCredits){
                temp2['includedInPlan']+=1
                }
                temp2['total']+=1
                temp[_location]=temp2
                }
                dispatch(setVirtualNumbersCount(temp))
            //if(remainingFreeNumbers>0 && !payWithCredits){
                // let location=vnBuyModalValues.location
                // if(location?.includes('India')) location='India'
                // let temp={...reduxVn}
                // temp[location]['includedInPlan']-=1
                // dispatch(setVirtualNumbersCount(temp))
                
           // }
          }
            setTimeout(()=>{
                updateCreditsData()
            },1000)
			enqueueSnackbar(res?.message, { variant: "success"})
            setVnBuyModalValues({number:'',price:'',location:'',countryCode:''})
            setVnBuyModalVisible(false)
        })
        .catch(err=>{
            if(payWithCredits && err?.response?.data?.message==='Insufficient balance'){
                setVnBuyModalVisible(false)
                setInsufficientCreditsModalVisible(true)
                return;
            }
			enqueueSnackbar(err?.message || 'Failed to buy the virtual number', { variant: "error"})
            setVnBuyModalValues({number:'',price:'',location:'',countryCode:''})
            setVnBuyModalVisible(false)
        })
        .finally(()=> setIsLoading(false))
    }

    const handleBuyBtnClick=(values)=>{
        setVnBuyModalValues({number:values?.number,price:values.price,location:values?.location,countryCode:values?.country_code})
        if(remainingFreeNumbers>0) {
            handleBuyVn(null,values?.number,values?.location)
            return
        }
        setVnBuyModalVisible(true)
    }

    const calcRemainingFreeNumbers=()=>{
        let freeAssignedNumbersCount=0
        if(reduxVn){
            for(let key in reduxVn){
                freeAssignedNumbersCount+=reduxVn[key]['includedInPlan']
            }
        }
        const val=reduxSubscription?.plan_licenses?.total-freeAssignedNumbersCount
        setRemainingFreeNumbers(val)
        let cols=[
            {field:'number',headerName:'Number',flex:19,width:220,renderCell:(params)=><span style={{paddingLeft:'14px'}}>{params?.row?.country_code+' '+params?.row?.number}</span>},
            {field:'location',headerName:'Location',flex:19,width:220},
            {field:'price',headerName:'Monthly price',flex:19,width:220,renderCell:(params)=><span>{reduxCredits?.currency==="INR"?'₹500':"$6"}</span>},
            {field:'buy',headerName:'',flex:43,width:220,renderCell:(params)=><Button onClick={()=>handleBuyBtnClick(params?.row)} hierarchy='green' variant='contained' buttonText={val>0? 'Add to account':'Buy'} styleOverride={{...btnStyle,backgroundColor:'#fff',color:'#2eb258',border:'1px solid #2eb258','&:hover':{backgroundColor:'#f7faf8',border:'1px solid #2eb258'}}} disabled={false}/>}
        ]
        if(val>0)
            cols=[cols[0],cols[1],cols[3]]
        setColumns(cols)
    }
    useEffect(()=>{
        if(reduxSubscription?.status==='TRIAL'){
            navigate('/virtual-numbers',{replace:true})
        }
    },[])

    useEffect(()=>{
        if(reduxSubscription && reduxVn)
        calcRemainingFreeNumbers()
    },[reduxSubscription,reduxVn])
    
    useEffect(()=>{
        handleSearch()
    },[pageData.pageSize,pageData.curPage])

    const breadcrumbs=[
    <Link
        style={{ color: colors.nc_gray_900, }}
        to="/virtual-numbers"
    >
        Virtual numbers
    </Link>,
    <Link
        style={{ color: colors.ic_green_400, }}
        to={
            "/virtual-numbers/add-number/" 
           
        }
    >
        Add new number
    </Link>,]

    

    const vnBuyModalBody=isLoading?<AnimationPage/>:(
        <div className={styles.modalBody}>
            <div>
                <p className="t7 medium-font">{vnBuyModalValues.countryCode} {vnBuyModalValues.number}</p>
                <p className="t7 regular-font">{vnBuyModalValues.location}</p>
            </div>
            <div className="regular-font">
                <span className="t5">{reduxCredits?.currency==='INR'?'₹500':"$6"}</span><span className="t7">per month</span>
            </div>
        </div>
    
    )
    return (
        <div 
        className="screen-container "
        style={{
          paddingTop: "32px",
          height:'max-content'
        }}
      >
            <GBreadcrumbs
            breadcrumbs={breadcrumbs}
            separator={
            <img src={breadcrumbsSeparatorIcon} alt="breadcrumbs separtor icon" />
        }
      />
       {/* <div style={{borderBottom:'1px solid #e1e5e2'}}></div> */}
       {remainingFreeNumbers >0 && <div className={styles.infoHeader}><p className="t7 regular-font"><span className="medium-font">{remainingFreeNumbers}</span> Free virtual number remaining <span style={{color:'#78807a'}}>{reduxSubscription?.plan?.name?.includes('INR')?'(India number)':'(USA, UK or Canada numbers)'}</span></p></div>}
       <form className={styles.searchVnForm} onSubmit={handleSearchBtnClick}>
       {/* <div className="select-container">
            <label className="t7 medium-font">Search by</label>
            <Select
              IconComponent={ExpandMoreIcon}
              sx={commonStyles.selectStyles}
              MenuProps={commonStyles.selectMenuStyles}
              value={searchBy}
              native={false}
              displayEmpty
              renderValue={(val) => {
                return (
                  <span style={{color:colors.nc_gray_900}}>{val}</span>
                )
              }}
              onChange={(e) => setSearchBy(e.target.value)}
            >
              {['City'].map((elem, index) => {
                return (
                  <MenuItem sx={commonStyles.selectMenuItemStyles} value={elem} key={index}>
                    <span>{elem}</span>{searchBy===elem && <span>{topNavAccountSelectedAccountTickIcon()}</span>}
                  </MenuItem>
                );
              })}
            </Select>
            </div> */}
            {/* <CustomInput label={searchBy} value={searchValue} handleChange={(e)=>setSearchValue(prev=>({...prev,value:e.target.value}))} handleFocus={()=>prev=>({...prev,error:null})} name='search_value' type='text'/> */}
            <div className="select-container">
            <label className="t7 medium-font">{searchBy}</label>
            <Select
              IconComponent={ExpandMoreIcon}
              sx={commonStyles.selectStyles}
              MenuProps={commonStyles.selectMenuStyles}
              placeholder="Select city"
              value={searchValue}
              native={false}
              displayEmpty
              renderValue={(val) => {
              
                return (
                  val?<span style={{color:colors.nc_gray_900}}>{val}</span>:<span style={{color:colors.nc_gray_400}}>Select City</span>
                )
              }}
              onChange={(e) => setSearchValue(e.target.value)}
            >
              {cities.map((elem, index) => {
                return (
                  <MenuItem sx={commonStyles.selectMenuItemStyles} value={elem} key={index}>
                    <span className="t7 regular-font">{elem}</span>{searchValue===elem && <span>{topNavAccountSelectedAccountTickIcon()}</span>}
                  </MenuItem>
                );
              })}
            </Select>
            </div>
            <Button type="button" buttonText='Search' hierarchy='green' onClick={handleSearchBtnClick} disabled={!searchBy || !searchValue} styleOverride={btnStyle}/> 
       </form>
       <GTable columns={columns} onRowClick={() => {}} rows={data} leftHeader={{ filters: [] }} rightHeader={{}} fromScreen="add-virtual-number" useMuiTable={true} hideHeader={true} isLoading={showLoader}  rowCount={pageData.rowCount || 0}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    // rowsPerPage={pageSize}
                    // gTablePageNumber={gTablePageNumber}
                     setGTablePageNumber={setGTablePageNumber}
                     paginationMode="server"
                     />
       <GModal bodyWrapperStyle={{paddingRight:'0'}} modalStyle={{minHeight:'auto',padding:'32px',marginBottom:'12px'}} bottomButtonsStyle={{borderTop:'none'}} visible={vnBuyModalVisible} heading='Buy number' closeModal={()=>setVnBuyModalVisible(false)} body={vnBuyModalBody} 
       bottomButtons={[<Button hierarchy='green' buttonText='Pay with credits' onClick={()=>handleBuyVn(true)} disabled={false} styleOverride={btnStyle}/>,
                       <Button hierarchy='green' buttonText='Pay with card' onClick={()=>handleBuyVn(false)} disabled={false} styleOverride={btnStyle}/> ]}/>
        <InsufficientCreditsModal visible={insufficientCreditsModalVisible} handleClose={()=>setInsufficientCreditsModalVisible(false)} handlePayWithCard={()=>handleBuyVn(false)}/>
        </div>
    )
}

const btnStyle={
    height:'36px',
    fontSize:'14px',
    lineHeight:'14px',
    fontWeight:'400',
    padding:'10px 16px'
}

export default AddVirtualNumber