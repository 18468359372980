import { useEffect, useState } from "react"
import GModal from "../../design/components/GModal"
import Button from "../../design/Button"
import { formatDate } from "../../../utility-functions"
import { commonStyles } from "../../signup/commonStyles"
import gStyles from '../../design/commonStyles'
import { CustomInput } from "../../design/components/customComponents"
import { MenuItem, Select } from "@mui/material"
import { errorIcon, topNavAccountSelectedAccountTickIcon } from "../../../utility-functions/assetLoader"
import colors from "../../design/colors"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import styles from './ManageLicenseModal.module.css'

const ManageLicenseModal=({data,handleClose,renewalDate,planType,handleAddLicense,handleRemoveLicense})=>{
    const [changeType,setChangeType]=useState('Add license')
    const [quantity,setQuantity]=useState({value:'',error:null})
    const [info,setInfo]=useState({visible:false,message:''})

    const handleUpdate=(e)=>{
        e.preventDefault()
        if(!changeType || !quantity?.value) return
        if(quantity.value<=0) 
            setQuantity(prev=>({...prev,error:'Please enter a valid value'}))
        else if(changeType==='Remove license' && quantity.value > data?.totalLicense)
            setQuantity(prev=>({...prev,error:'Please enter a value less than or equal to free licenses'}))
        else if(changeType==='Add license'){
            handleAddLicense(quantity.value,data?.type)
            setQuantity({value:'',error:null})
            setChangeType('Add license')
            handleClose()
        }
        else if(changeType==='Remove license'){
            handleRemoveLicense(quantity.value,data?.type)
            setQuantity({value:'',error:null})
            setChangeType('Add license')
            handleClose()
        }
    }

    const handleCloseModal=()=>{
        setChangeType('Add license')
        setQuantity({value:'',error:null})
        setInfo({visible:false,message:''})
        handleClose()
    }

    useEffect(()=>{
        if(!quantity?.value && info.visible) setInfo({visible:false,message:''})
        if(!changeType || !quantity?.value) return
        if(changeType==='Add license' && quantity?.value>0){
                setInfo({visible:true,message:<p className="t7 regular-font">By clicking on update licenses, you agree to purchase <span className="medium-font">{quantity?.value} {data?.type==='Plan'?planType:''} {data?.type}</span> license.</p>})
            }
        else if(changeType==='Remove license' && (data?.totalLicense >= quantity?.value) && quantity?.value>0){
                setInfo({visible:true,
                message:<p className="t7 regular-font">You are requesting to reduce your license from <b>{data?.totalLicense}</b> to <b>{data?.totalLicense - quantity?.value}</b>. 
                    The changes will take effect from <b>{formatDate(renewalDate)}</b>.
                    You can continue to use the license until then.</p>
        })
            
        }
    },[changeType,quantity?.value])

    return (
        <GModal visible={data?.visible} closeModal={handleCloseModal} heading={`Manage ${data?.type?.toLowerCase()} license`}
        bottomButtons={[
        <Button styleOverride={gStyles.btnStyle} hierarchy='white' variant='outlined' disabled={false} buttonText='Cancel' onClick={handleCloseModal}/>,
        <Button styleOverride={gStyles.btnStyle} hierarchy='green' buttonText='Update license' onClick={handleUpdate} disabled={!changeType || !quantity.value}/>]}
        bodyWrapperStyle={{width:'560px'}}
        body={
            <div className={styles.container}>
                <p className="t7">
                    <span className="medium-font">{data?.type} license: </span>
                    <span className="regular-font">{data?.totalLicense} license </span>
                    <span style={{color:colors.nc_gray_800}}>({data?.assignedLicense} assigned)</span>
                </p>
                <p className="t7">
                    <span className="medium-font">Renewal: </span>
                    <span className="regular-font">{formatDate(renewalDate)}</span>
                </p>
                <form>
                <div className="select-container" style={{marginBottom:0}}>
                    <label className="t7 medium-font">Add/remove {data?.type?.toLowerCase()} licenses</label>
                    <Select
                        IconComponent={ExpandMoreIcon}
                        sx={commonStyles.selectStyles}
                        MenuProps={commonStyles.selectMenuStyles}
                        value={changeType}
                        native={false}
                        displayEmpty
                        renderValue={(val) => {
                        return (
                            val?<span style={{color:colors.nc_gray_900}}>{val}</span>:null
                            )
                        }}
                        onChange={(e) => setChangeType(e.target.value)}
                        >
                        {['Add license','Remove license'].map((elem, index) => {
                            return (
                            <MenuItem sx={commonStyles.selectMenuItemStyles} value={elem} key={index}>
                                <span>{elem}</span>{changeType===elem && <span>{topNavAccountSelectedAccountTickIcon()}</span>}
                            </MenuItem>
                            );
                        })}
                        </Select>
                    </div>
                    <CustomInput label='' placeholder='Enter quantity' name='quantity' type='number' value={quantity} handleChange={(e)=>setQuantity(prev=>({...prev,value:e.target.value}))} handleFocus={()=>{setQuantity(prev=>({...prev,error:null}))}} inputProps={{min: 1}}/>
                </form>
                {info?.visible && <div className={styles.warningContainer}><span>{errorIcon('#464c48','16.67','16.67')}</span>{info?.message}</div>}
            </div>
        }
        />
    )
}
export default ManageLicenseModal