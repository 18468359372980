import React from "react";
import Button from "../../design/Button";
import GModal from "../../design/components/GModal";

function WarnModal({
  heading,
  message,
  visible,
  closeModal,
  actionButtonTitle,
  handleAction,
  hideCancelButton,
}) {
  return (
    <GModal
      modalStyle={{ width: "448px" }}
      closeModal={closeModal}
      heading={heading}
      visible={visible}
      bottomButtons={[
        <>
          {!hideCancelButton && (
            <Button
              variant="filled"
              buttonText="Cancel"
              icon={{}}
              disabled={false}
              isLoading={false}
              hierarchy="white"
              onClick={closeModal}
            />
          )}
        </>,
        <Button
          variant="filled"
          buttonText={actionButtonTitle}
          icon={{}}
          isLoading={false}
          hierarchy="red"
          disabled={false}
          onClick={handleAction}
        />,
      ]}
      body={<>{message}</>}
    />
  );
}

export default WarnModal;
