import { useEffect, useState } from "react"
import styles from './Billing.module.css';
import OverviewTab from './OverviewTab'
import CreditsTab from './CreditsTab'
import BillingTab from './BillingTab'
import { JService } from "../api-service/ApiService";
import AnimationPage from "../shared/animation-page/AnimationPage";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSubscriptionDetails } from "../features/account/account";
import { enqueueSnackbar } from 'notistack';

const Billing=()=>{
    const API_BASE_URL=process.env.REACT_APP_BETA

    const reduxAccount = useSelector((state) => state?.account?.value);

    const location=useLocation()
    const dispatch=useDispatch() 
    const [activeTab,setActiveTab]=useState(location?.state?.tab || 'Overview')
    const [data,setData]=useState()
    const [tabs,setTabs]=useState([])
    const [showLoader,setShowLoader]=useState(false)

    const handleActiveTab=(tab)=>{
      setActiveTab(tab)
    }
    const fetchData=()=>{
      setShowLoader(true)
      let orgUrlParam = "";
      if (
        reduxAccount?.data?.profile?.account_holder &&
        reduxAccount?.allOrgs?.length > 1
      ) {
        orgUrlParam = reduxAccount?.selectedOrg + "/";
        if (!reduxAccount?.selectedOrg && reduxAccount?.selectedOrg !== 0)
          orgUrlParam = "";
      }
      JService.get(`/api/v1/subscriptions/billing-details/${orgUrlParam}`)
      .then((res)=>{
        if(!res.success) throw new Error(res?.message || 'Error in retrieving billing details')
        setData(res.data)
        let temp={...res?.data}
        delete temp['addons']
        delete temp['numbers']
        temp['plan_licenses']={...temp?.plan?.licenses}
        temp['viewer_licenses']={...temp?.viewers?.licenses}
        if(temp && Object.keys(temp)?.length){
        dispatch(setSubscriptionDetails(temp))
        }
      })
      .catch(err=>{
        setData({})
		enqueueSnackbar(err?.message, { variant: "error"})
      })
      .finally(()=>{
        setShowLoader(false)
      })
    }
    useEffect(()=>{
      fetchData()
    },[reduxAccount?.selectedOrg])

   

    useEffect(()=>{
      if(!data) return
      // if(data?.status==='TRIAL') setTabs(['Overview','Credits'])
      // else 
      setTabs(['Overview','Credits','Billing'])
      if(data.status==='ACTIVE' && localStorage.getItem('account-upgraded-today') && JSON.parse(localStorage.getItem('account-upgraded-today')=='false')){
        if(Date.now()-new Date(data?.start_date)<86400000){
          localStorage.setItem('account-upgraded-today',JSON.stringify(true))
        }
      }
    },[data])


    const handleUpdateAddonUsers=(action,idList,addonName)=>{
      const addonData=data?.addons?.find(elem=>elem.name===addonName)
      let curIds=addonData?.users?.map(elem=>elem?.id)
      if(action==='remove')
        curIds=curIds?.filter(elem=>elem!==idList)
      else if(action==='add')
        curIds=[...curIds,...idList]

      let orgUrlParam = "";
      if (
        reduxAccount?.data?.profile?.account_holder &&
        reduxAccount?.allOrgs?.length > 1
      ) {
        orgUrlParam = reduxAccount?.selectedOrg + "/";
        if (!reduxAccount?.selectedOrg && reduxAccount?.selectedOrg !== 0)
          orgUrlParam = "";
      }

      JService.post(`/api/v1/subscriptions/activate-addon/${orgUrlParam}`,JSON.stringify({user_ids:curIds,addon:addonName}))
      .then((res)=>{
        if(!res?.success)
          throw new Error(res?.message || 'Failed to activate addon for provided users')
		enqueueSnackbar(res?.message || "Succesfully activated addon for provided users", { variant: "success"})
       
      })
      .catch(err=>{
		enqueueSnackbar(err?.message || err?.response?.data?.message, { variant: "error"})
      })
      .finally(()=>{
        setTimeout(()=>{
          fetchData()
        },1000)
      })
    }
    
    return (
    <div
      className="screen-container"
      style={{
        paddingTop: "24px",
        paddingLeft:'0',
        height:
          document.documentElement.scrollHeight - 56 >= window.innerHeight
            ? "fit-content"
            : window.innerHeight,
      }}
    >
    <div className={styles.tabContainer}>
    <hr />
    {
        [tabs.map((tab,index)=>{
            return (
                <p
                    className={`${styles.tabLabel} ${
                      tab === activeTab ? styles.selectedTabLabel:''
                    } b2 regular-font`}
                    key={index}
                    onClick={() => setActiveTab(tab)}
                  >
                    {tab}
                  </p>
            )
        })]
    }
     <hr />
     </div>
     {showLoader && <AnimationPage/>}
     {activeTab==="Overview" && !showLoader && <OverviewTab activeTab={activeTab} changeActiveTab={handleActiveTab} currency={data?.plan?.name?.includes('INR')?'₹':'$'} status={data?.status} plan={data?.plan} viewers={data?.viewers} addons={data?.addons} endDate={data?.end_date} startDate={data?.start_date} numbers={data?.numbers} handleUpdateAddonUsers={handleUpdateAddonUsers} refetchData={fetchData}/>}
     {activeTab==='Credits' && !showLoader && <CreditsTab activeTab={activeTab} setActiveTab={setActiveTab}/>}
     {activeTab==='Billing' && !showLoader && <BillingTab activeTab={activeTab} billingAddress={data?.billing_address} billingContacts={data?.billing_contacts} gstin={data?.gstin} refetchData={fetchData}/>}
    </div>
    )
}

export default Billing