import colors from "../design/colors"
export const commonStyles={
    selectStyles:{
        width: "100%",
        height: "36px",
        "& .MuiSvgIcon-root": { color: "#464d48" },
        '& .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${colors.nc_gray_300}`,
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #34CB65",
        },       
      },
      selectMenuStyles:{
        MenuListProps:{sx:{padding:0}},
        sx:{
          '& .MuiPaper-root':
                {
                  boxShadow:'0px 4px 8px 8px rgba(95, 102, 97, 0.04)',
                  borderRadius:'6px',
                  border:'1px solid #e1e5e2',
                  marginTop:'-9px',
                  maxHeight:'216px'
                },
            }
      },
      selectMenuItemStyles:{ display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingTop:'16px',
        paddingBottom:'16px',
        paddingLeft:'24px',
        paddingRight:'24px',
      "&.MuiMenuItem-root.Mui-selected": {
       background: "#F0FCF4",
      },
     '& .MuiButtonBase-root-MuiMenuItem-root:hover':{
        backgroundColor:colors.nc_gray_100
      }
    }
}