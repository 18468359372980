import './signupScreen2Styles.css'

import vectorArt from '../../assets/signup-screen2.svg'
import dotsBottom from '../../assets/login-dots-bottom.svg'
import dotsTop from '../../assets/login-dots-top.svg'
import logo from '../../assets/top-nav-logo.svg'

import { useRef, useState, useEffect } from 'react'

import {login} from '../features/user/user'

import { useNavigate } from 'react-router-dom'

import { loginScreen2Validation } from '../../request-handlers/validation/request-validation'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../design/Button'
import colors from '../design/colors'
import { enqueueSnackbar } from 'notistack'

const API_BASE_URL = process.env.REACT_APP_BETA
function SignupScreen2() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userDetails = useSelector(state => state.user.value)
    const userLoginAttemptWith = useSelector(state=> state.user.value)

    const [signupScreen2Values, setSignupScreen2Values] = useState({
        field1: '',
        field2: '',
        field3: '',
        field4: '',
        field5: '',
        field6: ''
    })
    const [showLoader,setShowLoader]=useState(false)

    function listenToPasteEvent(e)
    {
        const pasteContent = (e.clipboardData || window.clipboardData).getData('text')
        if(pasteContent === pasteContent.toUpperCase() && pasteContent.length === 6){
            // a code is copied
            const _fromClipboard = pasteContent.toUpperCase()
            setSignupScreen2Values(prevState => {
                return {
                    field1: _fromClipboard[0],
                    field2: _fromClipboard[1],
                    field3: _fromClipboard[2],
                    field4: _fromClipboard[3],
                    field5: _fromClipboard[4],
                    field6: _fromClipboard[5]
                }
            })
        }
    }
    useEffect(() => {
        document.addEventListener('paste', listenToPasteEvent)
        return () => {
            document.removeEventListener('paste', listenToPasteEvent)
        }
    }, [])

    const field1Ref = useRef()
    const field2Ref = useRef()
    const field3Ref = useRef()
    const field4Ref = useRef()
    const field5Ref = useRef()
    const field6Ref = useRef()

    const handleChange = e => {

        const _field = e.target.dataset.signupscreen2
        const _value = e.target.value
        if(_value.trim().length === 0  && _value === ' ') return
        const _inputType = e.nativeEvent.inputType
        if(_field === '1'){
            if(_inputType === 'deleteContentBackward' || _inputType === 'deleteContentForward') field1Ref.current.focus()
            else if(_inputType === 'insertText') field2Ref.current.focus()
        }
        else if(_field === '2'){
            if(_inputType === 'deleteContentBackward' || _inputType === 'deleteContentForward') field1Ref.current.focus()
            else if(_inputType === 'insertText') field3Ref.current.focus()
        }
        else if(_field === '3'){
            if(_inputType === 'deleteContentBackward' || _inputType === 'deleteContentForward') field2Ref.current.focus()
            else if(_inputType === 'insertText') field4Ref.current.focus()
        }
        else if(_field === '4'){
            if(_inputType === 'deleteContentBackward' || _inputType === 'deleteContentForward') field3Ref.current.focus()
            else if(_inputType === 'insertText') field5Ref.current.focus()
        }
        else if(_field === '5'){
            if(_inputType === 'deleteContentBackward' || _inputType === 'deleteContentForward') field4Ref.current.focus()
            else if(_inputType === 'insertText') field6Ref.current.focus()
        }
        else if(_field === '6') {
            if(_inputType === 'deleteContentBackward' || _inputType === 'deleteContentForward') field5Ref.current.focus()
            else if(_inputType === 'insertText') field6Ref.current.focus()
            // field6Ref.current.focus()
        }
        setSignupScreen2Values(prevState => {
            if(_field === '1') {
                return {...prevState, field1: _value.toUpperCase()}
            }
            else if(_field === '2') {
                return {...prevState, field2: _value.toUpperCase()}
            }
            else if(_field === '3') {
                return {...prevState, field3: _value.toUpperCase()}
            }
            else if(_field === '4') {
                return {...prevState, field4: _value.toUpperCase()}
            }
            else if(_field === '5') {
                return {...prevState, field5: _value.toUpperCase()}
            }
            else if(_field === '6') {
                return {...prevState, field6: _value.toUpperCase()}
            }
        })
    }
    const handleSubmit = e => {
        e.preventDefault()
        const _payload_key = [signupScreen2Values.field1, signupScreen2Values.field2, signupScreen2Values.field3, signupScreen2Values.field4, signupScreen2Values.field5, signupScreen2Values.field6].join('')
        const _payload = {verification_code: _payload_key}
        
        const isValid = loginScreen2Validation(_payload)
        if(isValid){
            setShowLoader(true)
            fetch(API_BASE_URL + '/api/v1/auth/verify-account/?webapp=True',{
                method:'post',
                body: JSON.stringify({email: userLoginAttemptWith?.email?.value, code: _payload_key}),
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: "include",
            })
            .then(res => {
                if(res?.status === 500) throw new Error('Server error please try again or if issue persists contact FreJun')
                return res?.json()
            })
            .then(res => {
                if(!res?.success) throw new Error(res?.message || "Error occurred while verifying code")
			 
                dispatch(login({email: userLoginAttemptWith?.email?.value}))
                navigate('/signup/company-details')
            })
            .catch(err => enqueueSnackbar(err?.message || 'Error occurred while verifying code', { variant: "error"}))
			.finally(()=>{
                setSignupScreen2Values({
                    field1: '',
                    field2: '',
                    field3: '',
                    field4: '',
                    field5: '',
                    field6: ''
                })
                setShowLoader(false)
            })
        }
    }

    const handleResendCode = e => {
        const _body = {
            email :  userLoginAttemptWith?.email?.value,
            source: 'login'
        }
        fetch(API_BASE_URL + '/api/v1/auth/send-code/', {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'post',
            body: JSON.stringify(_body)
        }).then(res => {
            if(res?.status === 500) throw new Error('Server error please try again or if issue persists contact FreJun.')
            return res?.json()
        })
        .then(res => {
            if(!res?.success) throw Error('Fetching account details failed')
			enqueueSnackbar(res?.message || 'Code resent', { variant: "success"})
        })
        .catch(err => enqueueSnackbar(err?.message || 'Error occurred while resending code', { variant: "error"}))
       .finally(()=>{
            // reset code
            setSignupScreen2Values({
                field1: '',
                field2: '',
                field3: '',
                field4: '',
                field5: '',
                field6: ''
            })
        })
    }
    
    return(
		<div className='signup-screen2-container signup-screen-container color-2d'>
			<div className='signup-art'>
            <a href="https://frejun.com/" target="_blank" rel="noopener noreferrer"> <img src={logo} alt="Logo" /></a>
				<img src={ dotsTop }/>
				<img src={ vectorArt }/>
				<img src={ dotsBottom }/>
			</div>
			<div className=' signup-screen-form signup-screen2-form'>
				<p className='title t5 medium-font'>Enter verification code</p>
				<form onSubmit={e => handleSubmit(e)}>
					<p className='sub-title t7 regular-font'>Please enter the verification code sent to <br/> <u className='t7 medium-font'>{ userLoginAttemptWith?.email?.value }</u></p>
					<div className='fields'>
						<input style={{borderColor:signupScreen2Values.field1?colors.ic_green_300:colors.nc_gray_300}} ref={field1Ref} type='text' maxLength='1' data-signupscreen2='1'  autoComplete='none' onChange={e => handleChange(e)} value={signupScreen2Values.field1}/>
						<input style={{borderColor:signupScreen2Values.field2?colors.ic_green_300:colors.nc_gray_300}} ref={field2Ref} type='text' maxLength='1' data-signupscreen2='2'  autoComplete='none' onChange={e => handleChange(e)} value={signupScreen2Values.field2}/>
						<input style={{borderColor:signupScreen2Values.field3?colors.ic_green_300:colors.nc_gray_300}} ref={field3Ref} type='text' maxLength='1' data-signupscreen2='3'  autoComplete='none' onChange={e => handleChange(e)} value={signupScreen2Values.field3}/>
						<input style={{borderColor:signupScreen2Values.field4?colors.ic_green_300:colors.nc_gray_300}} ref={field4Ref} type='text' maxLength='1' data-signupscreen2='4'  autoComplete='none' onChange={e => handleChange(e)} value={signupScreen2Values.field4}/>
						<input style={{borderColor:signupScreen2Values.field5?colors.ic_green_300:colors.nc_gray_300}} ref={field5Ref} type='text' maxLength='1' data-signupscreen2='5'  autoComplete='none' onChange={e => handleChange(e)} value={signupScreen2Values.field5}/>
						<input style={{borderColor:signupScreen2Values.field6?colors.ic_green_300:colors.nc_gray_300}} ref={field6Ref} type='text' maxLength='1' data-signupscreen2='6'  autoComplete='none' onChange={e => handleChange(e)} value={signupScreen2Values.field6}/>
					</div>
					<hr />
					<p className='send-again t7 medium-font' onClick={e => handleResendCode(e)}>Didn't receive the email? <span className='color-green'>Send again</span></p>
					<div className='buttons-container btnscreen2 ' style={{justifyContent:'flex-end'}}>
						{/* <button className='btn t7 medium-font' onClick={e => navigate('/signup')}  type='button'>Back</button> */}
						{/* <input id='signup-passcode-verify' type='submit' className='btn btn-green t7 medium-font' value='Verify' onSubmit={e => handleSubmit(e)} onClick={e => handleSubmit(e)}/> */}
						<Button className='btns2'disabled={Object.values(signupScreen2Values).join("").length !== 6} hierarchy="green" buttonText="Verify" onClick={handleSubmit} styleOverride={{padding:"8px 40px",fontSize:'14px'}} isLoading={showLoader}/>
					</div>
				</form>
			</div>
		</div>
    )
}

export default SignupScreen2