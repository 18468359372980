/*---------------------------------------------------------------------*
 *------------------------------ DESIGN 3.0 ----------------------------*
 *----------------------------------------------------------------------*
 *-------------------------------------------Frejun - 3.0 (figma)-------*
 */

/* default text styles */
export default {
  t1: {
    fontSize: "40px",
    lineHeight: "48px",
  },
  t2: {
    fontSize: "32px",
    lineHeight: "40px",
  },
  t3: {
    fontSize: "28px",
    lineHeight: "32px",
  },
  t4: {
    fontSize: "24px",
    lineHeight: "28px",
  },
  t5: {
    fontSize: "20px",
    lineHeight: "24px",
  },
  t6: {
    fontSize: "1rem",
    lineHeight: "24px",
  },
  t7: {
    fontSize: "14px",
    lineHeight: "20px",
  },
  t8: {
    fontSize: "12px",
    lineHeight: "16px",
  },
  regular_font: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
  },
  medium_font: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
  },
  semibold_font: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
  },
  bold_font: {
    fontFamily: "'Inter', sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
  },
  /* button text styles */
  b1: {
    fontSize: "16px",
    lineHeight: "16px",
  },
  b2: {
    fontSize: "14px",
    lineHeight: "14px",
  },
  b3: {
    fontSize: "12px",
    lineHeight: "12px",
  },

  b4: {
    fontSize: "10px",
    lineHeight: "10px",
  },

  /* interface colors */

  /* green */
  ic_green_500: {
    background: "#28994B",
  },
  ic_green_400: {
    background: "#2EB358",
  },
  ic_green_300: {
    background: "#34CB65",
  },
  ic_green_200: {
    background: "#CAFCDA",
  },
  ic_green_100: {
    background: "#F0FCF4",
  },

  /* red */
  ic_red_500: {
    background: "#B2433D",
  },
  ic_red_400: {
    background: "#CC4C45",
  },
  ic_red_300: {
    background: "#E0544C",
  },
  ic_red_200: {
    background: "#FCCCCA",
  },
  ic_red_100: {
    background: "#FCF0F0",
  },
  ic_white: {
    background: "#",
  },

  /* neutral colors */
  nc_gray_1000: {
    color: "#2E3330",
  },
  nc_gray_900: {
    color: "#464D48",
  },
  nc_gray_800: {
    color: "#5F6661",
  },
  nc_gray_700: {
    background: "#78807A",
  },
  nc_gray_600: {
    background: "#919994",
  },
  nc_gray_500: {
    background: "#ABB3AE",
  },
  nc_gray_400: {
    background: "#C6CCC8",
  },
  nc_gray_300: {
    background: "#E1E6E2",
  },
  nc_gray_200: {
    background: "#F5F7F6",
  },
  nc_gray_100: {
    background: "#F7FAF8",
  },

  /* tag colors */
  tc_green: {
    background: "#EDFCF2",
  },
  tc_border_green: {
    border: "1px solid #D3F8DF",
  },
  tc_red: {
    background: "#FFF1F3",
  },
  tc_border_red: {
    border: "1px solid #FFE4E8",
  },
  tc_blue: {
    background: "#F0F9FF",
  },
  tc_border_blue: {
    border: "1px solid #E0F2FE",
  },
  tc_gray: {
    background: "#F8FAFC",
  },
  tc_border_gray: {
    border: "1px solid #EEF2F6",
  },
  tc_deepblue: {
    background: "#EEF4FF",
  },
  tc_border_deepblue: {
    border: "1px solid #E0EAFF",
  },
  tc_violet: {
    background: "#F4F3FF",
  },
  tc_border_violet: {
    border: "1px solid #EBE9FE",
  },
  tc_pink: {
    background: "#FDF4FF",
  },
  tc_border_pink: {
    border: "1px solid #FBE8FF",
  },
  tc_bluegreen: {
    background: "#F0FDF9",
  },
  tc_border_bluegreen: {
    border: "1px solid #CCFBEF",
  },
  tc_orange: {
    background: "#FFF4ED",
  },
  tc_border_orange: {
    border: "1px solid #FFE6D5",
  },
  tc_yellow: {
    background: "#FEFBE8",
  },
  tc_border_yellow: {
    border: "1px solid #FEF7C3",
  },

  /* shadows */

  shadow_modal: {
    boxShadow: "0px 8px 8px 2px rgba(95, 102, 97, 0.08)",
  },
  shadow_draggable: {
    boxShadow: "0px 0px 8px 4px rgba(95, 102, 97, 0.1)",
  },
  shadow_dropdown: {
    boxShadow: "0px 2px 8px 2px rgba(95, 102, 97, 0.08)",
  },
  shadow_input_green: {
    border: "1px solid #34CB65",
    boxShadow: "0px 0px 0px 4px #F0FCF4",
  },
  shadow_input_red: {
    border: "1px solid #E0544C",
    boxShadow: "0px 0px 0px 4px #FCF0F0",
  },
};
