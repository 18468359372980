import { createSlice } from '@reduxjs/toolkit'

let _initial = null
try{
    _initial = localStorage.getItem('frejun-global-error-message')
}catch(err){
    console.warn('Error related to global error message: ', err)
}

_initial = _initial === null?false:_initial

export const globalErrorMessageSlice = createSlice({
    name: 'globalErrorMessage',
    initialState: {value:Object.keys(_initial).length === 0?false:false},
    reducers: {
        toggleGlobalErrorMessage: (state, action) => {
            state.value = action.payload
            localStorage.setItem('frejun-global-error-message', state.value)
        }
    }

})

export const { toggleGlobalErrorMessage } = globalErrorMessageSlice.actions
export default globalErrorMessageSlice.reducer