import { useEffect, useState } from 'react';
import AnimationPage from '../shared/animation-page/AnimationPage';
import OutgoingCallPricing from './OutgoingCallPricing';
import IncomingCallPricing from './IncomingCallPricing';
import "./callPricingStyles.css"
const PAGE_TABS = [ "Outgoing calls","Number rental & Incoming calls"]
const TAB_ELEMENTS = [<OutgoingCallPricing />,<IncomingCallPricing />]

export default function CallPricing() {
	const [tabNames, setTabNames] = useState(null);
	const [activeTab, setActiveTab] = useState(PAGE_TABS.at(0))
	const [showLoader, setShowLoader] = useState(false);
	// fetching data
	useEffect(() => {
		setShowLoader(false)
		setTabNames(PAGE_TABS)
	}, [])
	return <section className='fullscreen-container regular-font nc-gray-900' style={localStyles.centeredText}>
		<div>
			<p className='t2'>Virtual number & call charges</p>
		</div>
		
		{/* pricing card */}
		<div style={localStyles.pricingContainer}>
			
			{/* tabs */}
			{!!tabNames && (
				<div className="tab-selection" style={localStyles.tabContainer}>
					{tabNames.map(tabName => {
					return (
						<p
						className={`b1 nc-gray-100 tab-label ${
							tabName === activeTab && "selected-tab-label"
						}`}
						key={tabName}
						onClick={() => setActiveTab(tabName)}
						>
						{tabName}
						</p>
					);
					})}
				</div>
			)}

			{/* pricing table */}
			{showLoader ? <AnimationPage /> : TAB_ELEMENTS.at(PAGE_TABS.indexOf(activeTab)) }
			
		</div>
	</section>
}

const localStyles = {
	centeredText: {
		textAlign: "center",
		paddingTop: "64px",
	},

	pricingContainer: {
		paddingTop: "24px",
	},

	tabContainer: {
		justifyContent: "center",
	}
}