import Button from "../design/Button";
import GHeader from "../design/components/GHeader";
import { useEffect, useMemo, useRef, useState } from "react";
import { threeDotsIcon } from "../../utility-functions/assetLoader";

import colors from "../design/colors";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import GModal from "../design/components/GModal";
import { JService } from "../api-service/ApiService";
import GTable from "../../components/design/components/GTable";
import "./MessageTemplates.css";
import { useSelector } from "react-redux";
import TemplateModal from "./templateModal";
import { formatCurrentDate, sleep } from "../../utility-functions";
import { enqueueSnackbar } from 'notistack';
import TriggerModal from "./TriggerModal";

const observing = [];
const seenItems = new Set();
// ...{row1: __, row2: __ ,....}
const intersecting = [];
let intersectionObserverRef = null;
const MessageTemplates = () => {
  const reduxOrg = useSelector((state) => state?.account?.value);
  const variables = [
    "Contact name",
    "Contact phone number",
    "Agent name",
    "Agent virtual number",
    "Organization name",
  ];
  const [showModal, setShowModal] = useState({ display: false, mode: "" });
  const formatMessage = (msg) => {
    const vars = [
      "{contact_name}",
      "{contact_phone_number}",
      "{agent_name}",
      "{agent_virtual_number}",
      "{organization_name}",
    ];

    let markup = [];

    let str = ``;
    msg = msg.replaceAll(/\\\\n/g, "");
    msg = msg.replaceAll(/\\n/g, "");

    if (msg[0] === `"`) msg = msg.substring(1);

    if (msg.substring(msg.length - 3) === '\\n"')
      msg = msg.substring(0, msg.length - 3);
    const msgArray = msg.split("");

    for (let i = 0; i < msgArray.length; ) {
      if (msgArray[i] === "{") {
        let index = msgArray.slice(i).findIndex((ch) => ch === "}");

        if (vars.includes(msgArray.slice(i, i + index + 1).join(""))) {
          markup.push(str);

          str = ``;
          markup.push(
            <span className="msg-template__var">
              {
                variables[
                  vars.indexOf(msgArray.slice(i, i + index + 1).join(""))
                ]
              }
            </span>
          );

          i += index + 1;
        } else {
          i++;
        }
      } else {
        str += msgArray[i];
        i++;
      }
    }
    markup.push(str);
    return (
      <p>
        {markup.map((elem) => (
          <span>{elem}</span>
        ))}
      </p>
    );
  };
  const gtableCols = useMemo(
    () => [
      {
        field: "template_name",
        headerName: "Template name",

        flex: 1,
        renderCell: (params) => {
          return (
            <span
              style={{ paddingLeft: "0px", ...localStyles.spanNoOverFlowStyle,fontSize:'14px' }}
            >
              {params.row.title}
            </span>
          );
        },
      },
      {
        field: "message",
        headerName: "Message",

        flex: 2,
        renderCell: (params) => {
          const val = formatMessage(params.row.body);
          // from val append injectedNoOverFlowStyleForBlockedElement class to element that is observer by Intersection API
          (async function () {
            // dont start observing again, eventhough there is a set to observe only unique entries
            if (showModal.display) return false;
            await startObserving(val, params.id);
          })();
          return (
            <p
              style={{
                width: "600px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                lineHeight: "8px",
              }}
            >
              <span
                className={`template-row-span-${params.id}`}
                style={localStyles.spanNoOverFlowStyle}
              >
                {val}
              </span>
            </p>
          );
        },
      },
      {
        field: "msgTemplateEdit",
        headerName: "",
        flex: 0.3,
        cellClassName: "table-cell-edit", // @important add this field to your threedots component
        renderCell: (params) => {
          return (
            <Box
              style={{
                border: `1px solid ${colors.nc_gray_300}`,
                borderRadius: "4px",
                padding: "0 8px 4px 8px",
              }}
              onClick={(e) => handleThreeDotsClick(e, params.row.id, params?.row?.org)}
            >
              {threeDotsIcon()}
            </Box>
          );
        },
      },
    ],
    []
  );
  const API_BASE_URL=process.env.REACT_APP_BETA
  const reduxSubscription = useSelector(state=>state?.account?.value?.subscription)

  const curTemplateRef=useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [templateData, setTemplateData] = useState([]);
  const [templateDataDisplayed, setTemplateDataDisplayed] = useState([]);
  const [editableText, setEditableText] = useState("");
  const [triggersData, setTriggersData]=useState([])
  const [curTemplateId, setCurTemplateId] = useState(null);
  const [curOrgId, setCurOrgId]=useState(null);
  const [searchText, setSearchText] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showTriggerModal, setShowTriggerModal]=useState({visible:false, templateName:''})
  const [isTriggersLoading, setIsTriggersLoading]=useState(false)

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);

  const handleThreeDotsClick = (e, id, orgId) => {
    e.stopPropagation();
    setIsDropdownVisible(true);
    setDropdownAnchorEl(e.currentTarget || null);
    setCurTemplateId(id);
    setCurOrgId(orgId);
  };

  const createTemplateHandler = () => {
    setShowModal({ display: true, mode: "create" });
  };
  const closeModalHandler = () => {
    setShowModal({ display: false, mode: "" });
  };
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleCancelTemplate = () => {
    closeModalHandler();
  };

  const closeTriggerModalHandler=()=>{
    setTriggersData([])
    setShowTriggerModal({visible:false, templateName:''})
  }
  const handleCancelTriggers=()=>{
    closeTriggerModalHandler()
  }
  const formatForPost = (msg) => {
    let str = ``;
    msg = msg.replaceAll(/\\\\n/g, "\n");

    let msgArray = msg.split("");

    for (let i = 0; i < msgArray.length; ) {
      if (msgArray[i] !== "[") {
        str += msgArray[i];
        i++;
      } else {
        if (msgArray[i + 1] === "[" && msgArray[i + 2] === "{") {
          let idxLeft = i + 2;
          let idxRight = msgArray.slice(i + 2)?.findIndex((ch) => ch === "}");

          str += msgArray.slice(idxLeft, i + 3 + idxRight).join("");

          msgArray = msgArray.slice(i + 5 + idxRight);

          i = 0;
        } else {
          str += msgArray[i];
          i++;
        }
      }
    }
    return str;
  };
  const handleCreateTriggers=(payload)=>{
    
    setIsTriggersLoading(true)
    JService.post(API_BASE_URL+`/api/v1/core/create-triggers/`, JSON.stringify(payload))
    .then((res)=>{
      if(!res?.success)
        throw new Error(res?.message || "Failed to create the template triggers",)
         enqueueSnackbar(res?.message, {variant:'success'});
    })
    .catch((err)=>{
      enqueueSnackbar(err?.message, {variant:'error'})
    })
    .finally(()=>{
      closeTriggerModalHandler();
        setIsTriggersLoading(false);
    })
  }

 const handleUpdateTriggers=(payload)=>{
  setIsTriggersLoading(true)
    JService.patch(API_BASE_URL+`/api/v1/core/update-multiple-triggers/`, JSON.stringify(payload))
    .then((res)=>{
      if(!res?.success)
        throw new Error(res?.message || "Failed to update the template triggers",)
        enqueueSnackbar(res?.message, {variant:'success'})
    })
    .catch((err)=>{
      enqueueSnackbar(err?.message, {variant:'error'})
    })
    .finally(()=>{
      closeTriggerModalHandler();
      setIsTriggersLoading(false);
    })
 }

  const handleDeleteTrigger=async(triggerId)=>{
    try{
    await JService.delete(API_BASE_URL+`/api/v1/core/update-trigger/${triggerId}/?template_id=${curTemplateId}`)
    return true;
    }
    catch(err){
      enqueueSnackbar(err?.message || 'Failed to delete the trigger',{variant:'error'})
      return false
    }

  }

  const handleAddTrigger=(e)=>{
    e.stopPropagation()
    handleRetrieveTriggers(curTemplateRef.current);
  }

  const handleSaveTemplate = async (templateName, message, triggersData) => {
    if (!message || message.trim().length === 0) {
	  enqueueSnackbar("Please enter a valid value for message", { variant: "error"})
      return;
    }
    setIsLoading(true);
    message = formatForPost(message);
    let org = "";
    if (!reduxOrg || (!reduxOrg.selectedOrg && reduxOrg.selectedOrg !== 0)) {
      org = reduxOrg?.data?.profile?.org;
    } else {
      org = reduxOrg?.selectedOrg;
    }
    JService.post(`/api/v1/core/create-message-template/`, {
      title: templateName,
      body: message,
      org,
    })
      .then((res) => {
        if (!res.success) throw new Error(res?.message || "Could not create the template. ");
        
        setTemplateData((prev) => [res?.data, ...prev]);
        setTemplateDataDisplayed((prev) => [res?.data, ...prev]);
        setCurTemplateId(res?.data?.id);
        setCurOrgId(res?.data?.org)
        curTemplateRef.current={id:res?.data?.id, orgId:res?.data?.org, templateName:templateName}
        console.log(curTemplateRef.current);
        const snackbarMessage= <>
             <h5 className="t6 semibold-font">Template created successfully</h5>
             <p style={{marginTop:'4px'}} className="t6 regular-font">You can automate this template by adding automation triggers.</p>
             <span onClick={handleAddTrigger} className="t6 medium-font add-trigger-btn">Add trigger</span>
             </>;
        enqueueSnackbar(snackbarMessage,{variant:'success',title:null,styleOverride:{alignItems:'flex-start'}})
      })
      .catch((error) => enqueueSnackbar(error?.message, { variant: "error"}))
      .finally(() => {
        closeModalHandler();
        setIsLoading(false);
      });
  };

  const fetchTemplatesData = async () => {
    setShowLoader(true);
    JService.get(`/api/v1/core/view-message-templates`)
      .then((res) => {
        if(!res.success)  throw new Error(res?.message || "Could not fetch the templates. ");
        
        setTemplateData(res?.data);
        setTemplateDataDisplayed(res?.data);
      })
      .catch((error) => {enqueueSnackbar(error?.message, { variant: "error"})})
      .finally(() => setShowLoader(false));
  };

  const handleRetrieveTriggers=(data)=>{
    setIsTriggersLoading(true)
    console.log(data);
    let _templateId=data?.id ?? curTemplateId;
  
    const templateToEdit = templateData.find((t) => t.id == curTemplateId);
    console.log(templateToEdit)
    setShowTriggerModal({visible:true, templateName:data?.templateName ?? templateToEdit?.title});
    JService.get(`/api/v1/core/retrieve-triggers/?template_id=${_templateId}`)
    .then((res)=>{
      if(!res.success) throw new Error(res?.message || 'Failed to retrieve the template triggers')
      setTriggersData(res?.data || [])
    })
    .catch(error=>{enqueueSnackbar(error?.message, { variant: "error"})})
    .finally(()=>{
      setIsTriggersLoading(false)
    })
  }

  const handleDeleteOptionClicked = () => {
    setShowDeleteModal(true);
    setIsDropdownVisible(false);
    setDropdownAnchorEl(null);
  };
  const handleEditOptionClicked = () => {
    setIsDropdownVisible(false);
    setDropdownAnchorEl(null);
    setShowModal({ display: true, mode: "edit" });
    const templateToEdit = templateData.find((t) => t.id === curTemplateId);
  
    setEditableText({
    title: templateToEdit?.title,
    body: templateToEdit?.body,
    });
  };

  const handleEditTriggerOptionClicked=()=>{
    setIsDropdownVisible(false);
    setDropdownAnchorEl(null);
    handleRetrieveTriggers();
  }

  const handleEditTemplate = (templateName, message) => {
    setIsLoading(true);
    if (message.includes("@")) {
      message = formatForPost(editableText?.body || editableText);
    } else message = formatForPost(message);

    JService.patch(`/api/v1/core/update-message-template/${curTemplateId}/`,
      {
        title: templateName,
        body: message,
      }
    )
      .then((res) => {
        if(!res.success) throw new Error(res?.message || "Could not update the template. ");
        
        let temp = [...templateData];
        temp = temp.map((t) => {
          if (t.id === curTemplateId) {
            return {
              ...t,
              title: res?.data?.title,
              body: res?.data?.body,
            };
          } else return t;
        });
        setTemplateData(temp);
        setTemplateDataDisplayed(temp);
      })
      .catch((error) => enqueueSnackbar(error?.message || "Failed to update the template", { variant: "error"}))
      .finally(() => {
        closeModalHandler();
        setIsLoading(false);
      });
  };
  const handleDeleteTemplate = () => {
    JService.delete(`/api/v1/core/delete-message-template/${curTemplateId}`)
      .then((res) => {
        if (!res?.success) throw new Error(res?.message || "Could not delete the template. ");
        
        let temp = [...templateData];
        temp = temp.filter((t) => t.id !== curTemplateId);
        setTemplateData(temp);
        setTemplateDataDisplayed(temp);
      })
      .catch((error) => enqueueSnackbar(error?.message || "Failed to delete the template", { variant: "error"}))
      .finally(() => handleCloseDeleteModal());
  };

  useEffect(() => {
    (async function () {
      await fetchTemplatesData();
      // run after fetchTemplateData
      handleOnDOMContentLoaded();
    })();

    //FIXME: check for mem-leak
    return () => {
      //running cleanup for intersection observer
      if (observing.length && intersectionObserverRef) {
        observing.forEach(
          (entity) =>
            entity instanceof Element &&
            intersectionObserverRef?.unobserve(entity)
        );
        observing.length = 0;
        seenItems.clear();
      }
      if (intersectionObserverRef) {
        intersectionObserverRef.disconnect();
        intersectionObserverRef = null;
      }
    };
  }, []);

  // FIXME: add retries later; low priority
  function handleOnDOMContentLoaded() {
    const initFirstResponse = initIntersectionObserver();
    if (initFirstResponse) {
      return true;
    }

    // let retries = 0
    // const maxRetries = 1;
    // const retryIntersectionInit = (async function () {
    // 	do {
    // 		if(initIntersectionObserver()) {
    // 			return true
    // 		}
    // 		await sleep(x*retries + y)
    // 		++retries;
    // 	} while(!initIntersectionObserver() && retries < maxRetries)
    // })();
  }
  // intersectionObserverRef is set
  function initIntersectionObserver() {
    try {
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: [0.1, 0.2, 0.5, 0.75, 0.9, 0.99],
      };
      intersectionObserverRef = new IntersectionObserver(
        handleOnIntersection,
        options
      );
      if (observing) {
        // reset for observing array
        observing.length = 0;
      }

      if (seenItems.size) {
        // reset for seenItems set
        seenItems.clear();
      }
      return true;
    } catch (error) {
      console.error("error in initIntersectionOberserver ", error);
      return false;
    }
  }
  //FIXME: val to be removed from args
  async function startObserving(val, rowId) {
    // artificial delay
    await sleep(200);
    if (!intersectionObserverRef) {
      return false;
    }
    let rowMessageSpans = document.querySelectorAll(
      `.template-row-span-${rowId}`
    );

    // console.log("rowMessageSpans ", rowMessageSpans)
    rowMessageSpans.forEach((span, index) => {
      // all the textNodes
      if (span.childNodes.length === 1 && span.firstChild.nodeName === "P") {
        span.firstElementChild.classList?.add("message-p-wrapper");
        // probably the <p> wrapper around the spans in val
        let spansOfInterest = Array.from(span.firstChild.children);
        //console.log("spansOfInterest ", spansOfInterest);
        // do all children need intersection observers ? no; ideally only "last partially visible child will need one"
        // and special care for "tagify tags"
        spansOfInterest.forEach((spa, i) => {
          // checking if tagify tag
          let ele = spa;
          if (
            spa.hasChildNodes() &&
            spa.firstChild.classList?.contains("msg-template__var")
          ) {
            ele = spa.firstChild;
          }
          //set row-id, data-rowid attribute
          ele.setAttribute("data-rowId", rowId);
          ele.setAttribute("data-indexinrow", i);
          const eleSignature = `row:${rowId},i:${i}`;
          if (seenItems.has(eleSignature)) {
            let ii = observing.findIndex(
              (kk) => kk.id.at(0) === rowId && kk.id.at(1) === i
            );
            observing[ii].element = ele;
          } else {
            observing.push({ element: ele, id: [rowId, i] });
            seenItems.add(eleSignature);
            intersectionObserverRef.observe(ele);
          }
        });
      }
    });
  }
  function handleOnIntersection(entries) {
    if (!Array.isArray(entries)) {
      console.warn('Returning. Bad entires observed, got "entries" ', entries);
      return;
    }

    // FIXME: move resizeTargetElementBy, resizeTargetElementToOriginal, isLastPartiallyVisibleElement, isVisible outside the forEach block
    entries.forEach((entry, index) => {
      let currentWidth = 0;
      let newWidth = 0;
      let adjR = null;
      let targetElement = null;
      let isTagElement = false;
      const ratio = entry.intersectionRatio;
      if (!ratio || ratio === 1) return;
      targetElement = entry.target;
      if (
        targetElement.hasChildNodes() &&
        targetElement.firstChild.classList?.contains("msg-template__var")
      ) {
        //found tag
        targetElement = entry.target.firstChild;
        isTagElement = true;
        // targetElement.classList?.add('injected-for-only-tagify')
      }

      (async function () {
        const isValidElement = await isLastPartiallyVisibleElement(
          targetElement
        );
        if (!isValidElement) {
          //console.log('bad element coz not a "isLastPartiallyVisibleElement" ', targetElement)
          if (
            targetElement.classList?.contains(
              "injectedNoOverFlowStyleForBlockedElement"
            )
          ) {
            resizeTargetElementToOriginal();
            // console.log('been truncated before, resizing too ', targetElement)
          }
          return false;
        }
        // return all previously resized in the same row to original size

        let currRowId = parseInt(targetElement.dataset.rowid);
        let currElementInObserving = observing.find(
          (kk) => kk.element === targetElement
        );
        if (!currElementInObserving) {
          console.log("currElementInObserving is null for", targetElement);
        }

        if (!currRowId) {
          console.log("currRowId is null for", targetElement);
        }

        //find all in the samerow that are being observed; all observed from same row to be reset
        let allObservedFromSameRowToReset = observing.filter(
          (kk) =>
            kk.id.at(0) == currRowId &&
            kk.id.at(1) < parseInt(targetElement.dataset.indexinrow)
        ); // eslint-disable-line

        //proceeding with reset for all others
        allObservedFromSameRowToReset.forEach((kk) => {
          // console.log('removing allObservedFromSameRow for ', kk.element)
          kk.element.classList?.remove(
            "injectedNoOverFlowStyleForBlockedElement"
          );
          kk.element.classList?.remove("injected-for-only-tagify");
          kk.element.style.width = "";
        });

        //resize the target element
        resizeTargetElementBy(ratio);
      })();

      // util functions that need not be exposed
      function isVisible(domElement) {
        // convert to async-await
        return new Promise((resolve) => {
          const _observer = new IntersectionObserver(([entry]) => {
            //if completely visible
            resolve(entry.intersectionRatio === 1);
            _observer.disconnect();
          });
          _observer.observe(domElement);
        });
      }

      async function isLastPartiallyVisibleElement(domElement) {
        // nextSibling == null && current fully visible | ✅ nextSibling is not visible, only then allow
        // console.log('domElement ', domElement)
        if (domElement.nextSibling === null) {
          let currentElementVisible = await isVisible(domElement);
          return !currentElementVisible;
        }

        if (isTagElement) {
          domElement = domElement.parentElement;
        }
        // console.log('domElement ', domElement)
        let isNextSiblingVisible = await isVisible(domElement);
        if (isNextSiblingVisible) return false;
        else return true;
      }

      // resize domElement to ~resizeRatio times original size
      function resizeTargetElementBy(resizeRatio) {
        resizeRatio = parseInt(resizeRatio * 1000);
        targetElement.classList?.add(
          "injectedNoOverFlowStyleForBlockedElement"
        );
        currentWidth = parseInt(getComputedStyle(entry.target).width);
        newWidth = ((resizeRatio / 1000) * currentWidth + 4)
          .toString()
          .concat("px");

        // FIXME: with this widthl the cursor is barely visible after|before the tag
        targetElement.style.width = newWidth;

        // class that applies transform translateY; for tags only
        if (
          isTagElement ||
          targetElement.classList?.contains("msg-template__var")
        ) {
          targetElement.classList?.add("injected-for-only-tagify");
        }

        targetElement = null;
        currentWidth = 0;
        newWidth = 0;
        isTagElement = false;

        // console.log('changing width of targetElement ', targetElement)
        // console.log("currentWidth ", currentWidth)
        // console.log("newWidth ", newWidth)
      }

      function resizeTargetElementToOriginal() {
        // console.log('removing for ', targetElement)
        targetElement.classList?.remove(
          "injectedNoOverFlowStyleForBlockedElement"
        );
        targetElement.classList?.remove("injected-for-only-tagify");
        targetElement.style.width = "";
      }
    });
  }

  useEffect(() => {
    if (!searchText || searchText?.trim()?.length === 0) {
      setTemplateDataDisplayed(templateData);
      return;
    } else {
      const searchResults = templateData?.filter((t) =>
        t?.title?.toLowerCase()?.includes(searchText?.toLowerCase())
      );
      setTemplateDataDisplayed(searchResults);
    }
  }, [searchText]);

  //✅ when showModal, disconnect the "intersectionObserverRef"
  useEffect(() => {
    try {
      if (showModal.display && intersectionObserverRef) {
        if (observing.length) {
          observing.forEach(
            (entity) =>
              entity instanceof Element &&
              intersectionObserverRef?.unobserve(entity)
          );
          seenItems.clear();
          observing.length = 0;
        }
        intersectionObserverRef.disconnect();
        intersectionObserverRef = null;
      } else if (!showModal.display && !intersectionObserverRef) {
        handleOnDOMContentLoaded();
      }
    } catch (error) {
      console.error(
        "error when trying to disconnect intersectionObserver on showModal ",
        error
      );
    }
  }, [showModal]);

  const newTemplateBtn = (
    <Button
	  className="ui--button ui--template"
      hierarchy="green"
      disabled={false}
      onClick={createTemplateHandler}
      variant="outlined"
      styleOverride={{marginTop:'24px',marginBottom:'-24px'}}
      buttonText="+ New template"
    />
  );

  const deleteTemplateModalBody = showDeleteModal ? (
    <Box>
      <p className="medium-font t6 nc-gray-900">
        Are you sure you want to delete the template&nbsp;
        <span
          className="medium-font t6 nc-gray-900"
          style={{ textDecoration: "underline" }}
        >
          {templateData?.find((t) => t.id === curTemplateId)?.title}
        </span>
      </p>
    </Box>
  ) : null;
  return (
    <div className="screen-container" >
      <GHeader
        breadcrumbs={false}
        title=""
        threeDots={false}
        topHeader={{}}
        bottomHeader={{
          search: true,
          contentTitle: "Message templates",
          buttons: [newTemplateBtn],
          isCalendar: false,
        }}
        handleSetSearchText={(val) => setSearchText(val)}
      />

      {showModal.display && (
        <TemplateModal
          showModal={showModal}
          closeModalHandler={closeModalHandler}
          editableTitle={showModal.mode === "edit" ? editableText.title : ""}
          editableText={showModal.mode === "edit" ? editableText.body : ""}
          triggers={triggersData}
          handleSaveTemplate={handleSaveTemplate}
          handleEditTemplate={handleEditTemplate}
          handleCancel={handleCancelTemplate}
          isLoading={isLoading}
        />
      )}
      {showTriggerModal?.visible && (
        <TriggerModal
          showModal={showTriggerModal}
          closeModalHandler={closeTriggerModalHandler}
          triggers={triggersData}
          handleCreateTriggers={handleCreateTriggers}
          handleUpdateTriggers={handleUpdateTriggers}
          templateId={curTemplateId}
          orgId={curOrgId}
          handleEditTemplate={handleEditTemplate}
          handleCancel={handleCancelTriggers}
          isLoading={isTriggersLoading}
          deleteTrigger={handleDeleteTrigger}
        />
      )}
      <GTable
        columns={gtableCols}
        onRowClick={() => {}}
        rows={templateDataDisplayed}
        leftHeader={{ filters: [] }}
        rightHeader={{}}
        fromScreen="message-templates"
        useMuiTable={true}
        hideHeader={true}
        isLoading={showLoader}
        additionalProps={{ bgHover: true }}
      />
      <Menu
        disableScrollLock={true}
        open={isDropdownVisible}
        anchorEl={dropdownAnchorEl}
        onClose={() => {
          setIsDropdownVisible(false);
          setDropdownAnchorEl(null);
        }}
      >
        <MenuItem
          data-menuitemtype="delete"
          onClick={(e) => handleDeleteOptionClicked(e)}
          sx={{ cursor: "pointer" }}
        >
          Delete template
        </MenuItem>
        <MenuItem
          data-menuitemtype="edit"
          onClick={(e) => handleEditOptionClicked(e)}
          sx={{ cursor: "pointer" }}
        >
          Edit template
        </MenuItem>
       {reduxSubscription?.plan?.name?.includes('Professional-USD') && <MenuItem
          data-menuitemtype="edit-trigger"
          onClick={(e) => handleEditTriggerOptionClicked(e)}
          sx={{ cursor: "pointer" }}
        >
          Add/Edit trigger
        </MenuItem>}
        
      </Menu>
      <GModal
        disableCloseButton={showLoader}
        closeModal={handleCloseDeleteModal}
        body={deleteTemplateModalBody}
        heading={
          <span className="t6 nc-gray-900 medium-font">Delete template</span>
        }
        visible={showDeleteModal}
        bottomButtons={[
          <Button
		  	className="ui--button ui--cancel"
            disabled={false}
            hierarchy="white"
            variant="outlined"
            buttonText="Cancel"
            isLoading={false}
            onClick={handleCloseDeleteModal}
          />,
          <Button
			className="ui--button ui--del"
            disabled={false}
            onClick={handleDeleteTemplate}
            hierarchy="red"
            variant="contained"
            buttonText="Delete"
            isLoading={false}
          />,
        ]}
      />
    </div>
  );
};
export default MessageTemplates;

const localStyles = {
  spanNoOverFlowStyle: {
    display: "inline-block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "calc(100%)",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
  },
};
