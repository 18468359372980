// # css
import styles from "../../design/styles";
import colors from "../../design/colors";

// # components
import GHeader from "../../design/components/GHeader";
import Button from "../../design/Button";
import GTable from "../../design/components/GTable";
import GModal from "../../design/components/GModal";

// # assets
import createTeamIcon from "../../../assets/gusers-and-teams-create-team-icon.svg";
import userEditIcon from "../../../assets/call-logs-edit.svg";
import chipDeleteIcon from "../../../assets/gusers-and-teams-chip-delete-icon.svg";

// # lib
import { useEffect, useReducer, useState } from "react";
import { Box, Menu, MenuItem, Tooltip, tooltipClasses } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/styles";
import { JService } from "../../api-service/ApiService";
import { enqueueSnackbar } from 'notistack';

// returns response data in formatted form
const generateTeamsTableRowsData = (data) => {
  const returnData = Object.values(data).reduce((cumulative, key) => {
    let { id, name, total_members, virtual_numbers } = key;

    let row = {
      id,
      teamTeam: name,
      teamMemberCount: total_members,
      teamVirtualNumbers: virtual_numbers,
    };

    cumulative.push(row);
    return cumulative;
  }, []);
  return returnData;
};

const initialState = {
  searchText: "",
  searchFields: ["teamTeam"],
};

const API_BASE_URL = process.env.REACT_APP_BETA;

export default function Teams() {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [masterRows, setMasterRows] = useState([]);
  const [cols, setCols] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [controller, setController] = useState(1);
  // response data of sub-org for edit team
  const [data, setData] = useState([]);

  // response data of retreive-users
  const [users, setUsers] = useState([]);

  const [usersArray, setUsersArray] = useState([]);

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editTeamValues, setEditTeamValues] = useState({
    id: "",
    name: "",
    members: [],
    totalMembers: 0,
  });

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const [createTeamModalVisible, setCreateTeamModalVisible] = useState(false);

  const [createTeamValues, setCreateTeamValues] = useState({
    name: "",
    members: [],
  });

  // details of team on which latest click was made via threedots
  // or in the dropdown options if edit is clicked team is set to
  //  the item filtered out from data (response data)
  const [team, setTeam] = useState(null);

  const filtersReducer = (state, action) => {
    switch (action.type) {
      case "setSearchText": {
        return {
          ...state,
          searchText: action.payload,
        };
      }
      default:
        return state;
    }
  };

  const [availableData, dispatchAvailableData] = useReducer(
    filtersReducer,
    initialState
  );

  useEffect(() => {
    // after search call combineSearchAndFilters
    // simple search
    const searchTextLower = availableData?.searchText?.toLowerCase();
    let finalResult = [];
    let intermediateResult = []; // eslint-disable-line
    availableData?.searchFields?.forEach((searchField) => {
      let intermediateResult = masterRows?.filter((row) =>
        row[searchField]?.toLowerCase()?.includes(searchTextLower)
      );
      finalResult = finalResult.concat(intermediateResult);
    });

    combineSearchAndFilters(finalResult);
  }, [availableData]); //eslint-disable-line

  function combineSearchAndFilters(arg1) {
    // apply the filters on arg1
    let temp = arg1;
    // @note removing duplicates
    temp = temp?.reduce(function (x, y) {
      if (x.indexOf(y) < 0) x.push(y);
      return x;
    }, []);

    setRows(temp);
  }

  const handleCreateTeamClick = (e) => {
    if (deleteModalVisible) handleCloseDeleteModal();
    if (editModalVisible) handleEditModalClose();
    setCreateTeamModalVisible(true);
  };

  const createTeamButton = (
    <Button
      variant="filled"
      buttonText="Create team"
      icon={{
        startIcon: (
          <img
            style={{ width: "18px" }}
            src={createTeamIcon}
            alt="Create team icon"
          />
        ),
      }}
      isLoading={false}
      hierarchy="green"
      onClick={handleCreateTeamClick}
	  className="ui--create-team"
    />
  );

  function handleThreeDotsClick(e, id) {
    e.stopPropagation();

    setIsDropdownVisible(true);
    setDropdownAnchorEl(e.currentTarget || null);
    setTeam(id);
  }

  // onclick of one of threedots options
  const handleDropdownClick = (e) => {
    const menuitemtype = e.target.dataset.menuitemtype;
    if (menuitemtype === "edit") {
      let item = data?.filter((d) => d?.id == team);
      item = Array.isArray(item) && item.length > 0 ? item[0] : null;
      let payload = {
        id: item?.id,
        name: item?.name,
        totalMembers: item?.total_members,
        members: [], // for members data in teams page
        // api call needs to be made to /sub-org..../{team.id}/
      };

      JService.get(API_BASE_URL + `/api/v1/auth/teams/${item?.id}/`)
        .then((res) => {
          if (!res?.success)
            throw new Error(res?.message || "Could not retreive teams");
          let memTemp = res?.data?.users || []; //?.users?.filter(uu => uu?.role?.toLowerCase() === 'user')
          payload.members = memTemp?.map(
            (mm) => mm?.first_name + " " + mm?.last_name + "," + mm?.id
          );
          setEditTeamValues(payload);
          setEditModalVisible(true);
          if (deleteModalVisible) setDeleteModalVisible(false);
          if (Array.isArray(item) && item.length > 0) setTeam(item[0]);
        })
        .catch((error) => enqueueSnackbar(error?.message, { variant: "error"})) 
        
    } else if (menuitemtype === "delete") {
      setDeleteModalVisible(true);
      if (editModalVisible) setEditModalVisible(false);
    }
    // closing the dropdown
    setIsDropdownVisible(false);
  };

  const API_BASE_URL = process.env.REACT_APP_BETA;
  useEffect(() => {
    if (!showLoader) setShowLoader(true);
    JService.get(API_BASE_URL + `/api/v1/auth/teams/`)
      .then((res) => {
        if (!res?.success)
          throw new Error(res?.message || "Could not retreive teams");
        setCols([
          {
            field: "teamTeam",
            headerName: "Team",
            headerClassName: "teams-table-header-team-name",
            cellClassName: "teams-table-cell-team-name",
            flex: 1,
            renderCell: (params) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  transition: "all 0.3s ease-in",
                  textDecoration: "none",
                  paddingLeft: "16px",
                  /* Interface Colors/Green - 500 */
                  color: "#28994B",
                }}
              >
                <span style={{ textDecorationColor: "#28994B" }}>
                  {params?.row?.teamTeam}
                </span>
              </Box>
            ),
          },
          {
            field: "teamVirtualNumbers",
            headerName: "Virtual number",
            flex: 1,
            renderCell: (params) => {
              return (
                <StyledTooltip
                  disableHoverListener={
                    params?.row?.teamVirtualNumbers.length < 2
                  }
                  placement="top"
                  arrow
                  title={
                    <p className="t7">
                      {Array.isArray(params?.row?.teamVirtualNumbers) &&
                        params?.row?.teamVirtualNumbers.map((vn, index) => (
                          <span
                            key={index}
                            className="t6 regular-font nc-gray-900"
                          >
                            {(index != 0 ? ", " : "") +
                              (vn?.country_code || " ") +
                              " " +
                              (vn?.number || " ")}
                          </span>
                        ))}
                    </p>
                  }
                  sx={{
                    "& .MuiTooltip-arrow": {
                      color: "#E1E6E2",
                    },
                  }}
                >
                  <div style={{ maxWidth: "100%" }}>
                    <Box
                      sx={{
                        justifyContent: "center",
                        display: "inline-block",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "100%",
                      }}
                    >
                      {Array.isArray(params?.row?.teamVirtualNumbers) &&
                        params?.row?.teamVirtualNumbers
                          .map((vn) => {
                            return vn.country_code + " " + vn.number;
                          })
                          .join(", ")}
                    </Box>
                  </div>
                </StyledTooltip>
              );
            },
          },
          {
            field: "teamMemberCount",
            headerName: "Members",
            flex: 1,
          },
          {
            field: "teamEdit",
            headerName: "",
            flex: 0.5,
            cellClassName: "table-cell-edit",
            renderCell: (params) => {
              return (
                <Box data-teamid={params?.id}>
                  <img
                    data-teamid={params?.id}
                    onClick={(e) => handleThreeDotsClick(e, params?.id)}
                    src={userEditIcon}
                    alt="three dots icon"
                  />
                </Box>
              );
            },
          },
        ]);
        setData(res?.data);
        let temp = generateTeamsTableRowsData(res?.data?.reverse() || []);
        setRows(temp);
        setMasterRows(temp);
      })
      .catch((err) => enqueueSnackbar(err?.message || "Failed to retreive teams", { variant: "error"}))
      .finally(() => setShowLoader(false));

    JService.get(API_BASE_URL + `/api/v1/auth/retrieve-users/`)
      .then((res) => {
        if (!res?.success)
          throw new Error(res?.message || "Could not retreive users");
        setUsersArray(res?.data || []);
        let temp = res?.data?.map(
          (rd) => rd?.first_name + " " + rd?.last_name + "," + rd?.id
        );
        setUsers(temp);
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error"}))
      .finally(() => setShowLoader(false));
  }, [controller]); // eslint-disable-line

  function onRowClick(params, event, details) {
    // reference props.location.state.teamId in the TeamView page.
    navigate(`/teams/${params?.id}`, {
      state: {
        id: params?.id,
      },
    });
  }

  const deleteTeamModalBody = deleteModalVisible ? (
    <Box>
      <p className="medium-font t6 nc-gray-900">
        Are you sure you want to delete&nbsp;
        <span
          className="medium-font t6 nc-gray-900"
          style={{ textDecoration: "underline" }}
        >
          {team?.name || data?.find((d) => d?.id == team)?.name}
        </span>
        &nbsp;team?
      </p>
    </Box>
  ) : null;

  const editTeamModalBody = editModalVisible ? (
    <Box>
      <form style={localStyles.form}>
        {/* team name*/}
        <label
          className="t7 nc-gray-900 medium-font" style={localStyles.label}>
          Team name
          <input
            value={editTeamValues?.name}
            type="text"
            className="t6 regular-font nc-gray-900 ui--input"
            style={localStyles.textInput}
            data-edittype="name"
            onChange={handleEditTeamChange}
          />
        </label>

        {/* team members */}
        <label className="t7 nc-gray-900 medium-font" style={localStyles.membersLabel}>
          Members
        </label>
        <Select
          multiple={true}
          multiline={true}
          autoFocus={false}
          value={editTeamValues?.members}
          onChange={(e) => {
            let {
              target: { value },
            } = e;
            if (value instanceof Array) value = value?.reverse()[0];

            setEditTeamValues((prevState) => ({
              ...prevState,
              members: [...prevState?.members, value],
            }));

            // do not remove deleted user from users array
          }}
          renderValue={(selected) => {
            if (usersArray?.length === 0)
              return <p className="t6 nc-gray-400 regular-font">No members</p>;
            if (selected?.length === 0)
              return (
                <p className="t6 nc-gray-400 regular-font">Select members</p>
              );
            // check selected to see all seleted values, at this point, selected has duplicates
            // removing duplicates from rendering, remove duplicates from Select value before sending
            let temp = selected?.reduce(function (x, y) {
              if (x.indexOf(y) < 0) x.push(y);
              return x;
            }, []);
            return (
              <Box
                sx={{
                  maxWidth: "340px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                  rowGap: "4px",
                }}
              >
                {temp?.map((value) => {
                  if (!value || (Array.isArray(value) && value?.length === 0))
                    return;
                  let id = value.split(",")[1];
                  let name = value.split(",")[0];
                  return (
                    <Chip
                      sx={{
                        borderRadius: "4px",
                        border: "1px solid #E1E6E2",
                        marginRight: "8px",
                        height: "24px",
                      }}
                      deleteIcon={
                        <img src={chipDeleteIcon} alt="Delete manger icon" />
                      }
                      variant="outlined"
                      onMouseDown={(e) => e.stopPropagation()}
                      onDelete={(e) => {
                        e.stopPropagation();
                        handleRemoveFromCreateTeamMembers(value, selected);
                      }}
                      key={id}
                      data-selecteduser={value}
                      label={name}
                    />
                  );
                })}
              </Box>
            );
          }}
          sx={localStyles.membersSelect}
          displayEmpty
          placeholder="No members selected"
          inputProps={{ "aria-label": "Without label" }}
		  className="ui--select"
        >
          {usersArray?.map((user, index) => (
            <MenuItem
              key={index}
              value={user?.first_name + " " + user?.last_name + "," + user?.id}
            >
              {user?.first_name + " " + user?.last_name}{" "}
            </MenuItem>
          ))}
        </Select>
      </form>
    </Box>
  ) : null;

  function handleRemoveFromCreateTeamMembers(value, selectedUsers) {
	setEditTeamValues((prevState) => {
      let temp = selectedUsers?.filter(
        (selectedUser) => selectedUser !== value
      );
      return { ...prevState, members: temp };
    });
  }

  const createTeamModalBody = createTeamModalVisible ? (
    <Box>
      <form style={localStyles.form}>
        {/* team name*/}
        <label
          className="t7 nc-gray-900 medium-font"
          style={localStyles.label}
        >
          Team name
          <input
            value={createTeamValues?.name}
            type="text"
            className="t6 regular-font nc-gray-900 ui--input"
            style={localStyles.textInput}
            data-addtype="name"
            onChange={handleCreateTeamChange}
          />
        </label>

        {/* members */}
        <label
          className="t7 nc-gray-900 medium-font"
          style={localStyles.membersLabel}
        >
          Members
        </label>
        <Select
          multiple={true}
          multiline={true}
          autoFocus={false}
          value={createTeamValues?.members}
          onChange={(e) => {
            let {
              target: { value },
            } = e;
            if (value instanceof Array) value = value?.reverse()[0];

            setCreateTeamValues((prevState) => ({
              ...prevState,
              members: [...prevState?.members, value],
            }));

            // do not remove deleted user from users array
          }}
          renderValue={(selected) => {
            if (usersArray?.length === 0)
              return <p className="t6 nc-gray-400 regular-font">No members</p>;
            if (selected?.length === 0)
              return (
                <p className="t6 nc-gray-400 regular-font">Select members</p>
              );
            // check selected to see all seleted values, at this point, selected has duplicates
            // removing duplicates from rendering, remove duplicates from Select value before sending
            let temp = selected?.reduce(function (x, y) {
              if (x.indexOf(y) < 0) x.push(y);
              return x;
            }, []);
            return (
              <Box
                sx={{
                  maxWidth: "340px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                  rowGap: "4px",
                }}
              >
                {temp?.map((value) => {
                  if (!value || (Array.isArray(value) && value?.length === 0))
                    return;
                  let id = value.split(",")[1];
                  let name = value.split(",")[0];
                  return (
                    <Chip
                      sx={{
                        borderRadius: "4px",
                        border: "1px solid #E1E6E2",
                        marginRight: "8px",
                        height: "24px",
                      }}
                      deleteIcon={
                        <img src={chipDeleteIcon} alt="Delete manger icon" />
                      }
                      variant="outlined"
                      onMouseDown={(e) => e.stopPropagation()}
                      onDelete={(e) => {
                        e.stopPropagation();
                        handleRemoveFromCreateTeamMembers(value, selected);
                      }}
                      key={id}
                      data-selecteduser={value}
                      label={name}
                    />
                  );
                })}
              </Box>
            );
          }}
          sx={localStyles.membersSelect}
          displayEmpty
          placeholder="No member selected"
          inputProps={{ "aria-label": "Without label" }}
		  className="ui--select"
        >
          {usersArray?.map((user, index) => (
            <MenuItem
              key={index}
              value={user?.first_name + " " + user?.last_name + "," + user?.id}
            >
              {user?.first_name + " " + user?.last_name}{" "}
            </MenuItem>
          ))}
        </Select>
      </form>
    </Box>
  ) : null;

  function handleEditTeamChange(e) {
    const fieldType = e.target.dataset.edittype;
    const value = e.target.value;
    setEditTeamValues((prevState) => {
      if (fieldType === "name") return { ...prevState, name: value };
    });
  }

  function handleCreateTeamChange(e) {
    const fieldType = e.target.dataset.addtype;
    const value = e.target.value;
    setCreateTeamValues((prevState) => {
      if (fieldType === "name") return { ...prevState, name: value };
    });
  }

  function handleDeleteTeam(e) {
    JService.delete(
      API_BASE_URL +
        `/api/v1/auth/teams/${
          ["string", "number"]?.includes(typeof team) ? team : team?.id
        }/`
    )
      .then((res) => {
        if (!res?.success) throw new Error(res?.message || "Could not delete team");
        
		enqueueSnackbar(res?.message || "Team deleted", { variant: "success"})
        handleCloseDeleteModal();
        setController(prev => ++prev);
      })
      .catch((error) => enqueueSnackbar(error?.message, { variant: "error"}))
  }

  function handleEditTeamSave(e) {
    if (!showLoader) setShowLoader(true);

    let temp = [...editTeamValues?.members];
    let sendMemberIds = temp?.map((te) => {
      let id = te.split(",")[1];
      return id;
    });

    let finalSend = sendMemberIds.map((s) => parseInt(s));
    let payload = {
      name: editTeamValues?.name,
      members: finalSend,
    };
    let teamId = ["string", "number"]?.includes(typeof team) ? team : team?.id;
    JService.patch(
      API_BASE_URL + `/api/v1/auth/teams/${teamId}/`,
      JSON.stringify(payload)
    )
      .then((res) => {
        if (!res?.success) throw new Error(res?.message || "Could not update team details");

		enqueueSnackbar(res?.message || "Updated team", { variant: "success"})
        setController((prevState) => ++prevState);
        handleEditModalClose();
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error"}))
      .finally(() => setShowLoader(false));
  }

  function handleCreateTeamSave(e) {
    if (!showLoader) setShowLoader(true);

    // member manager sent together

    let temp = [
      ...createTeamValues?.members?.reduce(function (x, y) {
        if (x.indexOf(y) < 0) x.push(y);
        return x;
      }, []),
    ];

    let sendMemberIds = [];
    temp.map((te) => {
      let id = te.split(",")[1];
      if (!sendMemberIds?.includes(id)) sendMemberIds.push(id);
    });
    let finalSendData = sendMemberIds.map((sm) => parseInt(sm));

    let payload = {
      name: createTeamValues?.name,
      members: finalSendData,
    };

    JService.post(API_BASE_URL + `/api/v1/auth/teams/`, payload)
      .then((res) => {
        if (!res?.success) throw new Error(res?.message || "Could not create team");

		enqueueSnackbar(res?.message || "Created team successfully", { variant: "success"})
        setController((prevState) => ++prevState);
        handleAddModalClose();
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error"}))
      .finally(() => setShowLoader(false));
  }

  function handleCloseDeleteModal() {
    setDeleteModalVisible(false);
  }

  function handleEditModalClose() {
    setEditModalVisible(false);
    setEditTeamValues({
      id: "",
      name: "",
      managers: [],
      members: [],
      totalMembers: 0,
    });
  }

  function handleAddModalClose() {
    setCreateTeamModalVisible(false);
    setCreateTeamValues({
      name: "",
      managers: [],
      members: [],
      totalMembers: 0,
    });
  }

  const [csvData, setCSVData] = useState({
    headers: [["Team"], ["Virtual number"], ["Managers"], ["Members"]],
    data: [],
    ids: [],
  });

  const csvDownloadBody = null;

  useEffect(() => {
    setCSVData((prevState) => {
      let newIds = [];
      let newData = [];

      for (let i = 0; i < rows?.length; ++i) {
        let item = rows[i];
        if (!newIds?.includes(item?.id)) {
          newIds.push(item?.id);
          let temp = [
            item?.teamTeam,
            item?.teamVirtualNumbers
              .map((vn) => {
                return vn?.country_code + " " + vn?.number;
              })
              .join(", "),
            item?.teamManager?.join(", ") || "",
            item?.teamMemberCount,
          ];
          newData.push(temp);
        }
      }
      return { ...prevState, data: newData, ids: newIds };
    });
  }, [rows]);

  function threeDotsAutoClose(e) {
    const path = e.composedPath()?.map((pathItem) => {
      let newPathItem = null;
      newPathItem =
        pathItem?.tagName?.toLowerCase() +
        (pathItem?.className ? "." + pathItem?.className : "");
      return newPathItem;
    });

    if (!path?.length || path[0]?.includes("div.MuiBackdrop-root")) {
      // clicked outside
      setIsDropdownVisible(false);
      setDropdownAnchorEl(null);
      return;
    }
  }

  useEffect(() => {
    if (isDropdownVisible) window.addEventListener("click", threeDotsAutoClose);
    else window.removeEventListener("click", threeDotsAutoClose);

    return () => {
      window.removeEventListener("click", threeDotsAutoClose);
    };
  }, [isDropdownVisible]);

  return (
    <div className="screen-container">
      <GHeader
        breadcrumbs={false}
        //title="Team management"
        threeDots={false}
        topHeader={{}}
        bottomHeader={{
          contentTitle: "Teams" || "dummy_content_title",
          buttons: [createTeamButton],
          isCalendar: false,
        }}
      />

      <GTable
        leftHeader={{ filters: {}, isSearch: true }}
        dispatchAvailableData={dispatchAvailableData}
        availableData={availableData}
        rightHeader={{ isRefresh: true, isDownload: true }}
        download={{
          data: csvData,
          body: csvDownloadBody,
          filename:
            "frejun-teams-" +
            new Date().toLocaleString("en-US", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            }),
        }}
        rows={rows}
        fromScreen="teams"
        columns={cols}
        useMuiTable={true}
        isLoading={showLoader}
        // getTableRowClassName={getTableRowClassName}
        additionalProps={{ bgHover: true }}
        onRowClick={onRowClick}
        searchFields={["teamTeam"]} // set in availableData's initialState
        combineSearchAndFilters={combineSearchAndFilters}
      />

      {/* three dots dropdown menu */}
      <Menu
        disableScrollLock={true}
        open={isDropdownVisible}
        anchorEl={dropdownAnchorEl}
      >
        <MenuItem
          data-menuitemtype="edit"
          onClick={handleDropdownClick}
          sx={{ cursor: "pointer" }}
        >
          Edit
        </MenuItem>

        <MenuItem
          data-menuitemtype="delete"
          onClick={handleDropdownClick}
          sx={{ cursor: "pointer" }}
        >
          Delete
        </MenuItem>
      </Menu>

      {/* delete team modal */}
      <GModal
        // modalStyle={}
        disableCloseButton={showLoader}
        closeModal={handleCloseDeleteModal}
        body={deleteTeamModalBody}
        heading={
          <span className="t6 nc-gray-900 medium-font">Delete team</span>
        }
        visible={deleteModalVisible}
        bottomButtons={[
          <Button
            disabled={false}
            hierarchy="white"
            variant="contained"
            buttonText="Cancel"
            isLoading={false}
            onClick={handleCloseDeleteModal}
          />,
          <Button
            disabled={false}
            onClick={handleDeleteTeam}
            hierarchy="red"
            variant="contained"
            buttonText="Delete"
            isLoading={false}
          />,
        ]}
      />

      {/* edit team modal */}
      <GModal
        disableCloseButton={showLoader}
        closeModal={handleEditModalClose}
        body={editTeamModalBody}
        heading={<span className="t6 nc-gray-900 medium-font">Edit team</span>}
        visible={editModalVisible}
        bottomButtons={[
          <Button
            disabled={false}
            hierarchy="white"
            variant="contained"
            buttonText="Cancel"
            isLoading={false}
            onClick={handleEditModalClose}
          />,
          <Button
            disabled={false}
            hierarchy="green"
            variant="contained"
            buttonText="Save"
            isLoading={false}
            onClick={handleEditTeamSave}
          />,
        ]}
      />

      {/* create team modal */}
      <GModal
        disableCloseButton={showLoader}
        closeModal={handleAddModalClose}
        body={createTeamModalBody}
        heading={
          <span className="t6 nc-gray-900 medium-font">Create team</span>
        }
        visible={createTeamModalVisible}
        bottomButtons={[
          <Button
            disabled={false}
            hierarchy="white"
            variant="contained"
            buttonText="Cancel"
            isLoading={false}
            onClick={handleAddModalClose}
          />,
          <Button
            disabled={false}
            hierarchy="green"
            variant="contained"
            buttonText="Save"
            isLoading={false}
            onClick={handleCreateTeamSave}
          />,
        ]}
      />
    </div>
  );
}

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#E1E6E2",
    color: "#464D48",
    maxWidth: 294,
    border: "1px solid #dadde9",
    borderRadius: "4px",
  },
}));

const localStyles = {
	form: {
		display: "flex",
		flexDirection: "column",
		maxWidth: "100%"
	},
	label: {
		marginBottom: "24px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "stretch",
	},
	textInput: {
		marginTop: "4px",
		padding: "8px 12px",
		borderRadius: "4px",
		border: "1px solid #E1E6E2",
	},

	membersLabel: {
		marginBottom: "4px" 
	},
	membersSelect: {
		alignSelf: "flex-start",
		minWidth: 200,
		width: "100%",
		maxWidth: "100%",
		outline: "none",
		borderRadius: "4px",
		padding: 0,
		marginBottom: "24px",
		...styles.t6,
		...styles.regular_font,
		...colors.nc_gray_900,
		"&:before": {
			outline: "none",
			border: "none",
		},
		"&:focus .MuiOutlinedInput-input": {
			border: "none",
			outline: "none",
		},
		"& .MuiOutlinedInput-input": {
			padding: "8px 12px 8px 12px",
			border: "none",
			outline: "none",
		},
		"& .Mui-disabled": {
			cursor: "not-allowed",
		},
	}
}