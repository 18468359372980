import GModal from "../design/components/GModal"
import Button from "../design/Button"
import gStyles from "../design/commonStyles"
import { useEffect, useState } from "react"
import { CustomInput } from "../design/components/customComponents"
import { commonStyles } from "../signup/commonStyles"
import { MenuItem, Select } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import colors from "../design/colors"
import { topNavAccountSelectedAccountTickIcon } from "../../utility-functions/assetLoader"
import { JService } from "../api-service/ApiService"
import { useDispatch, useSelector } from "react-redux"
import BillingAddressForm from "../billing/BillingAddressForm"
import { saveSelectedOrg, setAllOrgsDetails, setSubscriptionDetails } from "../features/account/account"
import { enqueueSnackbar } from 'notistack'

const PlanModal2=({visible,handleClose,type,handleBack})=>{
    const reduxSubscription=useSelector((state)=>state?.account?.value?.subscription)
    const reduxAccount=useSelector((state)=>state?.account?.value)
    const dispatch=useDispatch()

    const [licenses,setLicenses]=useState({value:'',error:null})
    const [keepVn,setKeepVn]=useState('Keep number')

    const {line1,line2,city,country,state,zip}=reduxSubscription?.billing_address
    const [companyName,setCompanyName]=useState({value:'',error:null})
    const [gstNumber,setGstNumber]=useState(reduxSubscription?.gstin)
    const [address, setAddress]=useState({line1,line2,city,zip,state,country:country || 'IN'})
    const [companyNameModified,setCompanyNameModified]=useState(false)
    const [addressModified,setAddressModified]=useState(false)
    const [isDisabled,setIsDisabled]=useState(true)

    const handleLicenseChange=(e)=>{
        setLicenses(prev=>({...prev,value:e.target.value}))
    }

    const handleFocus=(e)=>{
        if(licenses.error){
            setLicenses(prev=>({...prev,error:null}))
        }
    }

    const handleCompanyNameChange=(e,val)=>{
        if(e==='focus') {
            setCompanyName(prev=>({...prev,error:null}))
            return;
          }
          else if(e==='value'){
            setCompanyName({value:val,error:null})
            return;
          }
          if(!companyNameModified) setCompanyNameModified(true)
          setCompanyName(prev=>({...prev,value:e.target.value}))
    }
    const handleAddressChange=(e,field)=>{
        
        if(!addressModified) setAddressModified(true)
        if(field==='country'){
          setAddress(prev=>({...prev,country:e}))
          return;
        }
        const name=e.target.name
        setAddress(prev=>({...prev,[name]:e.target.value}))
    }
    const handleGstNumberChange=(val)=>{
        if(!addressModified) setAddressModified(true)
        setGstNumber(val)
      }
      const fetchBillingDetails=()=>{
        let orgUrlParam = "";
        if (
          reduxAccount?.data?.profile?.account_holder &&
          reduxAccount?.allOrgs?.length > 1
        ) {
          orgUrlParam = reduxAccount?.selectedOrg + "/";
          if (!reduxAccount?.selectedOrg && reduxAccount?.selectedOrg !== 0)
            orgUrlParam = "";
        }
        JService.get(`/api/v1/subscriptions/billing-details/${orgUrlParam}`)
        .then((res)=>{
          if(!res.success){
            //setSnackbar(prev=>({...prev,open:true,data:{...prev.data,message:res.message || 'Error in retrieving billing details',type:'error'}}))
            return
          }
          
          let temp={...res?.data}
          delete temp['addons']
          delete temp['numbers']
          temp['licenses']={...temp?.plan?.licenses}
          if(temp && Object.keys(temp)?.length){
          dispatch(setSubscriptionDetails(temp))
          }
        })
        .catch(err=>{
    
        })
        .finally(()=>{
         
        })
      }
    const handleInitiateTrial=()=>{
        JService.post(`/api/v1/subscriptions/activate-subscription/`,JSON.stringify({
            plan:type,
            quantity:licenses.value,
            keep_vn:keepVn==='Keep number'?true:false
        }))
        .then((res)=>{
            if(!res.success && !res?.checkout_url) throw new Error(res?.message || 'Could not activate subscription')
            if(res?.checkout_url){
                localStorage.setItem('account-upgraded-today',JSON.stringify(false))
                window.location.href=res.checkout_url
            }

        })
        .catch((err)=>{
			enqueueSnackbar(err?.message, { variant: "error"})
        })
        .finally(()=>{
            handleClose()
        })
    }
    const handleContinue=(e)=>{
        e.preventDefault()
        if(licenses.value<(reduxSubscription?.plan_licenses?.total-reduxSubscription?.plan_licenses?.free)){
            setLicenses(prev=>({...prev,error:'Enter a number not lesser than the number of currently active users.'}))
            return;
        }
        let _companyNameModified=companyNameModified
        let _addressModified=addressModified
        setAddressModified(false)
        setCompanyNameModified(false)
        let orgUrlParam = "";
        if (
          reduxAccount?.data?.profile?.account_holder &&
          reduxAccount?.allOrgs?.length > 1
        ) {
          orgUrlParam = reduxAccount?.selectedOrg + "/";
          if (!reduxAccount?.selectedOrg && reduxAccount?.selectedOrg !== 0)
            orgUrlParam = "";
        }
        if(_companyNameModified){
            let errors=null
            JService.patch(
                `/api/v1/auth/update-organization/${orgUrlParam}`,
                JSON.stringify({ 
                  company: companyName.value    
                 })
              )
                .then((res) => {
                  if (!res?.success){
                    if(res?.details) errors=res?.details
                    throw new Error(
                      res?.message || res?.detail || "Could not update company details"
                    );
                  }
				  enqueueSnackbar(res?.message || "Updated company details", { variant: "success"})

                  let allOrgsData = [
                    ...reduxAccount?.allOrgs?.filter((ao) => ao?.id !== res.data?.id),
                    res.data,
                  ];
                  dispatch(setAllOrgsDetails(allOrgsData));

                //   dispatch(setAllOrgsDetails([res?.data]));
                //   dispatch(saveSelectedOrg(res?.data?.id));
                })
                .catch((err) =>{
                  if(errors && errors?.company){
                    setCompanyName(prev=>({...prev,error:Array.isArray(errors?.company)?errors?.company[0]:errors.company}))
                  }
                  else enqueueSnackbar(err?.message, { variant: "error"})
            	})
        }
        if(_addressModified){
        const payload={
            "address": {
            line1:address.line1 || '',
            line2:address.line2 || '',
            zip:address.zip || '',
            city:address.city || '',
            state:address.state || '',
            country:address.country || ''
            },
            "gstin":gstNumber || null
    }
    if((!payload.address.state || !payload.address.state?.trim().length) && type.includes('INR')){
      enqueueSnackbar('Please enter a valid value for state',{variant:'error'});
      return;
    }
        JService.patch(`/api/v1/subscriptions/subscription/${orgUrlParam}`,JSON.stringify(payload))
        .then((res)=>{
            if(!res?.success)
                throw new Error(res?.message || 'Failed to update the billing address')
            fetchBillingDetails()
            handleInitiateTrial()
			enqueueSnackbar(res?.message || 'Successfully updated the billing information', { variant: "success"})
        })
        .catch(err=>{
			      enqueueSnackbar(err?.message, { variant: "error"})
            // setAddress(reduxSubscription?.billing_address)
            setAddress({line1,line2,city,zip,state,country:country || 'IN'})
            setGstNumber(reduxSubscription?.gstin)
            setLicenses({value:'',error:null})
            setTimeout(()=>{
                handleClose()
            },500)
            
        })
        }else{
            handleInitiateTrial()
        }
    }
    useEffect(()=>{
        let addressInvalid=false
        for(let key in address){
            if(key=='line2') continue;
            if(!address[key] || !address[key]?.trim()?.length){
            addressInvalid=true
            break;
            }
        }
        if(!companyName.value || !companyName?.value?.trim()?.length || !licenses.value || addressInvalid){
            if(!setIsDisabled) setIsDisabled(true)
        }
        else{
            if(setIsDisabled) setIsDisabled(false)
        }
    },[address,companyName,licenses])
  
   
    return (
        <GModal
            visible={visible}
            bodyWrapperStyle={{width:'761px',paddingRight:0}}
            modalStyle={{width:'761px',minWidth:'auto',padding:'40px 16px'}}
            heading={`Upgrade to ${type?.split('-')[0]} Plan`}
            bottomButtons={[
                <Button styleOverride={gStyles.btnStyle} hierarchy="white" variant='outlined' buttonText='Back' disabled={false} onClick={handleBack}/>,
                <Button styleOverride={gStyles.btnStyle} hierarchy='green' buttonText='Continue' disabled={isDisabled} onClick={handleContinue}/>
            ]}
            closeModal={handleClose}
            body={
                <form style={{display:'flex', alignItems:'flex-start',justifyContent:'center',gap:'32px'}}>
                <BillingAddressForm visible={visible} gstOptional={true} companyName={companyName} handleCompanyNameChange={handleCompanyNameChange} address={address} handleAddressChange={handleAddressChange} gstNumber={gstNumber} handleGstNumberChange={handleGstNumberChange} style={{width:'320px'}} stateRequired={type?.includes('INR')}/>
                <div style={{width:'345px',marginRight:0,border:'1.25px solid #e1e5e2',borderRadius:'6px',padding:'32px'}}>
                    <CustomInput label="Licenses" value={licenses} handleChange={handleLicenseChange} handleFocus={handleFocus} type='number' name='license' placeholder='Enter quantity'/>
                    <div className="select-container">
                         <label className="t7 medium-font">Keep/remove trial virtual number</label>
                        <Select
                        IconComponent={ExpandMoreIcon}
                        sx={commonStyles.selectStyles}
                        MenuProps={commonStyles.selectMenuStyles}
                        value={keepVn}
                        native={false}
                        displayEmpty
                        renderValue={(val) => {
                        
                            return (
                            val?<span style={{color:colors.nc_gray_900}}>{val}</span>:null
                            )
                        }}
                        onChange={(e) => setKeepVn(e.target.value)}
                        >
                        {['Keep number', 'Remove number'].map((elem, index) => {
                            return (
                            <MenuItem sx={commonStyles.selectMenuItemStyles} value={elem} key={index}>
                                <span>{elem}</span>{keepVn===elem && <span>{topNavAccountSelectedAccountTickIcon()}</span>}
                            </MenuItem>
                            );
                        })}
                        </Select>
                        </div>
                </div>
                </form>
            }
        />
    )
}

export default PlanModal2