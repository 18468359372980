import { createSlice } from '@reduxjs/toolkit'

let _initial = null
try{
    _initial = JSON.parse(localStorage.getItem('frejun-global-modal'))
}catch(err){
    console.warn('Error related to modal: ', err)
}

_initial = _initial === null?false:_initial

export const globalModalSlice = createSlice({
    name: 'globalModal',
    initialState: {value:Object.keys(_initial).length === 0?false:_initial},
    reducers: {
        toggleGlobalModal: (state, action) => {
            state.value = action.payload
            localStorage.setItem('frejun-global-modal', JSON.stringify(state.value))
        }
    }

})

export const { toggleGlobalModal } = globalModalSlice.actions
export default globalModalSlice.reducer