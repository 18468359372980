import GModal from "../design/components/GModal";
import './WelcomeModal.css';

const WelcomeModal=({visible, handleClose})=>{
function handlePlay(e){
    e.target.params = {...e.target.params, fullscreen: 1}
    window.Storylane.Play({type: 'popup',demo_type: 'image', width: 2560, height: 1340.952380952381, element: e.target, demo_url: 'https://app.storylane.io/demo/sn4yc7am1qqe'});
}
return (
<GModal
     visible={visible} 
     closeModal={handleClose} 
     bottomButtons={[]}
     heading="Welcome to FreJun 🎉"
     headingStyle={{backgroundColor:'#fff',borderBottom:'none', marginLeft: "min(400px, calc(50vw - 175px)",paddingTop:'8px'}}
     modalStyle={{paddingTop:'0px'}}
     bodyWrapperStyle={{width: "min(1000px, calc(100vw - 150px))", height: "fit-content", minWidth: '350px'}}
     body={
            <div>
              <div className="sl-embed-container s1-embed-container-styles">
                <div className="sl-preview-heading s1-preview-heading-styles" >
                  <button onClick={handlePlay.bind(this)} className="sl-preview-cta s1-preview-cta-styles">VIEW DEMO<div className="sl-preview-cta-ripple s1-preview-cta-ripple-styles" ><div className="sl-preview-cta-ripple-shadow s1-preview-cta-ripple-shadow-styles"></div></div></button>
                  </div>
                <div className="sl-embed s1-embed-styles" data-sl-demo-type="image">
                  <div className="sl-preview s1-preview-styles"></div>
                  <iframe className="sl-demo s1-demo-styles" src="" name="sl-embed" allow="fullscreen"/>
                </div>
              </div>
          </div>
     }/>
)
}

export default WelcomeModal;