import './signupScreen6Styles.css'

import vectorArt from '../../assets/signup-screen2.svg'
import dotsBottom from '../../assets/login-dots-bottom.svg'
import dotsTop from '../../assets/login-dots-top.svg'
import logo from '../../assets/top-nav-logo.svg'

import { useState, useEffect } from 'react'

import { login } from '../features/user/user'

import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'


import Button from '../design/Button'
import colors from '../design/colors'
import { JService, refreshAccessToken } from '../api-service/ApiService'
import AnimationPage from '../shared/animation-page/AnimationPage'
import { commonStyles } from './commonStyles'
import './commonStyles.css'
import { Select, MenuItem } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { signupScreen3ButtonBackIcon, topNavAccountSelectedAccountTickIcon } from '../../utility-functions/assetLoader'
import { enqueueSnackbar, closeSnackbar } from 'notistack'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { pushToClevertap } from '../../utility-functions/clevertap'
import clevertap from 'clevertap-web-sdk'
import { saveAccountDetails } from '../features/account/account'

const SignupScreen6 = ({handleToggleWidget}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userDetails = useSelector(state => state.user.value)
    const userLoginAttemptWith = useSelector(state => state.user.value)
    const reduxStoreArg = useSelector(state => state)

    const [numberType, setNumberType] = useState(null);
    const [virtualNumber, setVirtualNumber] = useState(null);
    const [availableVns, setAvailableVns] = useState([]);
    const [vnsToDisplay, setVnsToDisplay] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [isLoading, setIsLoading] = useState(false)


    useEffect(() => {
        fetchTrialVirtualNumbers();
    }, []);

    useEffect(()=>{
      if(!numberType || !availableVns) return;
      const temp=availableVns?.filter((vn)=>{
        if(numberType==='India number')
          return ['Noida','Bengaluru','Hyderabad','Mumbai','Vijayawada'].includes(vn.location)
        else{
          const locations={USA:"United States",UK:"United Kingdom",Canada:'Canada'}
          return locations[numberType.split(' ')[0]].includes(vn.country)
        }
      })
      setVnsToDisplay(temp)
    },[numberType,availableVns])

    const fetchAccountProfile=()=>{
    
        JService.get("/api/v1/auth/retrieve-account-profile/")
          .then((res) => {
            if (!res?.success) throw Error(res?.message || "Failed to fetch account details");
            dispatch(saveAccountDetails(res));
    
            //@clevertap update
            const userDetails = res?.data;
    
            let role =
              userDetails?.profile?.role == 1
                ? "super admin"
                : userDetails?.profile?.role == 4
                ? "admin"
                : userDetails?.profile?.role == 2
                ? "user"
                : userDetails?.profile?.role == 3
                ? "viewer"
                : "unknown"; // eslint-disable-line
            let integrations = "";
            if (userDetails?.profile?.hubspot_access) {
              integrations += "hubspot";
            }
    
            if (userDetails?.profile?.monday_access) {
              if (!integrations) integrations += "monday";
              else integrations += ", monday";
            }
            if (userDetails?.profile?.deskera_access) {
              if (!integrations) integrations += "deskera";
              else integrations += ", deskera";
            }
    
            if (userDetails?.profile?.pipedrive_access) {
              if (!integrations) integrations += "pipedrive";
              else integrations += ", pipedrive";
            }
    
            if (userDetails?.profile?.zoho_access) {
              if (!integrations) integrations += "zoho";
              else integrations += ", zoho";
            }
    
            if (userDetails?.profile?.zoho_phonebridge_access) {
              if (!integrations) integrations += "zohophonebridge";
              else integrations += ", zohophonebridge";
            }
            let payload = {
              Name: userDetails?.first_name + " " + userDetails?.last_name, // fullname
              Identity: userDetails?.email, //email,
              Email: userDetails?.email,
    
              Phone:
                userDetails?.number?.country_code +
                "" +
                userDetails?.number?.phone_number, // string | number, // user phone number
              OrganizationName: userDetails?.profile?.org_name || "",
              OrganizationId: userDetails?.profile?.org_id,
              VirtualNumbers: JSON.stringify(userDetails?.virtual_numbers),
              SuperAdmin: userDetails?.account_holder ? "yes" : "no",
              Role: role,
              IsVerified: userDetails?.profile?.is_verified
                ? "verified"
                : "not verified",
              TotalMinutes: userDetails?.profile?.total_minutes,
              Integrations: integrations,
              InboundCalls: userDetails?.profile?.inbound_calls
                ? "enabled"
                : "disabled",
              UserEmail: userDetails?.email,
              UserPhoneNumber:
                userDetails?.number?.country_code +
                "" +
                userDetails?.number?.phone_number, // string | number, /
              BrowserCalls: userDetails?.profile?.browser_calls
                ? "enabled"
                : "disabled",
              NotifyEmails: userDetails?.profile?.notify_emails ? "true" : "false",
              MissedCallNotifications: userDetails?.profile
                ?.missed_call_notifications
                ? "true"
                : "false",
              PrimaryVirtualNumber: JSON.stringify(
                userDetails?.profile?.primary_virtual_number
              ),
              FeatureAddOns: JSON.stringify(
                userDetails?.profile?.feature_add_ons || []
              ),
            };
            clevertap.onUserLogin.push({ Site: payload });
            let country = res?.data?.profile?.primary_virtual_number?.location;
            country = ["Hyderabad", "Bengaluru", "Mumbai"].includes(country)
              ? "India"
              : country;
            pushToClevertap("New Account Created", true, {
              countryOfCalling: country,
            });
            
          })
          .catch((err) => {
            enqueueSnackbar(err?.message || "Error occurred while fetching account details", { variant: "error"})
            pushToClevertap("New Account Created", false, null, err);
          })
      }
    
    
    const handleSubmit = (e) => {
        e.preventDefault()
        if(!numberType || !virtualNumber) return;
          setIsLoading(true)
           JService.post('/api/v1/subscriptions/initiate-trial/', JSON.stringify({vn:virtualNumber?.number}))            
            .then(res => {
                if(!res?.success) throw new Error(res?.message || 'Failed to update numberType and virtual number details')
			    //dispatch(login(userLoginAttemptWith))
                console.log("intiate trial response")
                setTimeout(async ()=>{
                  fetchAccountProfile()
                //   navigate('/signup/integrate')
                  await refreshAccessToken();
                  navigate('/dashboard',{replace:true,state:{from:'signup'}});
                  setIsLoading(false)
                dispatch(login(userLoginAttemptWith))
                },3500)
                
            })
            .catch(err => enqueueSnackbar(err?.message, { variant: "error"}))
    }

    const fetchTrialVirtualNumbers=()=>{
        setShowLoader(true);
        JService.get('/api/v1/auth/get-trial-vns')          
          .then((res)=>{
            if(!res.success){
                throw new Error(res?.message || 'Failed to retrieve virtual numbers');
            }
            setAvailableVns(res?.data || []);
        })
        .catch(err => enqueueSnackbar(err?.message, { variant: "error" }))
        .finally(() => setShowLoader(false))
}




    const handleSnackbarClose = () => {
        closeSnackbar();

    };

    return (
        <div className='signup-screen-container signup-screen6-container color-2d'>
            <div className='signup-art'>
            <a href="https://frejun.com/" target="_blank" rel="noopener noreferrer"> <img src={logo} alt="Logo" /></a>
                <img src={dotsTop} />
                <img src={vectorArt} />
                <img src={dotsBottom} />
            </div>
            {showLoader && <div className='loader-container'><AnimationPage /></div>}
            {!showLoader && (
                <div className='signup-screen-form'>
                    <p className='title t4 medium-font s6font'>Select virtual number for trial</p>
                    <form onSubmit={e => handleSubmit(e)} className=' screen6-form'>
                        <div className="select-container">
                            <label className="t7 medium-font">Number type</label>
                            <Select
                                IconComponent={ExpandMoreIcon}
                                sx={commonStyles.selectStyles}
                                MenuProps={commonStyles.selectMenuStyles}
                                placeholder="Select type"
                                value={numberType}
                                native={false}
                                displayEmpty
                                renderValue={(val) => {

                                    return (
                                        val ? <span style={{ color: colors.nc_gray_900 }}>{val}</span> : <span style={{ color: colors.nc_gray_400 }}>Select type</span>
                                    )
                                }}
                                onChange={(e) => {
                                    setVirtualNumber(null);
                                    setNumberType(e.target.value)
                                   if(vnsToDisplay.length===0 || !numberType || !virtualNumber)
                                   enqueueSnackbar("Unable to select virtual number? ", {
                                    style: {
                                        backgroundColor: "white",
                                        color: "black",
                                        border: "1px solid #34cb65",
                                        height: '56px',
                                        fontWeight: '500px',
                                        fontSize: '16px',
                                        lineHeight: '24px',
    
                                    },
                                    
                                    action: (
                                        <>
                                       <div onClick={handleToggleWidget} className='snackbar' style={{ color: '#34cb65', cursor: 'pointer',marginRight:'249px' ,textDecoration: 'underline'}}><div></div>Contact&nbsp;Us</div>
                                       <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                onClick={handleSnackbarClose}
                                            >
                                                <CloseIcon sx={{ height: '20px', width: '20px' }} />
                                            </IconButton>
                                        </>
                                    ),
                                })

                                }}
                            >
                                {['India number', 'USA number', 'UK number', 'Canada number'].map((elem, index) => {
                                    return (
                                        <MenuItem sx={commonStyles.selectMenuItemStyles} value={elem} key={index}>
                                            <span className='t7 regular-font'>{elem}</span>{numberType === elem && <span>{topNavAccountSelectedAccountTickIcon()}</span>}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </div>
                        <div className="select-container">
                            <label className="t7 medium-font">Virtual number</label>
                            <Select
                                IconComponent={ExpandMoreIcon}
                                sx={commonStyles.selectStyles}
                                MenuProps={commonStyles.selectMenuStyles}
                                placeholder="Select number"
                                value={virtualNumber}
                                native={false}
                                displayEmpty
                                renderValue={(val) => {

                                    return (
                                        val ? <span style={{ color: colors.nc_gray_900 }}>{val.country_code + ' ' + val.number}</span> : <span style={{ color: colors.nc_gray_400 }}>Select number</span>
                                    )
                                }}
                                onChange={(e) => setVirtualNumber(e.target.value)}
                            >
                                {vnsToDisplay?.map((elem, index) => {
                                    return (
                                        <MenuItem sx={commonStyles.selectMenuItemStyles} value={elem} key={index}>
                                            <span className='t7 regular-font'>{elem.country_code + ' ' + elem.number}</span>{virtualNumber?.number === elem.number && <span>{topNavAccountSelectedAccountTickIcon()}</span>}
                                        </MenuItem>
                                    );
                                })}

                                {(!vnsToDisplay || vnsToDisplay.length === 0) && !numberType &&
                                    <MenuItem sx={commonStyles.selectMenuItemStyles} disabled>
                                        <span className='t7 light-font'>Select a number type</span>
                                    </MenuItem>}
                            </Select>
                        </div>
                        <p>
                            <span className='t7 medium-font note'>Note: </span>
                            <span className='t7 regular-font note'>This virtual number is just for the trial, you can choose a new number when you subscribe to a paid plan.</span>
                        </p>
                        <div className='buttons-container btnscreen6'>
                            <Button className='s6btn' isLoading={isLoading || showLoader} hierarchy="green" buttonText="Next" onClick={handleSubmit}  />
                        </div>
                    </form>
                 

                </div>)}

        </div>
    )
}

export default SignupScreen6;
