import './deniedStyles.css'
import accessDeniedArt from '../../assets/access-denied-art.svg'
import { useNavigate } from 'react-router-dom'

export default function Denied() {
    const navigate = useNavigate()
    const handleLoginClick = e => {
        localStorage.setItem('frejun-login-redirect', JSON.stringify({url: window.location.pathname, isRead:false}))
        navigate('/login')
    }
    return(
        <div className='access-denied-container nc-gray-900 regular-font t6'>
            <img src={accessDeniedArt}/>
            <p className='medium-font t5 '>Login to access</p>
            <p className='medium-font t6'>Log in to your FreJun account to access recording.</p>
            <button onClick={handleLoginClick} className='btn btn-green medium-font t6'>Login</button>
        </div>
    )
}
